import { BayLevelEnum, } from "open-vessel-definition";
import { createInputWithUnits, } from "@baplie-viewer2/tedivo-form";
import { pad3, sortByMultipleFields, } from "@baplie-viewer2/tedivo-pure-helpers";
import FieldsValuesStore from "../../../../../app/stores/FieldsValuesStore";
import { getTranslation } from "../../../../../app/i18/i18tn";
import globalUnits from "../../../../../app/units/globalUnits";
import goSquared from "../../../../../app/tracking/goSquared";
import ovdJsonStore from "../../../../../app/stores/OVDJsonStore";
export function createBulkheadsEdit(sizeSummary, bls, editDrawer) {
    goSquared.addEvent("Edit-OVD - Edit Bulkheads");
    const holder = document.createElement("div");
    const editingArea = document.createElement("section");
    editingArea.className = "editing-area";
    const dataStore = new FieldsValuesStore();
    createEditingFields(sizeSummary, bls, editingArea, dataStore, editDrawer);
    dataStore.updateConsumers();
    holder.appendChild(editingArea);
    const submitFunction = () => {
        for (let iBay = 1; iBay <= sizeSummary.isoBays; iBay += 2) {
            const isoBay = pad3(iBay);
            let bl = bls.find((bl) => bl.level === BayLevelEnum.BELOW && bl.isoBay === isoBay);
            const newBulkhead = dataStore.getValuesOfParent(isoBay);
            if (newBulkhead.aft === 0) {
                newBulkhead.aft = undefined;
                newBulkhead.aftLcg = undefined;
            }
            if (newBulkhead.fore === 0) {
                newBulkhead.fore = undefined;
                newBulkhead.foreLcg = undefined;
            }
            const finalBulkhead = newBulkhead.aft !== undefined || newBulkhead.fore !== undefined
                ? newBulkhead
                : undefined;
            if (bl === undefined) {
                if (finalBulkhead !== undefined) {
                    console.log("bl is undefined", isoBay);
                    bl = {
                        isoBay: isoBay,
                        level: BayLevelEnum.BELOW,
                        infoByContLength: {},
                        bulkhead: finalBulkhead,
                    };
                    bls.push(bl);
                }
            }
            else {
                bl.bulkhead = finalBulkhead;
            }
        }
        bls.sort(sortByMultipleFields([
            { name: "isoBay", ascending: true },
            { name: "level", ascending: true },
        ]));
        goSquared.addEvent("Edit-OVD - Edit Bulkheads - Save");
        ovdJsonStore.emitChange();
        return true;
    };
    return { node: holder, submitFunction };
}
function createEditingFields(sizeSummary, bls, editingArea, dataStore, editDrawer) {
    var _a, _b, _c, _d;
    //0. Data
    const bulkheadsByBay = {};
    bls.forEach((bl) => {
        if (bl.level === BayLevelEnum.BELOW && bl.bulkhead)
            bulkheadsByBay[bl.isoBay] = bl.bulkhead;
    });
    // 1. Bulkheads
    const table = document.createElement("table");
    const tHead = document.createElement("thead");
    const tBody = document.createElement("tbody");
    table.className = "tvd-table tvd-table-sticky";
    const cols = [
        "general:grid.isoBay",
        "enums:ForeAftEnum.AFT",
        "view:edit.bulkheads.aftLcg",
        "enums:ForeAftEnum.FWD",
        "view:edit.bulkheads.foreLcg",
    ];
    // 1.1 Headers
    const trh = document.createElement("tr");
    cols.forEach((colLabel) => {
        const td = document.createElement("th");
        const sp = document.createElement("span");
        sp.innerHTML = getTranslation(colLabel);
        sp.className = colLabel.replace(/[.|:]/gm, "-");
        td.appendChild(sp);
        trh.appendChild(td);
    });
    tHead.appendChild(trh);
    for (let iBay = 1; iBay <= sizeSummary.isoBays; iBay += 2) {
        const baseKey = pad3(iBay);
        const tr = document.createElement("tr");
        const label = document.createElement("td");
        label.innerHTML = baseKey;
        tr.appendChild(label);
        // 1.1.1 AFT checkbox
        const hasAft = !!dataStore.getValue(`${baseKey}.aft`) ||
            !!((_a = bulkheadsByBay[baseKey]) === null || _a === void 0 ? void 0 : _a.aft) ||
            false;
        const td1 = document.createElement("td");
        td1.className = "centered";
        const inp1 = document.createElement("sl-switch");
        inp1.name = `${baseKey}.aft`;
        inp1.checked = hasAft;
        inp1.tabIndex = 0;
        dataStore.registerCheckbox(inp1);
        td1.appendChild(inp1);
        tr.appendChild(td1);
        // 1.1.2 AFT Lcg Number
        const td2 = document.createElement("td");
        const inp2 = createInputWithUnits({
            name: `${baseKey}.aftLcg`,
            value: dataStore.getValueAsNumber(`${baseKey}.aftLcg`) ||
                ((_b = bulkheadsByBay[baseKey]) === null || _b === void 0 ? void 0 : _b.aftLcg),
            converter: globalUnits.lengthUnits,
            transponser: globalUnits.lcgTransposer,
            disabled: !hasAft,
        });
        dataStore.registerInputField(inp2);
        td2.appendChild(inp2);
        tr.appendChild(td2);
        connectCheckboxAndNumericField(inp1, inp2);
        // 1.1.3 FWD checkbox
        const hasFwd = !!dataStore.getValue(`${baseKey}.fore`) ||
            !!((_c = bulkheadsByBay[baseKey]) === null || _c === void 0 ? void 0 : _c.fore) ||
            false;
        const td3 = document.createElement("td");
        td3.className = "centered";
        const inp3 = document.createElement("sl-switch");
        inp3.name = `${baseKey}.fore`;
        inp3.checked = hasFwd;
        inp3.tabIndex = 0;
        dataStore.registerCheckbox(inp3);
        td3.appendChild(inp3);
        tr.appendChild(td3);
        // 1.1.4 FWD Lcg Number
        const td4 = document.createElement("td");
        const inp4 = createInputWithUnits({
            name: `${baseKey}.foreLcg`,
            value: dataStore.getValueAsNumber(`${baseKey}.foreLcg`) ||
                ((_d = bulkheadsByBay[baseKey]) === null || _d === void 0 ? void 0 : _d.foreLcg),
            converter: globalUnits.lengthUnits,
            transponser: globalUnits.lcgTransposer,
            disabled: !hasFwd,
        });
        dataStore.registerInputField(inp4);
        td4.appendChild(inp4);
        tr.appendChild(td4);
        connectCheckboxAndNumericField(inp3, inp4);
        tBody.appendChild(tr);
    }
    table.appendChild(tHead);
    table.appendChild(tBody);
    editingArea.appendChild(table);
    dataStore.onChange = () => {
        editDrawer.dataIsDirty = true;
    };
    function connectCheckboxAndNumericField(target, input) {
        function enableNumericField() {
            input.disabled = !target.checked;
        }
        target.addEventListener("sl-change", enableNumericField, false);
        target.addEventListener("keydown", onCheckboxKeyPressed, false);
    }
    function onCheckboxKeyPressed(ev) {
        const t = ev.target;
        if (t.disabled)
            return;
        if (ev.key === " ") {
            ev.preventDefault();
            t.checked = !t.checked;
            t.dispatchEvent(new Event("sl-change"));
        }
    }
}
