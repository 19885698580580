export function createSvgPath({
  strokeColor,
  strokeWidth,
  fillOpactiy,
}: {
  strokeColor?: string;
  strokeWidth?: number;
  fillOpactiy?: number;
}) {
  const svgPath = document.createElementNS(
    "http://www.w3.org/2000/svg",
    "path",
  );

  svgPath.setAttribute("stroke", strokeColor || "red");
  svgPath.setAttribute("fill-opacity", String(fillOpactiy || 0));
  svgPath.setAttribute("stroke-width", String(strokeWidth || 1));

  return svgPath;
}
