import { stafToOvdShipData } from "open-vessel-definition";
export default function stafFileReadShipData(f) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onload = (ev) => {
            try {
                const target = ev.target;
                const targetResult = target.result;
                if (targetResult === null) {
                    reject({ message: "File Read error" });
                    return;
                }
                const stafFile = targetResult.toString();
                const json = stafToOvdShipData(stafFile);
                if (json.shipClass && json.positionFormat && json.lcgOptions) {
                    resolve(json);
                }
                else {
                    const error = {
                        errorCode: "notAStafFile",
                        translationKey: "errors:notValidStafFile",
                        message: "Not a valid STAF file",
                    };
                    reject(error);
                    console.log(error.message);
                }
            }
            catch (err) {
                reject(err);
            }
        };
        reader.onerror = (ev) => {
            var _a;
            reject((_a = ev.target) === null || _a === void 0 ? void 0 : _a.error);
        };
        reader.readAsText(f, "utf-8");
    });
}
