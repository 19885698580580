import {
  CONTAINER_LENGTHS,
  ForeAftEnum,
  PositionFormatEnum,
} from "open-vessel-definition";
import {
  EnumHelpers,
  numberIsEven,
  numberIsOdd,
} from "@baplie-viewer2/tedivo-pure-helpers";

import { IEditSizeFields } from "../../../app/stores/OVDJsonStore";
import { IFields } from "@baplie-viewer2/tedivo-form";
import PairingHelpersEnum from "../../../app/enums/PairingHelpersEnum";
import TelescopingHelpersEnum from "../../../app/enums/TelescopingHelpersEnum";
import UoLcgReferenceEnum from "../../../app/enums/UoLcgReferenceEnum";
import { getTranslation } from "../../../app/i18/i18tn";
import globalUnits from "../../../app/units/globalUnits";
import { z } from "zod";

const positionFormatOptions = EnumHelpers.getNamesAndValues(PositionFormatEnum)
  .map(({ value, name }) => ({
    value,
    label: getTranslation(`enums:PositionFormatEnum.${name}`),
  }))
  .filter(
    (a) =>
      a.value === PositionFormatEnum.BAY_STACK_TIER ||
      a.value === PositionFormatEnum.BAY_TIER_STACK
  );

const paringHelpers = EnumHelpers.getNamesAndValues(PairingHelpersEnum).map(
  ({ value, name }) => ({
    value,
    label: getTranslation(`enums:PairingHelpersEnum.${name}`),
  })
);

const telescopingHelpers = EnumHelpers.getNamesAndValues(
  TelescopingHelpersEnum
).map(({ value, name }) => ({
  value,
  label: getTranslation(`enums:TelescopingHelpersEnum.${name}`),
}));

const aftFwdOptions = [
  {
    value: ForeAftEnum.AFT,
    label: getTranslation(`enums:ForeAftEnum.AFT`),
  },
  {
    value: ForeAftEnum.FWD,
    label: getTranslation(`enums:ForeAftEnum.FWD`),
  },
  { value: 0, label: getTranslation(`general:common.none`) },
];

const lcgRefOptions = EnumHelpers.getNamesAndValues(UoLcgReferenceEnum).map(
  ({ value, name }) => ({
    value,
    label: getTranslation(`enums:UoLcgReferenceEnum.${name}`),
  })
);

export function createFormFields(allFields = false): IFields<INewJsonFields> {
  const initialFields: IFields<INewJsonFields> = [
    {
      name: "",
      type: "title",
      label: "general:common.identification",
      createSection: true,
    },
    {
      name: "imoCode",
      label: "view:imoCode",
      type: "textBox",
      autocapitalize: true,
    },
  ];

  if (!allFields) return initialFields;

  const restOfFields: IFields<INewJsonFields> = [
    {
      name: "",
      type: "title",
      label: "view:shipDataTitle",
      createSection: true,
    },
    [
      {
        name: "shipName",
        label: "view:shipName",
        type: "textBox",
        autocapitalize: true,
      },
      {
        name: "lineOperator",
        label: "view:lineOperator",
        type: "textBox",
        autocapitalize: true,
      },
    ],
    [
      {
        name: "shipClass",
        label: "view:shipClass",
        type: "textBox",
        autocapitalize: true,
      },
      {
        name: "callSign",
        label: "view:callSign",
        type: "textBox",
        autocapitalize: true,
      },
      {
        name: "yearBuilt",
        label: "view:yearBuilt",
        type: "number",
      },
    ],
    [
      {
        name: "positionFormat",
        label: "view:positionFormat",
        fieldset: true,
        isNumericEnum: true,
        type: "radioButtonList",
        initialValue: PositionFormatEnum.BAY_STACK_TIER,
        options: positionFormatOptions,
      },
      {
        name: "containersLengths",
        label: "view:availableSizes",
        fieldset: true,
        isNumericEnum: true,
        type: "checkboxesList",
        initialValue: [20, 40, 45],
        options: CONTAINER_LENGTHS.map((v) => ({ value: v, label: `${v}'` })),
      },
    ],
    {
      name: "",
      type: "title",
      label: "view:size",
      createSection: true,
    },
    [
      {
        name: "isoBays",
        label: "general:grid.isoBays",
        type: "number",
        helpText: getTranslation("general:numberValidation.oddMin", {
          min: 1,
        }),
      },
      {
        name: "maxRow",
        label: "view:maxRow",
        type: "number",
        initialValue: 10,
        helpText: getTranslation("general:numberValidation.min", {
          min: 0,
        }),
      },
      {
        name: "centerLineRow",
        label: "view:centerLineRow",
        type: "checkbox",
        padStart: true,
        initialValue: false,
      },
    ],
    {
      name: "hasAboveDeck",
      label: "view:hasAboveDeck",
      type: "checkbox",
      initialValue: true,
    },
    [
      {
        name: "minAboveTier",
        label: "view:minAboveTier",
        type: "number",
        step: 2,
        initialValue: 80,
        helpText: getTranslation("general:numberValidation.evenMin", {
          min: 68,
        }),
      },
      {
        name: "maxAboveTier",
        label: "view:maxAboveTier",
        type: "number",
        step: 2,
        initialValue: 88,
        helpText: getTranslation("general:numberValidation.evenMin", {
          min: 68,
        }),
      },
    ],
    {
      name: "hasBelowDeck",
      label: "view:hasBelowDeck",
      type: "checkbox",
      initialValue: true,
    },
    [
      {
        name: "minBelowTier",
        label: "view:minBelowTier",
        type: "number",
        step: 2,
        initialValue: 0,
        helpText: getTranslation("general:numberValidation.evenMinMax", {
          min: 0,
          max: 66,
        }),
      },
      {
        name: "maxBelowTier",
        label: "view:maxBelowTier",
        type: "number",
        step: 2,
        initialValue: 10,
        helpText: getTranslation("general:numberValidation.evenMinMax", {
          min: 0,
          max: 66,
        }),
      },
    ],
    {
      name: "",
      type: "title",
      label: "view:specialFeatures",
      createSection: true,
    },
    {
      name: "restrictions",
      label: "view:featuresAllowed",
      fieldset: true,
      isNumericEnum: false,
      type: "checkboxesList",
      initialValue: [],
      options: [
        {
          value: "slotCoolStowProhibited",
          label: getTranslation("general:slotCapabilities.misc.h"),
        },
        {
          value: "slotHazardousProhibited",
          label: getTranslation("general:slotCapabilities.misc.k"),
        },
        {
          value: "slotConeRequired",
          label: getTranslation("general:slotCapabilities.conesPerSize"),
        },
      ],
    },
    {
      name: "",
      type: "title",
      label: "general:common.helpers",
      createSection: true,
    },
    {
      name: "prePopulateTelescoping",
      label: "general:prepopulate.telescoping",
      fieldset: true,
      isNumericEnum: true,
      type: "radioButtonList",
      initialValue: TelescopingHelpersEnum.ALL_TRUE,
      options: telescopingHelpers,
    },
    {
      name: "prePopulateBayPairings",
      label: "general:prepopulate.bayPairings",
      fieldset: true,
      isNumericEnum: true,
      type: "radioButtonList",
      initialValue: PairingHelpersEnum.BAY001_PAIRS_AFT,
      options: paringHelpers,
    },
    [
      {
        name: "doors",
        label: "general:prepopulate.doors",
        type: "radioButtonList",
        isNumericEnum: true,
        fieldset: true,
        initialValue: 0,
        options: aftFwdOptions,
      },
      {
        name: "reeferPlugs",
        label: "general:prepopulate.reeferPlugs",
        type: "radioButtonList",
        isNumericEnum: true,
        fieldset: true,
        initialValue: 0,
        options: aftFwdOptions,
      },
    ],
    {
      name: "",
      type: "title",
      label: "view:lcgReference",
      createSection: true,
    },
    {
      name: "lpp",
      label: "view:lpp",
      type: "number",
      initialValue: 0,
      helpText: getTranslation("view:lppNeededHelpTextNew"),
    },
    {
      name: "lcgReference",
      label: "view:lcgReferenceViewOnly",
      fieldset: true,
      isNumericEnum: true,
      type: "radioButtonList",
      initialValue: globalUnits.lcgTransposer.transposeTo,
      options: lcgRefOptions,
    },
  ];

  return [...initialFields, ...restOfFields];
}

export function createFormValidator(
  allFields = false
): z.Schema<INewJsonFields> {
  if (!allFields) {
    return z.object({
      imoCode: z
        .string()
        .min(6)
        .max(9)
        .transform((v) => v?.toUpperCase())
        .refine(
          (imoCode) =>
            /^\d{6,8}$/.test(imoCode) || /^[A-Z]{1,2}\d{6,7}$/.test(imoCode)
        ),
    }) as unknown as z.Schema<INewJsonFields>;
  } else {
    return z
      .object({
        shipClass: z
          .string()
          .min(1)
          .transform((v) => v?.toUpperCase()),
        shipName: z
          .string()
          .min(1)
          .transform((v) => v?.toUpperCase()),
        lineOperator: z
          .string()
          .optional()
          .transform((v) => v?.toUpperCase()),
        callSign: z
          .string()
          .optional()
          .transform((v) => v?.toUpperCase()),
        imoCode: z
          .string()
          .min(6)
          .max(9)
          .transform((v) => v?.toUpperCase())
          .refine(
            (imoCode) =>
              /^\d{6,8}$/.test(imoCode) || /^[A-Z]{1,2}\d{6,7}$/.test(imoCode)
          ),
        positionFormat: z.number(),
        yearBuilt: z.number().optional(),
        isoBays: z.number().min(1).refine(numberIsOdd),
        centerLineRow: z.boolean().optional(),
        maxRow: z.number().min(1).max(30),
        maxAboveTier: z
          .number()
          .min(68)
          .max(140)
          .refine(numberIsEven)
          .optional(),
        minAboveTier: z
          .number()
          .min(68)
          .max(140)
          .refine(numberIsEven)
          .optional(),
        maxBelowTier: z.number().min(0).max(66).refine(numberIsEven).optional(),
        minBelowTier: z.number().min(0).max(66).refine(numberIsEven).optional(),
        hasAboveDeck: z.boolean(),
        hasBelowDeck: z.boolean(),
        containersLengths: z.array(z.number()),
        restrictions: z.array(z.string()),
        prePopulateBayPairings: z.number(),
        prePopulateTelescoping: z.number(),
        doors: z.number(),
        reeferPlugs: z.number(),
        loa: z.number().optional(),
        lpp: z.number(),
        lcgReference: z.number(),
      })
      .refine(
        (d) =>
          !d.hasAboveDeck ||
          (d.minAboveTier !== undefined &&
            d.maxAboveTier !== undefined &&
            d.minAboveTier <= d.maxAboveTier),
        {
          path: ["minAboveTier", "maxAboveTier"],
        }
      )
      .refine(
        (d) =>
          !d.hasBelowDeck ||
          (d.minBelowTier !== undefined &&
            d.maxBelowTier !== undefined &&
            d.minBelowTier <= d.maxBelowTier),
        {
          path: ["minBelowTier", "maxBelowTier"],
        }
      )
      .refine(
        (d) => d.hasAboveDeck || d.hasBelowDeck,
        (v) => ({ path: [!v.hasAboveDeck ? "hasAboveDeck" : "hasBelowDeck"] })
      )
      .refine(
        (d) =>
          d.lcgReference === UoLcgReferenceEnum.AFT_PERPENDICULAR_DFWD ||
          (d.lcgReference !== UoLcgReferenceEnum.AFT_PERPENDICULAR_DFWD &&
            d.lpp > 0),
        { path: ["lpp"] }
      );
  }
}

export interface INewJsonFields extends IEditSizeFields {
  lineOperator?: string;
  shipName?: string;
  callSign?: string;
  imoCode?: string;
  shipClass: string;
  yearBuilt?: number;
  positionFormat: PositionFormatEnum;
  containersLengths: number[];
  restrictions: string[];
  prePopulateBayPairings: number;
  prePopulateTelescoping: number;
  doors: number;
  reeferPlugs: number;
  loa?: number;
  lpp: number;
  lcgReference: number;
}
