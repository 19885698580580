/* eslint-disable @typescript-eslint/no-explicit-any */

export const RECAPTCHA_SITE_KEY_V3 = "6Led2GAlAAAAABUGILEfPwL4JdYAHgubtKK4lBDL";
export const RECAPTCHA_SITE_KEY_V2_WIDGET =
  "6Le25XAnAAAAAGNmcWvg3sVvNLm_N3TRdoPHEQmx";

const googleRecaptcha = {
  isActive: false,

  initialize(v: "v2" | "v3" = "v2") {
    googleRecaptcha.isActive = true;

    function loadScript(v: "v2" | "v3", l: any, a: any, r: any) {
      const src =
        v === "v3"
          ? `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY_V3}`
          : `https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit`;

      const t = l.createElement(r);

      t.async = 1;
      t.defer = 1;
      t.id = a;
      t.src = src;

      const y = l.getElementsByTagName(r)[0];

      y.parentNode.insertBefore(t, y);
    }

    if (v === "v2") {
      window.onloadCallback = () => {
        // EMPTY
      };
    }

    loadScript(v, document, "googleRecaptcha", "script");
  },
};

export default googleRecaptcha;
