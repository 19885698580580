import { __awaiter } from "tslib";
import { EnumHelpers, sortNumericAsc, } from "@baplie-viewer2/tedivo-pure-helpers";
import { PositionFormatEnum, getContainerLengths, } from "open-vessel-definition";
import { TedivoForm, translateTedivoForm, } from "@baplie-viewer2/tedivo-form";
import ovdJsonStore from "../../../../../app/stores/OVDJsonStore";
import { CONTAINER_LENGTHS } from "open-vessel-definition/build/src/models/v1/parts/Types";
import { getTranslation } from "../../../../../app/i18/i18tn";
import goSquared from "../../../../../app/tracking/goSquared";
import { z } from "zod";
const FormValidator = z.object({
    lineOperator: z
        .string()
        .optional()
        .transform((v) => v === null || v === void 0 ? void 0 : v.toUpperCase()),
    shipClass: z
        .string()
        .min(1)
        .transform((v) => v === null || v === void 0 ? void 0 : v.toUpperCase()),
    shipName: z
        .string()
        .min(1)
        .transform((v) => v === null || v === void 0 ? void 0 : v.toUpperCase()),
    callSign: z
        .string()
        .optional()
        .transform((v) => v === null || v === void 0 ? void 0 : v.toUpperCase()),
    imoCode: z
        .string()
        .optional()
        .transform((v) => v === null || v === void 0 ? void 0 : v.toUpperCase()),
    //shipNameAkaStr: z.string().optional(),
    yearBuilt: z.number().optional(),
    positionFormat: z.nativeEnum(PositionFormatEnum),
    containersLengths: z.any().array(),
    featuresAllowed: z.array(z.string()),
});
export function createGeneralEdit(shipData, bls, submitButton) {
    var _a, _b, _c;
    goSquared.addEvent("Edit-OVD - Edit General Info");
    const holder = document.createElement("div");
    const positionFormatOptions = EnumHelpers.getNamesAndValues(PositionFormatEnum).map(({ value, name }) => ({
        value,
        label: getTranslation(`enums:PositionFormatEnum.${name}`),
    }));
    let containersLengths = shipData.containersLengths || [];
    if (containersLengths.length === 0) {
        containersLengths = getContainerLengths(bls);
    }
    const initialFeaturesAllowed = [
        ((_a = shipData.featuresAllowed) === null || _a === void 0 ? void 0 : _a.slotCoolStowProhibited)
            ? "slotCoolStowProhibited"
            : "",
        ((_b = shipData.featuresAllowed) === null || _b === void 0 ? void 0 : _b.slotHazardousProhibited)
            ? "slotHazardousProhibited"
            : "",
        ((_c = shipData.featuresAllowed) === null || _c === void 0 ? void 0 : _c.slotConeRequired) ? "slotConeRequired" : "",
    ].filter(Boolean);
    const formFields = [
        [
            {
                name: "lineOperator",
                label: "view:lineOperator",
                type: "textBox",
                autocapitalize: true,
                initialValue: shipData.lineOperator,
            },
            {
                name: "shipClass",
                label: "view:shipClass",
                type: "textBox",
                autocapitalize: true,
                initialValue: shipData.shipClass,
            },
            {
                name: "shipName",
                label: "view:shipName",
                type: "textBox",
                autocapitalize: true,
                initialValue: shipData.shipName,
            },
        ],
        [
            {
                name: "imoCode",
                label: "view:imoCode",
                type: "textBox",
                autocapitalize: true,
                initialValue: shipData.imoCode,
            },
            {
                name: "callSign",
                label: "view:callSign",
                type: "textBox",
                autocapitalize: true,
                initialValue: shipData.callSign,
            },
            {
                name: "yearBuilt",
                label: "view:yearBuilt",
                type: "number",
                initialValue: shipData.yearBuilt,
            },
        ],
        {
            name: "positionFormat",
            label: "view:positionFormat",
            fieldset: true,
            isNumericEnum: true,
            type: "radioButtonList",
            initialValue: shipData.positionFormat,
            options: positionFormatOptions,
        },
        {
            name: "containersLengths",
            label: "view:availableSizes",
            helpText: "view:availableSizesDisabled",
            fieldset: true,
            isNumericEnum: true,
            type: "checkboxesList",
            initialValue: containersLengths,
            options: CONTAINER_LENGTHS.map((v) => ({ value: v, label: `${v}'` })),
            disabled: getContainerLengths(bls),
        },
        {
            name: "featuresAllowed",
            label: "view:featuresAllowed",
            fieldset: true,
            isNumericEnum: false,
            type: "checkboxesList",
            initialValue: initialFeaturesAllowed,
            options: [
                {
                    value: "slotHazardousProhibited",
                    label: getTranslation("general:slotCapabilities.misc.h"),
                },
                {
                    value: "slotCoolStowProhibited",
                    label: getTranslation("general:slotCapabilities.misc.k"),
                },
                {
                    value: "slotConeRequired",
                    label: getTranslation("general:slotCapabilities.conesPerSize"),
                },
            ],
        },
        // {
        //   name: "shipNameAkaStr",
        //   label: "view:shipNameAkaStr",
        //   type: "textArea",
        //   rows: 3,
        //   initialValue: shipData.shipNameAkas?.join("\n") || "",
        // },
    ];
    const tedivoForm = new TedivoForm({
        fields: formFields,
        onSubmit: () => undefined,
        formValidator: FormValidator,
        submitButton: submitButton,
        autoFocusOnFirstInput: true,
    });
    translateTedivoForm({
        tedivoForm,
        getTranslation: getTranslation,
    });
    holder.appendChild(tedivoForm.form);
    return {
        node: holder,
        tedivoForm,
        submitFunction: submitPassedToEditDrawer,
    };
    function submitPassedToEditDrawer() {
        return __awaiter(this, void 0, void 0, function* () {
            const validResult = tedivoForm.doSubmitForm();
            if (validResult.success) {
                const values = validResult.data;
                values.containersLengths.sort(sortNumericAsc);
                goSquared.addEvent("Edit-OVD - Edit General Info - Save");
                ovdJsonStore.setShipDataGeneralInfo(values);
                return true;
            }
            else {
                return false;
            }
        });
    }
}
