import { IOpenVesselDefinitionV1 } from "open-vessel-definition";

export default function readOVDJsonFile(
  f: File,
): Promise<IOpenVesselDefinitionV1> {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = (ev: ProgressEvent) => {
      try {
        const target = ev.target as FileReader;
        const json = JSON.parse(
          target.result as string,
        ) as IOpenVesselDefinitionV1;

        if (isOvdSchema(json)) {
          resolve(json);
        } else {
          reject("errors:notValidOvdFile");
        }
      } catch (err) {
        reject(err);
      }
    };

    reader.onerror = (ev: ProgressEvent<FileReader>) => {
      reject(ev.target?.error);
    };

    reader.readAsText(f, "utf-8");
  });
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isOvdSchema(json: any): json is IOpenVesselDefinitionV1 {
  if (json.schema) {
    return (
      json.schema === "OpenVesselDefinition" &&
      json.version &&
      json.sizeSummary &&
      json.shipData
    );
  } else if (json.$schema) {
    return (
      (json.$schema ===
        "https://github.com/tedivo/OpenVesselDefinition/blob/master/schema.json" ||
        json.$schema ===
          "https://raw.githubusercontent.com/tedivo/OpenVesselDefinition/master/schema.json") &&
      (json.$schemaId === "IOpenVesselDefinitionV1" ||
        json.$schemaId === "OpenVesselDefinition") &&
      json.version &&
      json.sizeSummary &&
      json.shipData
    );
  }

  return false;
}
