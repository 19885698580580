import { __awaiter } from "tslib";
import HttpClient from "./HttpClient";
import { routeFns } from "../router/routes";
import router from "../router";
import securityModule from "../security/SecurityModule";
export default class NetworkService {
    static getMethod(url) {
        return __awaiter(this, void 0, void 0, function* () {
            // 0. Check if should be logged in
            const { idToken, sessionIsExpired } = securityModule;
            if (idToken && sessionIsExpired) {
                securityModule.signOut().then(() => {
                    router.navigate(routeFns.login(), undefined, true);
                });
            }
            // 1. Make the request
            const client = new HttpClient();
            return yield client.request({
                method: "GET",
                url,
            });
        });
    }
}
