import { createSelectShoelace, } from "@baplie-viewer2/tedivo-form";
import { createDictionary, formatDateInCurrentTimezone, safeDateString, } from "@baplie-viewer2/tedivo-pure-helpers";
import { getTranslation } from "../../app/i18/i18tn";
export function createVersionsDropdown({ selectedVersion, addCurrentVersion, versionsResponse, onChange, }) {
    var _a;
    if (!versionsResponse || versionsResponse.statusCode !== 200)
        return undefined;
    const versionsByV = createDictionary(versionsResponse.data || [], (v) => safeDateString(v.date));
    const options = [
        addCurrentVersion
            ? { name: getTranslation("view:comparer.currentVersion"), value: "" }
            : undefined,
        ...(versionsResponse.data || []).map((v) => ({
            name: labelOfVersion(v.date, v.vNumber),
            value: safeDateString(v.date),
        })),
    ].filter(Boolean);
    const select = createSelectShoelace({
        id: "versions",
        caret: true,
        ommitCheckSign: false,
        variant: "primary",
        size: "small",
        outlined: true,
        buttonText: labelOfVersion(selectedVersion, ((_a = versionsByV[selectedVersion]) === null || _a === void 0 ? void 0 : _a.vNumber) || 0),
        iconPrefix: "calendar3-week",
        options,
        onChange,
    });
    if (selectedVersion)
        select.value = safeDateString(selectedVersion);
    return select;
}
function labelOfVersion(dt, vNumber) {
    return `v${vNumber} - ${formatDateInCurrentTimezone(dt)}`;
}
