import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import { i18nReactive } from "../../../app/i18/i18tn";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";

export class TvdErrorElement extends HTMLElement {
  public static observedAttributes = [];
  private i18nCR: I18nComponentRegisterer;

  constructor() {
    super();
    this.i18nCR = new I18nComponentRegisterer(i18nReactive);
  }

  connectedCallback() {
    const wrapper = document.createElement("div"),
      h1 = document.createElement("h1"),
      text = document.createElement("div");

    this.i18nCR.addConsumer(h1, "errors:errorHasOcurred", "innerHTML");
    this.i18nCR.addConsumer(text, "errors:errorGeneral", "innerHTML");

    wrapper.className = "oss-centered-card";
    wrapper.appendChild(h1);
    wrapper.appendChild(text);

    this.appendChild(wrapper);

    const topMessageEl = topMessageElement.element;
    this.i18nCR.addConsumer(topMessageEl, "general:appName", "innerHTML");
  }

  disconnectedCallback() {
    this.i18nCR.disconnect();
  }
}

customElements.define("tvd-error-component", TvdErrorElement);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-error-component": TvdErrorElement;
  }
}
