export class TfHidden extends HTMLElement {
  public static observedAttributes = ["class"];

  private elInput: HTMLInputElement;

  private internalValue: string | number | undefined = undefined;
  private internalLabel?: string = "";
  private internalHelpText?: string = undefined;
  private internalId = "";

  public isNumeric = false;

  constructor() {
    super();

    this.elInput = document.createElement("input");
    this.elInput.type = "hidden";
  }

  get value(): string | number {
    return this.isNumeric
      ? Number(this.internalValue)
      : String(this.internalValue);
  }
  set value(s: string | number) {
    this.internalValue = s;
  }

  get label() {
    return this.internalLabel || "";
  }

  set label(v: string) {
    this.internalLabel = v;
  }

  get helpText() {
    return this.internalHelpText || "";
  }
  set helpText(v: string) {
    this.internalHelpText = v;
  }

  get name() {
    return this.internalId;
  }
  set name(s: string) {
    this.internalId = s;
  }

  get size() {
    return "medium";
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  set size(v: "medium" | "small" | "large") {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  focus() {}

  connectedCallback() {
    const shadow = this.attachShadow({ mode: "open" });
    shadow.appendChild(this.elInput);
  }
}

customElements.define("tf-hidden", TfHidden);

declare global {
  interface HTMLElementTagNameMap {
    "tf-hidden": TfHidden;
  }
}
