import { BayLevelEnum, } from "open-vessel-definition";
import { TedivoForm, translateTedivoForm, } from "@baplie-viewer2/tedivo-form";
import { numberIsEven, numberIsOdd, numberOrUndefined, sortNumericAsc, } from "@baplie-viewer2/tedivo-pure-helpers";
import ovdJsonStore from "../../../../../app/stores/OVDJsonStore";
import { getTranslation } from "../../../../../app/i18/i18tn";
import goSquared from "../../../../../app/tracking/goSquared";
import { z } from "zod";
export function createSizeEdit(sizeSummary, bls, submitButton) {
    var _a, _b, _c;
    goSquared.addEvent("Edit-OVD - Edit Size");
    const holder = document.createElement("div");
    const { summary: minimumSize, isoBaysInUse } = getMinimumSize();
    const FormValidator = z
        .object({
        isoBays: z.number().min(isoBaysInUse).refine(numberIsOdd),
        centerLineRow: z.boolean().optional(),
        maxRow: z
            .number()
            .min(minimumSize.maxRow || 1)
            .max(30),
        maxAboveTier: z
            .number()
            .min((_a = minimumSize.minAboveTier) !== null && _a !== void 0 ? _a : 68)
            .max(140)
            .refine(numberIsEven)
            .optional(),
        minAboveTier: z
            .number()
            .min(68)
            .max((_c = (_b = minimumSize.minAboveTier) !== null && _b !== void 0 ? _b : minimumSize.maxAboveTier) !== null && _c !== void 0 ? _c : 140)
            .refine(numberIsEven)
            .optional(),
        maxBelowTier: z
            .number()
            .min(minimumSize.maxBelowTier || 0)
            .max(66)
            .refine(numberIsEven)
            .optional(),
        minBelowTier: z
            .number()
            .min(0)
            .max(minimumSize.minBelowTier || 66)
            .refine(numberIsEven)
            .optional(),
        hasAboveDeck: z.boolean(),
        hasBelowDeck: z.boolean(),
    })
        .refine((d) => !d.hasAboveDeck ||
        (d.minAboveTier !== undefined &&
            d.maxAboveTier !== undefined &&
            d.minAboveTier <= d.maxAboveTier), {
        path: ["minAboveTier", "maxAboveTier"],
    })
        .refine((d) => !d.hasBelowDeck ||
        (d.minBelowTier !== undefined &&
            d.maxBelowTier !== undefined &&
            d.minBelowTier <= d.maxBelowTier), {
        path: ["minBelowTier", "maxBelowTier"],
    })
        .refine((d) => d.hasAboveDeck || d.hasBelowDeck, (v) => ({ path: [!v.hasAboveDeck ? "hasAboveDeck" : "hasBelowDeck"] }));
    const formFields = [
        [
            {
                name: "isoBays",
                label: "general:grid.isoBays",
                type: "number",
                initialValue: sizeSummary.isoBays || 1,
                helpText: getTranslation("general:numberValidation.oddMin", {
                    min: isoBaysInUse,
                }),
            },
            {
                name: "maxRow",
                label: "view:maxRow",
                type: "number",
                initialValue: numberOrUndefined(sizeSummary.maxRow),
                helpText: getTranslation("general:numberValidation.min", {
                    min: minimumSize.maxRow,
                }),
            },
            {
                name: "centerLineRow",
                label: "view:centerLineRow",
                type: "checkbox",
                padStart: true,
                initialValue: minimumSize.centerLineRow || !!sizeSummary.centerLineRow,
                disabled: minimumSize.centerLineRow,
                helpText: minimumSize.centerLineRow
                    ? getTranslation("view:centerLineRowDisabled")
                    : "",
            },
        ],
        {
            name: "hasAboveDeck",
            label: "view:hasAboveDeck",
            type: "checkbox",
            initialValue: minimumSize.hasAboveDeck,
            disabled: minimumSize.hasAboveDeck,
        },
        [
            {
                name: "minAboveTier",
                label: "view:minAboveTier",
                type: "number",
                step: 2,
                initialValue: numberOrUndefined(sizeSummary.minAboveTier),
                helpText: getTranslation("general:numberValidation.evenMinMax", {
                    min: 68,
                    max: minimumSize.minAboveTier,
                }),
            },
            {
                name: "maxAboveTier",
                label: "view:maxAboveTier",
                type: "number",
                step: 2,
                initialValue: numberOrUndefined(sizeSummary.maxAboveTier),
                helpText: getTranslation("general:numberValidation.evenMinMax", {
                    min: minimumSize.maxAboveTier,
                    max: 98,
                }),
            },
        ],
        {
            name: "hasBelowDeck",
            label: "view:hasBelowDeck",
            type: "checkbox",
            initialValue: minimumSize.hasBelowDeck,
            disabled: minimumSize.hasBelowDeck,
        },
        [
            {
                name: "minBelowTier",
                label: "view:minBelowTier",
                type: "number",
                step: 2,
                initialValue: numberOrUndefined(sizeSummary.minBelowTier),
                helpText: getTranslation("general:numberValidation.evenMinMax", {
                    min: 0,
                    max: minimumSize.minBelowTier,
                }),
            },
            {
                name: "maxBelowTier",
                label: "view:maxBelowTier",
                type: "number",
                step: 2,
                initialValue: numberOrUndefined(sizeSummary.maxBelowTier),
                helpText: getTranslation("general:numberValidation.evenMinMax", {
                    min: minimumSize.maxBelowTier,
                    max: 66,
                }),
            },
        ],
    ];
    const tedivoForm = new TedivoForm({
        fields: formFields,
        onSubmit: () => undefined,
        formValidator: FormValidator,
        submitButton: submitButton,
        autoFocusOnFirstInput: true,
    });
    translateTedivoForm({
        tedivoForm,
        getTranslation: getTranslation,
    });
    holder.appendChild(tedivoForm.form);
    return {
        node: holder,
        tedivoForm,
        submitFunction: submitPassedToEditDrawer,
    };
    function submitPassedToEditDrawer() {
        const validResult = tedivoForm.doSubmitForm();
        if (validResult.success) {
            goSquared.addEvent("Edit-OVD - Edit Size - Save");
            ovdJsonStore.setShipSize(validResult.data);
            return true;
        }
        return false;
    }
}
function getMinimumSize() {
    const currentJson = ovdJsonStore.currentJson;
    if (currentJson === undefined)
        return {
            summary: {
                isoBays: 1,
                centerLineRow: false,
                maxRow: 1,
                hasAboveDeck: false,
                hasBelowDeck: false,
            },
            isoBaysInUse: 1,
        };
    const summary = {
        isoBays: currentJson.sizeSummary.isoBays || 1,
        centerLineRow: false,
        maxRow: undefined,
        maxAboveTier: undefined,
        minAboveTier: undefined,
        maxBelowTier: undefined,
        minBelowTier: undefined,
        hasAboveDeck: false,
        hasBelowDeck: false,
    };
    let isoBaysInUse = 1;
    currentJson.baysData.forEach((bl) => {
        if (bl.perSlotInfo && Object.keys(bl.perSlotInfo).length)
            isoBaysInUse = Number(bl.isoBay);
        addBayToSummary(bl, summary);
    });
    summary.hasAboveDeck =
        compareExistingAndNew(currentJson.sizeSummary.maxAboveTier, summary.maxAboveTier, "max") !== undefined ||
            compareExistingAndNew(currentJson.sizeSummary.minAboveTier, summary.minAboveTier, "min") !== undefined;
    summary.hasBelowDeck =
        compareExistingAndNew(currentJson.sizeSummary.maxBelowTier, summary.maxBelowTier, "max") !== undefined ||
            compareExistingAndNew(currentJson.sizeSummary.minBelowTier, summary.minBelowTier, "min") !== undefined;
    return { summary, isoBaysInUse };
    function compareExistingAndNew(existing, newOne, fn) {
        if (existing === undefined && newOne === undefined)
            return undefined;
        if (existing === undefined)
            return newOne;
        if (newOne === undefined)
            return existing;
        return Math[fn](existing, newOne);
    }
}
function addBayToSummary(bl, summary) {
    // Tiers obtained from perSlotInfo
    const tiersFromSlotsInfo = bl.perSlotInfo;
    if (!tiersFromSlotsInfo)
        return;
    const allTiers = Object.keys(tiersFromSlotsInfo)
        .map((s) => Number(s.substring(2)))
        .sort(sortNumericAsc)
        .filter((v, idx, arr) => arr.indexOf(v) === idx);
    if (allTiers.length) {
        const maxTier = allTiers[allTiers.length - 1];
        const minTier = allTiers[0];
        if (bl.level === BayLevelEnum.ABOVE)
            if (bl.level === BayLevelEnum.ABOVE) {
                if (summary.maxAboveTier === undefined ||
                    summary.maxAboveTier < maxTier)
                    summary.maxAboveTier = maxTier;
                if (summary.minAboveTier === undefined ||
                    summary.minAboveTier > minTier)
                    summary.minAboveTier = minTier;
            }
            else if (bl.level === BayLevelEnum.BELOW) {
                if (summary.maxBelowTier === undefined ||
                    summary.maxBelowTier < maxTier)
                    summary.maxBelowTier = maxTier;
                if (summary.minBelowTier === undefined ||
                    summary.minBelowTier > minTier)
                    summary.minBelowTier = minTier;
            }
    }
    const rowsFromSlotsInfo = bl.perSlotInfo
        ? Object.keys(bl.perSlotInfo).map((s) => s.substring(0, 2))
        : [];
    const rowsFromRowInfo = bl.perRowInfo && bl.perRowInfo.each ? Object.keys(bl.perRowInfo.each) : [];
    // Concat and unique
    const allRows = rowsFromSlotsInfo
        .concat(rowsFromRowInfo)
        .filter((v, idx, arr) => arr.indexOf(v) === idx)
        .sort()
        .map(Number);
    const bayMaxRow = allRows[allRows.length - 1];
    const bayMinRow = allRows[0];
    // Centerline row, through row definitions
    if (!summary.centerLineRow && bayMinRow === 0)
        summary.centerLineRow = true;
    // Max row, through row definitions
    if (bayMaxRow !== undefined &&
        (summary.maxRow === undefined || summary.maxRow < bayMaxRow))
        summary.maxRow = bayMaxRow;
}
