import { __awaiter } from "tslib";
import { createScreen } from "../../helpers/createScreen";
import { passwordCompliesWithCriteria, } from "@baplie-viewer2/tedivo-api-models";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";
import DivWithSpinner from "../../common/divWithSpinner/div-with-spinner-element";
import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import IntegratedDialogError from "../../common/IntegratedDialogError";
import Services from "../../../app/services";
import goSquared from "../../../app/tracking/goSquared";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import { setAppTitle } from "../../../app/app.element";
import { z } from "zod";
export class TVDChangePasswordComponent extends HTMLElement {
    constructor() {
        super();
        this.showError = (e) => {
            this.dialogError.show(getTranslation(`errors:errorHasOcurred`), getTranslation(typeof e === "string" ? e : e.translationKey));
        };
        /** Replaces the UI */
        this.setCurrentFormAndNode = (fan) => {
            this.currentFormAndNode = fan;
            if (fan === null || fan === void 0 ? void 0 : fan.node) {
                this.divWithSpinner.setLoading(false);
                removeChildren(this.divWithSpinner);
                this.divWithSpinner.appendChild(fan.node);
            }
        };
        this.onSubmitChangePasswordForm = (values) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            goSquared.addEvent("Change password - Submit");
            this.divWithSpinner.setLoading(true);
            const resp = yield Services.accounts.changeMyPassword(values.password);
            this.divWithSpinner.setLoading(false);
            if (!((_a = resp.data) === null || _a === void 0 ? void 0 : _a.id)) {
                this.showError({
                    errorCode: String(resp.statusCode),
                    message: resp.code || "errorModifyingUser",
                    translationKey: resp.message || "errors:errorModifyingUser",
                });
                return false;
            }
            else {
                router.navigate(routeFns.login());
                return true;
            }
        });
        this.i18nCR = new I18nComponentRegisterer(i18nReactive);
        this.dialogError = new IntegratedDialogError(this);
        this.divWithSpinner = new DivWithSpinner();
        setAppTitle(getTranslation("auth:changePassword"));
    }
    connectedCallback() {
        goSquared.trackPage("Change password");
        goSquared.addEvent("Change password - Show page");
        this.appendChild(this.divWithSpinner);
        this.setCurrentFormAndNode(createChangePasswordScreen(this.onSubmitChangePasswordForm, this.i18nCR));
    }
    disconnectedCallback() {
        this.i18nCR.disconnect();
    }
}
TVDChangePasswordComponent.observedAttributes = [];
customElements.define("tvd-change-password-component", TVDChangePasswordComponent);
function createChangePasswordScreen(onSubmitDataForm, i18nCR) {
    const AuthLoginFormValidator = z
        .object({
        password: z
            .string()
            .refine((pswd) => passwordCompliesWithCriteria(pswd).length === 0),
        retypePassword: z.string(),
    })
        .refine((vals) => vals.password === vals.retypePassword, {
        path: ["retypePassword"],
    });
    const passReqs = document.createElement("div");
    passReqs.className = "oss-password-compliance-container";
    const passwordReqsTitle = document.createElement("strong");
    passwordReqsTitle.innerHTML = getTranslation("auth:passwordSchemaTitle");
    passReqs.appendChild(passwordReqsTitle);
    const ul = document.createElement("ul");
    ul.className = "oss-password-compliance";
    passReqs.appendChild(ul);
    const fields = [
        [
            {
                type: "textBox",
                name: "password",
                label: "auth:password",
                isPassword: true,
                helpText: "auth:passwordSchema",
                inputListener: true,
            },
            {
                type: "textBox",
                name: "retypePassword",
                label: "auth:retypePassword",
                isPassword: true,
                helpText: "auth:passwordsMustMatch",
            },
        ],
        {
            name: "password",
            type: "node",
            node: passReqs,
        },
    ];
    const screen = createScreen({
        fields,
        onSubmitDataForm,
        formValidator: AuthLoginFormValidator,
        i18nCR,
        h1Text: "auth:changePassword",
        titleText: "menu:organization",
    });
    const tedivoForm = screen.form;
    if (tedivoForm) {
        showDetailedCriteriaCompliance(true, new Set(), ul);
        tedivoForm.onDataChange = (data, name) => {
            if (name === "password") {
                const criteriaNotMet = new Set(passwordCompliesWithCriteria(data.password));
                showDetailedCriteriaCompliance(false, criteriaNotMet, ul);
            }
        };
    }
    return screen;
}
export function showDetailedCriteriaCompliance(initial, criteriaNotMet, ul) {
    const allCriteria = [
        "minLength",
        "minNumber",
        "minSpecialChar",
        "minUpperCase",
        "minLowerCase",
    ];
    removeChildren(ul);
    allCriteria.forEach((criteriaId) => {
        const li = document.createElement("li");
        if (initial || criteriaNotMet.has(criteriaId)) {
            li.classList.add("password-schema-wrong");
        }
        li.innerText = getTranslation(`auth:passwordSchemaReqs.${criteriaId}`);
        ul.appendChild(li);
    });
    return ul;
}
