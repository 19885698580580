import { BayLevelEnum, ForeAftEnum, } from "open-vessel-definition";
import { sortByMultipleFields } from "@baplie-viewer2/tedivo-pure-helpers";
export function getAllThePairedBays(bls, checkOnly40s) {
    const levels = [BayLevelEnum.ABOVE, BayLevelEnum.BELOW];
    const pairedBays = [];
    const unpairedBays = [];
    levels.forEach((level) => {
        const bays = bls
            .filter((a) => a.level === level)
            .sort(sortByMultipleFields([{ name: "isoBay", ascending: true }]));
        for (let i = 0; i < bays.length; i++) {
            const bay = bays[i];
            let has40 = true;
            if (checkOnly40s) {
                has40 = Object.keys(bay.perSlotInfo || {}).some((slot) => {
                    var _a, _b;
                    return Object.keys(((_b = (_a = bay.perSlotInfo) === null || _a === void 0 ? void 0 : _a[slot]) === null || _b === void 0 ? void 0 : _b.sizes) || {})
                        .map(Number)
                        .some((size) => size >= 40);
                });
            }
            if (bay.pairedBay === ForeAftEnum.AFT) {
                const nextBay = bays[i + 1];
                if (nextBay && nextBay.pairedBay === ForeAftEnum.FWD) {
                    pairedBays.push({ base: bay.isoBay, paired: nextBay.isoBay, level });
                    i++;
                }
                else {
                    if (has40 || !checkOnly40s)
                        unpairedBays.push({ base: bay.isoBay, level });
                }
            }
            else {
                if (has40 || !checkOnly40s)
                    unpairedBays.push({ base: bay.isoBay, level });
            }
        }
    });
    return { pairedBays, unpairedBays };
}
