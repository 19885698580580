export function isLocationRouteActive(
  currentRoute: string,
  basePath = "",
  routeTo?: string
): boolean {
  if (!routeTo) {
    return false;
  }

  const basedCurrentRoute = currentRoute.substring(basePath.length) || "/";

  if (basedCurrentRoute === "/") {
    return basedCurrentRoute === routeTo;
  }

  const routeToParts = routeTo.split("/").filter(Boolean);
  const currentRouteParts = basedCurrentRoute.split("/").filter(Boolean);

  if (routeToParts.length > currentRouteParts.length) {
    return false;
  }

  let routeToBuffer = "";
  let currentRouteBuffer = "";

  for (let i = 0; i < currentRouteParts.length; i += 1) {
    if (routeToParts[i] === undefined) {
      break;
    }
    routeToBuffer += `/${routeToParts[i]}`;
    currentRouteBuffer += `/${currentRouteParts[i]}`;
  }

  return routeToBuffer === currentRouteBuffer && routeToBuffer !== "";
}
