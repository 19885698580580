export const createSvgText = ({
  text,
  x,
  y,
  fontSize = 11,
  textColor = "#666666",
  textAnchor = "middle",
  textVertical = "middle",
  className = "",
}: IAddSvgTextProps): SVGTextElement => {
  const svgT = document.createElementNS("http://www.w3.org/2000/svg", "text");
  svgT.setAttribute("fill", textColor);
  svgT.setAttribute("stroke-width", "0");
  svgT.setAttribute("text-anchor", textAnchor);
  svgT.setAttribute("alignment-baseline", textVertical);
  svgT.setAttribute("font-size", String(fontSize));
  svgT.setAttribute("x", String(x));
  svgT.setAttribute("y", String(y));
  if (className) svgT.setAttribute("class", className);
  svgT.innerHTML = text;
  return svgT;
};

interface IAddSvgTextProps {
  text: string;
  x: number;
  y: number;
  className?: string;
  fontSize?: number;
  textColor?: string;
  textAnchor?: "start" | "middle" | "end";
  textVertical?:
    | "auto"
    | "baseline"
    | "before-edge"
    | "text-before-edge"
    | "middle"
    | "central"
    | "after-edge"
    | "text-after-edge"
    | "ideographic"
    | "alphabetic"
    | "hanging"
    | "mathematical"
    | "top"
    | "center"
    | "bottom";
}
