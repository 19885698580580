import { ForeAftEnum, ISizeSummary } from "open-vessel-definition";
import {
  IBayPattern,
  IRowPattern,
  ITierPattern,
} from "../../types/IPositionPatterns";
import {
  feetToMillimeters,
  roundDec,
} from "@baplie-viewer2/tedivo-pure-helpers";

import { I20LcgsByBay } from "./generate20Lcgs";
import { IBaySizesAndCgs } from "../../helpers/getBayLcgVcgTcgAndPairings";
import { SVG_WIDTH } from "./consts";
import { createRowsFromConfig } from "../../helpers/createRowsFromConfig";
import { createTiersFromConfig } from "../../helpers/createTiersFromConfig";

export const LCG_SEPARATION = feetToMillimeters(13);
export const LCG_20 = feetToMillimeters(24);
export const SEPARATION_IN_BETWEEN = feetToMillimeters(1);
export const LCG_20_WITH_SEPARATION = LCG_20 + SEPARATION_IN_BETWEEN;
export const TCG_IN_MMM = 2520;
const FEET_15_IN_MMM = feetToMillimeters(15);
const FEET_16_IN_MMM = feetToMillimeters(16);
const METERS_20_IN_MMM = 20000;

export function generateCoordFunctions({
  missingImportantXcgs,
  sizeSummary,
  bayLevelPositionsAbove,
  bayLevelPositionsBelow,
  maxLcg,
  minLcg,
  maxVcg,
  minVcg,
  maxTcg,
  minTcg,
  lcgNormalization = SVG_WIDTH,
  lcgsBy20Bay,
}: IGenerateCoordFunctionsProps): IGeneratedCoordFunctionsResult {
  // A. With full XCGs
  if (!missingImportantXcgs) {
    const svgSizeRatio =
      lcgNormalization / (maxLcg - minLcg + METERS_20_IN_MMM * 2);

    const scaled = (n: number) => n * svgSizeRatio;

    const xRange = roundDec(scaled(maxLcg - minLcg + METERS_20_IN_MMM * 2)), // 15*2 -add 10 meters for bow & stern
      yRange = roundDec(scaled(maxVcg - minVcg + FEET_15_IN_MMM * 2)), // Add 15*2 feet above and below
      zRange = roundDec(scaled(maxTcg - minTcg + FEET_16_IN_MMM * 2)); // Add 5*2 feet port and starboard

    return {
      addX: scaled(METERS_20_IN_MMM),
      addY: scaled(FEET_15_IN_MMM),
      addZ: scaled(FEET_16_IN_MMM),
      xRange,
      yRange,
      zRange,
      xPos: (lcg: number, d = 2) =>
        roundDec(scaled(lcg - minLcg + METERS_20_IN_MMM), d),
      yPos: (vcg: number, d = 2) =>
        roundDec(scaled(-vcg + maxVcg + FEET_15_IN_MMM), d),
      zPos: (tcg: number, d = 2) =>
        roundDec(scaled(tcg - minTcg + FEET_16_IN_MMM), d),
      svgSizeRatio,
    };
  }

  // B. Only for missing XCGs *******************************
  // B.1 LCGs

  const allLCGs = (Object.keys(lcgsBy20Bay) as IBayPattern[]).map(
    (k) => lcgsBy20Bay[k].lcg || 0,
  );

  const minIntLcg = Math.min.apply(null, allLCGs),
    maxIntLcg = Math.max.apply(null, allLCGs);

  // B.2 VCGs
  const aboveTiers = createTiersFromConfig(
    sizeSummary.minAboveTier,
    sizeSummary.maxAboveTier,
  );
  const belowTiers = createTiersFromConfig(
    sizeSummary.minBelowTier,
    sizeSummary.maxBelowTier,
  );

  const allTiers = belowTiers.concat(aboveTiers);
  const allTiersVcgs = allTiers.reduce((acc, t, idx) => {
    acc[t] = roundDec(
      feetToMillimeters(idx * 9) + (Number(t) >= 66 ? 2000 : -1000),
      2,
    );
    return acc;
  }, {} as { [t: ITierPattern]: number });

  const maxIntVcg = allTiersVcgs[allTiers[allTiers.length - 1]];

  // B.3 TCGs
  const rows = createRowsFromConfig(
    !!sizeSummary.centerLineRow,
    sizeSummary.maxRow,
  );

  const minIntTcg = -(Number(rows[0]) - 1) * 0.5 * TCG_IN_MMM;
  const allRowsTcgs = rows.reduce((acc, t, idx) => {
    acc[t] = roundDec(idx * TCG_IN_MMM + minIntTcg, 2);
    return acc;
  }, {} as { [t: IRowPattern]: number });

  const maxIntTcg = allRowsTcgs[rows[rows.length - 1]];

  // B.4 Reposition function
  const rePositionLcg = (blp: IBaySizesAndCgs) => {
    const lcgsOfBay = lcgsBy20Bay[blp.bayIsoNumber];
    if (lcgsOfBay) {
      blp.maxSizeLcg.size = lcgsOfBay.maxSize;
      blp.maxSizeLcg.lcg = lcgsOfBay.lcg;
      blp.maxSizeLcg.aftLcg = lcgsOfBay.aftLcg;
      blp.maxSizeLcg.foreLcg = lcgsOfBay.foreLcg;

      // B.4.2. VCGs
      (Object.keys(blp.tiers) as IRowPattern[]).forEach((tier) => {
        // As generated LCGs are for 20's, for 40's we need the aftLcg of a 20
        blp.tiers[tier].lcg = roundDec(
          blp.tiers[tier].maxSize >= 40
            ? blp.pairedBay === ForeAftEnum.AFT
              ? lcgsOfBay.aftLcg
              : lcgsOfBay.foreLcg
            : lcgsOfBay.lcg || 0,
          2,
        );

        blp.tiers[tier].vcg = allTiersVcgs[tier];
      });

      // B.4.3. TCGs
      const rowInfo = blp.rows;
      const rowsOfBay = Object.keys(rowInfo) as IRowPattern[];
      rowsOfBay.forEach((row) => {
        if (!blp.rows[row]) blp.rows[row] = {};
        blp.rows[row].tcg = allRowsTcgs[row];
      });
    }
  };

  bayLevelPositionsAbove.forEach(rePositionLcg);
  bayLevelPositionsBelow.forEach(rePositionLcg);

  const svgSizeRatio = lcgNormalization / (maxIntLcg + METERS_20_IN_MMM * 2);

  const scaled = (n: number) => n * svgSizeRatio;

  const xRange = roundDec(scaled(maxIntLcg - minIntLcg + METERS_20_IN_MMM * 2)), // 15*2 -add 10 meters for bow & stern
    yRange = roundDec(scaled(maxIntVcg + FEET_15_IN_MMM * 2)), // Add 15*2 feet above and below
    zRange = roundDec(scaled(maxIntTcg - minIntTcg + FEET_16_IN_MMM * 2)); // Add 5*2 feet port and starboard

  return {
    addX: scaled(METERS_20_IN_MMM),
    addY: scaled(FEET_15_IN_MMM),
    addZ: scaled(FEET_16_IN_MMM),
    xRange,
    yRange,
    zRange,
    xPos: (lcg: number, d = 2) =>
      roundDec(scaled(lcg - minIntLcg + METERS_20_IN_MMM), d),
    yPos: (vcg: number, d = 2) =>
      roundDec(scaled(-vcg + maxIntVcg + FEET_15_IN_MMM), d),
    zPos: (tcg: number, d = 2) =>
      roundDec(scaled(tcg - minIntTcg + FEET_16_IN_MMM), d),
    svgSizeRatio: svgSizeRatio,
  };
}

interface IGenerateCoordFunctionsProps {
  missingImportantXcgs: boolean;
  maxIsoBay: number;
  sizeSummary: ISizeSummary;
  bayLevelPositionsAbove: IBaySizesAndCgs[];
  bayLevelPositionsBelow: IBaySizesAndCgs[];
  maxLcg: number;
  minLcg: number;
  maxVcg: number;
  minVcg: number;
  maxTcg: number;
  minTcg: number;
  lcgNormalization?: number;
  lcgsBy20Bay: I20LcgsByBay;
}

interface IGeneratedCoordFunctionsResult {
  addX: number;
  addY: number;
  addZ: number;
  xRange: number;
  yRange: number;
  zRange: number;
  xPos: (lcg: number, d?: number) => number;
  yPos: (vcg: number, d?: number) => number;
  zPos: (tcg: number, d?: number) => number;
  svgSizeRatio: number;
}
