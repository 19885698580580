import {
  BayLevelEnum,
  ForeAftEnum,
  LcgReferenceEnum,
  LengthUnitsEnum,
  PortStarboardEnum,
  PositionFormatEnum,
  RowWeightCalculationEnum,
  ValuesSourceEnum,
  ValuesSourceRowTierEnum,
  WeightUnitsEnum,
} from "open-vessel-definition";

export function getPositionFormatEnumTranslationKey(
  v: PositionFormatEnum
): string {
  return `enums:PositionFormatEnum.${PositionFormatEnum[v]}`;
}

export function getBayLevelEnumTranslationKey(v: BayLevelEnum): string {
  return `enums:BayLevelEnum.${BayLevelEnum[v]}`;
}

export function getForeAftEnumTranslationKey(v: ForeAftEnum): string {
  return `enums:ForeAftEnum.${ForeAftEnum[v]}`;
}

export function getLcgReferenceEnumTranslationKey(v: LcgReferenceEnum): string {
  return `enums:LcgReferenceEnum.${LcgReferenceEnum[v]}`;
}

export function getPortStarboardEnumTranslationKey(
  v: PortStarboardEnum
): string {
  return `enums:PortStarboardEnum.${PortStarboardEnum[v]}`;
}

export function getRowWeightCalculationEnumTranslationKey(
  v: RowWeightCalculationEnum
): string {
  return `enums:RowWeightCalculationEnum.${RowWeightCalculationEnum[v]}`;
}

export function getValuesSourceRowTierEnumTranslationKey(
  v: ValuesSourceRowTierEnum
): string {
  return `enums:ValuesSourceRowTierEnum.${ValuesSourceRowTierEnum[v]}`;
}

export function getLengthUnitsEnumTranslationKey(v: LengthUnitsEnum): string {
  return `enums:LengthUnitsEnum.${LengthUnitsEnum[v]}`;
}

export function getValuesSourceEnumTranslationKey(v: ValuesSourceEnum): string {
  return `enums:ValuesSourceEnum.${ValuesSourceEnum[v]}`;
}

export function getWeightUnitsEnumTranslationKey(v: WeightUnitsEnum): string {
  return `enums:WeightUnitsEnum.${WeightUnitsEnum[v]}`;
}
