import IDictionaryDisplayElement from "../types/IDictionaryDisplayElement";
import i18n from "../../../../app/i18/i18tn";

export default function getTranslatedDictionary(
  elements: Array<IDictionaryDisplayElement>
): Array<IDictionaryDisplayElement> {
  return elements.map((dict) =>
    Object.assign({}, dict, { label: i18n.t(dict.label) })
  );
}
