import HttpClient, { IResponseModel } from "../HttpClient";
import { ICognitoUser, IOrganization } from "@baplie-viewer2/tedivo-api-models";

import { UserType } from "@aws-sdk/client-cognito-identity-provider";

const AccountsService = {
  createNewAccount: async (
    data: IAuthCreateAccountFields & { recaptchaToken: string },
  ): Promise<IResponseModel<ICreateAccountOutput>> => {
    const client = new HttpClient();
    return await client.request<ICreateAccountOutput>({
      method: "POST",
      url: "accounts/create",
      data,
    });
  },

  resendVerificationEmail: async (
    hash: string,
    email: string,
  ): Promise<IResponseModel<unknown>> => {
    const client = new HttpClient();
    return await client.request<unknown>({
      method: "POST",
      url: "accounts/resendVerificationEmail",
      data: { hash, email },
    });
  },

  verifyEmail: async (
    data: IAuthVerifyEmail,
  ): Promise<IResponseModel<IAuthVerifyEmailOutput>> => {
    const client = new HttpClient();
    return await client.request<IAuthVerifyEmailOutput>({
      method: "POST",
      url: "accounts/verify",
      data,
    });
  },

  notifyNewPassword: async (): Promise<IResponseModel<void>> => {
    const client = new HttpClient();
    return await client.request<void>({
      method: "POST",
      url: "accounts/notifyNewPassword",
    });
  },

  editMyDetails: async (
    data: Pick<ICognitoUser, "name" | "familyName" | "email">,
  ): Promise<IResponseModel<ISimpleResponse>> => {
    const client = new HttpClient();
    return await client.request<ISimpleResponse>({
      method: "PUT",
      url: "accounts/editMyDetails",
      data,
    });
  },

  getMyDetails: async (): Promise<IResponseModel<Partial<ICognitoUser>>> => {
    const client = new HttpClient();
    return await client.request<Partial<ICognitoUser>>({
      method: "GET",
      url: "accounts/getMyDetails",
    });
  },

  getUserDetailsByAdmin: async (
    orgId: string,
    id: string,
  ): Promise<IResponseModel<Partial<ICognitoUser>>> => {
    const client = new HttpClient();
    return await client.request<Partial<ICognitoUser>>({
      method: "GET",
      url: `/accounts/admin/getDetails/${orgId}/${id}`,
    });
  },

  getMyOrganizationUsers: async (
    orgId: string,
  ): Promise<IResponseModel<Record<string, string>>> => {
    const client = new HttpClient();
    return await client.request<Record<string, string>>({
      method: "GET",
      url: `accounts/usersNamesByIdOfOrganization/${orgId || ""}`,
    });
  },

  changeMyPassword: async (
    password: string,
  ): Promise<IResponseModel<ISimpleResponse>> => {
    const client = new HttpClient();
    return await client.request<ISimpleResponse>({
      method: "PUT",
      url: "accounts/changeMyPassword",
      data: { password },
    });
  },

  forgotMyPassword: async (
    email: string,
  ): Promise<IResponseModel<ISimpleResponse>> => {
    const client = new HttpClient();
    return await client.request<ISimpleResponse>({
      method: "POST",
      url: "accounts/forgotMyPassword",
      data: { email },
    });
  },

  changeOrganizationSelfDetails: async (
    name: string,
    mfaForAllUsers: boolean,
  ): Promise<IResponseModel<unknown>> => {
    const client = new HttpClient();
    return await client.request<unknown>({
      method: "PUT",
      url: "accounts/organizationSelf",
      data: { name, mfaForAllUsers },
    });
  },

  deleteMyUserAccount: async (
    mfaCode: string,
    accessToken: string,
  ): Promise<IResponseModel<unknown>> => {
    const client = new HttpClient();
    return await client.request<unknown>({
      method: "DELETE",
      url: "accounts/deleteMyAccount",
      data: { mfaCode, accessToken },
    });
  },

  deleteMyOrgAccount: async (
    mfaCode: string,
    accessToken: string,
  ): Promise<IResponseModel<unknown>> => {
    const client = new HttpClient();
    return await client.request<unknown>({
      method: "DELETE",
      url: "accounts/deleteMyOrgAccount",
      data: { mfaCode, accessToken },
    });
  },

  updateUserMarketingPreferences: async (
    acceptsMarketing: boolean,
  ): Promise<IResponseModel<ISimpleResponse | ICreateOutputError>> => {
    const client = new HttpClient();
    return await client.request<ISimpleResponse | ICreateOutputError>({
      method: "PUT",
      url: `accounts/marketingPreferences`,
      data: {
        acceptsMarketing,
      },
    });
  },
};

export default AccountsService;

export interface IAuthCreateAccountFields {
  email: string;
  name: string;
  familyName: string;
  orgName: string;
  acceptsMarketing?: boolean;
}

export interface IAuthVerifyEmail {
  email: string;
  hash: string;
}

export interface IAuthVerifyEmailOutput {
  isVerified: boolean;
  password?: string;
}

interface ISimpleResponse {
  id?: string;
}

export interface IMergeOrganizationsCandidates {
  organizations: Partial<IOrganization>[];
}

type ICreateOutputError = { id?: never; code: string; message: string };

export interface ICreateAccountOutput {
  hash: string;
}
