import IDictionaryDisplayElement from "../types/IDictionaryDisplayElement";
import i18n from "../../../../app/i18/i18tn";

export default function getDisplayValue(el: IDictionaryDisplayElement): string {
  switch (el.type) {
    case "yesNo":
      return el.value
        ? i18n.t("general:common.yes")
        : i18n.t("general:common.no");
      break;
    default:
      return el.value !== undefined && el.format
        ? el.format(el.value)
        : String(el.value);
  }
}
