import {
  ITransponser,
  ITransponserFunctions,
  IUnitsConverter,
  IUnitsConverterFunctions,
  ONE_MILLIMETER_IN_FEET,
  roundDec,
} from "@baplie-viewer2/tedivo-pure-helpers";

import LenUnitsEnum from "../enums/LenUnitsEnum";
import MassUnitsEnum from "../enums/MassUnitsEnum";
import { PortStarboardEnum } from "open-vessel-definition";
import UoLcgReferenceEnum from "../enums/UoLcgReferenceEnum";
import VcgVerticalReferenceEnum from "../enums/VcgVerticalReferenceEnum";
import globalStore from "../stores/globalStore";
import i18n from "../i18/i18tn";
import ovdJsonStore from "../stores/OVDJsonStore";
import { setPreferencesKeyAndValue } from "@baplie-viewer2/tedivo-preferences";

class GlobalUnits {
  get lpp() {
    return ovdJsonStore.currentJson?.shipData?.lcgOptions?.lpp || 0;
  }

  get lengthUnits(): IUnitsConverterFunctions<LenUnitsEnum> {
    const length = globalStore.units.length;

    return {
      units: length,
      fromDisplayToValue: lenghtUnitsFromDisplayToValue[length],
      fromValueToDisplay: lenghtUnitsFromValueToDisplay[length],
      fromValueToFormattedDisplay: (a: number): string => {
        const nn = new Intl.NumberFormat(i18n.language, {
          style: "decimal",
        });
        return nn.format(lenghtUnitsFromValueToDisplay[length](Number(a)));
      },
    };
  }

  get massUnits(): IUnitsConverterFunctions<MassUnitsEnum> {
    const mass = globalStore.units.mass;

    return {
      units: mass,
      fromDisplayToValue: massUnitsFromDisplayToValue[mass],
      fromValueToDisplay: massUnitsFromValueToDisplay[mass],
      fromValueToFormattedDisplay: (a: number): string => {
        const nn = new Intl.NumberFormat(i18n.language, {
          style: "decimal",
        });
        return nn.format(massUnitsFromValueToDisplay[mass](Number(a)));
      },
    };
  }

  get lcgTransposer(): ITransponserFunctions<UoLcgReferenceEnum> {
    const lpp = this.lpp;
    const lcgRef = globalStore.units.lcgRef;

    if (lpp !== undefined) {
      return {
        transposeTo: lcgRef,
        fromDisplayToValue: (a) =>
          lcgTransponserFromDisplayToValue[lcgRef](a, lpp),
        fromValueToDisplay: (a) =>
          lcgTransponserFromValueToDisplay[lcgRef](a, lpp),
      };
    } else {
      return {
        transposeTo: lcgRef,
        fromDisplayToValue: (a) => a,
        fromValueToDisplay: (a) => a,
      };
    }
  }

  get tcgTransposer(): ITransponserFunctions<PortStarboardEnum> {
    const tcgDir = globalStore.units.tcgDir;

    return {
      transposeTo: tcgDir,
      fromDisplayToValue: (a: number) =>
        tcgTransponserFromDisplayToValue[tcgDir](a, 0),
      fromValueToDisplay: (a: number) =>
        tcgTransponserFromValueToDisplay[tcgDir](a, 0),
    };
  }

  get vcgTransposer(): ITransponserFunctions<VcgVerticalReferenceEnum> {
    const vcgRef = globalStore.units.vcgRef;

    return {
      transposeTo: vcgRef,
      fromDisplayToValue: (a: number) =>
        vcgTransponserFromDisplayToValue[vcgRef](a, 0),
      fromValueToDisplay: (a: number) =>
        vcgTransponserFromValueToDisplay[vcgRef](a, 0),
    };
  }

  get saveFormattedJson(): boolean {
    return globalStore.formattedJson;
  }

  setLengthUnits = (u: LenUnitsEnum): boolean => {
    if (globalStore.units.length === u) return false;
    globalStore.units.length = u;
    setPreferencesKeyAndValue("units-length", u);
    return true;
  };

  setMassUnits = (u: MassUnitsEnum): boolean => {
    if (globalStore.units.mass === u) return false;
    globalStore.units.mass = u;
    setPreferencesKeyAndValue("units-mass", u);
    return true;
  };

  setLcgRef = (u: UoLcgReferenceEnum): boolean => {
    if (globalStore.units.lcgRef === u) return false;
    globalStore.units.lcgRef = u;
    setPreferencesKeyAndValue("units-lcgRef", u);
    return true;
  };

  setTcgDir = (u: PortStarboardEnum): boolean => {
    if (globalStore.units.tcgDir === u) return false;
    globalStore.units.tcgDir = u;
    setPreferencesKeyAndValue("units-tcgRef", u);
    return true;
  };

  setVcgRef = (u: VcgVerticalReferenceEnum): boolean => {
    if (globalStore.units.vcgRef === u) return false;
    globalStore.units.vcgRef = u;
    setPreferencesKeyAndValue("units-vcgRef", u);
    return true;
  };

  setSaveFormattedJson = (u: boolean): boolean => {
    globalStore.formattedJson = u;
    setPreferencesKeyAndValue("json-formatted", u ? "true" : "false");
    return false;
  };
}

const globalUnits = new GlobalUnits();
export default globalUnits;

export type IGlobalUnits = GlobalUnits;

export const lenghtUnitsFromDisplayToValue: Record<
  LenUnitsEnum,
  IUnitsConverter
> = {
  [LenUnitsEnum.MILLIMETERS]: (a) => Math.round(a),
  [LenUnitsEnum.CENTIMETERS]: (a) => Math.round(a * 10),
  [LenUnitsEnum.METERS]: (a) => Math.round(a * 1000),
  [LenUnitsEnum.FEET]: (a) => Math.round(a / ONE_MILLIMETER_IN_FEET),
};

export const lenghtUnitsFromValueToDisplay: Record<
  LenUnitsEnum,
  IUnitsConverter
> = {
  [LenUnitsEnum.MILLIMETERS]: (a) => a,
  [LenUnitsEnum.CENTIMETERS]: (a) => a / 10,
  [LenUnitsEnum.METERS]: (a) => a / 1000,
  [LenUnitsEnum.FEET]: (a) => roundDec(a * ONE_MILLIMETER_IN_FEET, 2),
};

export const massUnitsFromDisplayToValue: Record<
  MassUnitsEnum,
  IUnitsConverter
> = {
  [MassUnitsEnum.GRAMS]: (a) => a,
  [MassUnitsEnum.KILOGRAMS]: (a) => Math.round(a * 1000),
  [MassUnitsEnum.TONNES]: (a) => Math.round(a * 1000000),
  [MassUnitsEnum.SHORT_TONS]: (a) => Math.round(a * 0.907185 * 1000000),
  [MassUnitsEnum.LONG_TONS]: (a) => Math.round(a * 1.01605 * 1000000),
  [MassUnitsEnum.POUNDS]: (a) => Math.round(a * 453.592),
  [MassUnitsEnum.POUNDS_1000]: (a) => Math.round(a * 453592),
};

export const massUnitsFromValueToDisplay: Record<
  MassUnitsEnum,
  IUnitsConverter
> = {
  [MassUnitsEnum.GRAMS]: (a) => a,
  [MassUnitsEnum.KILOGRAMS]: (a) => roundDec(a / 1000, 3),
  [MassUnitsEnum.TONNES]: (a) => roundDec(a / 1000000, 3),
  [MassUnitsEnum.SHORT_TONS]: (a) => roundDec(a / 0.907185 / 1000000, 3),
  [MassUnitsEnum.LONG_TONS]: (a) => roundDec(a / 1.01605 / 1000000, 3),
  [MassUnitsEnum.POUNDS]: (a) => roundDec(a / 453.592, 3),
  [MassUnitsEnum.POUNDS_1000]: (a) => roundDec(a / 453592, 3),
};

export const lcgTransponserFromValueToDisplay: Record<
  UoLcgReferenceEnum,
  ITransponser
> = {
  [UoLcgReferenceEnum.AFT_PERPENDICULAR_DFWD]: (a: number) => a,
  [UoLcgReferenceEnum.FWD_PERPENDICULAR_DAFT]: (a, lpp) => (lpp || 0) - a,
  [UoLcgReferenceEnum.MIDSHIPS_DFWD]: (a, lpp) => a - (lpp || 0) * 0.5,
  [UoLcgReferenceEnum.MIDSHIPS_DAFT]: (a, lpp) => (lpp || 0) * 0.5 - a,
};

export const lcgTransponserFromDisplayToValue: Record<
  UoLcgReferenceEnum,
  ITransponser
> = {
  [UoLcgReferenceEnum.AFT_PERPENDICULAR_DFWD]: (a: number) => a,
  [UoLcgReferenceEnum.FWD_PERPENDICULAR_DAFT]: (a, lpp) => (lpp || 0) - a,
  [UoLcgReferenceEnum.MIDSHIPS_DFWD]: (a, lpp) => (lpp || 0) * 0.5 + a,
  [UoLcgReferenceEnum.MIDSHIPS_DAFT]: (a, lpp) => (lpp || 0) * 0.5 - a,
};

export const tcgTransponserFromValueToDisplay: Record<
  PortStarboardEnum,
  ITransponser
> = {
  [PortStarboardEnum.PORT]: (a: number) => -a,
  [PortStarboardEnum.STARBOARD]: (a: number) => a,
};

export const tcgTransponserFromDisplayToValue: Record<
  PortStarboardEnum,
  ITransponser
> = {
  [PortStarboardEnum.PORT]: (a: number) => -a,
  [PortStarboardEnum.STARBOARD]: (a: number) => a,
};

const CONT_8_6_IN_MM = 8.5 / ONE_MILLIMETER_IN_FEET;

export const vcgTransponserFromValueToDisplay: Record<
  VcgVerticalReferenceEnum,
  ITransponser
> = {
  [VcgVerticalReferenceEnum.BOTTOM_BASE]: (a: number) => a,
  [VcgVerticalReferenceEnum.VCG_45_8_6]: (a: number) =>
    Math.round(a + CONT_8_6_IN_MM * 0.45),
  [VcgVerticalReferenceEnum.VCG_50_8_6]: (a: number) =>
    Math.round(a + CONT_8_6_IN_MM * 0.5),
};

export const vcgTransponserFromDisplayToValue: Record<
  VcgVerticalReferenceEnum,
  ITransponser
> = {
  [VcgVerticalReferenceEnum.BOTTOM_BASE]: (a: number) => a,
  [VcgVerticalReferenceEnum.VCG_45_8_6]: (a: number) =>
    Math.round(a - CONT_8_6_IN_MM * 0.45),
  [VcgVerticalReferenceEnum.VCG_50_8_6]: (a: number) =>
    Math.round(a - CONT_8_6_IN_MM * 0.5),
};
