import { stylesFromStringToTag } from "@baplie-viewer2/tedivo-dom-helpers";
export class SectionElement extends HTMLElement {
    get actions() {
        return this.intActions;
    }
    set actions(v) {
        if (!v)
            return;
        this.intActions = v;
        const actionsDiv = document.createElement("div");
        actionsDiv.appendChild(v);
        actionsDiv.slot = "actions";
        this.titlePart.appendChild(actionsDiv);
    }
    constructor() {
        super();
        this.titleHtml = "";
        this.name = "";
        this.intActions = null;
        this.titlePart = document.createElement("title-with-actions-component");
    }
    connectedCallback() {
        const shadowRoot = this.attachShadow({ mode: "open" });
        const holder = document.createElement("div");
        holder.className = "section-element-holder";
        const titlePart = this.titlePart;
        titlePart.titleHtml = this.titleHtml;
        titlePart.titleNodeName = "h4";
        this.titlePart = titlePart;
        const slot = document.createElement("slot");
        holder.appendChild(titlePart);
        holder.appendChild(slot);
        shadowRoot.appendChild(stylesFromStringToTag(styles));
        shadowRoot.appendChild(holder);
    }
}
SectionElement.observedAttributes = [];
customElements.define("section-element", SectionElement);
const styles = `
  .section-element-holder {
    margin-bottom: var(--sl-spacing-medium);
  }

  .section-element-holder h3 {
    border-bottom: solid var(--border-width) var(--border-color);
  }

  title-with-actions-component {
    display: flex;
  }
`;
