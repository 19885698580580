import { BayLevelEnum, } from "open-vessel-definition";
import { TedivoForm, translateTedivoForm, } from "@baplie-viewer2/tedivo-form";
import LenUnitsEnum from "../../../../../../../app/enums/LenUnitsEnum";
import SmartTable from "../../../../../../../components/common/smartTable/smart-table.element";
import { getTranslation } from "../../../../../../../app/i18/i18tn";
import globalUnits from "../../../../../../../app/units/globalUnits";
import goSquared from "../../../../../../../app/tracking/goSquared";
import ovdJsonStore from "../../../../../../../app/stores/OVDJsonStore";
import { z } from "zod";
export function createTranslateLCGs(json) {
    if (!json) {
        return { node: document.createElement("div"), submitFunction: () => false };
    }
    goSquared.addEvent("Edit-OVD - Translate LCGs");
    const holder = document.createElement("div");
    const tedivoForm = createTranslateLCGsForm(json);
    holder.appendChild(tedivoForm.form);
    return {
        node: holder,
        submitFunction: submitPassedToEditDrawer,
    };
    function submitPassedToEditDrawer() {
        if (!json)
            return false;
        const validate = tedivoForm.doSubmitForm();
        if (!validate.success)
            return false;
        const amount = tedivoForm.getValues().amount;
        if (amount === 0)
            return true;
        const newJson = translateAllTheLCGsInVessel(amount, json);
        if (newJson) {
            newJson.baysData.forEach((bay) => {
                ovdJsonStore.replaceBayInfo(bay.isoBay, bay.level, bay);
            });
        }
        goSquared.addEvent("Edit-OVD - Translate LCGs - Save");
        return true;
    }
}
function createTranslateLCGsForm(json) {
    let prevLcgValue = 0;
    const fields = [
        [
            {
                name: "amount",
                type: "numberWithUnits",
                label: "view:edit.helpers.amountNegPos",
                converter: globalUnits.lengthUnits,
                initialValue: 0,
                helpText: getTranslation(`enums:LenUnitsEnum.${LenUnitsEnum[globalUnits.lengthUnits.units]}`),
                inputListener: true,
            },
            {
                name: "confirm",
                type: "checkbox",
                label: "view:edit.helpers.confirmLcgTranslation",
                initialValue: false,
                padStart: true,
            },
        ],
    ];
    const validator = z.object({
        amount: z.number(),
        confirm: z.boolean().refine((v) => !!v),
    });
    const tedivoForm = new TedivoForm({
        fields,
        onSubmit: () => null,
        formValidator: validator,
        submitButton: document.createElement("button"),
    });
    const example = new SmartTable().initialize({
        className: "tvd-table tvd-table-smart-table auto-width small-title",
        data: generateTranslatedLCGsExampleData(json, 0),
        defaultSort: "name",
        title: getTranslation("general:common.example"),
        fields: [
            {
                name: "name",
                label: getTranslation("view:edit.helpers.lcgAt"),
            },
            {
                name: "currentValue",
                label: getTranslation("general:common.currentValue"),
                className: "centered",
                mapper: (dta) => globalUnits.lengthUnits.fromValueToFormattedDisplay(globalUnits.lcgTransposer.fromValueToDisplay(dta.currentValue)),
            },
            {
                name: "newValue",
                label: getTranslation("general:common.newValue"),
                className: () => `centered ${tedivoForm.getValues().amount === 0 ? "" : "emphasized"}`,
                mapper: (dta) => globalUnits.lengthUnits.fromValueToFormattedDisplay(globalUnits.lcgTransposer.fromValueToDisplay(dta.newValue)),
            },
        ],
        settings: {
            sortEnabled: false,
            sortVisible: false,
            filterEnabled: false,
        },
        pkFunction: (dta) => dta.name,
    });
    tedivoForm.form.appendChild(example);
    tedivoForm.onDataChange = (data) => {
        const amount = Number(data.amount);
        if (!isNaN(amount) && prevLcgValue !== amount) {
            const chkConfirm = tedivoForm.getFormControlsByName().confirm;
            chkConfirm.value = false;
            chkConfirm.field.value = false;
            prevLcgValue = amount;
            const newExampleData = generateTranslatedLCGsExampleData(json, amount);
            example.updateData(newExampleData);
        }
    };
    translateTedivoForm({ tedivoForm, getTranslation });
    return tedivoForm;
}
function translateAllTheLCGsInVessel(amount, json) {
    if (!json)
        return null;
    const newJson = JSON.parse(JSON.stringify(json));
    // Each bay
    newJson.baysData.forEach((bay) => {
        // 1. infoByContLength
        const infoByContLength = bay.infoByContLength;
        if (infoByContLength !== undefined) {
            const sizesByContentLength = Object.keys(infoByContLength).map(Number);
            sizesByContentLength.forEach((size) => {
                const infoBySize = bay.infoByContLength[size];
                if ((infoBySize === null || infoBySize === void 0 ? void 0 : infoBySize.lcg) !== undefined) {
                    infoBySize.lcg += amount;
                }
            });
        }
        // 2. Per Row
        const perRowInfo = bay.perRowInfo;
        if ((perRowInfo === null || perRowInfo === void 0 ? void 0 : perRowInfo.each) !== undefined) {
            const rows = Object.keys(perRowInfo.each);
            rows.forEach((row) => {
                var _a;
                const rowInfo = (_a = perRowInfo.each) === null || _a === void 0 ? void 0 : _a[row];
                if (rowInfo.rowInfoByLength) {
                    const sizes = Object.keys(rowInfo.rowInfoByLength).map(Number);
                    sizes.forEach((size) => {
                        var _a;
                        const infoBySize = (_a = rowInfo.rowInfoByLength) === null || _a === void 0 ? void 0 : _a[size];
                        if ((infoBySize === null || infoBySize === void 0 ? void 0 : infoBySize.lcg) !== undefined) {
                            infoBySize.lcg += amount;
                        }
                    });
                }
            });
        }
        // 3. Bulkheads
        const bulkhead = bay.bulkhead;
        if (bulkhead !== undefined) {
            if (bulkhead.aft && bulkhead.aftLcg !== undefined)
                bulkhead.aftLcg += amount;
            if (bulkhead.fore && bulkhead.foreLcg !== undefined)
                bulkhead.foreLcg += amount;
        }
    });
    return newJson;
}
export function generateTranslatedLCGsExampleData(json, amount) {
    const data1 = [];
    const data2 = [];
    const newJson = translateAllTheLCGsInVessel(amount, json);
    json.baysData.forEach((bay) => {
        var _a, _b, _c, _d;
        const infoByContLength = bay.infoByContLength;
        const newJsonBayData = newJson === null || newJson === void 0 ? void 0 : newJson.baysData.find((b) => b.isoBay === bay.isoBay && b.level === bay.level);
        if (infoByContLength !== undefined) {
            const sizesByContentLength = Object.keys(infoByContLength).map(Number);
            sizesByContentLength.forEach((size) => {
                var _a, _b, _c;
                const infoBySize = bay.infoByContLength[size];
                if ((infoBySize === null || infoBySize === void 0 ? void 0 : infoBySize.lcg) !== undefined) {
                    data1.push({
                        name: `Bay ${bay.isoBay} - ${getTranslation(`enums:BayLevelEnum.${BayLevelEnum[bay.level]}`)} - ${size}'`,
                        currentValue: infoBySize.lcg,
                        newValue: (_c = (_b = (_a = newJsonBayData === null || newJsonBayData === void 0 ? void 0 : newJsonBayData.infoByContLength) === null || _a === void 0 ? void 0 : _a[size]) === null || _b === void 0 ? void 0 : _b.lcg) !== null && _c !== void 0 ? _c : 0,
                    });
                }
            });
        }
        // 3. Bulkheads
        const bulkhead = bay.bulkhead;
        if (bulkhead !== undefined) {
            if (bulkhead.aft && bulkhead.aftLcg !== undefined)
                data2.push({
                    name: `Bay ${bay.isoBay} - ${getTranslation(`enums:ForeAftEnum.AFT`)} Bulkhead`,
                    currentValue: bulkhead.aftLcg,
                    newValue: (_b = (_a = newJsonBayData === null || newJsonBayData === void 0 ? void 0 : newJsonBayData.bulkhead) === null || _a === void 0 ? void 0 : _a.aftLcg) !== null && _b !== void 0 ? _b : 0,
                });
            if (bulkhead.fore && bulkhead.foreLcg !== undefined)
                data2.push({
                    name: `Bay ${bay.isoBay} - ${getTranslation(`enums:ForeAftEnum.FWD`)} Bulkhead`,
                    currentValue: bulkhead.foreLcg,
                    newValue: (_d = (_c = newJsonBayData === null || newJsonBayData === void 0 ? void 0 : newJsonBayData.bulkhead) === null || _c === void 0 ? void 0 : _c.foreLcg) !== null && _d !== void 0 ? _d : 0,
                });
        }
    });
    return [...data1.slice(0, 7), ...data2.slice(0, 3)];
}
