import type { IDetailsDownloads, ILogEvent } from "./logEvents";
import type {
  TDetailsFileCreated,
  TDetailsStateChange,
  TDetailsUserCRUD,
  TSubEvent,
} from "./types";

import type { ILogEventBaseDBItem } from "./ILogEventBaseDBItem";
import { LogEventEntitiesEnum } from "./enums/LogEventEntitiesEnum";
import { LogEventTypesEnum } from "./enums/LogEventTypesEnum";

export function convertLogEventToDBItem(inp: ILogEvent): ILogEventBaseDBItem {
  const date = typeof inp.date === "string" ? new Date(inp.date) : inp.date;

  return {
    userSub: { S: inp.userSub },
    organizationId: { S: inp.organizationId },
    email: { S: inp.email || "" },
    userDisplayName: { S: inp.userDisplayName || "" },
    organizationName: { S: inp.organizationName || "" },
    logEventId: { S: `${inp.eventEntity}#${inp.itemId}#${date.toISOString()}` },
    eventType: { S: inp.eventType },
    subEvent: { S: inp.subEvent || "" },
    details: { S: JSON.stringify(inp.details) || "" },
    forNotifs: { S: inp.forNotifs || "N" },
  };
}

export function convertDBLogEventToItem(
  inp: ILogEventBaseDBItem,
): ILogEvent | undefined {
  const [eventEntity, itemId, dateStr] = inp.logEventId.S.split("#") as [
    LogEventEntitiesEnum,
    string,
    string,
  ];
  const date = new Date(dateStr);

  const eventType = inp.eventType.S as unknown as LogEventTypesEnum;

  const subEvent =
    eventEntity === LogEventEntitiesEnum.File &&
    eventType === LogEventTypesEnum.Modified
      ? (inp.subEvent.S as unknown as TSubEvent)
      : undefined;

  switch (eventEntity) {
    case LogEventEntitiesEnum.File:
      switch (eventType) {
        case LogEventTypesEnum.Modified:
          if ((subEvent as string) === "VersionReplace") {
            return {
              date,
              userSub: inp.userSub.S,
              email: inp.email.S,
              userDisplayName: inp.userDisplayName.S,
              organizationId: inp.organizationId.S,
              organizationName: inp.organizationName.S,
              eventEntity,
              eventType,
              subEvent: subEvent as TSubEvent,
              itemId: itemId,
              details: JSON.parse(inp.details.S) as { version: string },
            } as unknown as ILogEvent;
          } else {
            return {
              date,
              userSub: inp.userSub.S,
              email: inp.email.S,
              userDisplayName: inp.userDisplayName.S,
              organizationId: inp.organizationId.S,
              organizationName: inp.organizationName.S,
              eventEntity,
              eventType,
              subEvent: subEvent as TSubEvent,
              itemId: itemId,
              details: undefined,
            };
          }

        case LogEventTypesEnum.Created:
          return {
            date,
            userSub: inp.userSub.S,
            email: inp.email.S,
            userDisplayName: inp.userDisplayName.S,
            organizationId: inp.organizationId.S,
            organizationName: inp.organizationName.S,
            eventEntity,
            eventType,
            subEvent: undefined,
            itemId: itemId,
            details: JSON.parse(inp.details.S) as TDetailsFileCreated,
          };

        case LogEventTypesEnum.Deleted:
          return {
            date,
            userSub: inp.userSub.S,
            email: inp.email.S,
            userDisplayName: inp.userDisplayName.S,
            organizationId: inp.organizationId.S,
            organizationName: inp.organizationName.S,
            eventEntity,
            eventType,
            subEvent: undefined,
            itemId: itemId,
            details: undefined,
          };

        case LogEventTypesEnum.StateChanged:
          return {
            date,
            userSub: inp.userSub.S,
            email: inp.email.S,
            userDisplayName: inp.userDisplayName.S,
            organizationId: inp.organizationId.S,
            organizationName: inp.organizationName.S,
            eventEntity,
            eventType,
            subEvent: undefined,
            itemId: itemId,
            details: JSON.parse(inp.details.S) as TDetailsStateChange,
          };

        case LogEventTypesEnum.ClonedByThirdParty:
          return {
            date,
            userSub: "",
            email: "",
            userDisplayName: "-",
            organizationId: inp.organizationId.S,
            organizationName: inp.organizationName.S,
            eventEntity,
            eventType,
            subEvent: undefined,
            itemId: itemId,
            details: JSON.parse(inp.details.S) as IDetailsDownloads,
          };

        case LogEventTypesEnum.UpVoted:
        case LogEventTypesEnum.RemovedVote:
          return {
            date,
            userSub: "",
            email: "",
            userDisplayName: "-",
            organizationId: inp.organizationId.S,
            organizationName: inp.organizationName.S,
            eventEntity,
            eventType,
            subEvent: undefined,
            itemId: itemId,
            details: JSON.parse(inp.details.S) as Omit<
              IDetailsDownloads,
              "fileId"
            >,
          };

        case LogEventTypesEnum.ReplacedByConsumer:
          return {
            date,
            userSub: "",
            email: "",
            userDisplayName: "-",
            organizationId: inp.organizationId.S,
            organizationName: inp.organizationName.S,
            eventEntity,
            eventType,
            subEvent: undefined,
            itemId: itemId,
            details: JSON.parse(inp.details.S),
          };

        default:
          return undefined;
      }
      break;

    case LogEventEntitiesEnum.User:
      switch (eventType) {
        case LogEventTypesEnum.Created:
        case LogEventTypesEnum.Deleted:
        case LogEventTypesEnum.Modified:
          return {
            date,
            userSub: inp.userSub.S,
            email: inp.email.S,
            userDisplayName: inp.userDisplayName.S,
            organizationId: inp.organizationId.S,
            organizationName: inp.organizationName.S,
            eventEntity,
            eventType,
            subEvent: undefined,
            itemId: itemId,
            details: JSON.parse(inp.details.S) as TDetailsUserCRUD,
          };
        default:
          return undefined;
      }
      break;

    default:
      return undefined;
  }
}
