import {
  ICognitoUser,
  ICognitoUserWithOrgData,
  IOrganization,
  ISingleOrganizationAndUsers,
  TUserRole,
} from "@baplie-viewer2/tedivo-api-models";
import {
  IFields,
  IFormReturn,
  TedivoForm,
  translateTedivoForm,
} from "@baplie-viewer2/tedivo-form";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";

import BreadcrumbsElement from "../../common/misc/breadcrumbs.element";
import DivWithSpinner from "../../common/divWithSpinner/div-with-spinner-element";
import EditDrawer from "../../common/EditDrawer";
import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import ICustomError from "../../types/ICustomError";
import IntegratedDialogError from "../../common/IntegratedDialogError";
import Services from "../../../app/services";
import SlButton from "@shoelace-style/shoelace/dist/components/button/button";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";
import SmartTable from "../../common/smartTable/smart-table.element";
import UserTypeEnum from "packages/oss-editor/src/app/enums/UserTypeEnum";
import { formatDateInCurrentTimezone } from "@baplie-viewer2/tedivo-pure-helpers";
import globalStore from "../../../app/stores/globalStore";
import goSquared from "../../../app/tracking/goSquared";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import { setAppTitle } from "../../../app/app.element";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";
import { z } from "zod";

export class TVDAdminOrgsUsersComponent extends HTMLElement {
  public static observedAttributes = [];

  private i18nCR: I18nComponentRegisterer;
  private dialogError: IntegratedDialogError;
  private wrapper: DivWithSpinner;
  private mainBox: HTMLDivElement;
  private deleteDialog: SlDialog;
  private breadcrumbs: BreadcrumbsElement;
  private editDrawer: EditDrawer;

  private data: ISingleOrganizationAndUsers | undefined;

  constructor() {
    super();
    this.i18nCR = new I18nComponentRegisterer(i18nReactive);
    this.dialogError = new IntegratedDialogError(this);
    this.wrapper = document.createElement("div-spinner-element");
    this.mainBox = document.createElement("div");
    this.deleteDialog = document.createElement("sl-dialog");
    this.breadcrumbs = new BreadcrumbsElement();
    this.editDrawer = new EditDrawer();

    setAppTitle(getTranslation("general:users.users"));
  }

  connectedCallback() {
    goSquared.trackPage("TEDIVO Admin Pages - Users");
    goSquared.addEvent("Admin/Organization-Users - Show page");

    this.mainBox.className = "oss-card";

    const wrapper = this.wrapper,
      breadcrumbs = this.breadcrumbs,
      h1 = document.createElement("h1");

    breadcrumbs.items = [
      {
        href: routeFns.adminOrganizations(),
        label: getTranslation("general:organizations.organizations"),
      },
      { label: "" },
    ];

    wrapper.appendChild(breadcrumbs);
    wrapper.appendChild(h1);
    wrapper.appendChild(this.mainBox);

    this.appendChild(this.editDrawer.drawer);
    this.appendChild(wrapper);

    this.displayOrganizationUsers(router.getRouteParams().id);

    this.i18nCR.addConsumer(
      topMessageElement.element,
      "general:adminArea",
      "innerHTML",
    );

    this.i18nCR.addConsumer(h1, "general:users.users", "innerHTML");
  }

  private displayOrganizationUsers = async (orgId: string | undefined) => {
    globalStore.touchLastUserInteraction();

    if (!orgId) {
      router.navigate(routeFns.adminOrganizations());
      return;
    }

    const wrapper = this.wrapper;
    const box = this.mainBox;
    removeChildren(box);

    const actionAddOrgButton = document.createElement("sl-button");
    actionAddOrgButton.innerHTML = getTranslation("general:users.adminCreate");
    actionAddOrgButton.variant = "primary";
    actionAddOrgButton.addEventListener(
      "click",
      () => {
        this.openOrganizationUserEditModal();
      },
      false,
    );

    wrapper.setLoading(true);

    try {
      const resp = await Services.organizations.getSingleOrganizationAndUsers(
        orgId,
      );

      if (resp.data) {
        this.data = resp.data;
        this.breadcrumbs.setLastItemText(resp.data.organization?.name || "");

        box.appendChild(
          createUsersTable({
            data: resp.data,
            actionBox: actionAddOrgButton,
            onEditUser: (u: ICognitoUserWithOrgData) => {
              this.openOrganizationUserEditModal({ ...u });
            },
            onDeleteUserFromOrg: this.deleteOrganizationUser,
          }),
        );
      }
    } catch (e) {
      this.showError(e as ICustomError);
    }

    wrapper.setLoading(false);
  };

  disconnectedCallback() {
    this.i18nCR.disconnect();
  }

  showError = (e: ICustomError) => {
    this.dialogError.show(
      getTranslation(`errors:errorHasOcurred`),
      getTranslation(typeof e === "string" ? e : e.translationKey),
    );
  };

  private openOrganizationUserEditModal = (
    prevData?: ICognitoUserWithOrgData,
  ) => {
    const orgId = router.getRouteParams().id;
    if (!orgId) return;

    const editForm = createOrgUserEdit({
      submitButton: this.editDrawer.submitButton,
      orgId,
      prevData,
      showError: this.showError,
      organizationData: this.data?.organization,
    });

    if (editForm) {
      const drawer = this.editDrawer.getEditDrawer({
        title: getTranslation("general:users.adminCreate"),
        showUnits: false,
        readOnlyMode: false,
        onSave: async () => {
          this.editDrawer.disabled = true;
          const valid = await editForm.submitFunction();
          this.editDrawer.disabled = false;

          if (valid) {
            this.displayOrganizationUsers(router.getRouteParams().id);
          }

          return valid;
        },
      });
      drawer.appendChild(editForm.node);
      drawer.show();
    }
  };

  private deleteOrganizationUser = (
    orgId: string,
    dta: ICognitoUserWithOrgData,
  ) => {
    const dialog = this.deleteDialog;
    removeChildren(dialog);

    dialog.label = getTranslation("general:users.delete");

    const htmlContent = document.createElement("div");
    htmlContent.innerHTML = `${getTranslation(
      "general:users.delete",
    )}<br /><br /><strong>${dta.name} ${dta.familyName}</strong>`;

    const closeBtn = document.createElement("sl-button");
    closeBtn.slot = "footer";
    closeBtn.variant = "danger";
    closeBtn.autofocus = true;
    closeBtn.tabIndex = 0;
    closeBtn.innerHTML = getTranslation("general:common.delete");
    closeBtn.addEventListener(
      "click",
      async () => {
        closeBtn.disabled = true;
        closeBtn.loading = true;
        await Services.organizations.adminDeleteOrganizationUser(
          orgId,
          dta.sub,
        );
        closeBtn.disabled = false;
        closeBtn.loading = false;
        dialog.hide();
        this.displayOrganizationUsers(router.getRouteParams().id);
      },
      false,
    );

    dialog.appendChild(htmlContent);
    dialog.appendChild(closeBtn);
    this.appendChild(dialog);

    dialog.show();
  };
}

customElements.define(
  "tvd-admin-orgs-users-component",
  TVDAdminOrgsUsersComponent,
);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-admin-orgs-users-component": TVDAdminOrgsUsersComponent;
  }
}

function createUsersTable({
  data,
  actionBox,
  onEditUser,
  onDeleteUserFromOrg,
}: {
  data: ISingleOrganizationAndUsers;
  actionBox: HTMLElement | undefined;
  onEditUser: (org: ICognitoUserWithOrgData) => void;
  onDeleteUserFromOrg: (id: string, org: ICognitoUserWithOrgData) => void;
}): HTMLElement {
  const createSortIcon = (asc: boolean) => {
    const icon = document.createElement("sl-icon");
    icon.name = asc ? "sort-down-alt" : "sort-up-alt";
    return icon;
  };

  const organizationId = data.organization?.organizationId || "";

  const users = mergeUsersWithCurrentOrgData(organizationId, data.users || []);

  const tableUsers = new SmartTable<ICognitoUserWithOrgData>().initialize({
    className: "tvd-table tvd-table-smart-table",
    data: users,
    defaultSort: "name",
    title: data.organization?.name || "",
    fields: [
      {
        name: "name",
        label: getTranslation("general:common.name"),
      },
      {
        name: "familyName",
        label: getTranslation("general:users.familyName"),
      },
      {
        name: "email",
        label: getTranslation("general:common.email"),
      },
      {
        name: "organizationUserEnabled",
        label: getTranslation("general:common.enabled"),
        mapper: (u) =>
          getTranslation(
            `general:common.${
              u.organizationUserEnabled === "1" ? "yes" : "no"
            }`,
          ),
      },
      {
        name: "userStatusType",
        label: getTranslation("general:common.status"),
      },
      {
        name: "organizationUserRole",
        label: getTranslation("general:common.type"),
        mapper: (u) =>
          getTranslation(`enums:UserTypeEnum.${u.organizationUserRole}`),
      },
      {
        name: "userCreateDate",
        label: getTranslation("general:common.createdAt"),
        valueFunction: (dta) =>
          dta.userCreateDate
            ? formatDateInCurrentTimezone(dta.userCreateDate)
            : "",
        className: "centered",
      },
      {
        name: "userLastModifiedDate",
        label: getTranslation("general:common.modifiedAt"),
        valueFunction: (dta) =>
          dta.userLastModifiedDate
            ? formatDateInCurrentTimezone(dta.userLastModifiedDate)
            : "",

        className: "centered",
      },
      {
        name: "sub",
        label: getTranslation("general:common.edit"),
        type: "icon",
        icon: "pencil",
        notSortable: true,
        onClick: (dta) => {
          onEditUser(dta);
        },
      },
      {
        name: "sub",
        label: getTranslation("general:common.delete"),
        type: "icon",
        icon: "trash",
        iconVariant: "danger",
        notSortable: true,
        onClick: (dta) => {
          onDeleteUserFromOrg(organizationId, dta);
        },
      },
    ],
    settings: {
      sortEnabled: true,
      sortVisible: true,
      filterEnabled: true,
      labelSortBy: getTranslation("general:common.sortBy"),
      labelSearch: getTranslation("general:common.filterBy"),
      createSortIconFn: createSortIcon,
      filterFields: ["name", "familyName", "email"],
      actionBox,
    },
    pkFunction: (dta) => dta.sub,
  });

  return tableUsers;
}

function createOrgUserEdit({
  submitButton,
  orgId,
  prevData,
  showError,
  organizationData,
}: {
  submitButton: SlButton;
  orgId: string;
  prevData: ICognitoUserWithOrgData | undefined;
  showError: (e: ICustomError) => void;
  organizationData: IOrganization | undefined;
}): IFormReturn<ICognitoUserWithOrgData> | null {
  const holder = document.createElement("div");

  const availableUserTypeOptions = [
    { label: "USER", value: "USER" },
    { label: "ADMIN", value: "ADMIN" },
    { label: "SUPERADMIN", value: "SUPERADMIN" },
  ];

  const formFields: IFields<ICognitoUserWithOrgData> = [
    [
      {
        name: "name",
        label: "general:common.name",
        type: "textBox",
        initialValue: prevData?.name || "",
      },
      {
        name: "familyName",
        label: "general:users.familyName",
        type: "textBox",
        initialValue: prevData?.familyName || "",
      },
    ],
    [
      {
        name: "email",
        label: "general:common.email",
        type: "textBox",
        initialValue: prevData?.email || "",
        helpText: getTranslation("general:users.domainsHelpText", {
          domains: organizationData?.domains?.join(", ") || "",
        }),
      },
      {
        name: "organizationUserEnabled",
        label: "general:common.enabled",
        type: "checkbox",
        initialValue: prevData?.organizationUserEnabled === "1" ?? true,
        padStart: true,
      },
    ],
    {
      name: "organizationUserRole",
      label: "general:common.type",
      type: "radioButtonList",
      fieldset: true,
      initialValue: prevData?.organizationUserRole ?? "USER",
      options: availableUserTypeOptions,
    },
  ];

  const tedivoForm = new TedivoForm<ICognitoUserWithOrgData>({
    fields: formFields,
    onSubmit: () => undefined,
    formValidator: createFormValidator(false, organizationData?.domains || []),
    submitButton: submitButton,
    autoFocusOnFirstInput: true,
    hiddenData: prevData,
  });

  translateTedivoForm<ICognitoUserWithOrgData>({
    tedivoForm,
    getTranslation: getTranslation,
  });

  holder.appendChild(tedivoForm.form);

  return {
    node: holder,
    tedivoForm,
    submitFunction: submitPassedToEditDrawer,
  };

  async function submitPassedToEditDrawer() {
    const validResult = tedivoForm.doSubmitForm();

    if (validResult.success) {
      const values = validResult.data;
      if (prevData?.sub) {
        const data: ICognitoUserWithOrgData = {
          ...prevData,
          ...values,
          organizationId: orgId,
          organizationUserEnabled: values.organizationUserEnabled ? "1" : "0",
          organizationUserRole: values.organizationUserRole as TUserRole,
        };

        const resp = await Services.organizations.adminUpdateOrganizationUser(
          orgId,
          data,
        );

        if (!resp.data?.id) {
          showError({
            errorCode: String(resp.statusCode),
            message: resp.code || "errorModifyingUser",
            translationKey: resp.message || "errors:errorModifyingUser",
          });
          return false;
        }
      } else {
        const data: ICognitoUserWithOrgData = {
          ...values,
          organizationId: orgId,
          organizationUserEnabled: values.organizationUserEnabled ? "1" : "0",
          organizationUserRole: values.organizationUserRole as TUserRole,
        };

        const resp = await Services.organizations.adminCreateOrganizationUser(
          orgId,
          data,
        );

        if (!resp.data?.id) {
          showError({
            errorCode: String(resp.statusCode),
            message: resp.code || "errorCreatingUser",
            translationKey: resp.message || "errors:errorCreatingUser",
          });
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  function createFormValidator(
    hasPassword: boolean,
    allowedDomains: string[],
  ): z.Schema<ICognitoUserWithOrgData> {
    const formValidator = z
      .object({
        email: z
          .string()
          .email()
          .transform((v) => v?.toLowerCase()),
        name: z.string().min(1),
        familyName: z.string().min(1),
        organizationUserEnabled: z.boolean(),
        organizationUserRole: z.nativeEnum(UserTypeEnum),
        password: z.string().min(6).optional(),
      })
      .refine(
        (data) => {
          if (data.email) {
            const domain = (data.email.split("@")[1] || "")
              .toLowerCase()
              .trim();
            if (allowedDomains.indexOf(domain) < 0) return false;
            return true;
          } else return false;
        },
        {
          path: ["email"],
        },
      )
      .refine(
        (data) => {
          if (hasPassword) {
            return !!data.password;
          }
          return true;
        },
        {
          path: ["password"],
        },
      );

    return formValidator as unknown as z.Schema<ICognitoUserWithOrgData>;
  }
}

function mergeUserWithCurrentOrgData(
  orgId: string,
  user: ICognitoUser,
): ICognitoUserWithOrgData {
  const { organizations, ...rest } = user;
  const curr = organizations.find((o) => o.orgId === orgId);

  return {
    ...rest,
    organizationUserRole: curr?.role || "USER",
    organizationUserEnabled: curr?.uEnabled || "0",
  };
}

export function mergeUsersWithCurrentOrgData(
  orgId: string,
  users: ICognitoUser[],
): ICognitoUserWithOrgData[] {
  return users.map((user) => mergeUserWithCurrentOrgData(orgId, user));
}
