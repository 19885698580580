import SlSpinner from "@shoelace-style/shoelace/dist/components/spinner/spinner";

class FixedLoadingOverlay extends HTMLElement {
  private loadingDiv: HTMLElement;
  private intSpinner: SlSpinner;

  constructor() {
    super();

    this.loadingDiv = document.createElement("div");
    this.intSpinner = document.createElement("sl-spinner");
    this.intSpinner.style.fontSize = "6em";
    this.intSpinner.style.setProperty("--track-width", "4px");

    this.loadingDiv.className = "fixed_dialog__overlay";
  }

  connectedCallback() {
    this.loadingDiv.appendChild(this.intSpinner);
    this.appendChild(this.loadingDiv);
  }

  show() {
    this.loadingDiv.style.display = "flex";
  }

  hide() {
    this.loadingDiv.style.display = "none";
  }
}

customElements.define("loading-blocker-element", FixedLoadingOverlay);

declare global {
  interface HTMLElementTagNameMap {
    "loading-blocker-element": FixedLoadingOverlay;
  }
}

export default FixedLoadingOverlay;
