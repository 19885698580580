import {
  IAllBaysWith40sArePaired,
  allBaysWith40sArePaired,
} from "./allBaysWith40sArePaired";
import {
  IValidationResult,
  IValidator,
  TValidatorSpecialScopes,
} from "./types";

import { IGlobalUnits } from "../../units/globalUnits";
import { IOpenVesselDefinitionV1 } from "open-vessel-definition";

const validators: IValidator<IValidationResult>[] = [
  {
    name: "allBaysWith40sArePaired",
    description: "All bays with 40s are paired",
    fn: allBaysWith40sArePaired,
    specialScopes: ["exportStaf"],
  } as IValidator<IAllBaysWith40sArePaired>,
];

export default class OvdValidator {
  validators = validators;

  validate(
    json: IOpenVesselDefinitionV1,
    globalUnits: IGlobalUnits,
  ): IValidationResult[] {
    this.lastResult = this.validators.map((validator) =>
      validator.fn(validator, json, globalUnits),
    );

    return this.lastResult;
  }

  private lastResult: IValidationResult[] = [];

  public getLastResult = (
    scopes?: TValidatorSpecialScopes[],
  ): IValidationResult[] => {
    if (!this.lastResult) return [];

    return this.lastResult.filter(
      (a) =>
        !scopes ||
        (a.specialScopes && a.specialScopes.some((sc) => scopes.includes(sc))),
    );
  };
}
