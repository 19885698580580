import { stafToOvdV1Converter, } from "open-vessel-definition";
export default function stafFileConvertToOVD(f, vcgHeightFactor, lpp = 0, tier82is = 82) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onload = (ev) => {
            try {
                const target = ev.target;
                const targetResult = target.result;
                if (targetResult === null) {
                    reject({ message: "File Read error" });
                    return;
                }
                const stafFile = targetResult.toString();
                const json = stafToOvdV1Converter(stafFile, lpp, vcgHeightFactor, tier82is);
                if (json.shipData && json.baysData) {
                    useNameForMainNames(f.name.toUpperCase(), json);
                    add45ContainerToAvailableContainers(json);
                    resolve(json);
                }
                else {
                    const error = {
                        errorCode: "notAStafFile",
                        translationKey: "errors:notValidStafFile",
                        message: "Not a valid STAF file",
                    };
                    reject(error);
                    console.log(error.message);
                }
            }
            catch (err) {
                reject(err);
            }
        };
        reader.onerror = (ev) => {
            var _a;
            reject((_a = ev.target) === null || _a === void 0 ? void 0 : _a.error);
        };
        reader.readAsText(f, "utf-8");
    });
}
function useNameForMainNames(name, json) {
    json.shipData = Object.assign(Object.assign({}, json.shipData), splitNameForMainNames(name, json.shipData.shipClass));
}
export function splitNameForMainNames(name, stafShipClass) {
    // Use the name to create general shipName, shipClass and lineOperator
    const nameParts = name.toUpperCase().split(".");
    nameParts.pop(); // Remove the extension TXT
    const splittedParts = {
        lineOperator: "",
        shipClass: "",
        shipName: "",
    };
    if (nameParts.length > 1) {
        splittedParts.lineOperator = nameParts[0].trim();
        splittedParts.shipName = (nameParts[2] || "").trim();
        splittedParts.shipClass = (stafShipClass || nameParts[1]).trim();
    }
    return splittedParts;
}
function add45ContainerToAvailableContainers(json) {
    const ac = json.shipData.containersLengths;
    if (ac.indexOf(45) < 0)
        ac.push(45);
}
