/* eslint-disable @typescript-eslint/no-explicit-any */
import { __awaiter } from "tslib";
import HttpClient from "../../services/HttpClient";
const domainsBaseUrl = "logEvents";
class LogEventsBeaconServiceClass {
    constructor() {
        this.notifyXhttp = (ev) => __awaiter(this, void 0, void 0, function* () {
            const client = new HttpClient();
            return yield client.request({
                method: "POST",
                url: `${domainsBaseUrl}`,
                data: ev,
            });
        });
        this.getFileLogEvents = (orgId, fileId, lastEvaluatedKey) => __awaiter(this, void 0, void 0, function* () {
            const client = new HttpClient();
            const url = [
                `/fileEvents/${orgId}/${fileId}`,
                lastEvaluatedKey
                    ? `?lastEvaluatedKey=${encodeURIComponent(lastEvaluatedKey.replace(/#/g, "|"))}`
                    : "",
            ].join("");
            return yield client.request({
                method: "GET",
                url,
            });
        });
    }
}
const LogEventsBeaconService = new LogEventsBeaconServiceClass();
export default LogEventsBeaconService;
