/* eslint-disable @typescript-eslint/no-explicit-any */

const googleTags = {
  isActive: false,

  initialize(appId?: string) {
    if (!appId) return;

    googleTags.isActive = true;

    function loadScript(c: any, l: any, a: any, r: any, i: any) {
      const t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.googletagmanager.com/gtag/js?id=" + i;
      const y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    }

    loadScript(window, document, "googleTags", "script", appId);

    (window as any).dataLayer = (window as any).dataLayer || [];

    function gtag(...args: any[]) {
      (window as any).dataLayer.push(args);
    }

    gtag("js", new Date());
    gtag("config", appId);
  },
};

export default googleTags;
