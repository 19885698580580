import { ILidData } from "open-vessel-definition";

export function validateLidData(lidData?: ILidData[]): ILidData[] {
  if (!lidData || !lidData.length) return [];

  return lidData.filter((lid) => {
    return (
      lid.label !== undefined &&
      lid.portIsoRow !== undefined &&
      lid.starboardIsoRow !== undefined &&
      lid.startIsoBay !== undefined &&
      lid.endIsoBay !== undefined
    );
  });

  return [];
}
