import SlCheckbox from "@shoelace-style/shoelace/dist/components/checkbox/checkbox";
import SlSwitch from "@shoelace-style/shoelace/dist/components/switch/switch";

export function onCheckboxKeyPressed(ev: KeyboardEvent) {
  const t = ev.target as SlCheckbox | SlSwitch;
  if (t.disabled) return;
  if (ev.key === " ") {
    ev.preventDefault();
    t.checked = !t.checked;
    t.dispatchEvent(new Event("sl-change"));
  }
}
