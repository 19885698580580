import HttpClient, { IResponseModel } from "../HttpClient";

import { IResultByImoData } from "@baplie-viewer2/tedivo-api-models";

const MarineTrafficService = {
  searchVesselByImoCode: async (
    imoCode: string,
    forceApi = false
  ): Promise<IResponseModel<IResultByImoData>> => {
    const client = new HttpClient();
    const url = [
      `vesselInfo/byImo/${encodeURI(imoCode)}`,
      forceApi ? "forceApi=true" : "",
    ]
      .filter(Boolean)
      .join("?");

    return await client.request<IResultByImoData>({
      method: "GET",
      url,
    });
  },

  searchVesselByImoCodeWithParticulars: async (
    imoCode: string
  ): Promise<IResponseModel<IResultByImoData>> => {
    const client = new HttpClient();
    return await client.request<IResultByImoData>({
      method: "GET",
      url: `vesselInfo/byImoWithParticulars/${encodeURI(imoCode)}`,
    });
  },
};

export default MarineTrafficService;
