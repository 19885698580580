import {
  IBayLevelData,
  ISlotData,
  TContainerLengths,
} from "open-vessel-definition";

import { IJoinedRowTierPattern } from "open-vessel-definition/build/src/models/base/types/IPositionPatterns";
import { createDictionary } from "@baplie-viewer2/tedivo-pure-helpers";
import { getAllThePairedBays } from "./getAllThePairedBays";

/** Returns the TEUS of slots, taking into account paired bay information */
export function calculateTeusFromSlots(bls: IBayLevelData[]) {
  const blsByBayAndLevel = createDictionary(
    bls,
    (bl) => `${bl.isoBay}-${bl.level}`,
  );

  const { pairedBays, unpairedBays } = getAllThePairedBays(bls, false);

  const teusInPairedBays = pairedBays.reduce((pAcc, pair) => {
    const baseBay = blsByBayAndLevel[`${pair.base}-${pair.level}`];
    const pairedBay = blsByBayAndLevel[`${pair.paired}-${pair.level}`];

    const slotsFromBase = baseBay.perSlotInfo || {};
    const slotsFromPaired = pairedBay.perSlotInfo || {};

    const allSlotKeys = new Set([
      ...(Object.keys(slotsFromBase) as IJoinedRowTierPattern[]),
      ...(Object.keys(slotsFromPaired) as IJoinedRowTierPattern[]),
    ]);

    return (
      pAcc +
      Array.from(allSlotKeys).reduce((acc, slotKey) => {
        const slotFromBase = slotsFromBase[slotKey];
        const slotFromPaired = slotsFromPaired[slotKey];

        if (slotFromBase === undefined && slotFromPaired === undefined) {
          return acc;
        }

        const pairedTeus = Math.min(
          getTeusFromSlot(slotFromBase) + getTeusFromSlot(slotFromPaired),
          2,
        );

        return acc + pairedTeus;
      }, 0)
    );
  }, 0);

  const teusInUnpairedBays = unpairedBays.reduce((pAcc, pair) => {
    const baseBay = blsByBayAndLevel[`${pair.base}-${pair.level}`];
    const slotsFromBase = baseBay.perSlotInfo || {};

    const allSlotKeys = Object.keys(slotsFromBase) as IJoinedRowTierPattern[];

    return (
      pAcc +
      Array.from(allSlotKeys).reduce((acc, slotKey) => {
        const slotFromBase = slotsFromBase[slotKey];

        const teus = Math.min(getTeusFromSlot(slotFromBase), 2);

        return acc + teus;
      }, 0)
    );
  }, 0);

  return teusInPairedBays + teusInUnpairedBays;
}

export function getTeusFromSlot(slotInfo: ISlotData | undefined): 0 | 1 | 2 {
  if (!slotInfo || slotInfo.restricted) return 0;

  const slotSizes = Object.keys(slotInfo.sizes).map(
    Number,
  ) as TContainerLengths[];

  const teu = slotSizes.some((size) => size > 24)
    ? 2
    : slotSizes.some((size) => size <= 24)
    ? 1
    : 0;

  return teu;
}
