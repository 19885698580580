import { formatDateInCurrentTimezone, } from "@baplie-viewer2/tedivo-pure-helpers";
import SmartTable from "../../common/smartTable/smart-table.element";
import { createModalToForNotes } from "../view-json/parts/createModalToForNotes";
import { createModalToTagFiles } from "../view-json/parts/createModalToTagFiles";
import { getTranslation } from "../../../app/i18/i18tn";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import showCGsCell from "./showCGsCell";
import { showFileHistory } from "../view-json/showFileHistory";
export function createFilesList({ data = [], fileTags, organizationUsersBySub, onDelete, onOpen, onSort, onClone, onRefreshList, onFilter, onTagsUpdated, onTagSelected, onColumnSelectorChange, onError, sortBy, actionsDropdown, dialogHistory, dialogTagFile, dialogFileNotes, isReadOnly, initialTextFilter, initialTagFilter, initialColumnsVisible, canShowDeleteButton, }) {
    var _a;
    const isOwnCloud = !!onDelete;
    let internalFileTags = fileTags || { tags: {}, filesByTag: {} };
    let selectTagNode;
    let columnsVisible = initialColumnsVisible || [];
    const fields = [
        {
            name: "name",
            label: getTranslation("general:common:name"),
        },
        {
            name: "imoCode",
            label: getTranslation("view:imoCode"),
        },
        {
            name: "callSign",
            label: getTranslation("view:callSign"),
        },
        {
            name: "cgsPercentage",
            label: getTranslation("view:cgsPercentage"),
            className: "right-aligned",
            type: "number",
            mapper: showCGsCell,
            fixedWidth: "100px",
        },
        {
            name: "hasHatchCovers",
            label: getTranslation("view:hasHatchCovers"),
            valueFunction: (dta) => dta.hasHatchCovers
                ? getTranslation("general:common.yes")
                : getTranslation("general:common.no"),
            className: "centered",
            fixedWidth: "100px",
        },
        {
            name: "state",
            label: getTranslation("general:fileState"),
            valueFunction: (dta) => getTranslation(`enums:FileStateEnum.${dta.state}`),
            className: "centered",
        },
        isOwnCloud
            ? {
                name: "userSub",
                label: getTranslation("general:common.author"),
                valueFunction: (dta) => dta.fromBvoName
                    ? getTranslation("general:bvo")
                    : (organizationUsersBySub === null || organizationUsersBySub === void 0 ? void 0 : organizationUsersBySub[dta.userSub]) || "-",
                className: "centered",
            }
            : undefined,
        isOwnCloud
            ? {
                name: "lastAuthor",
                label: getTranslation("general:common.modifiedBy"),
                valueFunction: (dta) => lastUpdatedByFn(dta, organizationUsersBySub),
                className: "centered",
            }
            : undefined,
        {
            name: "lastModified",
            label: getTranslation("general:common:lastUpdated"),
            valueFunction: (dta) => formatDateInCurrentTimezone(dta.lastModified),
            className: "centered",
        },
        isOwnCloud
            ? {
                name: "tagsStr",
                label: getTranslation("general:fileTags.tags"),
                fixedWidth: "300px",
            }
            : undefined,
        {
            name: "fileId",
            label: isOwnCloud
                ? getTranslation("general:common.edit")
                : getTranslation("general:common.view"),
            type: "icon",
            icon: isOwnCloud ? "pencil" : "eye",
            notSortable: true,
            fixedWidth: "40px",
            smallPadding: true,
            onClick: (dta) => {
                onOpen(dta.fileId);
            },
        },
        isOwnCloud
            ? {
                name: "fileId",
                label: getTranslation("general:common.actions"),
                className: "files-actions",
                type: "actions",
                iconVariant: (dta) => dta.cloneDiffers || dta.consumersUpdates ? "warning" : "primary",
                icon: "three-dots-vertical",
                notSortable: true,
                fixedWidth: "40px",
                smallPadding: true,
                onClick: (dta, name, table, value) => {
                    let node;
                    switch (value) {
                        case "duplicate":
                            if (onClone)
                                onClone(dta);
                            break;
                        case "delete":
                            onDelete(dta.fileId, dta);
                            break;
                        case "fileHistory":
                            removeChildren(dialogHistory);
                            node = showFileHistory({
                                fileId: dta.fileId,
                                organizationId: dta.organizationId,
                                createdAt: dta.createdAt,
                                updatedAt: dta.lastModified,
                                userSub: dta.userSub,
                                updatedBy: dta.lastAuthor,
                                name: dta.name,
                                fromBvoName: dta.fromBvoName,
                                lastComment: dta.lastComment,
                            });
                            if (node)
                                dialogHistory.appendChild(node);
                            dialogHistory.label = `${getTranslation("view:edit.fileHistory.title")}: ${dta.name}`;
                            dialogHistory.show();
                            break;
                        case "viewTvlMap":
                            router.navigate(routeFns.fileMap(dta.fileId));
                            break;
                        case "manageTags":
                            if (dialogTagFile)
                                createModalToTagFiles({
                                    fileId: dta.fileId,
                                    fileName: dta.name,
                                    modal: dialogTagFile,
                                    filesTags: internalFileTags,
                                    onUpdated: (fileTags) => {
                                        if (fileTags) {
                                            internalFileTags = fileTags;
                                            if (onTagsUpdated)
                                                onTagsUpdated(fileTags, table);
                                            updateTagsFilter(initialTagFilter, internalFileTags, selectTagNode);
                                        }
                                    },
                                });
                            break;
                        case "viewNotes":
                            if (dialogFileNotes)
                                createModalToForNotes({
                                    organizationId: dta.organizationId,
                                    fileId: dta.fileId,
                                    fileName: dta.name,
                                    modal: dialogFileNotes,
                                    organizationUsersBySub,
                                    onUpdated: undefined,
                                });
                            break;
                    }
                },
                options: (d) => [
                    {
                        label: getTranslation("general:fileTags.tags"),
                        value: "manageTags",
                        icon: "tags",
                    },
                    {
                        label: getTranslation("view:edit.fileHistory.title"),
                        value: "fileHistory",
                        icon: "binoculars",
                    },
                    {
                        label: getTranslation("general:fileNotes.fileNotes"),
                        value: "viewNotes",
                        icon: "stickies",
                    },
                    {
                        label: getTranslation("view:fileMap.tvlMap"),
                        value: "viewTvlMap",
                        icon: "geo",
                        variant: d.cloneDiffers || d.consumersUpdates ? "warn" : "",
                    },
                    isReadOnly
                        ? undefined
                        : onClone
                            ? {
                                label: getTranslation("general:common.duplicate"),
                                value: "duplicate",
                                icon: "file-earmark-plus",
                            }
                            : undefined,
                    isReadOnly
                        ? undefined
                        : canShowDeleteButton
                            ? {
                                label: getTranslation("general:common.delete"),
                                value: "delete",
                                icon: "trash",
                                variant: "danger",
                            }
                            : undefined,
                ].filter(Boolean),
            }
            : undefined,
    ];
    const filesCount = data.reduce((acc, f) => {
        acc[f.state]++;
        return acc;
    }, { DRAFT: 0, ORG_READY: 0, COMMUNITY: 0 });
    const filesCountStr = ["DRAFT", "ORG_READY", "COMMUNITY"]
        .reduce((acc, state) => {
        if (filesCount[state]) {
            acc.push(`${getTranslation(`enums:FileStateEnum.${state}`)} ${filesCount[state]}`);
        }
        return acc;
    }, [])
        .join(" / ");
    const tableTitle = `${isOwnCloud
        ? getTranslation("menu:myCloud")
        : getTranslation("menu:theirCloud")} <small class="filesCount">(${filesCountStr})</small>`;
    const table = new SmartTable().initialize({
        className: "tvd-table tvd-table-smart-table tvd-table-sticky-caption",
        title: tableTitle,
        data,
        defaultSort: sortBy.name,
        defaultSortAsc: sortBy.ascending,
        fields: fields.filter(Boolean), // Remove undefined fields
        settings: {
            sortEnabled: true,
            sortVisible: true,
            filterEnabled: true,
            labelSearch: getTranslation("general:common.filterBy"),
            labelSortBy: getTranslation("general:common.sortBy"),
            createSortIconFn: createSortIcon,
            actionBox: actionsDropdown,
            filterFields: ["name", "imoCode", "callSign"],
            columnSelector: true,
            columnSelectorHelp: getTranslation("general:common.selectColumns"),
            columnSelectorFixed: ["name", "state", "fileId"],
            onColumnSelectorChange: (fields) => {
                columnsVisible = fields;
                if (onColumnSelectorChange)
                    onColumnSelectorChange(fields);
            },
        },
        initialFilter: initialTextFilter,
        pkFunction: (dta) => dta.fileId,
        onSortParamsChanged: onSort,
        onFilterTextChange: onFilter,
        rowClassFunction: (dta) => {
            if (!dta.imoCode || !dta.callSign)
                return "warning";
            return undefined;
        },
    });
    table.columnsVisible = columnsVisible || [];
    // Add tags filter
    if (fileTags) {
        const { tooltip, selectTag } = addTagsFilter(internalFileTags, table, onTagSelected, initialTagFilter);
        selectTag.addEventListener("sl-change", (e) => {
            var _a;
            const tag = e.target.value;
            table.restrictedToPks = (_a = internalFileTags.filesByTag[tag]) !== null && _a !== void 0 ? _a : [];
            window.setTimeout(() => {
                if (tag)
                    selectTag.focus();
            }, 500);
            if (onTagSelected)
                onTagSelected(tag);
        });
        selectTagNode = selectTag;
        table.appendToHeaderPseudoSlot(tooltip, false);
        if (initialTagFilter) {
            table.restrictedToPks =
                (_a = internalFileTags.filesByTag[initialTagFilter]) !== null && _a !== void 0 ? _a : [];
            initialTagFilter = "";
        }
    }
    return table;
}
// function addColumnsSelector(
//   fields: Array<IField<IFileDisplay> | undefined>,
//   table: SmartTable<IFile>,
//   dialog: IntegratedDialog<"ok"> | undefined,
// ) {
//   if (!dialog) return;
//   const btnSelectColumns = document.createElement("sl-button");
//   btnSelectColumns.className = "btn-select-columns";
//   btnSelectColumns.title = getTranslation("general:common.selectColumns");
//   const icon = document.createElement("sl-icon");
//   icon.name = "columns";
//   icon.slot = "prefix";
//   btnSelectColumns.appendChild(icon);
//   return btnSelectColumns;
// }
function addTagsFilter(internalFileTags, table, onTagSelected, initialTagFilter) {
    const tooltip = document.createElement("sl-tooltip");
    tooltip.content = getTranslation("general:fileTags.tagHelp");
    const selectTag = document.createElement("sl-select");
    selectTag.title = getTranslation("general:fileTags.tags");
    selectTag.placeholder = getTranslation("general:fileTags.tagFilter");
    selectTag.clearable = true;
    selectTag.filled = false;
    selectTag.style.textAlign = "left";
    //selectTag.style.maxWidth = "270px";
    selectTag.defaultValue = "";
    const iconTags = document.createElement("sl-icon");
    iconTags.name = "tags";
    iconTags.slot = "prefix";
    selectTag.appendChild(iconTags);
    updateTagsFilter(initialTagFilter, internalFileTags, selectTag);
    tooltip.appendChild(selectTag);
    return { selectTag, tooltip };
}
function updateTagsFilter(tag, internalFileTags, selectTag) {
    if (!selectTag)
        return;
    removeChildren(selectTag);
    const tags = Object.keys(internalFileTags.tags)
        .filter((tag) => { var _a; return ((_a = internalFileTags.filesByTag[tag]) === null || _a === void 0 ? void 0 : _a.length) > 0; })
        .sort();
    if (tags.length > 0) {
        tags.forEach((tag) => {
            const option = document.createElement("sl-option");
            option.value = tag;
            option.innerHTML = internalFileTags.tags[tag];
            selectTag.appendChild(option);
        });
    }
    if (tag && tags.includes(tag))
        selectTag.value = tag;
}
function lastUpdatedByFn(dta, organizationUsersBySub) {
    const la = dta.lastAuthor
        ? organizationUsersBySub === null || organizationUsersBySub === void 0 ? void 0 : organizationUsersBySub[dta.lastAuthor]
        : undefined;
    // Get initials only of last author
    if (la) {
        const names = la.split(" ");
        return names.reduce((acc, curr, idx) => acc + (idx === 0 ? curr + " " : curr.charAt(0) + "."), "");
    }
    return "-";
}
function createSortIcon(asc) {
    const icon = document.createElement("sl-icon");
    icon.name = asc ? "sort-down-alt" : "sort-up-alt";
    return icon;
}
