import Bugsnag, { BreadcrumbType } from "@bugsnag/js";

export const errorTracking = {
  start(bugsnagId: string, stage: string | undefined) {
    const appVersion = `v1.0.${process.env.NX_BUILD_NUMBER ?? "Beta"}`;
    Bugsnag.start({
      apiKey: bugsnagId || "",
      releaseStage: stage || "unknown",
      appVersion,
      maxBreadcrumbs: 100,
    });

    console.info("TEDIVO Vessel Designer ", appVersion);

    if (process.env.NX_STAGE === "dev") window.Bugsnag = Bugsnag;
  },

  identify(
    userSub: string,
    email: string,
    displayName: string,
    organizationName: string
  ) {
    if (!Bugsnag.isStarted()) return;

    Bugsnag.setUser(userSub, email, `${displayName} / ${organizationName}`);
  },

  leaveBreadcrumb(
    message: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    metadata?: { [key: string]: any },
    type?: BreadcrumbType
  ) {
    if (!Bugsnag.isStarted()) return;

    Bugsnag.leaveBreadcrumb(message, metadata, type);
  },
};
