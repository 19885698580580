import { IShipDataFromStaf, stafToOvdShipData } from "open-vessel-definition";

export default function stafFileReadShipData(
  f: File
): Promise<IShipDataFromStaf> {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onload = (ev: ProgressEvent) => {
      try {
        const target = ev.target as FileReader;
        const targetResult = target.result;

        if (targetResult === null) {
          reject({ message: "File Read error" });
          return;
        }

        const stafFile = targetResult.toString();
        const json = stafToOvdShipData(stafFile) as IShipDataFromStaf;

        if (json.shipClass && json.positionFormat && json.lcgOptions) {
          resolve(json);
        } else {
          const error = {
            errorCode: "notAStafFile",
            translationKey: "errors:notValidStafFile",
            message: "Not a valid STAF file",
          };
          reject(error);
          console.log(error.message);
        }
      } catch (err) {
        reject(err);
      }
    };

    reader.onerror = (ev: ProgressEvent<FileReader>) => {
      reject(ev.target?.error);
    };

    reader.readAsText(f, "utf-8");
  });
}
