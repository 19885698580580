import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";

import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import ICustomError from "../../types/ICustomError";
import IntegratedDialog from "../../common/IntegratedDialog";
import Services from "../../../app/services";
import SlButton from "@shoelace-style/shoelace/dist/components/button/button";
import SlIcon from "@shoelace-style/shoelace/dist/components/icon/icon";
import securityModule from "../../../app/security/SecurityModule";
import { systemRights } from "@baplie-viewer2/tedivo-api-models";

export class TVDVoteTvlFileComponent extends HTMLElement {
  public static observedAttributes = [];

  private voteButton: SlButton;
  private iconButton: SlIcon;

  private _fileOrgId: string | undefined = undefined;
  private _fileId: string | undefined = undefined;
  private _isOwnVote = false;

  private i18nCR: I18nComponentRegisterer;

  constructor() {
    super();

    this.voteButton = document.createElement("sl-button");
    this.iconButton = document.createElement("sl-icon");

    this.i18nCR = new I18nComponentRegisterer(i18nReactive);
  }

  set fileOrgId(orgId: string) {
    this._fileOrgId = orgId;
  }

  set fileId(fileId: string) {
    this._fileId = fileId;
  }

  connectedCallback() {
    this.createNodes();
    this.update();
  }

  private createNodes() {
    this.iconButton.name = "heart";
    this.iconButton.slot = "prefix";

    this.voteButton.variant = "success";
    this.voteButton.size = "small";
    this.voteButton.outline = true;
    this.voteButton.innerHTML = getTranslation("general:votes.totalVotes", {
      totalVotes: 0,
    });
    this.voteButton.disabled = true;
    this.voteButton.appendChild(this.iconButton);

    this.voteButton.addEventListener("click", this.toggleVote);
    this.appendChild(this.voteButton);
  }

  public update = async () => {
    if (!this._fileOrgId || !this._fileId) return;

    this.voteButton.loading = true;
    const resp = await Services.files.getTvlVotes(
      this._fileOrgId,
      this._fileId,
    );
    this.voteButton.loading = false;

    if (resp.statusCode === 200 || resp.statusCode === 204) {
      this.updateVoteButtonContent(
        resp.data?.totalVotes || 0,
        resp.data?.date ? true : false,
      );
    }
  };

  private updateVoteButtonContent = (
    totalVotes: number,
    isOwnVote: boolean,
  ) => {
    this.i18nCR.addConsumer(
      this.voteButton,
      "general:votes.totalVotes",
      "innerHTML",
      { totalVotes },
    );
    this.iconButton.name = isOwnVote ? "heart-fill" : "heart";
    this.voteButton.appendChild(this.iconButton);
    this.voteButton.disabled = false;
    this._isOwnVote = isOwnVote;
  };

  private toggleVote = async () => {
    if (
      !securityModule.userHasPermission(systemRights.ORG.CanVotePublicFiles) ||
      !this._fileOrgId ||
      !this._fileId ||
      !(await continueWithVotingAction(this._isOwnVote))
    )
      return;

    this.voteButton.loading = true;
    const resp = await Services.files.toggleOwnTvlVote(
      this._fileOrgId,
      this._fileId,
    );
    this.voteButton.loading = false;

    if (resp.statusCode === 204 || resp.statusCode === 200) {
      this.updateVoteButtonContent(
        resp.data?.totalVotes || 0,
        resp.data?.date ? true : false,
      );
    } else {
      const err = resp.message || "Error toggling vote";
      document.body.dispatchEvent(
        new CustomEvent<ICustomError>("customError", {
          detail: {
            errorCode: "",
            message: "general:votes.vote",
            translationKey: err,
          },
        }),
      );
    }

    async function continueWithVotingAction(
      isOwnVote: boolean,
    ): Promise<boolean> {
      return new Promise<boolean>((resolve) => {
        if (!isOwnVote) return resolve(true);

        const intDialog = new IntegratedDialog<"remove" | "cancel">({
          parentNode: document.body,
          removeDialogWhenHidden: true,
          buttonsAttrs: [
            {
              label: "general:votes.removeVote",
              value: "remove",
              variant: "danger",
              type: "submit",
              icon: "heartbreak-fill",
            },
            {
              label: "general:common.cancel",
              value: "cancel",
              variant: "primary",
              type: "button",
              icon: "heart-fill",
              autofocus: true,
            },
          ],
        });

        intDialog.onButtonClicked = (value): void => {
          resolve(value === "remove");
        };

        intDialog.show(
          getTranslation("general:votes.removeVoteQuestion"),
          getTranslation("general:votes.removeVoteConfirm"),
        );
      });
    }
  };
}

customElements.define("tvd-vote-tvl-component", TVDVoteTvlFileComponent);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-vote-tvl-component": TVDVoteTvlFileComponent;
  }
}
