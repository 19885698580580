import { IFeaturesAllowed, TContainerLengths } from "open-vessel-definition";
import {
  ILegendOptions,
  TReducerISlotDataActions,
  getSlotCapabilitiesLegendOptions,
} from "@baplie-viewer2/tedivo-bay-grid-core";

import EditToolsEnum from "./types/EditToolsEnum";
import { IOptionChanged } from "./../../../../typings";
import { IToolsState } from "./types/IToolsState";
import SlButton from "@shoelace-style/shoelace/dist/components/button/button";
import SlRadioGroup from "@shoelace-style/shoelace/dist/components/radio-group/radio-group";
import SlSwitch from "@shoelace-style/shoelace/dist/components/switch/switch";
import { createMultiEditButton } from "@baplie-viewer2/tedivo-form";
import { getTranslation } from "../../../app/i18/i18tn";

interface IMenuOption {
  id: EditToolsEnum;
  label: string;
  optionsControl?: "sl-radio" | "sl-checkbox";
  initial?: TReducerISlotDataActions[];
  getOptions?: () => ILegendOptions[];
}

const getMenuOptions = (
  availableLengths: TContainerLengths[],
  featuresAllowed = {} as IFeaturesAllowed,
): IMenuOption[] => {
  const options = [
    {
      id: EditToolsEnum.ADD_MANY,
      label: "view:edit.tools.addMany",
      optionsControl: "sl-checkbox",
      initial: ["ADD_SIZE_20", "ADD_SIZE_40"],
      getOptions: () =>
        getSlotCapabilitiesLegendOptions(
          availableLengths,
          ["sizes", "misc"],
          featuresAllowed,
        ),
    },
    {
      id: EditToolsEnum.ADD_REMOVE_SINGLE,
      label: "view:edit.tools.addRemoveSingle",
      optionsControl: "sl-radio",
      initial: ["ADD_SIZE_20"],
      getOptions: () =>
        getSlotCapabilitiesLegendOptions(
          availableLengths,
          ["sizes", "misc"],
          featuresAllowed,
        ),
    },
    featuresAllowed.slotConeRequired
      ? {
          id: EditToolsEnum.ADD_REMOVE_SPECIAL,
          label: "view:edit.tools.special",
          optionsControl: "sl-radio",
          initial: ["ADD_SIZE_20_CONEREQ"],
          getOptions: () =>
            getSlotCapabilitiesLegendOptions(
              availableLengths,
              ["sizesWithCones"],
              featuresAllowed,
            ),
        }
      : undefined,
    {
      id: EditToolsEnum.ADD_REMOVE_RESTRICTED,
      label: "view:edit.tools.restricted",
    },
    {
      id: EditToolsEnum.CLEAR,
      label: "view:edit.tools.clear",
    },
  ].filter(Boolean);

  return options as IMenuOption[];
};

/**
 * Generates the menu options
 * @returns HTMLNode
 */
export class EditToolsComponent extends HTMLElement {
  state: IToolsState = {
    activeTool: EditToolsEnum.ADD_MANY,
    tools: {
      [EditToolsEnum.ADD_MANY]: [],
      [EditToolsEnum.ADD_REMOVE_SINGLE]: [],
      [EditToolsEnum.CLEAR]: [],
      [EditToolsEnum.ADD_REMOVE_RESTRICTED]: [],
      [EditToolsEnum.ADD_REMOVE_SPECIAL]: [],
    },
  };

  public containersLengths: Array<TContainerLengths> = [];
  public featuresAllowed: IFeaturesAllowed | undefined = {} as IFeaturesAllowed;

  private chkEmphasizePairedSlots: SlSwitch | undefined;
  private chkMirrorSlotsDefinition: SlSwitch | undefined;

  get mirrorSlotsDefinition() {
    return this.chkMirrorSlotsDefinition?.checked || false;
  }

  get emphasizedValue() {
    return this.chkEmphasizePairedSlots?.checked || false;
  }

  get emphasizeCheckboxDisabled() {
    return this.chkEmphasizePairedSlots?.disabled || false;
  }
  set emphasizeCheckboxDisabled(v) {
    if (this.chkEmphasizePairedSlots) {
      this.chkEmphasizePairedSlots.disabled = v;
    }
  }

  connectedCallback() {
    const toolsDiv = document.createElement("div");
    toolsDiv.className = "edit-tools";

    // Main buttons
    const label = document.createElement("label");
    label.innerHTML = getTranslation("view:edit.tools.clickAction");

    // flex-holder
    const flexHolderTop = document.createElement("div");
    flexHolderTop.className = "flex-holder-top";

    // Add the buttons to main
    const buttonGroup = document.createElement("sl-radio-group");
    buttonGroup.addEventListener("click", this.onClickMenu, false);

    // Emphasize paired slots
    const chkEmphasizePairedSlots = document.createElement("sl-switch");
    chkEmphasizePairedSlots.innerHTML = getTranslation(
      "view:edit.helpers.highlightPairedSlots",
    );
    chkEmphasizePairedSlots.addEventListener(
      "sl-change",
      (ev) => {
        const isChecked = (ev.target as SlSwitch).checked;
        this.dispatchEvent(
          new CustomEvent<ICustomEventEmphasizeCheckbox>(
            "emphasizePairedSlotsChanged",
            { detail: { isChecked } },
          ),
        );
      },
      false,
    );
    this.chkEmphasizePairedSlots = chkEmphasizePairedSlots;

    // Mirror slots definition
    const chkMirrorSlotsDefinition = document.createElement("sl-switch");
    chkMirrorSlotsDefinition.innerHTML = getTranslation(
      "view:edit.helpers.mirrorSlotsDefinition",
    );
    chkMirrorSlotsDefinition.checked = true;
    chkMirrorSlotsDefinition.addEventListener(
      "sl-change",
      (ev) => {
        const isChecked = (ev.target as SlSwitch).checked;
        this.dispatchEvent(
          new CustomEvent<ICustomEventEmphasizeCheckbox>(
            "mirrorSlotsDefinitionChanged",
            { detail: { isChecked } },
          ),
        );
      },
      false,
    );
    this.chkMirrorSlotsDefinition = chkMirrorSlotsDefinition;

    // Helpers

    const helpersHolder = document.createElement("div");
    helpersHolder.className = "helpers-holder";
    const helpersMainButton = createMultiEditButton({
      id: `helpersCGs`,
      title: getTranslation("view:edit.cgHelpers.title"),
      size: "small",
      variant: "primary",
      showTitleAsTooltip: false,
      iconName: "command",
      options: [
        {
          value: "copyFromBay",
          name: getTranslation("view:edit.helpers.copyFromBay.title"),
          action: () => {
            this.dispatchEvent(new CustomEvent("copyFromBay"));
          },
        },
        {
          value: "remapTiers",
          name: getTranslation("view:edit.helpers.remapTiers.title"),
          action: () => {
            this.dispatchEvent(new CustomEvent("remapTiers"));
          },
        },
        {
          value: "move40Definitions",
          name: getTranslation("view:edit.helpers.move40Definitions.title"),
          action: () => {
            this.dispatchEvent(new CustomEvent("move40Definitions"));
          },
        },
      ],
    });
    helpersHolder.appendChild(helpersMainButton);

    // Options for each button
    const toolsOptionsDiv = document.createElement("div");
    toolsOptionsDiv.className = "tools-options";

    getMenuOptions(this.containersLengths || [], this.featuresAllowed).forEach(
      (option, idx) => {
        const button = document.createElement("sl-radio-button");
        button.value = String(option.id);
        button.pill = true;
        button.innerHTML = getTranslation(option.label);
        buttonGroup.appendChild(button);

        if (idx === 0) {
          this.state.activeTool = option.id;
          buttonGroup.value = String(option.id);
        }

        this.state.tools[option.id] = [];

        const d = document.createElement("div");
        d.className = "tools-options-inst";
        d.dataset.value = String(option.id);
        toolsOptionsDiv.appendChild(d);
        d.style.display = idx === 0 ? "flex" : "none";

        // Add the toolsOptions to each option
        if (option.optionsControl) {
          const parent =
            option.optionsControl === "sl-radio"
              ? document.createElement("sl-radio-group")
              : d;

          if (option.getOptions) {
            const toolsOptions = option.getOptions();
            toolsOptions.forEach((v) => {
              this.createSubOption(option, v, this.state, parent);
            });
          }

          if (option.optionsControl === "sl-radio") {
            d.appendChild(parent);
            // sl-radio-group uses "value" (v2.0.82), not individual sl-radios to assign value
            if (option.initial)
              (parent as SlRadioGroup).value = option.initial[0];
            // same version needs listener
            parent.addEventListener(
              "sl-change",
              () => {
                const state = this.state;
                const menuId = option.id;
                const value = (parent as SlRadioGroup)
                  .value as TReducerISlotDataActions;
                if (state.tools[menuId][0] === value) return;
                state.tools[option.id] = [value];

                this.dispatchEvent(
                  new CustomEvent<IOptionChanged>("optionChanged", {
                    detail: { menuId, optionId: value, state },
                  }),
                );
              },
              false,
            );
          }
        }
      },
    );

    flexHolderTop.appendChild(buttonGroup);
    flexHolderTop.appendChild(helpersHolder);
    flexHolderTop.appendChild(chkMirrorSlotsDefinition);
    flexHolderTop.appendChild(chkEmphasizePairedSlots);

    toolsDiv.appendChild(label);
    toolsDiv.appendChild(flexHolderTop);
    toolsDiv.appendChild(toolsOptionsDiv);

    this.appendChild(toolsDiv);

    // Start with emphasized slots of pairedBay
    chkEmphasizePairedSlots.checked = true;
    chkEmphasizePairedSlots.dispatchEvent(new Event("sl-change"));
  }

  private onClickMenu = (ev: Event) => {
    const target = ev.target as SlButton;
    const value = target.value;

    this.state.activeTool = Number(value);

    // hack
    document
      .querySelectorAll<HTMLElement>(".tools-options-inst")
      .forEach((d) => {
        d.style.display = d.dataset.value === value ? "flex" : "none";
      });
  };

  private onClickOption = (
    ev: Event,
    menuId: EditToolsEnum,
    optionId: TReducerISlotDataActions,
    optionsControl: "sl-checkbox" | "sl-radio",
  ) => {
    const state = this.state;

    if (optionsControl === "sl-checkbox") {
      if (state.tools[menuId].indexOf(optionId) >= 0) {
        state.tools[menuId] = state.tools[menuId].filter((v) => v !== optionId);
      } else {
        state.tools[menuId].push(optionId);
      }
    } else {
      if (state.tools[menuId][0] === optionId) return;
      state.tools[menuId] = [optionId];
    }

    this.dispatchEvent(
      new CustomEvent<IOptionChanged>("optionChanged", {
        detail: { menuId, optionId, state },
      }),
    );
  };

  private createSubOption(
    option: IMenuOption,
    v: ILegendOptions,
    state: IToolsState,
    parent: HTMLElement,
  ) {
    if (option === undefined || option.optionsControl === undefined) return;
    const optionsControl = option.optionsControl;

    const ctrl = document.createElement(optionsControl);
    ctrl.appendChild(
      createSubLabel(
        `${getTranslation(v.labelKey)} ${
          v.labelKey.indexOf("misc") > 0
            ? `- ${v.labelKey.split(".").pop()}`
            : ""
        }`,
        v.bgColor,
      ),
    );
    ctrl.value = v.id;

    if (option.initial !== undefined && option.initial.indexOf(v.id) >= 0) {
      if (optionsControl === "sl-checkbox") ctrl.checked = true;
      state.tools[option.id].push(v.id);
    }

    if (optionsControl === "sl-checkbox")
      ctrl.addEventListener(
        "sl-change",
        (ev) => this.onClickOption(ev, option.id, v.id, optionsControl),
        false,
      );

    parent.appendChild(ctrl);
  }
}

customElements.define("tvd-bay-tools-component", EditToolsComponent);

function createSubLabel(label: string, bgColor: string): HTMLElement {
  const sp = document.createElement("span");
  const em = document.createElement("em");
  sp.innerHTML = label;
  sp.style.position = "relative";
  em.style.backgroundColor = bgColor;
  em.style.display = "inline-block";
  em.style.position = "absolute";
  em.style.width = "100%";
  em.style.height = "2px";
  em.style.bottom = "-1px";
  em.style.left = "0";
  em.style.borderRadius = "2px";
  sp.appendChild(em);
  sp.style.pointerEvents = "none";
  return sp;
}

export interface ICustomEventEmphasizeCheckbox {
  isChecked: boolean;
}
