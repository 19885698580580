import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
export class SelectShoelace extends HTMLElement {
    get value() {
        return this.valueInternal;
    }
    set value(s) {
        if (this.valueInternal === s)
            return;
        if (this.items[this.valueInternal] && !this.ommitCheckSign)
            this.items[this.valueInternal].checked = false;
        if (this.items[s] && !this.ommitCheckSign)
            this.items[s].checked = true;
        this.valueInternal = s;
        if (this.updateButtonText) {
            const text = this.updateButtonText(this.value);
            if (text && this.button) {
                this.button.innerHTML = text;
                if (this.iconPrefix) {
                    const iconPrx = document.createElement("sl-icon");
                    iconPrx.slot = "prefix";
                    iconPrx.setAttribute("name", this.iconPrefix);
                    this.button.appendChild(iconPrx);
                }
                if (this.iconSuffix) {
                    const iconSfx = document.createElement("sl-icon");
                    iconSfx.slot = "suffix";
                    iconSfx.setAttribute("name", this.iconSuffix);
                    this.button.appendChild(iconSfx);
                }
            }
        }
    }
    constructor() {
        super();
        this.isSelectShoelace = true;
        this.name = "";
        this.title = "";
        this.buttonText = "";
        this.caret = false;
        this.pill = false;
        this.size = "medium";
        this.variant = "default";
        this.outlined = false;
        this.disabled = false;
        this.placement = "bottom-start";
        this.options = [];
        this.ommitCheckSign = false;
        this.updateButtonText = undefined;
        this.valueInternal = "";
        this.items = {};
        const dropdown = document.createElement("sl-dropdown");
        const button = document.createElement("sl-button");
        const menu = document.createElement("sl-menu");
        this.button = button;
        this.dropdown = dropdown;
        this.menu = menu;
    }
    connectedCallback() {
        const dropdown = this.dropdown;
        const button = this.button;
        dropdown.id = this.name;
        dropdown.className = this.className;
        if (this.placement)
            dropdown.placement = this.placement;
        if (typeof this.buttonText === "string")
            button.innerHTML = this.buttonText;
        else
            button.appendChild(this.buttonText);
        if (this.iconPrefix) {
            const iconPrx = document.createElement("sl-icon");
            iconPrx.slot = "prefix";
            iconPrx.setAttribute("name", this.iconPrefix);
            button.appendChild(iconPrx);
        }
        if (this.iconSuffix) {
            const iconSfx = document.createElement("sl-icon");
            iconSfx.slot = "suffix";
            iconSfx.setAttribute("name", this.iconSuffix);
            button.appendChild(iconSfx);
        }
        button.slot = "trigger";
        button.variant = this.variant;
        button.caret = this.caret;
        button.pill = this.pill;
        button.size = this.size;
        button.outline = this.outlined;
        button.disabled = !!this.disabled;
        button.setAttribute("part", "trigger-btn");
        if (this.title)
            button.title = this.title;
        const { menu, items } = createMenuList({
            menuNode: this.menu,
            options: this.options,
            ommitCheckSign: this.ommitCheckSign,
            currentValue: this.value,
        });
        this.items = items;
        dropdown.appendChild(button);
        dropdown.appendChild(menu);
        dropdown.setAttribute("exportparts", "trigger-btn");
        menu.addEventListener("sl-select", (ev) => {
            const prevValue = this.value;
            const item = ev.detail.item;
            this.value = item.value;
            if (this.onChange) {
                this.onChange(this.value, prevValue, this);
            }
        }, false);
        if (this.autoScroll) {
            const autoScrollFn = () => {
                const isOpened = menu.getClientRects().length === 0;
                const itemSel = this.items[this.valueInternal];
                if (isOpened && itemSel)
                    setTimeout(() => itemSel.scrollIntoView({ block: "center", behavior: "smooth" }), 100);
            };
            button.addEventListener("click", autoScrollFn, false);
            button.addEventListener("keydown", autoScrollFn, false);
        }
        this.appendChild(dropdown);
        if (this.onNodesCreated)
            this.onNodesCreated(this.items, button);
    }
}
SelectShoelace.observedAttributes = [];
customElements.define("tf-select", SelectShoelace);
export function createSelectShoelace({ id, title, buttonText, caret, pill, size, options, selectedValue, autoScroll, className, ommitCheckSign, variant, outlined, placement, iconPrefix, iconSuffix, disabled, onChange, updateButtonText, onNodesCreated, }) {
    const selectShoelace = new SelectShoelace();
    selectShoelace.id = id;
    selectShoelace.buttonText = buttonText;
    selectShoelace.iconPrefix = iconPrefix;
    selectShoelace.iconSuffix = iconSuffix;
    selectShoelace.caret = !!caret;
    selectShoelace.pill = !!pill;
    selectShoelace.size = size || "medium";
    selectShoelace.options = options;
    selectShoelace.variant = variant || "default";
    selectShoelace.outlined = !!outlined;
    selectShoelace.autoScroll = !!autoScroll;
    selectShoelace.placement = placement;
    selectShoelace.disabled = disabled || false;
    selectShoelace.title = title || "";
    if (className)
        selectShoelace.className = className;
    if (ommitCheckSign)
        selectShoelace.ommitCheckSign = true;
    if (selectedValue !== undefined)
        selectShoelace.value = String(selectedValue);
    if (onChange)
        selectShoelace.onChange = onChange;
    if (updateButtonText)
        selectShoelace.updateButtonText = updateButtonText;
    if (onNodesCreated)
        selectShoelace.onNodesCreated = onNodesCreated;
    return selectShoelace;
}
export function createMenuList({ options, ommitCheckSign, currentValue, menuNode, }) {
    const items = {};
    const menu = menuNode || document.createElement("sl-menu");
    if (menuNode)
        removeChildren(menu);
    options.forEach(({ name, value, divider, variant, icon }) => {
        if (divider !== undefined) {
            menu.appendChild(document.createElement("sl-divider"));
        }
        else {
            const val = String(value);
            const item = document.createElement("sl-menu-item");
            item.value = val;
            item.textContent = name || "";
            item.title = name || "";
            if (variant)
                item.classList.add(variant);
            if (icon) {
                const iconEl = document.createElement("sl-icon");
                iconEl.setAttribute("name", icon);
                iconEl.setAttribute("slot", "prefix");
                item.append(iconEl);
            }
            menu.appendChild(item);
            items[val] = item;
            if (!ommitCheckSign) {
                item.type = "checkbox";
                if (currentValue === val) {
                    item.checked = true;
                }
            }
        }
    });
    return {
        menu,
        items,
    };
}
