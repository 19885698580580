/* eslint-disable @typescript-eslint/no-explicit-any */

const msClarity = {
  isActive: false,

  initialize(appId?: string) {
    if (!appId) return;

    msClarity.isActive = true;

    function loadScript(
      c: any,
      l: any,
      a: any,
      r: any,
      i: any,
      t?: any,
      y?: any,
    ) {
      c[a] =
        c[a] ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    }

    loadScript(window, document, "clarity", "script", appId);
  },
};

export default msClarity;
