import { __awaiter } from "tslib";
import { BayLevelEnum, PositionFormatEnum, } from "open-vessel-definition";
import { getBayLcgVcgTcgAndPairings, } from "@baplie-viewer2/tedivo-bay-grid-core";
import { LogEventEntitiesEnum, LogEventTypesEnum, } from "@baplie-viewer2/tedivo-api-models";
import { arraysAreEqual, cloneObject, objectsAreEqual, pad3, roundDec, sanitizeString, sortByMultipleFields, } from "@baplie-viewer2/tedivo-pure-helpers";
import BeaconServices from "../beaconServices";
import OvdValidator from "./validators";
import Services from "../services";
import { errorTracking } from "../tracking/errorTracking";
import globalUnits from "../units/globalUnits";
import { safeSet } from "./safeSet";
import securityModule from "../security/SecurityModule";
export class OVDJsonStore extends EventTarget {
    constructor() {
        super(...arguments);
        this.internalJson = undefined;
        this.originalInternalJson = undefined;
        this.internalName = undefined;
        this.internalCloudInfo = undefined;
        this._timeoutEmitter = undefined;
        this.readonlyMode = false;
        this.ovdValidator = new OvdValidator();
        this.saveToCloud = (originalSource) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d;
            if (this.readonlyMode)
                return;
            const json = this.internalJson;
            const shipData = json === null || json === void 0 ? void 0 : json.shipData;
            if (!json || !(shipData === null || shipData === void 0 ? void 0 : shipData.shipName))
                return;
            const lcgVcgTcgAndPairings = getBayLcgVcgTcgAndPairings(json.baysData, json.shipData.masterCGs);
            const cgs = lcgVcgTcgAndPairings.cgsStats;
            const cgsPercentage = roundDec(((cgs.definedLcgs + cgs.definedBottomBase + cgs.definedTcgs) /
                (cgs.countLcgs + cgs.countBottomBase + cgs.countTcgs)) *
                100, 2), lcgsPercentage = roundDec((cgs.definedLcgs / cgs.countLcgs) * 100, 2), tcgsPercentage = roundDec((cgs.definedTcgs / cgs.countTcgs) * 100, 2), vcgsPercentage = roundDec((cgs.definedBottomBase / cgs.countBottomBase) * 100, 2);
            const dataToSave = {
                name: shipData.shipName,
                cgsPercentage,
                lcgsPercentage,
                tcgsPercentage,
                vcgsPercentage,
                imoCode: shipData.imoCode,
                callSign: shipData.callSign,
                lastModified: new Date(),
                shipNameAkas: ((_a = shipData.shipNameAkas) === null || _a === void 0 ? void 0 : _a.join("\n")) || "",
                originalSource,
                data: JSON.stringify(json),
            };
            if (this.tvdId !== undefined) {
                // UPDATE
                const { data } = yield Services.files.update(Object.assign({ fileId: this.tvdId.fileId, userSub: this.tvdId.userSub, organizationId: this.tvdId.organizationId, organizationName: securityModule.currentOrganizationName, state: this.tvdId.fileState, hasHatchCovers: !!((_b = json.lidData) === null || _b === void 0 ? void 0 : _b.length) }, dataToSave));
                if (!data) {
                    this.dispatchEvent(new CustomEvent("error"));
                }
                else {
                    if (data === null || data === void 0 ? void 0 : data.state) {
                        this.tvdId.fileState = data.state;
                        this.tvdId.lastComment = data.lastComment;
                    }
                    this.tvdId.updatedBy = securityModule.userSub;
                }
            }
            else {
                // CREATE
                const apiResp = yield Services.files.create(Object.assign({ userSub: securityModule.userSub, organizationId: securityModule.currentOrganizationId, organizationName: securityModule.currentOrganizationName, state: "DRAFT", hasHatchCovers: !!((_c = json.lidData) === null || _c === void 0 ? void 0 : _c.length) }, dataToSave));
                if (((_d = apiResp.data) === null || _d === void 0 ? void 0 : _d.id) !== undefined) {
                    this.tvdId = {
                        fileId: apiResp.data.id,
                        userSub: securityModule.userSub,
                        organizationId: securityModule.currentOrganizationId,
                        organizationName: securityModule.currentOrganizationName,
                        createdAt: new Date(),
                        fileState: "DRAFT",
                    };
                }
                else {
                    this.tvdId = undefined;
                    throw apiResp;
                }
            }
        });
        this.emitChange = (maxAwaitInMs = 500) => __awaiter(this, void 0, void 0, function* () {
            var _e;
            window.clearTimeout(this._timeoutEmitter);
            const json = this.internalJson;
            if (json) {
                const oldState = ((_e = this.tvdId) === null || _e === void 0 ? void 0 : _e.fileState) || "DRAFT";
                this._timeoutEmitter = window.setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                    // Set loading
                    this.dispatchEvent(new CustomEvent("updatingDB", { detail: { isUpdating: true } }));
                    // Improve speed by saving to cloud in the background
                    // OPTMISTIC SAVE
                    yield maxAwait({
                        maxAwaitInMs,
                        fn: () => __awaiter(this, void 0, void 0, function* () {
                            yield this.saveToCloud();
                        }),
                        fnResolved: () => {
                            var _a;
                            const newState = ((_a = this.tvdId) === null || _a === void 0 ? void 0 : _a.fileState) || "DRAFT";
                            this.dispatchEvent(new CustomEvent("stateChanged", {
                                detail: { oldState, newState },
                            }));
                        },
                    });
                    // Remove loading
                    this.dispatchEvent(new CustomEvent("updatingDB", { detail: { isUpdating: false } }));
                    // Emit change
                    this.dispatchEvent(new CustomEvent("jsonUpdated"));
                }), 10);
                this.ovdValidator.validate(json, globalUnits);
            }
        });
    }
    setJson(shipName, json) {
        this.internalName = shipName;
        this.internalJson = json === undefined ? undefined : cloneObject(json);
        this.originalInternalJson =
            json === undefined ? undefined : cloneObject(json);
        if (json !== undefined) {
            Object.freeze(this.originalInternalJson);
            this.ovdValidator.validate(json, globalUnits);
        }
        errorTracking.leaveBreadcrumb(`JSON "${this.internalName}" total slots defined: ${this.totalSlotsDefined}`);
        return this;
    }
    setLpp(lpp) {
        var _a, _b;
        if (this.internalJson === undefined)
            return this;
        if (((_b = (_a = this.internalJson.shipData) === null || _a === void 0 ? void 0 : _a.lcgOptions) === null || _b === void 0 ? void 0 : _b.lpp) === lpp)
            return this;
        safeSet(this.internalJson.shipData, "lcgOptions.lpp", lpp);
        this.emitChange();
        return this;
    }
    setCgOptions(lcgValues, tcgValues, vcgValues, loa, sternToAftPp, lpp) {
        if (this.internalJson === undefined || !this.internalCloudInfo)
            return this;
        const oldData = {
            lcgValues: this.internalJson.shipData.lcgOptions.values,
            tcgValues: this.internalJson.shipData.tcgOptions.values,
            vcgValues: this.internalJson.shipData.vcgOptions.values,
            loa: this.internalJson.shipData.loa,
            sternToAftPp: this.internalJson.shipData.sternToAftPp,
            lpp: this.internalJson.shipData.lcgOptions.lpp,
        };
        const newData = {
            lcgValues,
            tcgValues,
            vcgValues,
            loa,
            sternToAftPp,
            lpp,
        };
        const isDataEqual = objectsAreEqual(oldData, newData);
        if (isDataEqual) {
            return this;
        }
        if (lcgValues !== undefined) {
            safeSet(this.internalJson.shipData, "lcgOptions.values", lcgValues);
        }
        if (tcgValues !== undefined) {
            safeSet(this.internalJson.shipData, "tcgOptions.values", tcgValues);
        }
        if (vcgValues !== undefined) {
            safeSet(this.internalJson.shipData, "vcgOptions.values", vcgValues);
        }
        if (loa !== undefined) {
            safeSet(this.internalJson.shipData, "loa", loa);
        }
        if (sternToAftPp !== undefined) {
            safeSet(this.internalJson.shipData, "sternToAftPp", sternToAftPp);
        }
        if (lpp !== undefined)
            safeSet(this.internalJson.shipData, "lcgOptions.lpp", lpp);
        this.emitChange();
        BeaconServices.logEvents.notifyXhttp(Object.assign({ eventEntity: LogEventEntitiesEnum.File, eventType: LogEventTypesEnum.Modified, subEvent: "CGsOptions", itemId: this.internalCloudInfo.fileId }, securityModule.getBeaconMetadata()));
        return this;
    }
    setShipDataGeneralInfo({ lineOperator, shipClass, shipName, callSign, imoCode, positionFormat, containersLengths, featuresAllowed: fA, yearBuilt, loa, shipNameAkaStr, }, doEmitChange = true) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        if (this.internalJson === undefined)
            return this;
        const featuresAllowed = fA
            ? {
                slotCoolStowProhibited: fA.indexOf("slotCoolStowProhibited") >= 0,
                slotHazardousProhibited: fA.indexOf("slotHazardousProhibited") >= 0,
                slotConeRequired: fA.indexOf("slotConeRequired") >= 0,
            }
            : {
                slotCoolStowProhibited: false,
                slotHazardousProhibited: false,
                slotConeRequired: false,
            };
        const shipNameAkas = (shipNameAkaStr === null || shipNameAkaStr === void 0 ? void 0 : shipNameAkaStr.replace(/,/i, "\n").split("\n").map((s) => s.trim().toUpperCase())) || [];
        const newShipData = {
            lineOperator,
            shipClass: shipClass || "",
            shipName,
            callSign,
            imoCode,
            positionFormat: positionFormat || PositionFormatEnum.BAY_STACK_TIER,
            containersLengths: containersLengths || [],
            featuresAllowed,
            yearBuilt,
            loa,
            shipNameAkas,
        };
        const oldSubShipData = {
            lineOperator: (_a = this.internalJson.shipData) === null || _a === void 0 ? void 0 : _a.lineOperator,
            shipClass: ((_b = this.internalJson.shipData) === null || _b === void 0 ? void 0 : _b.shipClass) || "",
            shipName: (_c = this.internalJson.shipData) === null || _c === void 0 ? void 0 : _c.shipName,
            callSign: (_d = this.internalJson.shipData) === null || _d === void 0 ? void 0 : _d.callSign,
            imoCode: (_e = this.internalJson.shipData) === null || _e === void 0 ? void 0 : _e.imoCode,
            positionFormat: ((_f = this.internalJson.shipData) === null || _f === void 0 ? void 0 : _f.positionFormat) ||
                PositionFormatEnum.BAY_STACK_TIER,
            containersLengths: ((_g = this.internalJson.shipData) === null || _g === void 0 ? void 0 : _g.containersLengths) || [],
            featuresAllowed: ((_h = this.internalJson.shipData) === null || _h === void 0 ? void 0 : _h.featuresAllowed) || {
                slotCoolStowProhibited: false,
                slotHazardousProhibited: false,
                slotConeRequired: false,
            },
            yearBuilt: (_j = this.internalJson.shipData) === null || _j === void 0 ? void 0 : _j.yearBuilt,
            loa: (_k = this.internalJson.shipData) === null || _k === void 0 ? void 0 : _k.loa,
            shipNameAkas: ((_l = this.internalJson.shipData) === null || _l === void 0 ? void 0 : _l.shipNameAkas) || [],
        };
        const shipDataIsEqual = objectsAreEqual(oldSubShipData, newShipData);
        if (shipDataIsEqual)
            return this;
        const shipData = this.internalJson.shipData;
        safeSet(shipData, "lineOperator", lineOperator);
        safeSet(shipData, "shipClass", shipClass);
        safeSet(shipData, "shipName", shipName);
        safeSet(shipData, "callSign", callSign);
        safeSet(shipData, "imoCode", imoCode);
        safeSet(shipData, "positionFormat", positionFormat);
        safeSet(shipData, "containersLengths", containersLengths);
        safeSet(shipData, "yearBuilt", yearBuilt);
        if (loa !== undefined)
            safeSet(shipData, "loa", loa);
        safeSet(shipData, "shipNameAkas", shipNameAkas);
        if (fA) {
            const featuresAllowed = {
                slotCoolStowProhibited: fA.indexOf("slotCoolStowProhibited") >= 0,
                slotHazardousProhibited: fA.indexOf("slotHazardousProhibited") >= 0,
                slotConeRequired: fA.indexOf("slotConeRequired") >= 0,
            };
            safeSet(shipData, "featuresAllowed", featuresAllowed);
        }
        if (doEmitChange) {
            this.emitChange();
            if (!shipDataIsEqual && this.internalCloudInfo)
                BeaconServices.logEvents.notifyXhttp(Object.assign({ eventEntity: LogEventEntitiesEnum.File, eventType: LogEventTypesEnum.Modified, subEvent: "VesselInfo", itemId: this.internalCloudInfo.fileId }, securityModule.getBeaconMetadata()));
        }
        return this;
    }
    setShipSize(prms) {
        var _a, _b;
        if (this.internalJson === undefined || !this.internalCloudInfo)
            return this;
        const prevSizeSummary = Object.assign({}, this.internalJson.sizeSummary);
        const createEmptyBayData = (isoBay, level) => ({
            isoBay,
            level,
            infoByContLength: {},
            perSlotInfo: {},
            centerLineRow: prms.centerLineRow ? 1 : 0,
        });
        const newSizeSummary = {
            isoBays: prms.isoBays,
            centerLineRow: prms.centerLineRow ? 1 : 0,
            maxRow: prms.maxRow,
            minBelowTier: prms.minBelowTier,
            maxBelowTier: prms.maxBelowTier,
            minAboveTier: prms.minAboveTier,
            maxAboveTier: prms.maxAboveTier,
        };
        if (objectsAreEqual(prevSizeSummary, newSizeSummary))
            return this;
        // If isoBays changes, create/delete new bayData
        if (prms.isoBays !== prevSizeSummary.isoBays) {
            const currentBaysData = this.internalJson.baysData;
            const baysDataPerIsoBayAndLevel = {};
            currentBaysData.forEach((bl) => {
                if (!baysDataPerIsoBayAndLevel[bl.isoBay])
                    baysDataPerIsoBayAndLevel[bl.isoBay] = {};
                baysDataPerIsoBayAndLevel[bl.isoBay][bl.level === BayLevelEnum.ABOVE ? "above" : "below"] = bl;
            });
            const newBaysData = [];
            for (let i = 1; i <= prms.isoBays; i += 2) {
                const isoBay = pad3(i);
                if (prms.hasAboveDeck) {
                    const bayDataAbove = (_a = baysDataPerIsoBayAndLevel[isoBay]) === null || _a === void 0 ? void 0 : _a.above;
                    if (bayDataAbove !== undefined) {
                        newBaysData.push(bayDataAbove);
                    }
                    else {
                        newBaysData.push(createEmptyBayData(isoBay, BayLevelEnum.ABOVE));
                    }
                }
                if (prms.hasBelowDeck) {
                    const bayDataBelow = (_b = baysDataPerIsoBayAndLevel[isoBay]) === null || _b === void 0 ? void 0 : _b.below;
                    if (bayDataBelow !== undefined) {
                        newBaysData.push(bayDataBelow);
                    }
                    else {
                        newBaysData.push(createEmptyBayData(isoBay, BayLevelEnum.BELOW));
                    }
                }
            }
            newBaysData.sort(sortByMultipleFields([
                { name: "isoBay", ascending: true },
                { name: "level", ascending: true },
            ]));
            this.internalJson.baysData = newBaysData;
        }
        this.internalJson.sizeSummary = newSizeSummary;
        this.emitChange();
        BeaconServices.logEvents.notifyXhttp(Object.assign({ eventEntity: LogEventEntitiesEnum.File, eventType: LogEventTypesEnum.Modified, subEvent: "Size", itemId: this.internalCloudInfo.fileId }, securityModule.getBeaconMetadata()));
        return this;
    }
    setLids(lidData) {
        if (this.internalJson === undefined || !this.internalCloudInfo)
            return this;
        if (arraysAreEqual(this.internalJson.lidData, lidData))
            return this;
        this.internalJson.lidData = lidData;
        this.emitChange();
        BeaconServices.logEvents.notifyXhttp(Object.assign({ eventEntity: LogEventEntitiesEnum.File, eventType: LogEventTypesEnum.Modified, subEvent: "HatchCovers", itemId: this.internalCloudInfo.fileId }, securityModule.getBeaconMetadata()));
        return this;
    }
    findBayInfo(isoBay, level) {
        if (this.internalJson === undefined)
            return undefined;
        return this.internalJson.baysData.find((bl) => bl.isoBay === isoBay && bl.level === level);
    }
    replaceBayInfo(isoBay, level, bayDataSrc) {
        if (this.internalJson === undefined)
            return this;
        const bayData = cleanUpBay(bayDataSrc);
        const idx = this.internalJson.baysData.findIndex((bl) => bl.isoBay === isoBay && bl.level === level);
        if (idx >= 0) {
            this.internalJson.baysData[idx] = bayData;
        }
        else {
            this.internalJson.baysData.push(bayData);
            this.internalJson.baysData.sort(sortByMultipleFields([
                { name: "isoBay", ascending: true },
                { name: "level", ascending: true },
            ]));
        }
        this.emitChange();
        return this;
    }
    replaceJson(json) {
        if (this.internalJson === undefined)
            return this;
        this.internalJson = json;
        this.emitChange();
        return this;
    }
    setCGsInfo(isoBay, level, perRowInfo, infoByContLength) {
        if (this.internalJson === undefined || !this.internalCloudInfo)
            return this;
        const idx = this.internalJson.baysData.findIndex((bl) => bl.isoBay === isoBay && bl.level === level);
        if (idx < 0)
            return this;
        if (objectsAreEqual(perRowInfo, this.internalJson.baysData[idx].perRowInfo) &&
            objectsAreEqual(infoByContLength, this.internalJson.baysData[idx].infoByContLength)) {
            return this;
        }
        this.internalJson.baysData[idx].perRowInfo = perRowInfo;
        this.internalJson.baysData[idx].infoByContLength = infoByContLength;
        this.emitChange();
        BeaconServices.logEvents.notifyXhttp(Object.assign({ eventEntity: LogEventEntitiesEnum.File, eventType: LogEventTypesEnum.Modified, subEvent: "BayCGs", itemId: this.internalCloudInfo.fileId }, securityModule.getBeaconMetadata()));
        return this;
    }
    setMasterCGs(masterCGs) {
        if (this.internalJson === undefined || !this.internalCloudInfo)
            return this;
        if (objectsAreEqual(this.internalJson.shipData.masterCGs, masterCGs))
            return this;
        this.internalJson.shipData.masterCGs = masterCGs;
        this.emitChange();
        BeaconServices.logEvents.notifyXhttp(Object.assign({ eventEntity: LogEventEntitiesEnum.File, eventType: LogEventTypesEnum.Modified, subEvent: "MasterCGs", itemId: this.internalCloudInfo.fileId }, securityModule.getBeaconMetadata()));
        return this;
    }
    setFileState(newState, comments) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.internalJson === undefined || !this.tvdId)
                return undefined;
            const oldState = this.tvdId.fileState;
            if (oldState === newState)
                return undefined;
            this.tvdId.fileState = newState;
            this.dispatchEvent(new CustomEvent("updatingDB", { detail: { isUpdating: true } }));
            const resp = yield Services.files.changeFileState(this.tvdId.fileId, newState, comments);
            this.dispatchEvent(new CustomEvent("updatingDB", { detail: { isUpdating: false } }));
            if (resp.statusCode !== 200) {
                this.tvdId.fileState = oldState;
            }
            else {
                this.tvdId.fileState = newState;
                if (newState !== "DRAFT")
                    this.tvdId.lastComment = comments;
                this.dispatchEvent(new CustomEvent("stateChanged", { detail: { oldState, newState } }));
                this.dispatchEvent(new CustomEvent("jsonUpdated"));
            }
            return resp;
        });
    }
    get originalJson() {
        return this.originalInternalJson;
    }
    get currentJson() {
        return this.internalJson;
    }
    get filenameSanitized() {
        return sanitizeString(this.internalName || "");
    }
    get filenameSanitizedForSE() {
        var _a;
        const shipData = (_a = this.internalJson) === null || _a === void 0 ? void 0 : _a.shipData;
        function snUpp(s) {
            return sanitizeString(s || "").toUpperCase();
        }
        const name = shipData
            ? `${snUpp(shipData.shipClass || "___")}.${snUpp(shipData.lineOperator || "___")}.${snUpp(shipData.shipName || "___")}`
            : sanitizeString(this.internalName || "");
        return name;
    }
    get tvdId() {
        return this.internalCloudInfo;
    }
    set tvdId(cloudInfo) {
        this.internalCloudInfo = cloudInfo;
    }
    get totalSlotsDefined() {
        if (this.internalJson === undefined)
            return 0;
        return this.internalJson.baysData.reduce((acc, bayData) => acc + Object.keys(bayData.perSlotInfo || {}).length, 0);
    }
}
const ovdJsonStore = new OVDJsonStore();
export default ovdJsonStore;
function cleanUpBay(bayData) {
    deleteVerboseOptionalFalsyKeysInBayData(bayData, [
        "label20",
        "label40",
        "pairedBay",
        "reeferPlugs",
        "doors",
        "reeferPlugLimit",
        "centerLineRow",
        "athwartShip",
        "foreHatch",
        "ventilated",
        "heatSrcFore",
        "ignitionSrcFore",
        "quartersFore",
        "engineRmBulkFore",
    ]);
    return bayData;
    function deleteVerboseOptionalFalsyKeysInBayData(bayData, keys) {
        keys.forEach((key) => {
            if (!bayData[key])
                delete bayData[key];
        });
    }
}
/**
 * Does an execution of an async function, but if it takes more than maxAwaitInMs, it will resolve anyway.
 * @param fn Async function to execute
 * @param maxAwaitInMs Max time to wait for the async function to resolve (default 500ms)
 * @param fnResolved Async fn that will be executed when finally resolved
 * @returns void
 */
function maxAwait({ fn, maxAwaitInMs = 500, fnResolved, }) {
    return __awaiter(this, void 0, void 0, function* () {
        window.setTimeout(() => __awaiter(this, void 0, void 0, function* () {
            return false;
        }), maxAwaitInMs);
        yield fn();
        fnResolved === null || fnResolved === void 0 ? void 0 : fnResolved();
        return true;
    });
}
