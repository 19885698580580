import { __awaiter } from "tslib";
import Services from "packages/oss-editor/src/app/services";
import { getTranslation } from "packages/oss-editor/src/app/i18/i18tn";
export function createModalToTagFiles({ fileId, fileName, filesTags, modal, onUpdated, }) {
    if (!fileId)
        return;
    const holder = document.createElement("div");
    const sel = document.createElement("sl-select");
    const input = document.createElement("sl-input");
    sel.name = "tags";
    sel.multiple = true;
    sel.clearable = true;
    sel.style.marginBottom = "1rem";
    sel.maxOptionsVisible = 10;
    sel.hoist = true;
    sel.placeholder = getTranslation("general:fileTags.selectTags");
    sel.label = getTranslation("general:fileTags.tags");
    sel.placement = "top";
    input.name = "newTag";
    input.label = getTranslation("general:fileTags.tagPlaceholder");
    input.placeholder = `${getTranslation("general:fileTags.tags")}...`;
    input.autofocus = true;
    let values = [];
    let prevValues = [];
    if (filesTags) {
        const tags = Object.keys(filesTags.tags || {}).sort();
        tags.forEach((tag) => {
            const opt = document.createElement("sl-option");
            opt.value = tag;
            opt.innerText = filesTags.tags[tag];
            sel.appendChild(opt);
            if (filesTags.filesByTag[tag].includes(fileId)) {
                values = [...values, tag];
            }
        });
        prevValues = values.filter((v, i, a) => a.indexOf(v) === i);
        sel.value = prevValues;
    }
    holder.appendChild(sel);
    holder.appendChild(input);
    modal.updateOptions({
        buttonsAttrs: [
            {
                value: "ok",
                label: getTranslation("general:common.ok"),
                type: "submit",
            },
        ],
    });
    modal.onButtonClicked = (action) => __awaiter(this, void 0, void 0, function* () {
        if (action === "ok") {
            const actions = [];
            const newTags = (input.value || "")
                .split(",")
                .filter(Boolean)
                .map((t) => t.trim());
            // FOR THE INPUT
            if (newTags.length) {
                // First create the tags
                newTags.forEach((tag) => {
                    actions.push({
                        action: "addTag",
                        tag,
                    });
                });
                // Then tag the file
                newTags.forEach((tag) => {
                    actions.push({
                        action: "addFileToTag",
                        fileId,
                        tag,
                    });
                });
            }
            // FOR THE SELECT
            if (filesTags) {
                const selectedTags = sel.value;
                const tagsToRemove = prevValues.filter((v) => !selectedTags.includes(v));
                tagsToRemove.forEach((tag) => {
                    actions.push({
                        action: "removeFileFromTag",
                        fileId,
                        sanitizedTag: tag,
                    });
                });
                selectedTags.forEach((tag) => {
                    if (!prevValues.includes(tag)) {
                        actions.push({
                            action: "addFileToTag",
                            fileId,
                            sanitizedTag: tag,
                        });
                    }
                });
            }
            if (actions.length)
                Services.organizations.updateFileTags(actions).then((res) => {
                    if (onUpdated)
                        onUpdated(res.data);
                });
            return true;
        }
        return false;
    });
    modal.show(getTranslation("general:fileTags.tagFile", { fileName }), holder);
    window.setTimeout(() => {
        input.focus();
    }, 150);
}
