/**
 * Rounds a number `n` to `decs` decimals
 * @param n Number
 * @param decs Integer >= 0. Default is 2.
 * @returns Number
 */
export function roundDec(n: number, decs = 2): number {
  const mult = Math.pow(10, decs);
  return Math.round(n * mult) / mult;
}

/**
 * Sort function for array.sort(), Numeric Ascending
 */
export function sortNumericAsc(a: string | number, b: string | number): number {
  return Number(a) - Number(b);
}

/**
 * Sort function for array.sort(), Numeric Descending
 */
export function sortNumericDesc(a: string, b: string): number {
  return Number(b) - Number(a);
}

export const ONE_MILLIMETER_IN_FEET = 0.003280839895;

export const feetToMillimeters = (feet: number) =>
  Math.round(feet / ONE_MILLIMETER_IN_FEET);

export function numberOrUndefined(v?: string | number) {
  const vAsNumber = Number(v);
  if (v === undefined || isNaN(vAsNumber)) return undefined;
  return vAsNumber;
}

/**
 * Sorts an array using multiple fields. Typed
 * @param fields Object of (name:string, asc:boolean, isNumeric:boolean)
 * @returns
 */
export function sortByMultipleFields<T>(
  fields: Array<ISortByField<T>>
): (a: T, b: T) => number {
  const compareString = (a: T[keyof T], b: T[keyof T]) =>
    String(a).localeCompare(String(b));
  const compareNumber = (a: T[keyof T], b: T[keyof T]) => Number(a) - Number(b);

  return (a: T, b: T) =>
    fields
      .map(({ name, ascending, isNumeric }) => {
        const dir = ascending ? 1 : -1;
        const compareFn = isNumeric ? compareNumber : compareString;
        if (compareFn(a[name], b[name]) > 0) return dir;
        if (compareFn(a[name], b[name]) < 0) return -dir;
        return 0;
      })
      .reduce((p, n) => (p ? p : n), 0);
}

export interface ISortByField<T> {
  name: keyof T;
  ascending: boolean;
  isNumeric?: boolean;
}

export function numberIsEven(v: number) {
  return v % 2 === 0;
}

export function numberIsOdd(v: number) {
  return v % 2 === 1;
}
