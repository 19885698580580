import { __rest } from "tslib";
import { BayLevelEnum, ForeAftEnum, } from "open-vessel-definition";
import { createRowsFromConfig, getRowsAndTiersFromSlotKeys, hasZeroRow, } from "@baplie-viewer2/tedivo-bay-grid-core";
import { TedivoForm, translateTedivoForm, } from "@baplie-viewer2/tedivo-form";
import { LogEventEntitiesEnum, LogEventTypesEnum, } from "@baplie-viewer2/tedivo-api-models";
import { BayEditComponent } from "../../bay-edit.component";
import BeaconServices from "../../../../../app/beaconServices";
import TYesNoEnum from "../../../../../app/enums/TYesNoEnum";
import { getTranslation } from "../../../../../app/i18/i18tn";
import goSquared from "../../../../../app/tracking/goSquared";
import ovdJsonStore from "../../../../../app/stores/OVDJsonStore";
import { pad2 } from "@baplie-viewer2/tedivo-pure-helpers";
import securityModule from "../../../../../app/security/SecurityModule";
import { z } from "zod";
const FormValidator = z.object({
    notes: z.string().optional(),
    label20: z.string().optional(),
    label40: z.string().optional(),
    reeferPlugs: z.number().optional(),
    doors: z.number().optional(),
    pairedBay: z.number().optional(),
    reeferPlugLimit: z.number().optional(),
    centerLineRow: z.nativeEnum(TYesNoEnum).optional(),
    athwartShip: z.nativeEnum(TYesNoEnum).optional(),
    foreHatch: z.nativeEnum(TYesNoEnum).optional(),
    ventilated: z.nativeEnum(TYesNoEnum).optional(),
    heatSrcFore: z.nativeEnum(TYesNoEnum).optional(),
    ignitionSrcFore: z.nativeEnum(TYesNoEnum).optional(),
    quartersFore: z.nativeEnum(TYesNoEnum).optional(),
    engineRmBulkFore: z.nativeEnum(TYesNoEnum).optional(),
    telescoping: z.nativeEnum(TYesNoEnum).optional(),
});
/**
 * Opens the Bay Editor with tools and Grid
 * @returns nothing
 */
export function openBayEdit({ isoBay, level, availableLengths, bayData, sizeSummary, shipData, gridData, editDrawer, readOnlyMode, }) {
    var _a, _b;
    goSquared.addEvent("Edit-OVD - Edit Bay");
    let firstTedivoFormUpdate = true;
    const title = `${getTranslation("general:common.edit")} ${getTranslation("general:grid.bay")} ${isoBay} ${getTranslation(`enums:BayLevelEnum.${BayLevelEnum[level]}`)}`;
    const aftFwdOptions = [
        {
            value: ForeAftEnum.AFT,
            label: getTranslation(`enums:ForeAftEnum.AFT`),
        },
        {
            value: ForeAftEnum.FWD,
            label: getTranslation(`enums:ForeAftEnum.FWD`),
        },
        { value: 0, label: getTranslation(`general:common.none`) },
    ];
    let internaSlotData = gridData.cellsToDraw || [];
    if (!editDrawer)
        return;
    const drawer = editDrawer.getEditDrawer({
        title,
        showUnits: false,
        readOnlyMode,
    });
    editDrawer.shouldNotSelectPanel = true;
    const bayEditComp = new BayEditComponent().initialize(isoBay, level, gridData, availableLengths, sizeSummary.isoBays, shipData.featuresAllowed, bayData, editDrawer);
    const dataHasZeroRow = bayData ? hasZeroRow(bayData) : false;
    const bayDetailsFormFields = [
        [
            {
                name: "centerLineRow",
                label: "view:centerLineRow",
                type: "checkbox",
                isNumericEnum: true,
                initialValue: bayData === null || bayData === void 0 ? void 0 : bayData.centerLineRow,
                defaultValue: 0,
                disabled: (!sizeSummary.centerLineRow && !dataHasZeroRow) ||
                    (!!sizeSummary.centerLineRow && dataHasZeroRow),
                helpText: !sizeSummary.centerLineRow
                    ? "view:centerLineRowNotSet"
                    : dataHasZeroRow
                        ? "view:centerLineRowDisabled"
                        : "",
            },
            {
                name: "athwartShip",
                label: "view:details.athwartShip",
                type: "checkbox",
                isNumericEnum: true,
                initialValue: bayData === null || bayData === void 0 ? void 0 : bayData.athwartShip,
                defaultValue: 0,
            },
        ],
        {
            name: "pairedBay",
            label: "view:details.paired",
            type: "radioButtonList",
            isNumericEnum: true,
            fieldset: true,
            initialValue: bayData === null || bayData === void 0 ? void 0 : bayData.pairedBay,
            defaultValue: 0,
            options: aftFwdOptions,
        },
        [
            {
                name: "doors",
                label: "view:details.doors",
                type: "radioButtonList",
                isNumericEnum: true,
                fieldset: true,
                initialValue: bayData === null || bayData === void 0 ? void 0 : bayData.doors,
                defaultValue: 0,
                options: aftFwdOptions,
            },
            {
                name: "reeferPlugs",
                label: "view:details.reeferPlugs",
                type: "radioButtonList",
                isNumericEnum: true,
                fieldset: true,
                initialValue: bayData === null || bayData === void 0 ? void 0 : bayData.reeferPlugs,
                defaultValue: 0,
                options: aftFwdOptions,
            },
        ],
        [
            {
                name: "foreHatch",
                label: "view:details.foreHatch",
                type: "checkbox",
                initialValue: bayData === null || bayData === void 0 ? void 0 : bayData.foreHatch,
                defaultValue: 0,
                isNumericEnum: true,
            },
            {
                name: "ventilated",
                label: "view:details.ventilated",
                type: "checkbox",
                initialValue: bayData === null || bayData === void 0 ? void 0 : bayData.ventilated,
                defaultValue: 0,
                isNumericEnum: true,
            },
        ],
        [
            {
                name: "heatSrcFore",
                label: "view:details.heatSrcFore",
                type: "checkbox",
                initialValue: bayData === null || bayData === void 0 ? void 0 : bayData.heatSrcFore,
                defaultValue: 0,
                isNumericEnum: true,
            },
            {
                name: "ignitionSrcFore",
                label: "view:details.ignitionSrcFore",
                type: "checkbox",
                initialValue: bayData === null || bayData === void 0 ? void 0 : bayData.ignitionSrcFore,
                defaultValue: 0,
                isNumericEnum: true,
            },
        ],
        [
            {
                name: "quartersFore",
                label: "view:details.quartersFore",
                type: "checkbox",
                initialValue: bayData === null || bayData === void 0 ? void 0 : bayData.quartersFore,
                defaultValue: 0,
                isNumericEnum: true,
            },
            {
                name: "engineRmBulkFore",
                label: "view:details.engineRmBulkFore",
                type: "checkbox",
                initialValue: bayData === null || bayData === void 0 ? void 0 : bayData.engineRmBulkFore,
                defaultValue: 0,
                isNumericEnum: true,
            },
        ],
        [
            {
                name: "telescoping",
                label: "view:details.telescoping",
                type: "checkbox",
                initialValue: bayData === null || bayData === void 0 ? void 0 : bayData.telescoping,
                defaultValue: 0,
                isNumericEnum: true,
            },
        ],
        [
            {
                name: "label20",
                label: "view:edit.bayLevelData.label20",
                type: "textBox",
                initialValue: bayData === null || bayData === void 0 ? void 0 : bayData.label20,
            },
            {
                name: "label40",
                label: "view:edit.bayLevelData.label40",
                type: "textBox",
                initialValue: bayData === null || bayData === void 0 ? void 0 : bayData.label40,
            },
        ],
        {
            name: "notes",
            label: "view:edit.bayLevelData.notes",
            type: "textArea",
            initialValue: (_a = bayData === null || bayData === void 0 ? void 0 : bayData.meta) === null || _a === void 0 ? void 0 : _a.notes,
        },
    ];
    const bayDetailsForm = new TedivoForm({
        formProps: { size: "small", className: "form-boxed max500px" },
        fields: bayDetailsFormFields,
        onSubmit: () => undefined,
        formValidator: FormValidator,
        submitButton: editDrawer.submitButton,
    });
    translateTedivoForm({
        tedivoForm: bayDetailsForm,
        getTranslation,
    });
    // On Form change
    bayDetailsForm.onDataChange = (values, keyChanged) => {
        switch (keyChanged) {
            case "pairedBay":
                bayEditComp.pairedBayValue = values.pairedBay;
                break;
            case "athwartShip":
                bayDetailsForm.getFormControlsByName().pairedBay.field.disabled = !!values.athwartShip;
                break;
            case "centerLineRow":
                bayEditComp.centerLineRowValue = values.centerLineRow || 0;
                break;
        }
        if (!firstTedivoFormUpdate)
            editDrawer.dataIsDirty = true;
        firstTedivoFormUpdate = false;
    };
    bayEditComp.updateDetailsForm = (data) => {
        Object.keys(data).forEach((key) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            bayDetailsForm.setValue(key, data[key]);
        });
    };
    // Do a first call
    (_b = bayDetailsForm.onDataChange) === null || _b === void 0 ? void 0 : _b.call(bayDetailsForm, bayDetailsForm.getValues(), "pairedBay");
    // On Interactive drawing change
    bayEditComp.onSlotsChanged = (slotData) => {
        internaSlotData = slotData;
        createOnBayEditComponentChanged(slotData, bayDetailsForm);
        editDrawer.dataIsDirty = true;
    };
    // Append Edit part
    const editPart = document.createElement("div");
    editPart.className = "bay-edit-components";
    editPart.appendChild(bayEditComp);
    editPart.appendChild(bayDetailsForm.form);
    drawer.appendChild(editPart);
    // Finally, Do open Drawer
    editDrawer.onSave = submitPassedToEditDrawer;
    drawer.show();
    function submitPassedToEditDrawer() {
        const validResult = bayDetailsForm.doSubmitForm();
        const shouldSendBeacon = validResult.success && (editDrawer === null || editDrawer === void 0 ? void 0 : editDrawer.dataIsDirty);
        if (editDrawer) {
            editDrawer.shouldNotClose = false;
            editDrawer.dataIsDirty = false;
        }
        if (validResult.success) {
            const newBayData = postProcessingForm(shipData, validResult.data, isoBay, level, bayData, internaSlotData);
            if (bayEditComp.postSaveActions.length) {
                bayEditComp.postSaveActions.forEach(({ isoBay, level, action }) => {
                    const targetBayData = ovdJsonStore.findBayInfo(isoBay, level);
                    if (targetBayData)
                        ovdJsonStore.replaceBayInfo(isoBay, level, action(targetBayData));
                });
            }
            goSquared.addEvent("Edit-OVD - Edit Bay - Save");
            ovdJsonStore.replaceBayInfo(isoBay, level, newBayData);
            if (shouldSendBeacon && ovdJsonStore.tvdId)
                BeaconServices.logEvents.notifyXhttp(Object.assign({ eventEntity: LogEventEntitiesEnum.File, eventType: LogEventTypesEnum.Modified, subEvent: `Bay-${isoBay}-${level === BayLevelEnum.ABOVE ? "Ab" : "Be"}-Modified`, itemId: ovdJsonStore.tvdId.fileId }, securityModule.getBeaconMetadata()));
            return true;
        }
        return false;
    }
}
function createOnBayEditComponentChanged(slotData, tedivoForm) {
    // Enable/disable centerLineRow field and boxes
    const some00RowInUse = slotData
        .map((slotData) => slotData.pos)
        .some((pos) => pos.indexOf("00") === 0);
    const centerLineRowField = tedivoForm.getFormControlsByName().centerLineRow;
    centerLineRowField.field.disabled = some00RowInUse;
}
function postProcessingForm(shipData, result, isoBay, level, prevBayData, newSlotsData) {
    var _a;
    const { notes, pairedBay, doors, reeferPlugs, centerLineRow, athwartShip, foreHatch, ventilated, heatSrcFore, ignitionSrcFore, quartersFore, engineRmBulkFore, telescoping } = result, restOfFormData = __rest(result, ["notes", "pairedBay", "doors", "reeferPlugs", "centerLineRow", "athwartShip", "foreHatch", "ventilated", "heatSrcFore", "ignitionSrcFore", "quartersFore", "engineRmBulkFore", "telescoping"]);
    const bayData = Object.assign(Object.assign(Object.assign({}, prevBayData), restOfFormData), { isoBay,
        level, pairedBay: pairedBay || undefined, doors: doors || undefined, reeferPlugs: reeferPlugs || undefined, centerLineRow: centerLineRow ? 1 : 0, athwartShip: athwartShip ? 1 : undefined, foreHatch: foreHatch ? 1 : undefined, ventilated: ventilated ? 1 : undefined, heatSrcFore: heatSrcFore ? 1 : undefined, ignitionSrcFore: ignitionSrcFore ? 1 : undefined, quartersFore: quartersFore ? 1 : undefined, engineRmBulkFore: engineRmBulkFore ? 1 : undefined, telescoping: telescoping ? 1 : undefined, meta: { notes }, infoByContLength: (prevBayData === null || prevBayData === void 0 ? void 0 : prevBayData.infoByContLength) || {} });
    if (newSlotsData !== undefined) {
        const newPerSlotInfo = {};
        const newPerRowInfo = { each: {}, common: {} };
        const newSlotsDataPosArray = newSlotsData.map((slotData) => slotData.pos);
        const { maxRow, centerLineRow, tiersByRow } = getRowsAndTiersFromSlotKeys(newSlotsDataPosArray);
        // 1. perSlotInfo
        newSlotsData.forEach((slotData) => {
            const pos = slotData.pos;
            newPerSlotInfo[pos] = slotData;
        });
        bayData.perSlotInfo = newPerSlotInfo;
        // 2. perRowInfo
        const newRows = createRowsFromConfig(!!centerLineRow, maxRow);
        const prevRowsCalcs = getRowsAndTiersFromSlotKeys(((prevBayData === null || prevBayData === void 0 ? void 0 : prevBayData.perSlotInfo)
            ? Object.keys(prevBayData === null || prevBayData === void 0 ? void 0 : prevBayData.perSlotInfo)
            : []));
        let rowsThatDifferInMinTier = 0;
        newRows.forEach((row) => {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            const prevRowInfoClonedOrNew = ((_b = (_a = prevBayData === null || prevBayData === void 0 ? void 0 : prevBayData.perRowInfo) === null || _a === void 0 ? void 0 : _a.each) === null || _b === void 0 ? void 0 : _b[row])
                ? Object.assign(Object.assign({}, (_c = prevBayData.perRowInfo.each) === null || _c === void 0 ? void 0 : _c[row]), { rowInfoByLength: Object.assign({}, (_d = prevBayData.perRowInfo.each) === null || _d === void 0 ? void 0 : _d[row].rowInfoByLength) }) : { isoRow: row };
            if (((_e = prevRowsCalcs.tiersByRow[row]) === null || _e === void 0 ? void 0 : _e.minTier) !== ((_f = tiersByRow[row]) === null || _f === void 0 ? void 0 : _f.minTier)) {
                const newMinTier = ((_g = tiersByRow[row]) === null || _g === void 0 ? void 0 : _g.minTier) !== undefined
                    ? pad2((_h = tiersByRow[row]) === null || _h === void 0 ? void 0 : _h.minTier)
                    : undefined;
                prevRowInfoClonedOrNew.bottomBase =
                    newMinTier !== undefined
                        ? shipData.masterCGs.bottomBases[newMinTier]
                        : undefined;
                prevRowInfoClonedOrNew.maxHeight;
                rowsThatDifferInMinTier += 1;
            }
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            newPerRowInfo.each[row] = prevRowInfoClonedOrNew;
        });
        if (rowsThatDifferInMinTier < prevRowsCalcs.rows.length) {
            newPerRowInfo.common = Object.assign({}, (_a = prevBayData === null || prevBayData === void 0 ? void 0 : prevBayData.perRowInfo) === null || _a === void 0 ? void 0 : _a.common);
        }
        bayData.perRowInfo = newPerRowInfo;
    }
    return bayData;
}
