import { IFeaturesAllowed, TContainerLengths } from "open-vessel-definition";

import { getSlotCapabilitiesLegendOptions } from "@baplie-viewer2/tedivo-bay-grid-core";
import i18n from "../../../../app/i18/i18tn";

export function createLegend(
  availableSizes: TContainerLengths[],
  featuresAllowed?: IFeaturesAllowed
): HTMLUListElement {
  const options = getSlotCapabilitiesLegendOptions(
    availableSizes,
    ["sizes", "misc", "restricted"],
    featuresAllowed
  );
  const ul = document.createElement("ul");
  ul.className = "slot-legend-list";
  ul.dataset.title = i18n.t("general:common.legend") as unknown as string;
  options.forEach((opt) => {
    const li = document.createElement("li");
    li.style.backgroundColor = opt.bgColor;
    li.style.color = opt.color;
    li.innerHTML = i18n.t(opt.labelKey);
    ul.appendChild(li);
  });
  return ul;
}
