import {
  ICellName,
  ICreateBayGridPartProps,
  ISvgWithSize,
} from "../../types/ICreateBayGridBaseProps";

import { calculateGridPartDimensions } from "./calculateGridDimensions";
import { createSvgText } from "../../helpers/createSvgText";

const SYMBOL_NAMES = {
  REGULAR: "bxCnReg",
  DISABLED: "bxCnDis",
  PROHIBITED: "bxCnPrh",
  EMPHASIZED: "bxEmph",
};

/**
 * Creates a Grid with labels. For instance, the above deck. Used by other functions.
 * @returns ISvgWithSize { svgGroup, width, height, symbols }
 */
export default function createGridPart<U>({
  tiersArray,
  rowsArray,
  enabledCells,
  prohibitedCells,
  containerWidth,
  containerHeight,
  displayNumbers,
  cellSeparation = 0,
  nodesOptions,
  symbolPrefix = "",
  cellsToDraw,
  cellsDrawFunction,
  emphasizeCells,
}: ICreateBayGridPartProps<U>): ISvgWithSize {
  const mainSvgPaths: string[] = [];
  const svgGroup = document.createElementNS("http://www.w3.org/2000/svg", "g");

  const {
    boxDisabledBackgroundColor = "#ddd",
    boxDisabledBorderColor = "#aaa",
    boxProhibitedBorderColor = "#aaa",
    boxBackgroundColor = "#f4f4f4",
    boxBorderColor = "#ccc",
    lineColor = "#ccc",
    gridNumbersFontSize = 9,
    gridNumbersTextColor = "#777",
  } = nodesOptions;

  function createSymbols() {
    const symbols: { [id: string]: SVGSymbolElement } = {};

    // 1. Regular box
    const svgBoxSymbol = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "symbol"
    );

    const rect = document.createElementNS("http://www.w3.org/2000/svg", "path");
    rect.setAttribute(
      "d",
      `M${0},${0} h${containerWidth} v${containerHeight} h-${containerWidth} z`
    );
    rect.setAttribute("fill", boxBackgroundColor);
    rect.setAttribute("stroke-width", "0");
    rect.setAttribute("data-enabled", "1");

    if (cellSeparation) {
      rect.setAttribute("stroke", boxBorderColor);
      rect.setAttribute("stroke-width", "1");
    }

    svgBoxSymbol.id = `${symbolPrefix}${SYMBOL_NAMES.REGULAR}`;
    svgBoxSymbol.setAttribute("style", "overflow:visible");
    svgBoxSymbol.appendChild(rect);

    symbols[SYMBOL_NAMES.REGULAR] = svgBoxSymbol;

    // 2. Disabled box
    const svgBoxDisabledSymbol = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "symbol"
    );

    const rectD = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path"
    );
    rectD.setAttribute(
      "d",
      `M${0},${0} h${containerWidth} v${containerHeight} h-${containerWidth} z`
    );
    rectD.setAttribute("fill", boxDisabledBackgroundColor);
    rectD.setAttribute("stroke-width", "0");
    rectD.setAttribute("data-enabled", "0");

    if (cellSeparation) {
      rectD.setAttribute("stroke", boxDisabledBorderColor);
      rectD.setAttribute("stroke-width", "0.5");
    }

    svgBoxDisabledSymbol.id = `${symbolPrefix}${SYMBOL_NAMES.DISABLED}`;
    svgBoxDisabledSymbol.setAttribute("style", "overflow:visible");
    svgBoxDisabledSymbol.appendChild(rectD);

    symbols[SYMBOL_NAMES.DISABLED] = svgBoxDisabledSymbol;

    // 3. Emphasized box
    const svgEmphasizedBox = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "symbol"
    );

    const rectE = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path"
    );
    rectE.setAttribute(
      "d",
      `M${0},${0} h${containerWidth} v${containerHeight} h-${containerWidth} z`
    );
    rectE.setAttribute("data-enabled", "0");
    rectE.setAttribute("fill-opacity", "0");
    rectE.setAttribute("stroke", nodesOptions.emphasizeLineColor || "#000");
    rectE.setAttribute("stroke-width", "2");

    svgEmphasizedBox.id = `${symbolPrefix}${SYMBOL_NAMES.EMPHASIZED}`;
    svgEmphasizedBox.setAttribute("style", "overflow:visible");
    svgEmphasizedBox.appendChild(rectE);

    symbols[SYMBOL_NAMES.EMPHASIZED] = svgEmphasizedBox;

    // 4. Prohibited box
    const svgBoxProhibitedSymbol = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "symbol"
    );

    const rectDProh = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path"
    );
    rectDProh.setAttribute(
      "d",
      `M0,0 
       h${containerWidth} v${containerHeight} h-${containerWidth} L0,0 M0,0
       L${containerWidth},${containerHeight} M${containerWidth},0 L0,${containerHeight} z`
    );
    rectDProh.setAttribute("fill", boxDisabledBackgroundColor);
    rectDProh.setAttribute("fill-opacity", "0.65");
    rectDProh.setAttribute("stroke", boxProhibitedBorderColor);
    rectDProh.setAttribute("stroke-width", "1");
    rectDProh.setAttribute("stroke-linecap", "round");
    rectDProh.setAttribute("data-enabled", "0");

    if (cellSeparation) {
      rectDProh.setAttribute("stroke", boxProhibitedBorderColor);
      rectDProh.setAttribute("stroke-width", "1");
    }

    svgBoxProhibitedSymbol.id = `${symbolPrefix}${SYMBOL_NAMES.PROHIBITED}`;
    svgBoxProhibitedSymbol.setAttribute(
      "style",
      "overflow:visible; cursor: not-allowed;"
    );
    svgBoxProhibitedSymbol.appendChild(rectDProh);

    symbols[SYMBOL_NAMES.PROHIBITED] = svgBoxProhibitedSymbol;
    return symbols;
  }

  function appendSvgText(text: string, x: number, y: number) {
    const svgT = createSvgText({
      text,
      x,
      y,
      fontSize: gridNumbersFontSize,
      textColor: gridNumbersTextColor,
      className: "st-lab",
    });
    svgGroup.appendChild(svgT);
  }

  function createSvgLinePath(x1: number, y1: number, x2: number, y2: number) {
    mainSvgPaths.push("M" + x1 + "," + y1 + " " + x2 + "," + y2);
  }

  // Dimensions
  const {
    numberOfTiers,
    numberOfRows,
    addLeftWidth,
    addTopHeight,
    widthOfCells,
    heightOfCells,
    widthOfGrid,
    heightOfGrid,
    cellPositions,
  } = calculateGridPartDimensions({
    tiersArray,
    rowsArray,
    enabledCells,
    containerWidth,
    containerHeight,
    displayNumbers,
    cellSeparation,
  });

  // Boxes for each cell
  for (let t = 0; t < numberOfTiers; t += 1) {
    for (let c = 0; c < numberOfRows; c += 1) {
      const name = `${rowsArray[c]}|${tiersArray[t]}` as ICellName;
      const cellIsDisabled = enabledCells && enabledCells.indexOf(name) < 0;
      const cellIsProhib =
        prohibitedCells && prohibitedCells.indexOf(name) >= 0;

      const { x, y } = cellPositions[name];

      const symbolName = cellIsProhib
        ? `#${symbolPrefix}${SYMBOL_NAMES.PROHIBITED}`
        : cellIsDisabled
        ? `#${symbolPrefix}${SYMBOL_NAMES.DISABLED}`
        : `#${symbolPrefix}${SYMBOL_NAMES.REGULAR}`;

      const box = document.createElementNS("http://www.w3.org/2000/svg", "use");
      box.setAttribute("href", symbolName);
      box.setAttribute("x", String(x));
      box.setAttribute("y", String(y));

      box.dataset["pos"] = name;
      svgGroup.appendChild(box);

      if (emphasizeCells && emphasizeCells.indexOf(name) >= 0) {
        const emph = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "use"
        );
        emph.setAttribute("x", String(x));
        emph.setAttribute("y", String(y));
        emph.setAttribute("href", `#${symbolPrefix}${SYMBOL_NAMES.EMPHASIZED}`);
        svgGroup.appendChild(emph);
      }
    }
  }

  //draw horizontal lines & numbers
  for (let j = 0; j <= numberOfTiers; j += 1) {
    if (!cellSeparation)
      createSvgLinePath(
        addLeftWidth,
        addTopHeight + containerHeight * j,
        addLeftWidth + widthOfCells,
        addTopHeight + containerHeight * j
      );

    const isoTier = tiersArray[j];

    if (displayNumbers.left && j < numberOfTiers)
      appendSvgText(
        isoTier || "",
        containerWidth * 0.5,
        addTopHeight +
          (containerHeight + cellSeparation) * j +
          containerHeight * 0.5
      );

    if (displayNumbers.right && j < numberOfTiers)
      appendSvgText(
        isoTier || "",
        addLeftWidth + widthOfCells + containerWidth * 0.5,
        addTopHeight +
          (containerHeight + cellSeparation) * j +
          containerHeight * 0.5
      );
  }

  //draw vertical lines & numbers
  for (let j = 0; j <= numberOfRows; j += 1) {
    if (!cellSeparation)
      createSvgLinePath(
        addLeftWidth + containerWidth * j,
        addTopHeight,
        addLeftWidth + containerWidth * j,
        addTopHeight + heightOfCells
      );

    const isoRow = rowsArray[j];

    if (displayNumbers.top && j < numberOfRows)
      appendSvgText(
        isoRow || "",
        addLeftWidth +
          (containerWidth + cellSeparation) * j +
          containerWidth * 0.5,
        containerHeight * 0.5
      );

    if (displayNumbers.bottom && j < numberOfRows)
      appendSvgText(
        isoRow || "",
        addLeftWidth +
          (containerWidth + cellSeparation) * j +
          containerWidth * 0.5,
        addTopHeight + heightOfCells + containerHeight * 0.5
      );
  }

  // Add grid lines if no cell-separation
  if (!cellSeparation) {
    const svgL = document.createElementNS("http://www.w3.org/2000/svg", "path");
    svgL.setAttribute("stroke", lineColor);
    svgL.setAttribute("stroke-width", "1");
    svgL.setAttribute("class", "grid-lines");
    svgL.setAttribute("stroke-linecap", "round");
    svgL.setAttribute("d", mainSvgPaths.join(" "));
    svgGroup.appendChild(svgL);
  }

  svgGroup.setAttribute("width", String(widthOfGrid));
  svgGroup.setAttribute("height", String(heightOfGrid));

  // Add Cells
  if (cellsToDraw && cellsToDraw.length && cellsDrawFunction) {
    const svgCellsGroup = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "g"
    );
    svgCellsGroup.setAttribute("class", "cells-group");

    cellsToDraw.forEach((cellInfo) => {
      const { node, pos } = cellsDrawFunction(cellInfo);
      const cellPosition = cellPositions[pos];
      if (pos && cellPosition && node) {
        svgCellsGroup.appendChild(node);
        node.setAttribute(
          "transform",
          `translate(${cellPosition.x}, ${cellPosition.y})`
        );
      }
    });

    svgGroup.appendChild(svgCellsGroup);
  }

  return {
    svgGroup,
    width: widthOfGrid,
    height: heightOfGrid,
    symbols: createSymbols(),
    cellPositions,
  };
}
