import { __awaiter } from "tslib";
import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import Services from "../../../app/services";
import awsCognito from "../../../app/security/awsCognito";
import { i18nReactive } from "../../../app/i18/i18tn";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
export class TVDTopNotificationsElement extends HTMLElement {
    set btnLookAvailable(notificationsAvailable) {
        if (notificationsAvailable) {
            this.btn.variant = "warning";
            this.btn.outline = true;
        }
        else {
            this.btn.variant = "default";
            this.btn.outline = false;
        }
    }
    constructor() {
        super();
        this.inThrottle = 0;
        this.onAuthChanged = ((ev) => {
            const { isLoggedIn } = ev.detail;
            if (isLoggedIn && securityModule.currentOrganizationId) {
                this.updateNotificationsUI();
            }
            else {
                this.btnLookAvailable = false;
            }
        });
        this.onOrgChanged = ((ev) => {
            if (securityModule.isLoggedIn && securityModule.currentOrganizationId)
                this.updateNotificationsUI();
        });
        this.i18nCR = new I18nComponentRegisterer(i18nReactive);
        this.btn = document.createElement("sl-button");
    }
    connectedCallback() {
        const icon = document.createElement("sl-icon");
        icon.name = "bell";
        const btn = this.btn;
        btn.appendChild(icon);
        btn.pill = true;
        btn.size = "small";
        btn.outline = false;
        this.appendChild(btn);
        this.i18nCR.addConsumer(btn, "menu:notifications", "title");
        btn.addEventListener("click", () => {
            router.navigate(routeFns.notificationsList());
        });
        awsCognito.addEventListener("authenticationChanged", this.onAuthChanged);
        awsCognito.addEventListener("userDataRetrieved", this.onOrgChanged);
    }
    disconnectedCallback() {
        this.i18nCR.disconnect();
        awsCognito.removeEventListener("authenticationChanged", this.onAuthChanged);
        awsCognito.removeEventListener("userDataRetrieved", this.onOrgChanged);
    }
    updateNotificationsUI() {
        return __awaiter(this, void 0, void 0, function* () {
            window.clearTimeout(this.inThrottle);
            this.inThrottle = window.setTimeout(() => __awaiter(this, void 0, void 0, function* () {
                const notifications = yield Services.notifications.getAllCountOnly();
                this.btnLookAvailable = !!notifications.data;
            }), 250);
        });
    }
}
TVDTopNotificationsElement.observedAttributes = [];
customElements.define("tvd-notifications-component", TVDTopNotificationsElement);
