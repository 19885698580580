import { __awaiter, __rest } from "tslib";
import { TedivoForm, translateTedivoForm, } from "@baplie-viewer2/tedivo-form";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";
import BreadcrumbsElement from "../../common/misc/breadcrumbs.element";
import EditDrawer from "../../common/EditDrawer";
import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import IntegratedDialogError from "../../common/IntegratedDialogError";
import Services from "../../../app/services";
import SmartTable from "../../common/smartTable/smart-table.element";
import UserTypeEnum from "packages/oss-editor/src/app/enums/UserTypeEnum";
import { formatDateInCurrentTimezone } from "@baplie-viewer2/tedivo-pure-helpers";
import globalStore from "../../../app/stores/globalStore";
import goSquared from "../../../app/tracking/goSquared";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import { setAppTitle } from "../../../app/app.element";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";
import { z } from "zod";
export class TVDAdminOrgsUsersComponent extends HTMLElement {
    constructor() {
        super();
        this.displayOrganizationUsers = (orgId) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            globalStore.touchLastUserInteraction();
            if (!orgId) {
                router.navigate(routeFns.adminOrganizations());
                return;
            }
            const wrapper = this.wrapper;
            const box = this.mainBox;
            removeChildren(box);
            const actionAddOrgButton = document.createElement("sl-button");
            actionAddOrgButton.innerHTML = getTranslation("general:users.adminCreate");
            actionAddOrgButton.variant = "primary";
            actionAddOrgButton.addEventListener("click", () => {
                this.openOrganizationUserEditModal();
            }, false);
            wrapper.setLoading(true);
            try {
                const resp = yield Services.organizations.getSingleOrganizationAndUsers(orgId);
                if (resp.data) {
                    this.data = resp.data;
                    this.breadcrumbs.setLastItemText(((_a = resp.data.organization) === null || _a === void 0 ? void 0 : _a.name) || "");
                    box.appendChild(createUsersTable({
                        data: resp.data,
                        actionBox: actionAddOrgButton,
                        onEditUser: (u) => {
                            this.openOrganizationUserEditModal(Object.assign({}, u));
                        },
                        onDeleteUserFromOrg: this.deleteOrganizationUser,
                    }));
                }
            }
            catch (e) {
                this.showError(e);
            }
            wrapper.setLoading(false);
        });
        this.showError = (e) => {
            this.dialogError.show(getTranslation(`errors:errorHasOcurred`), getTranslation(typeof e === "string" ? e : e.translationKey));
        };
        this.openOrganizationUserEditModal = (prevData) => {
            var _a;
            const orgId = router.getRouteParams().id;
            if (!orgId)
                return;
            const editForm = createOrgUserEdit({
                submitButton: this.editDrawer.submitButton,
                orgId,
                prevData,
                showError: this.showError,
                organizationData: (_a = this.data) === null || _a === void 0 ? void 0 : _a.organization,
            });
            if (editForm) {
                const drawer = this.editDrawer.getEditDrawer({
                    title: getTranslation("general:users.adminCreate"),
                    showUnits: false,
                    readOnlyMode: false,
                    onSave: () => __awaiter(this, void 0, void 0, function* () {
                        this.editDrawer.disabled = true;
                        const valid = yield editForm.submitFunction();
                        this.editDrawer.disabled = false;
                        if (valid) {
                            this.displayOrganizationUsers(router.getRouteParams().id);
                        }
                        return valid;
                    }),
                });
                drawer.appendChild(editForm.node);
                drawer.show();
            }
        };
        this.deleteOrganizationUser = (orgId, dta) => {
            const dialog = this.deleteDialog;
            removeChildren(dialog);
            dialog.label = getTranslation("general:users.delete");
            const htmlContent = document.createElement("div");
            htmlContent.innerHTML = `${getTranslation("general:users.delete")}<br /><br /><strong>${dta.name} ${dta.familyName}</strong>`;
            const closeBtn = document.createElement("sl-button");
            closeBtn.slot = "footer";
            closeBtn.variant = "danger";
            closeBtn.autofocus = true;
            closeBtn.tabIndex = 0;
            closeBtn.innerHTML = getTranslation("general:common.delete");
            closeBtn.addEventListener("click", () => __awaiter(this, void 0, void 0, function* () {
                closeBtn.disabled = true;
                closeBtn.loading = true;
                yield Services.organizations.adminDeleteOrganizationUser(orgId, dta.sub);
                closeBtn.disabled = false;
                closeBtn.loading = false;
                dialog.hide();
                this.displayOrganizationUsers(router.getRouteParams().id);
            }), false);
            dialog.appendChild(htmlContent);
            dialog.appendChild(closeBtn);
            this.appendChild(dialog);
            dialog.show();
        };
        this.i18nCR = new I18nComponentRegisterer(i18nReactive);
        this.dialogError = new IntegratedDialogError(this);
        this.wrapper = document.createElement("div-spinner-element");
        this.mainBox = document.createElement("div");
        this.deleteDialog = document.createElement("sl-dialog");
        this.breadcrumbs = new BreadcrumbsElement();
        this.editDrawer = new EditDrawer();
        setAppTitle(getTranslation("general:users.users"));
    }
    connectedCallback() {
        goSquared.trackPage("TEDIVO Admin Pages - Users");
        goSquared.addEvent("Admin/Organization-Users - Show page");
        this.mainBox.className = "oss-card";
        const wrapper = this.wrapper, breadcrumbs = this.breadcrumbs, h1 = document.createElement("h1");
        breadcrumbs.items = [
            {
                href: routeFns.adminOrganizations(),
                label: getTranslation("general:organizations.organizations"),
            },
            { label: "" },
        ];
        wrapper.appendChild(breadcrumbs);
        wrapper.appendChild(h1);
        wrapper.appendChild(this.mainBox);
        this.appendChild(this.editDrawer.drawer);
        this.appendChild(wrapper);
        this.displayOrganizationUsers(router.getRouteParams().id);
        this.i18nCR.addConsumer(topMessageElement.element, "general:adminArea", "innerHTML");
        this.i18nCR.addConsumer(h1, "general:users.users", "innerHTML");
    }
    disconnectedCallback() {
        this.i18nCR.disconnect();
    }
}
TVDAdminOrgsUsersComponent.observedAttributes = [];
customElements.define("tvd-admin-orgs-users-component", TVDAdminOrgsUsersComponent);
function createUsersTable({ data, actionBox, onEditUser, onDeleteUserFromOrg, }) {
    var _a, _b;
    const createSortIcon = (asc) => {
        const icon = document.createElement("sl-icon");
        icon.name = asc ? "sort-down-alt" : "sort-up-alt";
        return icon;
    };
    const organizationId = ((_a = data.organization) === null || _a === void 0 ? void 0 : _a.organizationId) || "";
    const users = mergeUsersWithCurrentOrgData(organizationId, data.users || []);
    const tableUsers = new SmartTable().initialize({
        className: "tvd-table tvd-table-smart-table",
        data: users,
        defaultSort: "name",
        title: ((_b = data.organization) === null || _b === void 0 ? void 0 : _b.name) || "",
        fields: [
            {
                name: "name",
                label: getTranslation("general:common.name"),
            },
            {
                name: "familyName",
                label: getTranslation("general:users.familyName"),
            },
            {
                name: "email",
                label: getTranslation("general:common.email"),
            },
            {
                name: "organizationUserEnabled",
                label: getTranslation("general:common.enabled"),
                mapper: (u) => getTranslation(`general:common.${u.organizationUserEnabled === "1" ? "yes" : "no"}`),
            },
            {
                name: "userStatusType",
                label: getTranslation("general:common.status"),
            },
            {
                name: "organizationUserRole",
                label: getTranslation("general:common.type"),
                mapper: (u) => getTranslation(`enums:UserTypeEnum.${u.organizationUserRole}`),
            },
            {
                name: "userCreateDate",
                label: getTranslation("general:common.createdAt"),
                valueFunction: (dta) => dta.userCreateDate
                    ? formatDateInCurrentTimezone(dta.userCreateDate)
                    : "",
                className: "centered",
            },
            {
                name: "userLastModifiedDate",
                label: getTranslation("general:common.modifiedAt"),
                valueFunction: (dta) => dta.userLastModifiedDate
                    ? formatDateInCurrentTimezone(dta.userLastModifiedDate)
                    : "",
                className: "centered",
            },
            {
                name: "sub",
                label: getTranslation("general:common.edit"),
                type: "icon",
                icon: "pencil",
                notSortable: true,
                onClick: (dta) => {
                    onEditUser(dta);
                },
            },
            {
                name: "sub",
                label: getTranslation("general:common.delete"),
                type: "icon",
                icon: "trash",
                iconVariant: "danger",
                notSortable: true,
                onClick: (dta) => {
                    onDeleteUserFromOrg(organizationId, dta);
                },
            },
        ],
        settings: {
            sortEnabled: true,
            sortVisible: true,
            filterEnabled: true,
            labelSortBy: getTranslation("general:common.sortBy"),
            labelSearch: getTranslation("general:common.filterBy"),
            createSortIconFn: createSortIcon,
            filterFields: ["name", "familyName", "email"],
            actionBox,
        },
        pkFunction: (dta) => dta.sub,
    });
    return tableUsers;
}
function createOrgUserEdit({ submitButton, orgId, prevData, showError, organizationData, }) {
    var _a, _b, _c;
    const holder = document.createElement("div");
    const availableUserTypeOptions = [
        { label: "USER", value: "USER" },
        { label: "ADMIN", value: "ADMIN" },
        { label: "SUPERADMIN", value: "SUPERADMIN" },
    ];
    const formFields = [
        [
            {
                name: "name",
                label: "general:common.name",
                type: "textBox",
                initialValue: (prevData === null || prevData === void 0 ? void 0 : prevData.name) || "",
            },
            {
                name: "familyName",
                label: "general:users.familyName",
                type: "textBox",
                initialValue: (prevData === null || prevData === void 0 ? void 0 : prevData.familyName) || "",
            },
        ],
        [
            {
                name: "email",
                label: "general:common.email",
                type: "textBox",
                initialValue: (prevData === null || prevData === void 0 ? void 0 : prevData.email) || "",
                helpText: getTranslation("general:users.domainsHelpText", {
                    domains: ((_a = organizationData === null || organizationData === void 0 ? void 0 : organizationData.domains) === null || _a === void 0 ? void 0 : _a.join(", ")) || "",
                }),
            },
            {
                name: "organizationUserEnabled",
                label: "general:common.enabled",
                type: "checkbox",
                initialValue: (_b = (prevData === null || prevData === void 0 ? void 0 : prevData.organizationUserEnabled) === "1") !== null && _b !== void 0 ? _b : true,
                padStart: true,
            },
        ],
        {
            name: "organizationUserRole",
            label: "general:common.type",
            type: "radioButtonList",
            fieldset: true,
            initialValue: (_c = prevData === null || prevData === void 0 ? void 0 : prevData.organizationUserRole) !== null && _c !== void 0 ? _c : "USER",
            options: availableUserTypeOptions,
        },
    ];
    const tedivoForm = new TedivoForm({
        fields: formFields,
        onSubmit: () => undefined,
        formValidator: createFormValidator(false, (organizationData === null || organizationData === void 0 ? void 0 : organizationData.domains) || []),
        submitButton: submitButton,
        autoFocusOnFirstInput: true,
        hiddenData: prevData,
    });
    translateTedivoForm({
        tedivoForm,
        getTranslation: getTranslation,
    });
    holder.appendChild(tedivoForm.form);
    return {
        node: holder,
        tedivoForm,
        submitFunction: submitPassedToEditDrawer,
    };
    function submitPassedToEditDrawer() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            const validResult = tedivoForm.doSubmitForm();
            if (validResult.success) {
                const values = validResult.data;
                if (prevData === null || prevData === void 0 ? void 0 : prevData.sub) {
                    const data = Object.assign(Object.assign(Object.assign({}, prevData), values), { organizationId: orgId, organizationUserEnabled: values.organizationUserEnabled ? "1" : "0", organizationUserRole: values.organizationUserRole });
                    const resp = yield Services.organizations.adminUpdateOrganizationUser(orgId, data);
                    if (!((_a = resp.data) === null || _a === void 0 ? void 0 : _a.id)) {
                        showError({
                            errorCode: String(resp.statusCode),
                            message: resp.code || "errorModifyingUser",
                            translationKey: resp.message || "errors:errorModifyingUser",
                        });
                        return false;
                    }
                }
                else {
                    const data = Object.assign(Object.assign({}, values), { organizationId: orgId, organizationUserEnabled: values.organizationUserEnabled ? "1" : "0", organizationUserRole: values.organizationUserRole });
                    const resp = yield Services.organizations.adminCreateOrganizationUser(orgId, data);
                    if (!((_b = resp.data) === null || _b === void 0 ? void 0 : _b.id)) {
                        showError({
                            errorCode: String(resp.statusCode),
                            message: resp.code || "errorCreatingUser",
                            translationKey: resp.message || "errors:errorCreatingUser",
                        });
                        return false;
                    }
                }
                return true;
            }
            else {
                return false;
            }
        });
    }
    function createFormValidator(hasPassword, allowedDomains) {
        const formValidator = z
            .object({
            email: z
                .string()
                .email()
                .transform((v) => v === null || v === void 0 ? void 0 : v.toLowerCase()),
            name: z.string().min(1),
            familyName: z.string().min(1),
            organizationUserEnabled: z.boolean(),
            organizationUserRole: z.nativeEnum(UserTypeEnum),
            password: z.string().min(6).optional(),
        })
            .refine((data) => {
            if (data.email) {
                const domain = (data.email.split("@")[1] || "")
                    .toLowerCase()
                    .trim();
                if (allowedDomains.indexOf(domain) < 0)
                    return false;
                return true;
            }
            else
                return false;
        }, {
            path: ["email"],
        })
            .refine((data) => {
            if (hasPassword) {
                return !!data.password;
            }
            return true;
        }, {
            path: ["password"],
        });
        return formValidator;
    }
}
function mergeUserWithCurrentOrgData(orgId, user) {
    const { organizations } = user, rest = __rest(user, ["organizations"]);
    const curr = organizations.find((o) => o.orgId === orgId);
    return Object.assign(Object.assign({}, rest), { organizationUserRole: (curr === null || curr === void 0 ? void 0 : curr.role) || "USER", organizationUserEnabled: (curr === null || curr === void 0 ? void 0 : curr.uEnabled) || "0" });
}
export function mergeUsersWithCurrentOrgData(orgId, users) {
    return users.map((user) => mergeUserWithCurrentOrgData(orgId, user));
}
