import HttpClient, { IResponseModel } from "../HttpClient";
import {
  ICognitoUserWithOrgData,
  IFilesTags,
  IFilesTagsAction,
  IOrganization,
  ISingleOrganizationAndUsers,
} from "@baplie-viewer2/tedivo-api-models";

const urlOrganizations = "organizations";

const OrganizationsService = {
  adminGetAllOrganizations: async (): Promise<
    IResponseModel<IOrganization[]>
  > => {
    const client = new HttpClient();
    return await client.request<IOrganization[]>({
      method: "GET",
      url: urlOrganizations,
    });
  },

  getSingleOrganizationAndUsers: async (
    id: string,
  ): Promise<IResponseModel<ISingleOrganizationAndUsers>> => {
    const client = new HttpClient();
    return await client.request<ISingleOrganizationAndUsers>({
      method: "GET",
      url: `${urlOrganizations}/${id}/users`,
    });
  },

  adminCreateOrganization: async (
    data: Omit<IOrganization, "organizationId">,
  ): Promise<IResponseModel<ICreateOutput>> => {
    const client = new HttpClient();
    return await client.request<ICreateOutput>({
      method: "PUT",
      url: urlOrganizations,
      data,
    });
  },

  adminUpdateOrganization: async (
    data: IOrganization,
  ): Promise<IResponseModel<unknown>> => {
    const client = new HttpClient();
    return await client.request<unknown>({
      method: "PUT",
      url: urlOrganizations,
      data,
    });
  },

  adminDeleteOrganization: async (
    id: string,
  ): Promise<IResponseModel<unknown>> => {
    const client = new HttpClient();
    return await client.request<unknown>({
      method: "DELETE",
      url: `${urlOrganizations}/${id}`,
      data: undefined,
    });
  },

  adminCreateOrganizationUser: async (
    orgId: string,
    data: Omit<ICognitoUserWithOrgData, "sub">,
  ): Promise<IResponseModel<ICreateOutput | ICreateOutputError>> => {
    const client = new HttpClient();
    return await client.request<ICreateOutput | ICreateOutputError>({
      method: "PUT",
      url: `${urlOrganizations}/${orgId}/users`,
      data,
    });
  },

  adminUpdateOrganizationUser: async (
    orgId: string,
    data: ICognitoUserWithOrgData,
  ): Promise<IResponseModel<ICreateOutput | ICreateOutputError>> => {
    const client = new HttpClient();
    return await client.request<ICreateOutput | ICreateOutputError>({
      method: "PUT",
      url: `${urlOrganizations}/${orgId}/users`,
      data,
    });
  },

  adminDeleteOrganizationUser: async (
    orgId: string,
    userId: string,
  ): Promise<IResponseModel<unknown>> => {
    const client = new HttpClient();
    return await client.request<unknown>({
      method: "DELETE",
      url: `${urlOrganizations}/${orgId}/users/${userId}`,
      data: undefined,
    });
  },

  getOrganizationName: async (
    orgId: string,
  ): Promise<IResponseModel<{ name: string }>> => {
    const client = new HttpClient();
    return await client.request<{ name: string }>({
      method: "GET",
      url: `organizationName/${orgId}`,
    });
  },

  updateFileTags: async (
    data: IFilesTagsAction[],
  ): Promise<IResponseModel<IFilesTags>> => {
    const client = new HttpClient();
    return await client.request<IFilesTags>({
      method: "PUT",
      url: "organizations/updateTags",
      data,
    });
  },

  getFileTags: async (): Promise<IResponseModel<IFilesTags>> => {
    const client = new HttpClient();
    return await client.request<IFilesTags>({
      method: "GET",
      url: "organizations/getTags",
    });
  },
};

export default OrganizationsService;

type ICreateOutput = { id: string; merged?: boolean };
type ICreateOutputError = { id?: never; code: string; message: string };
