import SlIcon from "@shoelace-style/shoelace/dist/components/icon/icon";
import SlTooltip from "@shoelace-style/shoelace/dist/components/tooltip/tooltip";

export class EditButton extends HTMLElement {
  name = "";
  title = "";
  pill = false;
  size: "small" | "medium" | "large" = "small";
  onClick: (() => void) | undefined;
  tooltip: SlTooltip | undefined = undefined;
  disabled = false;

  connectedCallback() {
    const button = document.createElement("sl-button");
    button.pill = this.pill;
    button.size = this.size;
    button.disabled = this.disabled;

    const editIcon = document.createElement("sl-icon");
    editIcon.name = "pencil";
    editIcon.slot = "prefix";
    button.appendChild(editIcon);

    if (this.title) {
      const tooltip = document.createElement("sl-tooltip");
      tooltip.placement = "left";
      tooltip.content = this.title;

      tooltip.appendChild(button);
      this.appendChild(tooltip);
      this.tooltip = tooltip;
    } else {
      this.appendChild(button);
    }

    button.onclick = () => {
      if (this.tooltip) this.tooltip.hide();
      if (this.onClick !== undefined) {
        this.onClick();
      }
    };
  }
}

customElements.define("tf-edit-button", EditButton);

declare global {
  interface HTMLElementTagNameMap {
    "tf-edit-button": EditButton;
  }
}

export function createEditButton({
  id,
  pill,
  onClick,
  title,
  disabled,
}: IEditButtonProps): HTMLElement {
  const button = new EditButton();
  button.name = id;
  button.pill = pill || false;
  if (disabled) {
    button.disabled = true;
  } else {
    button.onClick = onClick;
  }
  if (title) button.title = title;
  return button;
}

interface IEditButtonProps {
  id: string;
  pill?: boolean;
  onClick: () => void;
  title?: string;
  disabled?: boolean;
}
