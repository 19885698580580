import { ForeAftEnum, } from "open-vessel-definition";
import { LCG_20, SEPARATION_IN_BETWEEN } from "./generateCoordFunctions";
import { feetToMillimeters, pad3, roundDec, sortNumericAsc, } from "@baplie-viewer2/tedivo-pure-helpers";
const FEET_20 = feetToMillimeters(20);
const FEET_10 = feetToMillimeters(10);
/**
 * Given a set of LCGs of different sizes, find the LCG for every 20 bay
 * @param sizeSummary
 * @param bayLevelSizesAndCgsAbove
 * @param bayLevelSizesAndCgsBelow
 * @param missingImportantXcgs boolean
 * @returns An object that maps IsoBayNumber to 20's LCG. It doesn't differentiate between ABOVE and BELOW
 */
export default function generate20Lcgs(sizeSummary, bayLevelSizesAndCgsAbove, bayLevelSizesAndCgsBelow, missingImportantXcgs) {
    const maxIsoBay = sizeSummary.isoBays;
    const lcgsBy20Bay = {};
    for (let bay = 1; bay <= maxIsoBay; bay += 2) {
        lcgsBy20Bay[pad3(bay)] = {
            aftLcg: 0,
            foreLcg: 0,
            lcg: undefined,
            maxSize: 20,
        };
    }
    if (!missingImportantXcgs) {
        findForCompleteCGs();
    }
    else {
        findForMissingCgs();
    }
    return lcgsBy20Bay;
    function findForCompleteCGs() {
        bayLevelSizesAndCgsBelow
            .filter((b) => { var _a; return ((_a = b.maxSizeLcg) === null || _a === void 0 ? void 0 : _a.size) !== undefined; })
            .forEach(find20Lcgs(lcgsBy20Bay));
        bayLevelSizesAndCgsAbove
            .filter((b) => {
            var _a, _b;
            return ((_a = lcgsBy20Bay[b.bayIsoNumber]) === null || _a === void 0 ? void 0 : _a.lcg) === undefined &&
                ((_b = b.maxSizeLcg) === null || _b === void 0 ? void 0 : _b.size) !== undefined;
        })
            .forEach(find20Lcgs(lcgsBy20Bay));
        fillMissingPlausibleData(lcgsBy20Bay);
    }
    function findForMissingCgs() {
        var _a;
        for (let bay = 1; bay <= maxIsoBay; bay += 2) {
            const bI = maxIsoBay - bay + 1;
            const baseLcg = bI * 0.5 * LCG_20 + (bI - 1) * 0.5 * SEPARATION_IN_BETWEEN;
            const bayIsoNumber = pad3(bay);
            let bl = bayLevelSizesAndCgsBelow.find((bl) => bl.bayIsoNumber == bayIsoNumber);
            if (!((_a = bl === null || bl === void 0 ? void 0 : bl.sizes) === null || _a === void 0 ? void 0 : _a.length))
                bl = bayLevelSizesAndCgsAbove.find((bl) => bl.bayIsoNumber == bayIsoNumber);
            const maxSize = bl
                ? Math.max(...(bl.sizes || []))
                : 20;
            lcgsBy20Bay[bayIsoNumber] = {
                lcg: baseLcg,
                aftLcg: roundDec(baseLcg - FEET_10, 2),
                foreLcg: roundDec(baseLcg + FEET_10, 2),
                paired: bl ? bl.pairedBay : undefined,
                maxSize: maxSize,
            };
        }
    }
}
/** Find the LCG for a 20 bay of EACH level */
function find20Lcgs(lcgsBy20Bay) {
    return (bl) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const maxSize = bl.maxSizeLcg.size;
        if (maxSize < 40) {
            const lcg2x20 = bl.maxSizeLcg.lcg || 0;
            lcgsBy20Bay[bl.bayIsoNumber] = {
                lcg: lcg2x20,
                aftLcg: roundDec(lcg2x20 - FEET_10, 2),
                foreLcg: roundDec(lcg2x20 + FEET_10, 2),
                paired: bl.pairedBay,
                maxSize,
            };
        }
        else {
            const lcg4x = bl.maxSizeLcg.lcg || 0;
            const pairedMultiplier = bl.pairedBay === ForeAftEnum.FWD ? -1 : 1;
            const lcg4x20 = roundDec(lcg4x + pairedMultiplier * FEET_10, 2);
            lcgsBy20Bay[bl.bayIsoNumber] = {
                lcg: lcg4x20,
                aftLcg: roundDec(lcg4x20 - FEET_10, 2),
                foreLcg: roundDec(lcg4x20 + FEET_10, 2),
                paired: bl.pairedBay,
                maxSize,
            };
        }
    };
}
function fillMissingPlausibleData(lcgsBy20Bay) {
    var _a, _b;
    const bays20Keys = Object.keys(lcgsBy20Bay).sort(sortNumericAsc); // From 001 to maxIsoBay
    const bays20KeysCount = bays20Keys.length;
    // Missing Fore
    let idx = 1;
    while (idx < bays20KeysCount) {
        const bay = bays20Keys[idx];
        const prevBay = bays20Keys[idx - 1];
        if (lcgsBy20Bay[bay].paired === ForeAftEnum.FWD &&
            lcgsBy20Bay[prevBay].lcg === undefined) {
            const lcg = lcgsBy20Bay[bay].lcg;
            if (lcg !== undefined) {
                // As LCG reference is AFT PP, prev LCG must be greater than current LCG
                const prevLcg20 = roundDec(lcg + FEET_20, 2);
                lcgsBy20Bay[prevBay] = {
                    lcg: prevLcg20,
                    aftLcg: roundDec(prevLcg20 - FEET_10, 2),
                    foreLcg: roundDec(prevLcg20 + FEET_10, 2),
                    paired: ForeAftEnum.AFT,
                    maxSize: ((_a = lcgsBy20Bay[prevBay]) === null || _a === void 0 ? void 0 : _a.maxSize) || 20,
                };
            }
        }
        idx++;
    }
    // Missing Aft
    idx = 0;
    while (idx < bays20KeysCount - 1) {
        const bay = bays20Keys[idx];
        const nextBay = bays20Keys[idx + 1];
        if (lcgsBy20Bay[bay].paired === ForeAftEnum.AFT &&
            lcgsBy20Bay[nextBay].lcg === undefined) {
            const lcg = lcgsBy20Bay[bay].lcg;
            if (lcg !== undefined) {
                // As LCG reference is AFT PP, next LCG must be smaller than current LCG
                const nextLcg20 = roundDec(lcg - FEET_20, 2);
                lcgsBy20Bay[nextBay] = {
                    lcg: nextLcg20,
                    aftLcg: roundDec(nextLcg20 - FEET_10, 2),
                    foreLcg: roundDec(nextLcg20 + FEET_10, 2),
                    paired: ForeAftEnum.FWD,
                    maxSize: ((_b = lcgsBy20Bay[nextBay]) === null || _b === void 0 ? void 0 : _b.maxSize) || 20,
                };
            }
        }
        idx++;
    }
}
