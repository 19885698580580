import { BayLevelEnum, IOpenVesselDefinitionV1 } from "open-vessel-definition";
import { IValidationResult, IValidator } from "./types";

import { IIsoBayPattern } from "open-vessel-definition/build/src/models/base/types/IPositionPatterns";
import { getAllThePairedBays } from "@baplie-viewer2/tedivo-bay-grid-core";

export function allBaysWith40sArePaired(
  validator: IValidator<IAllBaysWith40sArePaired>,
  json: IOpenVesselDefinitionV1,
): IAllBaysWith40sArePaired {
  const { pairedBays, unpairedBays } = getAllThePairedBays(json.baysData, true);

  return {
    name: validator.name,
    specialScopes: validator.specialScopes,
    isValid: unpairedBays.length === 0,
    invalidResults: unpairedBays.map(({ base, level }) => ({
      bay: base,
      level,
    })),
    validResults: pairedBays.map(({ base, level }) => ({ bay: base, level })),
  };
}

export interface IAllBaysWith40sArePaired extends IValidationResult {
  validResults: IAllBaysWith40sArePairedSingleResult[];
  invalidResults: IAllBaysWith40sArePairedSingleResult[];
}

export interface IAllBaysWith40sArePairedSingleResult {
  bay: IIsoBayPattern;
  level: BayLevelEnum;
}
