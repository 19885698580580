import { addStyles } from "../../helpers/addStyles";
const styles = (padStart) => `

  .checkbox {
    ${padStart
    ? "padding-top: calc(var(--sl-input-label-font-size-medium) * 2);"
    : ""}
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .checkbox__help-text {
    font-size: var(--sl-input-help-text-font-size-medium);
    color: var(--sl-input-help-text-color);
    margin-top: 0.5em;
  }

  .checkbox:not(.checkbox--has-help-text) .checkbox__help-text {
    display:none;
  }

  .has-error::part(label),
  :host(.has-error)::part(label) {
    color: var(--sl-color-danger-700) !important;
  }

  .has-error::part(thumb),
  .has-error::part(control),
  :host(.has-error)::part(thumb),
  :host(.has-error)::part(control) {
    border-color: var(--sl-color-danger-700) !important;
  }

  a {
    color: var(--sl-color-primary-600);
  }
  
  a:visited {
    color: var(--sl-color-primary-500);
  }
`;
export class TfCheckbox extends HTMLElement {
    get required() {
        return this.slCheckbox.required;
    }
    set required(v) {
        this.slCheckbox.required = v;
    }
    constructor() {
        super();
        this.internalLabel = undefined;
        this.internalHelpText = undefined;
        this.internalId = "";
        this.internalPadStart = false;
        this.internalIsNumericEnum = false;
        this.focus = () => {
            this.slCheckbox.focus();
        };
        this.select = () => {
            this.focus();
        };
        this.holder = document.createElement("div");
        this.holder.className = "checkbox";
        this.slCheckbox = document.createElement("sl-switch");
        this.slCheckbox.tabIndex = 0;
        this.slCheckbox.setAttribute("exportparts", "label base control");
        this.internalHelpDiv = document.createElement("div");
        this.internalHelpDiv.className = "checkbox__help-text";
        const onKeyPressed = (ev) => {
            const t = ev.target;
            if (t.disabled)
                return;
            if (ev.key === " ") {
                ev.preventDefault();
                this.slCheckbox.checked = !this.slCheckbox.checked;
                t.dispatchEvent(new Event("sl-change"));
            }
        };
        this.slCheckbox.addEventListener("keydown", onKeyPressed, false);
    }
    get name() {
        return this.internalId;
    }
    set name(n) {
        this.setAttribute("name", n);
        this.slCheckbox.id = n;
        this.internalId = n;
    }
    get label() {
        return this.internalLabel;
    }
    set label(v) {
        var _a;
        this.internalLabel = v;
        this.slCheckbox.innerHTML = (_a = this.internalLabel) !== null && _a !== void 0 ? _a : "";
    }
    get helpText() {
        return this.internalHelpText;
    }
    set helpText(v) {
        var _a;
        this.internalHelpText = v;
        this.internalHelpDiv.innerHTML = (_a = this.internalHelpText) !== null && _a !== void 0 ? _a : "";
        v
            ? this.holder.classList.add("checkbox--has-help-text")
            : this.holder.classList.remove("checkbox--has-help-text");
    }
    get size() {
        return "medium";
    }
    set size(_s) {
        //Nothings
    }
    get disabled() {
        return this.slCheckbox.disabled;
    }
    set disabled(v) {
        this.slCheckbox.disabled = v;
    }
    get value() {
        return this.isNumericEnum
            ? this.slCheckbox.checked
                ? 1
                : 0
            : this.slCheckbox.checked;
    }
    set value(s) {
        if (typeof s === "boolean") {
            this.slCheckbox.checked = s;
        }
        else {
            this.slCheckbox.checked = s === 1;
        }
    }
    get valueAsNumber() {
        return this.value ? 1 : 0;
    }
    set valueAsNumber(s) {
        this.slCheckbox.checked = s === 1;
    }
    get padStart() {
        return !this.internalPadStart;
    }
    set padStart(s) {
        this.internalPadStart = s;
    }
    get isNumericEnum() {
        return this.internalIsNumericEnum;
    }
    set isNumericEnum(v) {
        this.internalIsNumericEnum = v;
    }
    connectedCallback() {
        const shadow = this.attachShadow({ mode: "open" });
        this.holder.appendChild(this.slCheckbox);
        this.holder.appendChild(this.internalHelpDiv);
        shadow.appendChild(addStyles(styles(this.internalPadStart)));
        shadow.appendChild(this.holder);
    }
    attributeChangedCallback(name, oldValue, newValue) {
        if (name === "class") {
            if (newValue.indexOf("has-error") >= 0) {
                if (!this.slCheckbox.classList.contains("has-error"))
                    this.slCheckbox.classList.add("has-error");
            }
            else {
                this.slCheckbox.classList.remove("has-error");
            }
        }
    }
}
TfCheckbox.observedAttributes = ["class"];
customElements.define("tf-checkbox", TfCheckbox);
