import { __awaiter } from "tslib";
import Services from "../../../app/services";
import { getTranslation } from "../../../app/i18/i18tn";
import goSquared from "../../../app/tracking/goSquared";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
export function cloneFileDialog({ onlyCommunity, modal, fileData, actionLabel, goSquaredLabel, }) {
    let newName = "";
    modal.label = getTranslation(actionLabel);
    removeChildren(modal);
    const htmlContent = document.createElement("div");
    htmlContent.innerHTML = `<strong>${fileData.name}</strong>`;
    const closeBtn = document.createElement("sl-button");
    closeBtn.slot = "footer";
    closeBtn.variant = "primary";
    closeBtn.autofocus = true;
    closeBtn.tabIndex = 0;
    closeBtn.innerHTML = getTranslation(actionLabel);
    closeBtn.addEventListener("click", () => __awaiter(this, void 0, void 0, function* () {
        var _a;
        goSquared.addEvent(goSquaredLabel);
        const spinner = document.createElement("sl-spinner");
        spinner.style.fontSize = "1rem";
        removeChildren(htmlContent);
        htmlContent.appendChild(spinner);
        closeBtn.disabled = true;
        closeBtn.loading = true;
        try {
            const res = yield Services.files.clone(fileData.fileId, fileData.organizationId, onlyCommunity, newName);
            closeBtn.loading = false;
            switch (res.statusCode) {
                case 200:
                    modal.hide();
                    if ((_a = res.data) === null || _a === void 0 ? void 0 : _a.id)
                        router.navigate(routeFns.ovdEdit(res.data.id));
                    break;
                case 409:
                    closeBtn.disabled = false;
                    removeChildren(htmlContent);
                    htmlContent.appendChild(createNameTextBox());
                    break;
                case 402:
                    closeBtn.disabled = false;
                    htmlContent.className = "text-danger";
                    htmlContent.innerHTML = getTranslation("errors:maxFreeDownloadsReached");
                    break;
                default:
                    throw res.code || res.message || "Error cloning file";
            }
        }
        catch (err) {
            removeChildren(htmlContent);
            htmlContent.className = "text-danger";
            htmlContent.innerHTML = getTranslation(err);
        }
    }), false);
    modal.appendChild(htmlContent);
    modal.appendChild(closeBtn);
    modal.show();
    function createNameTextBox() {
        const input = document.createElement("sl-input");
        input.type = "text";
        input.label = getTranslation("general:search.setVesselName");
        input.placeholder = getTranslation("general:common.name");
        input.autofocus = true;
        input.value = fileData.name;
        input.addEventListener("keyup", updateNameVar, false);
        input.addEventListener("change", updateNameVar, false);
        function updateNameVar() {
            newName = input.value.toUpperCase().trim();
        }
        return input;
    }
}
