import { __awaiter } from "tslib";
import "@shoelace-style/shoelace/dist/components/button/button";
import "@shoelace-style/shoelace/dist/components/input/input";
import { TedivoForm, translateTedivoForm, } from "@baplie-viewer2/tedivo-form";
import { getFilenameParts, sanitizeString, } from "@baplie-viewer2/tedivo-pure-helpers";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";
import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import IntegratedDialogError from "../../common/IntegratedDialogError";
import Services from "../../../app/services";
import goSquared from "../../../app/tracking/goSquared";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/app.element";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";
import { z } from "zod";
const FormValidator = z.object({
    fileInput: z
        .any()
        .refine((f) => f !== undefined, { message: "FileIsRequired" }),
});
export class TVDOpenJsonComponent extends HTMLElement {
    constructor() {
        super();
        this.dialogError = new IntegratedDialogError(this);
        this.filename = "";
        this.imoCode = "";
        this.ownFiles = {
            lastUpdated: 0,
            list: [],
        };
        this.onSubmitDataForm = (values) => __awaiter(this, void 0, void 0, function* () {
            const file = values.fileInput;
            const filenameParts = getFilenameParts(file.name);
            const name = sanitizeString(filenameParts.name);
            const viewState = {
                file,
                source: "ovdJson",
                filenameParts,
            };
            yield this.getOwnFiles();
            const deductedName = this.filename;
            const existingNameInCloud = this.ownFiles.list.some(({ name }) => name === deductedName);
            if (existingNameInCloud) {
                this.showError(undefined, "general:common.attention", "errors:fileWithSameNameExists");
            }
            else {
                goSquared.addEvent("Open-OVD - Exec");
                router.navigate(routeFns.ovdEdit(name), viewState);
            }
        });
        this.showError = (e, errorTlt = "errors:errorHasOcurred", errorStr = "errors:notValidStafFile") => {
            this.dialogError.show(getTranslation(errorTlt), getTranslation(errorStr));
            if (e !== undefined)
                console.error(e);
        };
        this.i18nCR = new I18nComponentRegisterer(i18nReactive);
        setAppTitle(getTranslation("menu:fileOpen"));
    }
    connectedCallback() {
        if (securityModule.planIsReadOnly) {
            router.navigate(routeFns.myCloud());
            return;
        }
        goSquared.trackPage("Open OVD");
        goSquared.addEvent("Open-OVD - Show page");
        const formFields = [
            {
                name: "",
                type: "title",
                label: "general:ovd",
                createSection: true,
            },
            {
                name: "fileInput",
                label: "general:openOSS.fileLabel",
                helpText: "general:openOSS.browseButtonText",
                type: "file",
            },
        ];
        const submitButton = document.createElement("sl-button");
        submitButton.type = "submit";
        submitButton.innerHTML = getTranslation("general:common.submit");
        submitButton.variant = "primary";
        const tedivoForm = new TedivoForm({
            fields: formFields,
            onSubmit: this.onSubmitDataForm,
            formValidator: FormValidator,
            submitButton: submitButton,
        });
        tedivoForm.onDataChange = (values, name) => {
            if (name === "fileInput") {
                if (values.fileInput === undefined)
                    return;
                const file = values.fileInput;
                Services.ovdJson.readFile
                    .readOVDJsonFile(file)
                    .then((json) => {
                    this.filename = [
                        json.shipData.lineOperator,
                        json.shipData.shipClass,
                        json.shipData.shipName,
                    ]
                        .map((s) => s === null || s === void 0 ? void 0 : s.trim())
                        .filter(Boolean)
                        .join(".");
                    this.imoCode = json.shipData.imoCode || "";
                })
                    .catch((e) => {
                    console.error(e);
                });
            }
        };
        translateTedivoForm({
            tedivoForm,
            i18nCR: this.i18nCR,
        });
        const wrapper = document.createElement("div"), h1 = document.createElement("h1");
        const box = document.createElement("div");
        box.className = "sections-oss-card";
        box.appendChild(tedivoForm.form);
        const divButtons = document.createElement("div");
        divButtons.className = "form-buttons";
        divButtons.appendChild(submitButton);
        tedivoForm.form.appendChild(divButtons);
        wrapper.appendChild(h1);
        wrapper.appendChild(box);
        this.appendChild(wrapper);
        // Translations
        const i18nCR = this.i18nCR;
        i18nCR.addConsumer(h1, "menu:fileOpen", "innerHTML");
        i18nCR.addConsumer(topMessageElement.element, "menu:fileOpen", "innerHTML");
    }
    disconnectedCallback() {
        this.i18nCR.disconnect();
    }
    getOwnFiles() {
        return __awaiter(this, void 0, void 0, function* () {
            const lastUpdated = this.ownFiles.lastUpdated;
            //Fetch if first-time or cache is > 30s
            if (lastUpdated === 0 || Date.now() - lastUpdated > 30000) {
                const data = yield Services.files.getAll();
                if (data.statusCode === 200 && data.data !== undefined) {
                    this.ownFiles.list = data.data;
                    this.ownFiles.lastUpdated = Date.now();
                }
            }
        });
    }
}
TVDOpenJsonComponent.observedAttributes = [];
customElements.define("tvd-open-json-component", TVDOpenJsonComponent);
