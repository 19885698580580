export const browserHelper = (
  userAgent = "",
  doLog = true
): {
  browser: TBrowserName;
  version: string | null;
  macV: number;
  macSv: number;
  majorVersion: number;
} => {
  const browserRgxp =
      /(MSIE|Trident|(?!Gecko.+)Firefox|(?!AppleWebKit.+Chrome.+)Safari(?!.+Edg(?:e?))|(?!AppleWebKit.+)Chrome(?!.+Edg(?:e?))|(?!AppleWebKit.+Chrome.+Safari.+)Edg(?:e?)|AppleWebKit(?!.+Chrome|.+Safari)|Gecko(?!.+Firefox))(?: |\/)([\d.apre]+)/i,
    browserData = browserRgxp.exec(userAgent);

  let macV = 0,
    macSv = 0;

  if (userAgent.indexOf("Mac OS X") > -1) {
    const macVer = userAgent.substring(userAgent.indexOf("Mac OS X") + 8),
      verRgxp = /\s?(\d+)[_.](\d+).*/i,
      versionData = verRgxp.exec(macVer);
    let v = 0,
      sv = 0;

    if (versionData && versionData.length === 3) {
      v = parseInt(versionData[1], 10);
      sv = parseInt(versionData[2], 10);
      macV = v;
      macSv = sv;
    }
  }

  if (!browserData || browserData.length !== 3) {
    //Couldn't get the browser name and version from the userAgent
    return { browser: null, version: null, macV, macSv, majorVersion: 0 };
  }

  //Hack for Safari Version
  if (browserData[1] === "Safari") {
    const verStrPos = userAgent.indexOf("Version/");
    if (verStrPos >= 0) {
      let verStr = userAgent.substring(verStrPos + 8);
      verStr = verStr.split(" ")[0];
      browserData[2] = verStr;
    }
  }

  const majorVersion = browserData[2]
    ? Number(browserData[2].split(".")[0])
    : 0;

  if (doLog) {
    console.log(
      `Browser: ${browserData[1]}, Version: ${browserData[2]}. (${majorVersion})`
    );
  }

  return {
    browser: browserData[1] as TBrowserName,
    version: browserData[2],
    majorVersion,
    macV,
    macSv,
  };
};

const ACCEPTED_BROWSERS = {
  Firefox: { minVer: 74 },
  Chrome: { minVer: 80 },
  Safari: { minVer: 14 },
  Edge: { minVer: 80 },
  Edg: { minVer: 80 },
};

/**
 * Should return "". If not, it will return a string with the error message.
 * @param userAgent
 * @param verifyWebGL
 * @returns
 */
export const browserIsNotSupported = (
  userAgent: string,
  verifyWebGL = false
) => {
  const BROWSER_PROBLEMS = {
    INVALID_UA: "Unable to get your web browser name and version.",
    ALLOWED_BROWSERS: "This web browser is unsupported.",
    BROWSER_VERSION_OLD:
      "This web browser version is too old. Please update it.",
    OS_OLD:
      "Mac OS older than Yosemite (OS X 10.10) will not display 3D graphics.",
  };

  const currBrowserInfo = browserHelper(userAgent, true);

  if (!currBrowserInfo.browser) {
    return BROWSER_PROBLEMS.INVALID_UA;
  }

  const candidateBrowser = ACCEPTED_BROWSERS[currBrowserInfo.browser];

  if (!candidateBrowser) {
    return BROWSER_PROBLEMS.ALLOWED_BROWSERS;
  }

  if (currBrowserInfo.majorVersion < candidateBrowser.minVer) {
    return BROWSER_PROBLEMS.BROWSER_VERSION_OLD;
  }

  if (
    verifyWebGL &&
    currBrowserInfo.macV &&
    ((currBrowserInfo.macV === 10 && currBrowserInfo.macSv < 9) ||
      currBrowserInfo.macV < 10)
  ) {
    return BROWSER_PROBLEMS.OS_OLD;
  }

  return "";
};

type TBrowserName = "Firefox" | "Safari" | "Chrome" | "Edge" | null;
