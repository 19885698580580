import HttpClient, { IResponseModel } from "../HttpClient";

import { IGetFilesSearch } from "@baplie-viewer2/tedivo-api-models";

const CommunitySearchService = {
  search: async (
    q: string,
    fields?: string[],
  ): Promise<IResponseModel<IGetFilesSearch[]>> => {
    const client = new HttpClient();
    const fieldsQuery = fields?.length ? `&fields=${fields.join(",")}` : "";
    return await client.request<IGetFilesSearch[]>({
      method: "GET",
      url: `vessels/community-search?q=${encodeURI(q)}${fieldsQuery}`,
    });
  },
};

export default CommunitySearchService;
