import { __awaiter } from "tslib";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
export default class DivWithSpinner extends HTMLElement {
    constructor() {
        super();
        this.isShowingSpinner = false;
        this.intSlot = document.createElement("slot");
        this.intSpinner = document.createElement("sl-spinner");
        this.intSpinner.style.display = "none";
        this.intSlot.style.display = "block";
    }
    connectedCallback() {
        const root = this.attachShadow({ mode: "open" });
        root.appendChild(this.intSlot);
        root.appendChild(this.intSpinner);
    }
    setLoading(showSpinner = false, force = false) {
        return new Promise((resolve) => {
            if (this.isShowingSpinner === showSpinner && !force) {
                resolve();
                return;
            }
            this.isShowingSpinner = showSpinner;
            this.intSpinner.style.display = showSpinner ? "block" : "none";
            this.intSlot.style.display = showSpinner ? "none" : "block";
            window.setTimeout(() => resolve(), 10);
        });
    }
    clearChildren() {
        removeChildren(this.intSlot);
    }
    withLoading(fn) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.setLoading(true);
            const result = yield fn();
            yield this.setLoading(false);
            return result;
        });
    }
}
customElements.define("div-spinner-element", DivWithSpinner);
