enum MassUnitsEnum {
  "GRAMS" = 1,
  "TONNES" = 2,
  "KILOGRAMS" = 3,
  "LONG_TONS" = 4,
  "SHORT_TONS" = 5,
  "POUNDS" = 6,
  "POUNDS_1000" = 7,
}

export default MassUnitsEnum;
