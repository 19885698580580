import { IFile } from "@baplie-viewer2/tedivo-api-models";
import IntegratedDialog from "../../common/IntegratedDialog";
import { TfRadioGroup } from "@baplie-viewer2/tedivo-form";
import { getTranslation } from "../../../app/i18/i18tn";

type IActions = "view" | "update" | "viewDiff" | "ignoreUpdate";

export function createModalOptionsForCloneModified(
  dta: IFile,
  onButtonClicked: (action: IActions) => void,
) {
  const modal = new IntegratedDialog<"ok">({
    parentNode: document.body,
    buttonsAttrs: [
      {
        label: "general:common.ok",
        value: "ok",
        variant: "primary",
        type: "submit",
      },
    ],
    removeDialogWhenHidden: true,
    width: "800px",
  });

  const content = document.createElement("div");

  const radioBtns = new TfRadioGroup();
  radioBtns.fieldset = true;
  radioBtns.singleColumn = true;
  radioBtns.items = [
    {
      label: getTranslation("view:edit.cloneModified.seeNewVersion"),
      value: "view",
    },
    {
      label: getTranslation("view:edit.cloneModified.viewDiff"),
      value: "viewDiff",
    },
    {
      label: getTranslation("view:edit.cloneModified.ignoreUpdate"),
      value: "ignoreUpdate",
    },
    {
      label: getTranslation("view:edit.cloneModified.update"),
      value: "update",
    },
  ];

  radioBtns.value = "view";
  radioBtns.label = getTranslation("view:edit.cloneModified.actionQuestion");
  content.appendChild(radioBtns);

  const confirmHolder = document.createElement("div");
  confirmHolder.className = "confirm-holder";
  const chkConfirm = document.createElement("sl-checkbox");
  chkConfirm.innerHTML = getTranslation("view:edit.cloneModified.confirm");
  chkConfirm.disabled = true;
  confirmHolder.appendChild(chkConfirm);
  content.appendChild(confirmHolder);

  modal.show(getTranslation("view:edit.cloneModified.intro"), content);

  addEvents();

  return modal;

  function addEvents() {
    radioBtns.addEventListener("sl-change", () => {
      chkConfirm.disabled = radioBtns.value !== "update";
      modal.setButtonEnabled(
        "ok",
        radioBtns.value !== "update" || chkConfirm.checked,
      );

      if (radioBtns.value !== "update") chkConfirm.checked = false;
    });

    chkConfirm.addEventListener("sl-change", () => {
      modal.setButtonEnabled(
        "ok",
        radioBtns.value !== "update" || chkConfirm.checked,
      );
    });

    modal.onButtonClicked = () => {
      if (radioBtns.value === "update" && !chkConfirm.checked) return false;

      onButtonClicked(
        radioBtns.value as "view" | "update" | "viewDiff" | "ignoreUpdate",
      );
    };
  }
}
