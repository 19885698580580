import {
  ICognitoUserWithOrgData,
  ISingleOrganizationAndUsers,
  TUserRole,
  systemRights,
} from "@baplie-viewer2/tedivo-api-models";
import {
  IField as IFieldForm,
  IFields,
  IFormReturn,
  TedivoForm,
  translateTedivoForm,
} from "@baplie-viewer2/tedivo-form";
import SmartTable, {
  IField,
} from "../../common/smartTable/smart-table.element";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";

import DivWithSpinner from "../../common/divWithSpinner/div-with-spinner-element";
import EditDrawer from "../../common/EditDrawer";
import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import ICustomError from "../../types/ICustomError";
import IntegratedDialogError from "../../common/IntegratedDialogError";
import Services from "../../../app/services";
import SlButton from "@shoelace-style/shoelace/dist/components/button/button";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";
import UserTypeEnum from "../../../app/enums/UserTypeEnum";
import createNotification from "../../../app/notifications/createNotification";
import { formatDateInCurrentTimezone } from "@baplie-viewer2/tedivo-pure-helpers";
import globalStore from "../../../app/stores/globalStore";
import goSquared from "../../../app/tracking/goSquared";
import { mergeUsersWithCurrentOrgData } from "../admin/organization-users.component";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/app.element";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";
import { z } from "zod";

export class TVDMyOrganizationComponent extends HTMLElement {
  public static observedAttributes = [];

  private i18nCR: I18nComponentRegisterer;
  private dialogError: IntegratedDialogError;
  private wrapper: DivWithSpinner;
  private mainBox: HTMLDivElement;
  private deleteDialog: SlDialog;
  private editDrawer: EditDrawer;

  constructor() {
    super();
    this.i18nCR = new I18nComponentRegisterer(i18nReactive);
    this.dialogError = new IntegratedDialogError(this);
    this.wrapper = new DivWithSpinner();
    this.mainBox = document.createElement("div");
    this.mainBox.className = "oss-card";
    this.deleteDialog = document.createElement("sl-dialog");
    this.editDrawer = new EditDrawer();

    setAppTitle(getTranslation("menu:organization"));
  }

  connectedCallback() {
    goSquared.trackPage("My Organization");
    goSquared.addEvent("My Organization - Show page");

    const wrapper = this.wrapper,
      h1 = document.createElement("h1");

    wrapper.appendChild(h1);
    wrapper.appendChild(this.mainBox);

    this.appendChild(this.editDrawer.drawer);
    this.appendChild(wrapper);

    this.displayOrganizationUsers();

    this.i18nCR.addConsumer(
      topMessageElement.element,
      "menu:organization",
      "innerHTML",
    );

    this.i18nCR.addConsumer(h1, "general:users.users", "innerHTML");

    if (
      securityModule.userHasPermission(
        systemRights.ORG.AppAdminCrudOrganization,
      )
    ) {
      const btnAdminOrganizations = document.createElement("sl-button");
      btnAdminOrganizations.addEventListener("click", () => {
        router.navigate(routeFns.adminOrganizations());
      });
      btnAdminOrganizations.innerHTML = `Admin - ${getTranslation(
        "general:organizations.organizations",
      )}`;
      btnAdminOrganizations.variant = "primary";

      wrapper.appendChild(btnAdminOrganizations);
    }
  }

  private displayOrganizationUsers = () => {
    globalStore.touchLastUserInteraction();

    const orgId = securityModule.currentOrganizationId;
    if (!orgId) return;

    const wrapper = this.wrapper;
    const box = this.mainBox;
    const actionBox = document.createElement("div");
    actionBox.className = "table-action-box";

    removeChildren(box);

    if (
      !securityModule.planIsReadOnly &&
      securityModule.userHasPermission(
        systemRights.ORG.CanCreateMoreThanOneUser,
      ) &&
      securityModule.userHasPermission(systemRights.USER.CreateUser)
    ) {
      const actionAddUserButton = document.createElement("sl-button");
      actionAddUserButton.innerHTML = getTranslation(
        "general:users.adminCreate",
      );
      actionAddUserButton.variant = "primary";
      actionAddUserButton.addEventListener(
        "click",
        () => {
          this.openOrganizationUserEditModal();
        },
        false,
      );
      actionBox.appendChild(actionAddUserButton);
    }

    if (
      !securityModule.planIsReadOnly &&
      securityModule.userHasPermission(
        systemRights.USER.CanChangeOrganizationName,
      )
    ) {
      const actionEditOrgNameButton = document.createElement("sl-button");
      actionEditOrgNameButton.innerHTML = getTranslation(
        "general:organizations.changeOrganizationDetails",
      );
      actionEditOrgNameButton.variant = "primary";
      actionEditOrgNameButton.addEventListener(
        "click",
        () => {
          this.openOrganizationNameEditModal();
        },
        false,
      );
      actionBox.appendChild(actionEditOrgNameButton);
    }

    wrapper.setLoading(true);
    Services.organizations
      .getSingleOrganizationAndUsers(orgId)
      .then((resp) => {
        if (resp.data) {
          box.appendChild(
            createOrgUsersTable({
              data: resp.data,
              actionBox,
              onEditOrganizationUser: (o: ICognitoUserWithOrgData) => {
                this.openOrganizationUserEditModal({ ...o });
              },
              onDeleteOrganizationUser: this.deleteOrganizationUser,
              userType: securityModule.userRole,
              isReadOnly: securityModule.planIsReadOnly,
            }),
          );
        }
      })
      .catch((e) => {
        this.showError(e);
      })
      .finally(() => {
        wrapper.setLoading(false);
      });
  };

  disconnectedCallback() {
    this.i18nCR.disconnect();
  }

  showError = (e: ICustomError, data?: Record<string, unknown>) => {
    this.dialogError.show(
      getTranslation(`errors:errorHasOcurred`),
      getTranslation(typeof e === "string" ? e : e.translationKey, data),
    );
  };

  private openOrganizationUserEditModal = async (
    prevData?: ICognitoUserWithOrgData,
  ) => {
    const orgId = securityModule.currentOrganizationId;
    if (!orgId) return;

    const sub = prevData?.sub;
    let editForm: IFormReturn<ICognitoUserWithOrgData> | null = null;

    const drawer = this.editDrawer.getEditDrawer({
      title: getTranslation(
        prevData ? "general:users.adminEdit" : "general:users.adminCreate",
      ),
      showUnits: false,
      readOnlyMode: false,
      onSave: async () => {
        this.editDrawer.disabled = true;
        const valid = (await editForm?.submitFunction()) || false;
        this.editDrawer.disabled = false;

        if (valid) {
          this.displayOrganizationUsers();
        }

        return valid;
      },
    });

    const holder = document.createElement("div-spinner-element");
    holder.setLoading(true);
    drawer.appendChild(holder);
    drawer.show();

    if (sub && prevData) {
      const u = await Services.accounts.getUserDetailsByAdmin(orgId, sub);
      prevData.mfaOptions = u.data?.mfaOptions;
    }

    editForm = createUserEdit(
      this.editDrawer.submitButton,
      orgId,
      prevData,
      this.showError,
      securityModule.orgAllowedDomains,
    );

    if (editForm) {
      holder.appendChild(editForm.node);
    }

    holder.setLoading(false);
  };

  private openOrganizationNameEditModal = () => {
    const editForm = createOrgNameEdit(
      this.editDrawer.submitButton,
      this.showError,
    );

    if (editForm) {
      const drawer = this.editDrawer.getEditDrawer({
        title: getTranslation("general:organizations.changeOrganizationName"),
        showUnits: false,
        readOnlyMode: false,
        onSave: async () => {
          this.editDrawer.disabled = true;
          const valid = await editForm.submitFunction();
          this.editDrawer.disabled = false;

          if (valid) {
            this.displayOrganizationUsers();
          }

          return valid;
        },
      });
      drawer.appendChild(editForm.node);
      drawer.show();
    }
  };

  private deleteOrganizationUser = (
    orgId: string,
    dta: ICognitoUserWithOrgData,
  ) => {
    const dialog = this.deleteDialog;
    removeChildren(dialog);

    dialog.label = getTranslation("general:users.delete");

    const htmlContent = document.createElement("div");
    htmlContent.innerHTML = `${getTranslation(
      "general:users.delete",
    )}<br /><br /><strong>${dta.name} ${dta.familyName}</strong>`;

    const deleteBtn = document.createElement("sl-button");
    deleteBtn.slot = "footer";
    deleteBtn.variant = "danger";
    deleteBtn.autofocus = true;
    deleteBtn.tabIndex = 0;
    deleteBtn.innerHTML = getTranslation("general:common.delete");
    deleteBtn.addEventListener(
      "click",
      async () => {
        deleteBtn.loading = true;
        deleteBtn.disabled = true;
        await Services.organizations.adminDeleteOrganizationUser(
          orgId,
          dta.sub,
        );
        deleteBtn.loading = false;
        dialog.hide();
        this.displayOrganizationUsers();
      },
      false,
    );

    dialog.appendChild(htmlContent);
    dialog.appendChild(deleteBtn);
    this.appendChild(dialog);

    dialog.show();
  };
}

customElements.define(
  "tvd-my-organization-component",
  TVDMyOrganizationComponent,
);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-my-organization-component": TVDMyOrganizationComponent;
  }
}

function createOrgUsersTable({
  data,
  actionBox,
  onEditOrganizationUser,
  onDeleteOrganizationUser,
  userType,
  isReadOnly,
}: {
  data: ISingleOrganizationAndUsers;
  actionBox: HTMLElement | undefined;
  onEditOrganizationUser: (org: ICognitoUserWithOrgData) => void;
  onDeleteOrganizationUser: (id: string, org: ICognitoUserWithOrgData) => void;
  userType: TUserRole | undefined;
  isReadOnly: boolean;
}): HTMLElement {
  const createSortIcon = (asc: boolean) => {
    const icon = document.createElement("sl-icon");
    icon.name = asc ? "sort-down-alt" : "sort-up-alt";
    return icon;
  };

  const organizationId = data.organization?.organizationId || "";

  const users = mergeUsersWithCurrentOrgData(organizationId, data.users || []);

  const fields: Array<IField<ICognitoUserWithOrgData>> = [
    {
      name: "name",
      label: getTranslation("general:common.name"),
    },
    {
      name: "familyName",
      label: getTranslation("general:users.familyName"),
    },
    {
      name: "email",
      label: getTranslation("general:common.email"),
    },
    {
      name: "organizationUserEnabled",
      label: getTranslation("general:common.enabled"),
      mapper: (u) =>
        getTranslation(
          `general:common.${u.organizationUserEnabled === "1" ? "yes" : "no"}`,
        ),
    },
    {
      name: "userStatusType",
      label: getTranslation("general:common.status"),
    },
    {
      name: "organizationUserRole",
      label: getTranslation("general:common.type"),
      mapper: (u) =>
        getTranslation(`enums:UserTypeEnum.${u.organizationUserRole}`),
    },
    {
      name: "userCreateDate",
      label: getTranslation("general:common.createdAt"),
      valueFunction: (dta) =>
        dta.userCreateDate
          ? formatDateInCurrentTimezone(dta.userCreateDate)
          : "",
      className: "centered",
    },
    {
      name: "userLastModifiedDate",
      label: getTranslation("general:common.modifiedAt"),
      valueFunction: (dta) =>
        dta.userLastModifiedDate
          ? formatDateInCurrentTimezone(dta.userLastModifiedDate)
          : "",

      className: "centered",
    },
  ];

  if (userType !== "USER" && isReadOnly === false) {
    fields.push(
      {
        name: "sub",
        label: getTranslation("general:common.edit"),
        type: "icon",
        icon: (dta) =>
          userType === "SUPERADMIN" ||
          (userType === "ADMIN" && dta.planId !== "SUPERADMIN")
            ? "pencil"
            : "",
        notSortable: true,
        onClick: (dta) => {
          onEditOrganizationUser(dta);
        },
      },
      {
        name: "sub",
        label: getTranslation("general:common.delete"),
        type: "icon",
        icon: (dta) =>
          `USER#${securityModule.userSub}` !== dta.sub
            ? userType === "SUPERADMIN" ||
              (userType === "ADMIN" && dta.planId !== "SUPERADMIN")
              ? "trash"
              : ""
            : "",
        iconVariant: "danger",
        notSortable: true,
        onClick: (dta) => {
          onDeleteOrganizationUser(organizationId, dta);
        },
      },
    );
  }

  const tableUsers = new SmartTable<ICognitoUserWithOrgData>().initialize({
    className: "tvd-table tvd-table-smart-table",
    data: users || [],
    defaultSort: "name",
    title: data.organization?.name || "",
    fields,
    settings: {
      sortEnabled: true,
      sortVisible: true,
      filterEnabled: true,
      labelSortBy: getTranslation("general:common.sortBy"),
      labelSearch: getTranslation("general:common.filterBy"),
      createSortIconFn: createSortIcon,
      filterFields: ["name", "familyName", "email"],
      actionBox,
    },
    pkFunction: (dta) => dta.sub,
  });

  return tableUsers;
}

function createUserEdit(
  submitButton: SlButton,
  orgId: string,
  prevData: ICognitoUserWithOrgData | undefined,
  showError: (e: ICustomError, data?: Record<string, unknown>) => void,
  allowedDomains: string[],
): IFormReturn<ICognitoUserWithOrgData> | null {
  const holder = document.createElement("div");

  const formValidator = z
    .object({
      email: z
        .string()
        .email()
        .transform((v) => v?.toLowerCase()),
      name: z.string().min(1),
      familyName: z.string().min(1),
      organizationUserEnabled: z.boolean(),
      organizationUserRole: z.nativeEnum(UserTypeEnum),
      mfa: z.boolean().optional(),
    })
    .refine(
      (data) => {
        if (data.email) {
          const domain = (data.email.split("@")[1] || "").toLowerCase().trim();
          if (allowedDomains.indexOf(domain) < 0) return false;
          return true;
        } else return false;
      },
      {
        path: ["email"],
      },
    ) as unknown as z.Schema<ICognitoUserWithOrgData>;

  const hasMfa = prevData?.mfa ?? false;
  const sameUser = prevData?.sub === securityModule.userSub;

  const formFields: IFields<ICognitoUserWithOrgData> = [
    [
      {
        name: "name",
        label: "general:common.name",
        type: "textBox",
        initialValue: prevData?.name || "",
      },
      {
        name: "familyName",
        label: "general:users.familyName",
        type: "textBox",
        initialValue: prevData?.familyName || "",
      },
    ],
    [
      {
        name: "email",
        label: "general:common.email",
        type: "textBox",
        initialValue: prevData?.email || "",
        helpText: getTranslation("general:users.domainsHelpText", {
          domains: allowedDomains.join(", ") || "-",
        }),
      },
      {
        name: "organizationUserEnabled",
        label: "general:common.enabled",
        type: "checkbox",
        helpText: sameUser
          ? "general:organizations.userCannotBeDeactivated"
          : "general:organizations.disableUserHelp",
        initialValue:
          prevData?.organizationUserEnabled === undefined
            ? true
            : prevData?.organizationUserEnabled === "1",
        padStart: true,
        disabled: sameUser,
      },
      securityModule.userHasPermission(systemRights.USER.CanDisableOthersMfa)
        ? {
            name: "mfa",
            label: "auth:mfa.activeMfa",
            helpText:
              !hasMfa || sameUser
                ? "auth:mfa.mfaCanOnlyBeActivatedBySameUser"
                : "auth:mfa.mfaDeactivateAction",
            disabled: !hasMfa || sameUser,
            type: "checkbox",
            padStart: true,
            initialValue: hasMfa,
          }
        : undefined,
    ],
    createFieldOptionsForUserTypeEdit(securityModule.userRole || "USER"),
  ];

  const tedivoForm = new TedivoForm<ICognitoUserWithOrgData>({
    fields: formFields,
    onSubmit: () => undefined,
    formValidator,
    submitButton: submitButton,
    autoFocusOnFirstInput: true,
    hiddenData: prevData,
  });

  translateTedivoForm<ICognitoUserWithOrgData>({
    tedivoForm,
    getTranslation: getTranslation,
  });

  holder.appendChild(tedivoForm.form);

  return {
    node: holder,
    tedivoForm,
    submitFunction: submitPassedToEditDrawer,
  };

  async function submitPassedToEditDrawer() {
    const validResult = tedivoForm.doSubmitForm();
    console.log(validResult);

    if (validResult.success) {
      const values = validResult.data;

      if (prevData?.sub) {
        const data: ICognitoUserWithOrgData = {
          ...prevData,
          ...values,
          organizationUserEnabled: values.organizationUserEnabled ? "1" : "0",
          organizationUserRole: values.organizationUserRole as TUserRole,
        };

        const resp = await Services.organizations.adminUpdateOrganizationUser(
          orgId,
          data,
        );

        if (!resp.data?.id) {
          showError(
            {
              errorCode: String(resp.statusCode),
              message: resp.code || "errorModifyingUser",
              translationKey: resp.message || "errors:errorModifyingUser",
            },
            data,
          );
          return false;
        }
      } else {
        const data: ICognitoUserWithOrgData = {
          ...validResult.data,
        };

        const resp = await Services.organizations.adminCreateOrganizationUser(
          orgId,
          data,
        );

        if (!resp.data?.id) {
          showError(
            {
              errorCode: String(resp.statusCode),
              message: resp.code || "errorCreatingUser",
              translationKey: resp.message || "errors:errorCreatingUser",
            },
            data,
          );
          return false;
        } else {
          if (resp.data?.merged) {
            createNotification(getTranslation("general:users.adminInvited"));
          }
        }
      }
      return true;
    } else {
      return false;
    }
  }

  function createFieldOptionsForUserTypeEdit(currentUserType: TUserRole) {
    const options = [
      { value: "USER", label: getTranslation(`enums:UserTypeEnum.USER`) },
    ];

    if (currentUserType === "ADMIN" || currentUserType === "SUPERADMIN") {
      options.push({
        value: "ADMIN",
        label: getTranslation(`enums:UserTypeEnum.ADMIN`),
      });
    }

    if (currentUserType === "SUPERADMIN") {
      options.push({
        value: "SUPERADMIN",
        label: getTranslation(`enums:UserTypeEnum.SUPERADMIN`),
      });
    }

    const editUserTypeField: IFieldForm<ICognitoUserWithOrgData> = {
      name: "organizationUserRole",
      label: "general:common.type",
      type: "radioButtonList",
      fieldset: true,
      initialValue: prevData?.organizationUserRole ?? "USER",
      options,
      disabled:
        options.length <= 1 ||
        (prevData?.organizationUserRole === "SUPERADMIN" &&
          currentUserType !== "SUPERADMIN"),
    };

    return editUserTypeField;
  }
}

function createOrgNameEdit(
  submitButton: SlButton,
  showError: (e: ICustomError) => void,
): IFormReturn<IEditOrganization> | null {
  const holder = document.createElement("div");

  const formFields: IFields<IEditOrganization> = [
    {
      name: "name",
      label: "general:common.organizationName",
      type: "textBox",
      initialValue: securityModule.currentOrganizationName || "",
    },
    {
      name: "mfaForAll",
      label: "auth:mfa.mfaForAllUsers",
      helpText: "auth:mfa.mfaForAllUsersInfo",
      type: "checkbox",
      initialValue: securityModule.organizationMfaForAllUsers,
    },
  ];

  const formValidator = z.object({
    name: z.string().min(3),
    mfaForAll: z.boolean().optional(),
  }) as unknown as z.Schema<IEditOrganization>;

  const tedivoForm = new TedivoForm<IEditOrganization>({
    fields: formFields,
    onSubmit: () => undefined,
    formValidator,
    submitButton: submitButton,
    autoFocusOnFirstInput: true,
  });

  translateTedivoForm<IEditOrganization>({
    tedivoForm,
    getTranslation: getTranslation,
  });

  holder.appendChild(tedivoForm.form);

  return {
    node: holder,
    tedivoForm,
    submitFunction: submitPassedToEditDrawer,
  };

  async function submitPassedToEditDrawer() {
    const validResult = tedivoForm.doSubmitForm();

    if (validResult.success) {
      const resp = await Services.accounts.changeOrganizationSelfDetails(
        validResult.data.name,
        validResult.data.mfaForAll || false,
      );

      if (resp.statusCode !== 200) {
        showError({
          errorCode: String(resp.statusCode),
          message: resp.code || "errorModifyingOrg",
          translationKey: resp.message || "errors:errorModifyingOrg",
        });
        return false;
      }

      securityModule.updateOrganizationDetails(
        validResult.data.name,
        validResult.data.mfaForAll || false,
      );

      return true;
    } else {
      return false;
    }
  }
}

interface IEditOrganization extends Record<string, unknown> {
  name: string;
  mfaForAll: boolean;
}
