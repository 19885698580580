import { TVDAuthProfileComponent } from "./profile.component";
import awsCognito from "packages/oss-editor/src/app/security/awsCognito";
import { routeFns } from "../../../../app/router/routes";
import { systemRights } from "@baplie-viewer2/tedivo-api-models";

const USER_LINKS: IUserOptions = [
  {
    label: "general:users.documentation",
    links: [
      {
        label: "general:users.documentationLabel",
        url: "https://docs.tedivo.com",
        description: "general:users.documentationLabel2",
      },
    ],
  },
  {
    congnitoCondition: (awsCogInstance) =>
      awsCogInstance.allOrganizationsInfo.length > 1,
    label: "general:organizations.organizations",
    links: [
      {
        label: "auth:changeOrganization",
        action: "changeOrganization",
        variant: "success",
      },
    ],
  },
  {
    label: "general:users.detailsTitle",
    links: [
      {
        label: "auth:editDetails",
        route: routeFns.editDetails(),
      },
    ],
  },
  {
    label: "general:users.authTitle",
    links: [
      {
        label: "auth:changePassword",
        route: routeFns.changePassword(),
      },
      {
        label: "auth:mfa.setMfa",
        action: "setMfa",
      },
    ],
  },
  {
    label: "general:organizations.yourAccount",
    links: [
      {
        label: "auth:deleteAccount.deleteMyAccount",
        route: routeFns.deleteMyAccount(),
        variant: "danger",
        systemRight: systemRights.USER.CanDeleteOwnUserAccount,
      },
      {
        label: "auth:deleteAccount.deleteMyOrgAccount",
        route: routeFns.deleteMyOrgAccount(),
        variant: "danger",
        systemRight: systemRights.USER.CanDeleteOrgAccount,
      },
    ],
  },
];

type IUserOptions = Array<{
  label: string;
  links: IUserLink[];
  congnitoCondition?: (awsCogInstance: typeof awsCognito) => boolean;
}>;

type IUserLink = {
  label: string;
  variant?: "danger" | "warning" | "success";
  systemRight?: string;
  description?: string;
} & (
  | {
      route: string;
      action?: never;
      url?: never;
    }
  | {
      route?: never;
      action: keyof InstanceType<typeof TVDAuthProfileComponent>;
      url?: never;
    }
  | {
      route?: never;
      action?: never;
      url: string;
    }
);

export default USER_LINKS;
