import "@shoelace-style/shoelace/dist/components/button/button";
import "@shoelace-style/shoelace/dist/components/input/input";

import {
  IFields,
  TedivoForm,
  translateTedivoForm,
} from "@baplie-viewer2/tedivo-form";
import {
  IGetFileName,
  getFilenameParts,
  sanitizeString,
} from "@baplie-viewer2/tedivo-pure-helpers";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";

import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import ICustomError from "../../types/ICustomError";
import { IFile } from "@baplie-viewer2/tedivo-api-models";
import { IOpenVesselDefinitionV1 } from "open-vessel-definition";
import IntegratedDialogError from "../../common/IntegratedDialogError";
import Services from "../../../app/services";
import VcgVerticalReferenceEnum from "../../../app/enums/VcgVerticalReferenceEnum";
import goSquared from "../../../app/tracking/goSquared";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/app.element";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";
import { z } from "zod";

const FormValidator: z.Schema<IOpenJsonFields> = z.object({
  fileInput: z
    .any()
    .refine((f) => f !== undefined, { message: "FileIsRequired" }),
});

export class TVDOpenJsonComponent extends HTMLElement {
  public static observedAttributes = [];

  private i18nCR: I18nComponentRegisterer;
  private dialogError: IntegratedDialogError = new IntegratedDialogError(this);

  private filename = "";
  private imoCode = "";
  private ownFiles: { lastUpdated: number; list: IFile[] } = {
    lastUpdated: 0,
    list: [],
  };

  constructor() {
    super();
    this.i18nCR = new I18nComponentRegisterer(i18nReactive);

    setAppTitle(getTranslation("menu:fileOpen"));
  }

  connectedCallback() {
    if (securityModule.planIsReadOnly) {
      router.navigate(routeFns.myCloud());
      return;
    }

    goSquared.trackPage("Open OVD");
    goSquared.addEvent("Open-OVD - Show page");

    const formFields: IFields<IOpenJsonFields> = [
      {
        name: "",
        type: "title",
        label: "general:ovd",
        createSection: true,
      },
      {
        name: "fileInput",
        label: "general:openOSS.fileLabel",
        helpText: "general:openOSS.browseButtonText",
        type: "file",
      },
    ];

    const submitButton = document.createElement("sl-button");
    submitButton.type = "submit";
    submitButton.innerHTML = getTranslation("general:common.submit");
    submitButton.variant = "primary";

    const tedivoForm = new TedivoForm<IOpenJsonFields>({
      fields: formFields,
      onSubmit: this.onSubmitDataForm,
      formValidator: FormValidator,
      submitButton: submitButton,
    });

    tedivoForm.onDataChange = (
      values: IOpenJsonFields,
      name?: keyof IOpenJsonFields,
    ) => {
      if (name === "fileInput") {
        if (values.fileInput === undefined) return;

        const file = values.fileInput as File;

        Services.ovdJson.readFile
          .readOVDJsonFile(file)
          .then((json) => {
            this.filename = [
              json.shipData.lineOperator,
              json.shipData.shipClass,
              json.shipData.shipName,
            ]
              .map((s) => s?.trim())
              .filter(Boolean)
              .join(".");

            this.imoCode = json.shipData.imoCode || "";
          })
          .catch((e) => {
            console.error(e);
          });
      }
    };

    translateTedivoForm<IOpenJsonFields>({
      tedivoForm,
      i18nCR: this.i18nCR,
    });

    const wrapper = document.createElement("div"),
      h1 = document.createElement("h1");

    const box = document.createElement("div");
    box.className = "sections-oss-card";

    box.appendChild(tedivoForm.form);

    const divButtons = document.createElement("div");
    divButtons.className = "form-buttons";
    divButtons.appendChild(submitButton);
    tedivoForm.form.appendChild(divButtons);

    wrapper.appendChild(h1);
    wrapper.appendChild(box);
    this.appendChild(wrapper);

    // Translations
    const i18nCR = this.i18nCR;

    i18nCR.addConsumer(h1, "menu:fileOpen", "innerHTML");
    i18nCR.addConsumer(topMessageElement.element, "menu:fileOpen", "innerHTML");
  }

  disconnectedCallback() {
    this.i18nCR.disconnect();
  }

  onSubmitDataForm = async (values: IOpenJsonFields) => {
    const file = values.fileInput as File;
    const filenameParts = getFilenameParts(file.name);
    const name = sanitizeString(filenameParts.name);

    const viewState: IViewHistoryState = {
      file,
      source: "ovdJson",
      filenameParts,
    };

    await this.getOwnFiles();
    const deductedName = this.filename;

    const existingNameInCloud: boolean = this.ownFiles.list.some(
      ({ name }) => name === deductedName,
    );

    if (existingNameInCloud) {
      this.showError(
        undefined,
        "general:common.attention",
        "errors:fileWithSameNameExists",
      );
    } else {
      goSquared.addEvent("Open-OVD - Exec");
      router.navigate(routeFns.ovdEdit(name), viewState);
    }
  };

  showError = (
    e: ICustomError | undefined,
    errorTlt = "errors:errorHasOcurred",
    errorStr = "errors:notValidStafFile",
  ) => {
    this.dialogError.show(getTranslation(errorTlt), getTranslation(errorStr));
    if (e !== undefined) console.error(e);
  };

  private async getOwnFiles() {
    const lastUpdated = this.ownFiles.lastUpdated;

    //Fetch if first-time or cache is > 30s
    if (lastUpdated === 0 || Date.now() - lastUpdated > 30000) {
      const data = await Services.files.getAll();
      if (data.statusCode === 200 && data.data !== undefined) {
        this.ownFiles.list = data.data;
        this.ownFiles.lastUpdated = Date.now();
      }
    }
  }
}

customElements.define("tvd-open-json-component", TVDOpenJsonComponent);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-open-json-component": TVDOpenJsonComponent;
  }
}

export type IViewHistoryState = {
  organizationId?: string;
  organizationName?: string;
  impersonate?: boolean;
} & (
  | {
      source: "cloudOvdJson";
      cloudId: string;
      file?: never;
      filenameParts?: IGetFileName;
      formFields?: never;
      json?: never;
    }
  | {
      source: "ovdJson";
      cloudId?: never;
      file: File;
      filenameParts: IGetFileName;
      formFields?: never;
      json?: never;
    }
  | {
      source: "stafText";
      cloudId?: never;
      file: File;
      filenameParts: IGetFileName;
      formFields: {
        lpp: number;
        loa: number;
        vcgRef: VcgVerticalReferenceEnum;
        aboveTier82is: number;
        lineOperator: string;
        shipClass: string;
        shipName: string;
        imoCode: string;
        yearBuilt?: number;
        callSign?: string;
        removeCGs?: boolean;
      };
      json?: never;
    }
  | {
      source: "newOvd";
      cloudId?: never;
      file?: never;
      filenameParts: IGetFileName;
      formFields?: never;
      json: IOpenVesselDefinitionV1;
    }
);

interface IOpenJsonFields extends Record<string, unknown> {
  fileInput?: File;
}
