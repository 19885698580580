import { routeFns } from "../../../app/router/routes";

export enum ViewJsonTypeEnum {
  "EDIT" = "EDIT",
  "VIEW_OVD" = "VIEW_OVD",
  "VIEW_VERSION" = "VIEW_VERSION",
}

export function getViewTypesFromRouteKey(
  currentRouteKey: string | undefined,
): ViewJsonTypeEnum | undefined {
  if (!currentRouteKey) return undefined;

  if (currentRouteKey === routeFns.ovdEdit(":id")) return ViewJsonTypeEnum.EDIT;

  if (currentRouteKey === routeFns.ovdViewOnly(":id"))
    return ViewJsonTypeEnum.VIEW_OVD;

  if (currentRouteKey === routeFns.ovdViewOnlyVersion(":id", ":version"))
    return ViewJsonTypeEnum.VIEW_VERSION;

  return undefined;
}
