import {
  IBayLevelData,
  ILidData,
  IShipData,
  ISizeSummary,
} from "open-vessel-definition";
import {
  createMultiEditButton,
  createSelectShoelace,
} from "@baplie-viewer2/tedivo-form";
import {
  createTranslateLCGs,
  generateTranslatedLCGsExampleData,
} from "./edits/helpers/editCGs/createTranslateLCGsDialog";

import CellSpacingEnum from "../../../../app/enums/CellSpacingEnum";
import EditDrawer from "../../../common/EditDrawer";
import { EnumHelpers } from "@baplie-viewer2/tedivo-pure-helpers";
import IntegratedDialogError from "../../../common/IntegratedDialogError";
import SizeSmallMidLargeEnum from "../../../../app/enums/SizeSmallMidLargeEnum";
import { createBayBoxes } from "./createBayBoxes";
import { createLegend } from "./createLegend";
import { createMoveAll40s } from "./edits/helpers/editBays/createMoveAll40s";
import { getTranslation } from "../../../../app/i18/i18tn";
import ovdJsonStore from "../../../../app/stores/OVDJsonStore";
import { setPreferencesKeyAndValue } from "@baplie-viewer2/tedivo-preferences";

export function createBaySection(
  bls: IBayLevelData[],
  sizeSummary: ISizeSummary,
  shipData: IShipData,
  lidData: Array<ILidData>,
  bayDrawOptions: [SizeSmallMidLargeEnum, CellSpacingEnum],
  redrawFn: () => void,
  editDrawer: EditDrawer,
  dialogError: IntegratedDialogError,
  readOnlyMode: boolean,
  canOpenDrawer: boolean,
): HTMLElement {
  const boxBays = document.createElement("div");
  boxBays.className = `oss-card holder-bays`;

  const titlePart = document.createElement("title-with-actions-component");
  titlePart.titleHtml = getTranslation("view:bayDataTitle");
  titlePart.titleNodeName = "h2";

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const json = ovdJsonStore.currentJson!;

  // Helpers
  const helpersHolder = document.createElement("div");
  helpersHolder.className = "helpers-holder helpers-bays";
  const helpersMainButton = createMultiEditButton({
    id: `helpersBays`,
    title: getTranslation("general:common.helpers"),
    size: "small",
    variant: "primary",
    showTitleAsTooltip: false,
    iconName: "command",
    disabled: !canOpenDrawer,
    options: [
      {
        value: "translateLCGs",
        name: getTranslation("view:edit.cgHelpers.translateLCGs"),
        action: () => {
          if (generateTranslatedLCGsExampleData(json, 0).length === 0) {
            dialogError.show(
              getTranslation("view:edit.cgHelpers.translateLCGs"),
              getTranslation("view:edit.helpers.noLcgsInFile"),
            );
            return;
          }

          const editForm = createTranslateLCGs(json);

          const drawer = editDrawer.getEditDrawer({
            title: getTranslation("view:edit.cgHelpers.translateLCGs"),
            onSave: editForm.submitFunction,
            readOnlyMode,
          });

          drawer.appendChild(editForm.node);
          drawer.show();
        },
      },
      {
        value: "moveAll40s",
        name: getTranslation(
          "view:edit.helpers.move40Definitions.moveAll40sInShip",
        ),
        action: () => {
          const editForm = createMoveAll40s(json);

          const drawer = editDrawer.getEditDrawer({
            title: getTranslation(
              "view:edit.helpers.move40Definitions.moveAll40sInShip",
            ),
            onSave: editForm.submitFunction,
            readOnlyMode,
          });

          drawer.appendChild(editForm.node);
          drawer.show();
        },
      },
    ],
  });
  helpersHolder.appendChild(helpersMainButton);

  // Actions
  const sizeSelShoelace = createSelectShoelace({
    id: "baySize",
    caret: true,
    buttonText: getTranslation("view:bayDataSize"),
    options: EnumHelpers.getNamesAndValues(SizeSmallMidLargeEnum).map(
      ({ name, value }) => ({
        value,
        name: getTranslation(`enums:SizeSmallMidLargeEnum.${name}`),
      }),
    ),
    onChange: (v) => {
      bayDrawOptions[0] = Number(v);
      setPreferencesKeyAndValue("bay-data-size", Number(v));
      redrawFn();
    },
  });

  const cellpaddingSelShoelace = createSelectShoelace({
    id: "cellSeparation",
    caret: true,
    buttonText: getTranslation("view:bayCellSeparation"),
    options: EnumHelpers.getNamesAndValues(CellSpacingEnum).map(
      ({ name, value }) => ({
        value,
        name: getTranslation(`enums:CellSpacingEnum.${name}`),
      }),
    ),
    onChange: (v) => {
      bayDrawOptions[1] = Number(v);
      setPreferencesKeyAndValue("bay-cell-separation", Number(v));
      redrawFn();
    },
  });

  sizeSelShoelace.value = String(bayDrawOptions[0]);
  cellpaddingSelShoelace.value = String(bayDrawOptions[1]);

  const actionsDiv = document.createElement("div");
  actionsDiv.appendChild(helpersHolder);
  actionsDiv.appendChild(sizeSelShoelace);
  actionsDiv.appendChild(cellpaddingSelShoelace);
  actionsDiv.slot = "actions";

  titlePart.appendChild(actionsDiv);

  boxBays.appendChild(titlePart);

  boxBays.appendChild(
    createLegend(shipData.containersLengths, shipData.featuresAllowed),
  );

  boxBays.appendChild(
    createBayBoxes(
      bls,
      sizeSummary,
      shipData,
      lidData,
      bayDrawOptions[0],
      bayDrawOptions[1],
      editDrawer,
      ovdJsonStore.ovdValidator.getLastResult(),
      readOnlyMode,
      canOpenDrawer,
    ),
  );

  return boxBays;
}
