import HttpClient, { IResponseModel } from "../HttpClient";

import { IApiKeyRecord } from "@baplie-viewer2/tedivo-api-models";

const apiKeysBaseUrl = "apiKeys";

const ApiKeysService = {
  getAll: async (): Promise<IResponseModel<IApiKeyRecord[]>> => {
    const client = new HttpClient();
    return await client.request<IApiKeyRecord[]>({
      method: "GET",
      url: apiKeysBaseUrl,
    });
  },

  get: async (id: string): Promise<IResponseModel<IApiKeyRecord>> => {
    const client = new HttpClient();
    return await client.request<IApiKeyRecord>({
      method: "GET",
      url: `${apiKeysBaseUrl}/${id}`,
    });
  },

  delete: async (id: string): Promise<IResponseModel<unknown>> => {
    const client = new HttpClient();
    return await client.request<unknown>({
      method: "DELETE",
      url: `${apiKeysBaseUrl}/${id}`,
      data: undefined,
    });
  },

  create: async (
    data: IApiKeyRecord,
  ): Promise<IResponseModel<ICreateOutput>> => {
    const client = new HttpClient();
    return await client.request<ICreateOutput>({
      method: "PUT",
      url: apiKeysBaseUrl,
      data,
    });
  },

  update: async (data: IApiKeyRecord): Promise<IResponseModel<unknown>> => {
    const client = new HttpClient();
    return await client.request<unknown>({
      method: "PUT",
      url: apiKeysBaseUrl,
      data,
    });
  },
};

export default ApiKeysService;

interface ICreateOutput {
  apiKey: string;
}
