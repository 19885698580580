import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import { i18nReactive } from "../../../app/i18/i18tn";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";

export class OssWelcomeElement extends HTMLElement {
  public static observedAttributes = [];
  private i18nCR: I18nComponentRegisterer;

  constructor() {
    super();
    this.i18nCR = new I18nComponentRegisterer(i18nReactive);
  }

  connectedCallback() {
    // const wrapper = document.createElement("div");

    // const h1 = document.createElement("h1"),
    //   text = document.createElement("div");

    // this.i18nCR.addConsumer(h1, "general:welcomeComp.title", "innerHTML");
    // this.i18nCR.addConsumer(text, "general:welcomeComp.text", "innerHTML");

    // wrapper.className = "oss-centered-card";
    // wrapper.appendChild(h1);
    // wrapper.appendChild(text);

    if (securityModule.isLoggedIn) {
      router.navigate(routeFns.myCloud());
    } else {
      router.navigate(routeFns.login(), undefined, true);
      // const loginBtn = document.createElement("sl-button");
      // loginBtn.className = "login-button-home";
      // loginBtn.variant = "primary";

      // wrapper.appendChild(loginBtn);
      // loginBtn.onclick = () => {
      //   router.navigate(routeFns.login());
      // };

      // const orSeparator = document.createElement("span");
      // orSeparator.className = "text-with-separation";
      // wrapper.appendChild(orSeparator);

      // const newAccountBtn = document.createElement("sl-button");
      // newAccountBtn.className = "login-button-home";
      // newAccountBtn.variant = "primary";
      // newAccountBtn.outline = true;

      // wrapper.appendChild(newAccountBtn);
      // newAccountBtn.onclick = () => {
      //   router.navigate(routeFns.newAccount());
      // };

      // this.i18nCR.addConsumer(orSeparator, "general:common.or", "innerHTML");
      // this.i18nCR.addConsumer(loginBtn, "auth:logIn", "innerHTML");
      // this.i18nCR.addConsumer(
      //   newAccountBtn,
      //   "general:organizations.createNewAccount",
      //   "innerHTML",
      // );
    }

    // this.appendChild(wrapper);

    // const topMessageEl = topMessageElement.element;

    // if (!securityModule.isLoggedIn) {
    //   this.i18nCR.addConsumer(topMessageEl, "general:appName", "innerHTML");
    // } else if (topMessageEl) {
    //   topMessageEl.innerHTML = securityModule.organizationName;
    // }
  }

  disconnectedCallback() {
    this.i18nCR.disconnect();
  }
}

customElements.define("tvd-welcome-component", OssWelcomeElement);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-welcome-component": OssWelcomeElement;
  }
}
