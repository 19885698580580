/* eslint-disable @typescript-eslint/no-non-null-assertion */

import SlButton from "@shoelace-style/shoelace/dist/components/button/button";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";
import { getTranslation } from "../../app/i18/i18tn";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";

export default class IntegratedDialog<IActions = string> {
  private dialog: SlDialog | undefined;
  private htmlContent: HTMLDivElement | undefined;
  private buttons: SlButton[] = [];

  private options: IIntegratedDialogProps;

  public onButtonClicked:
    | ((v: IActions) => boolean | Promise<boolean> | void)
    | undefined = undefined;

  constructor(options: IIntegratedDialogProps) {
    this.options = options;
  }

  updateOptions(options: Partial<IIntegratedDialogProps>) {
    this.options = { ...this.options, ...options };
    return this;
  }

  show(title: string, message: string | HTMLElement) {
    let btnToFocus: SlButton | undefined = undefined;
    const { dialog } = this.createDialog();
    dialog.label = title;

    const btnActionToFocus = this.options.buttonsAttrs?.find(
      (btnAttrs) => btnAttrs.autofocus,
    )?.value;

    if (btnActionToFocus) {
      const btn = this.buttons.find(
        (f) => f.dataset.action === btnActionToFocus,
      );
      if (btn) btnToFocus = btn;
    }

    if (typeof message === "string") {
      this.htmlContent!.innerHTML = message;
    } else {
      removeChildren(this.htmlContent!);
      this.htmlContent!.appendChild(message);
    }

    dialog.show();

    setTimeout(() => {
      try {
        if (btnToFocus?.focus) btnToFocus.focus();
      } catch (e) {
        console.warn(e);
      }
    }, 150);
  }

  setButtonEnabled(action: IActions, enabled: boolean) {
    const btn = this.buttons.find((f) => f.dataset.action === action);
    if (btn) btn.disabled = !enabled;
  }

  private createDialog(): {
    dialog: SlDialog;
  } {
    if (this.dialog) {
      return { dialog: this.dialog };
    }

    const dialog = document.createElement("sl-dialog");
    dialog.className = "integrated-dialog";

    this.htmlContent = document.createElement("div");

    const buttonsHolder = document.createElement("div");
    buttonsHolder.className = "dialog-buttons-holder";
    buttonsHolder.slot = "footer";

    if (this.options.width) {
      dialog.setAttribute("style", `--width: ${this.options.width}`);
    }

    const onClicked = async (value: IActions) => {
      if (this.onButtonClicked) {
        const res = await this.onButtonClicked(value);
        if (res === false) return;
      }

      dialog.hide();
    };

    this.options.buttonsAttrs?.forEach((btnAttrs) => {
      const btn = document.createElement("sl-button");
      btn.variant = btnAttrs.variant || "primary";
      btn.outline = btnAttrs.outlined || false;
      btn.tabIndex = 0;
      btn.type = btnAttrs.type || "button";
      btn.dataset.action = btnAttrs.value;
      btn.innerHTML = getTranslation(btnAttrs.label);
      //btn.role = "button";
      btn.addEventListener(
        "click",
        () => onClicked(btnAttrs.value as unknown as IActions),
        false,
      );
      buttonsHolder.appendChild(btn);
      this.buttons.push(btn);

      if (btnAttrs.icon) {
        const icon = document.createElement("sl-icon");
        icon.name = btnAttrs.icon;
        icon.slot = "prefix";
        btn.appendChild(icon);
      }
    });

    dialog.appendChild(this.htmlContent);
    dialog.appendChild(buttonsHolder);

    this.options.parentNode.appendChild(dialog);

    dialog.addEventListener("sl-request-close", (event: Event) => {
      if (
        this.options.preventClose ||
        (this.options.preventDefaultClose &&
          (event as CustomEvent).detail.source === "overlay")
      ) {
        event.preventDefault();
      }
    });

    if (this.options.removeDialogWhenHidden) {
      dialog.addEventListener("sl-after-hide", () => {
        dialog.remove();
        this.dialog = undefined;
      });
    }

    this.dialog = dialog;
    return { dialog };
  }

  hide() {
    this.dialog?.hide();
  }
}

export interface IIntegratedDialogProps<IActions = string> {
  parentNode: HTMLElement;
  buttonsAttrs?: IButtonAttributes<IActions>[];
  preventDefaultClose?: boolean;
  preventClose?: boolean;
  removeDialogWhenHidden?: boolean;
  width?: string;
}
export interface IButtonAttributes<IActions = string> {
  value: IActions;
  label: string;
  type: "submit" | "button";
  outlined?: boolean;
  variant?: "primary" | "success" | "danger" | "warning" | "neutral";
  icon?: string;
  autofocus?: boolean;
}
