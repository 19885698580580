import {
  ForeAftEnum,
  ILidData,
  ISizeSummary,
  TContainerLengths,
} from "open-vessel-definition";
import { IBayPattern, IRowPattern } from "../../types/IPositionPatterns";
import {
  IBaySizesAndCgs,
  IGetBayLcgVcgAndPairingsResult,
} from "../../helpers/getBayLcgVcgTcgAndPairings";
import {
  LCG_20_WITH_SEPARATION,
  SEPARATION_IN_BETWEEN,
  TCG_IN_MMM,
  generateCoordFunctions,
} from "../helpers/generateCoordFunctions";
import {
  feetToMillimeters,
  roundDec,
  sortNumericAsc,
  sortNumericDesc,
} from "@baplie-viewer2/tedivo-pure-helpers";
import generate20Lcgs, { I20LcgsByBay } from "../helpers/generate20Lcgs";

import { createSvgText } from "../../helpers/createSvgText";
import { generateTopContainerSymbols } from "./generateTopContainerSymbols";
import { makeHull } from "../helpers/convexHull";
import { setTopBayNumbers } from "../helpers/setTopBayNumbers";
import { sortRowsArray } from "../../helpers/sortRowsArray";

export function createSimpleDeckView({
  sizeSummary,
  lidData,
  lcgVcgTcgAndPairings,
  symbolsOptions,
  bayNumbersDoubleClickAction,
  svgGroupId = "createSimpleDeckView-g",
}: ICreateSimpleDeckViewProps): {
  deckViewSvg: SVGElement;
  missingImportantXcgs: boolean;
  xRange: number;
  zRange: number;
  xPos: (lcg: number, d?: number | undefined) => number;
  zPos: (tcg: number, d?: number | undefined) => number;
  addX: number;
  lcgsBy20Bay: I20LcgsByBay;
} {
  const {
    bayLevelPositionsAbove,
    bayLevelPositionsBelow,
    missingImportantTcgs,
    missingImportantLcgs,
    isoBaysArray,
    maxIsoBay,
    minLcg,
    maxLcg,
    maxVcg,
    minVcg,
    minTcg,
    maxTcg,
    totalSlotsCount,
  } = lcgVcgTcgAndPairings;

  const missingImportantXcgs = missingImportantTcgs || missingImportantLcgs;

  // 1.1 LCGs of 20
  const lcgsBy20Bay = generate20Lcgs(
    sizeSummary,
    bayLevelPositionsAbove,
    bayLevelPositionsBelow,
    missingImportantXcgs,
  );

  // 1. Add missing LCGs and VCGs if needed, return x,y functions
  const { xRange, zRange, xPos, zPos, addX, svgSizeRatio } =
    generateCoordFunctions({
      missingImportantXcgs,
      maxIsoBay,
      sizeSummary,
      bayLevelPositionsAbove,
      bayLevelPositionsBelow,
      maxLcg,
      minLcg,
      maxVcg,
      minVcg,
      maxTcg,
      minTcg,
      lcgsBy20Bay,
    });

  // 2. SVG Node
  const svgNode = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svgNode.setAttribute("width", String(xRange));
  svgNode.setAttribute("height", String(zRange));
  svgNode.setAttribute("viewBox", `0 0 ${xRange} ${zRange}`);

  const svgGroup = document.createElementNS("http://www.w3.org/2000/svg", "g");
  svgGroup.setAttribute("id", svgGroupId);

  // 3. Add symbols
  const symbols = generateTopContainerSymbols(
    symbolsOptions || {},
    svgSizeRatio,
  );

  (Object.keys(symbols) as unknown as TContainerLengths[]).forEach((len) => {
    svgGroup.appendChild(symbols[len]);
  });

  // 4. Top bay numbers
  const drawTopBayNumbers = (blps: IBaySizesAndCgs[]) =>
    setTopBayNumbers({
      blps,
      lcgsBy20Bay,
      svgNode: svgGroup,
      xPos,
      fontColor: symbolsOptions && symbolsOptions.fontColor,
      onDoubleClick: bayNumbersDoubleClickAction,
    });

  if (bayLevelPositionsAbove.length) drawTopBayNumbers(bayLevelPositionsAbove);
  else drawTopBayNumbers(bayLevelPositionsBelow);

  if (totalSlotsCount > 0) {
    const blPosBelowOrAboveIfMissing = isoBaysArray
      .map((isoBay) => {
        const blp = bayLevelPositionsBelow.find(
          (blp) => blp.bayIsoNumber === isoBay,
        );
        if (blp?.sizes?.length) return blp;

        return bayLevelPositionsAbove.find(
          (blp) => blp.bayIsoNumber === isoBay,
        );
      })
      .filter(Boolean) as IBaySizesAndCgs[];

    // 5. Ship Contour
    generateShipContour(missingImportantXcgs);

    // 6. Draw containers
    blPosBelowOrAboveIfMissing.forEach(generateContainers);

    // 7. Draw Lids
    drawLids(lcgsBy20Bay);
  }

  svgNode.appendChild(svgGroup);

  return {
    deckViewSvg: svgNode,
    missingImportantXcgs,
    xRange,
    zRange,
    xPos,
    zPos,
    lcgsBy20Bay,
    addX,
  };

  function generateContainers(blp: IBaySizesAndCgs): void {
    const rows = Object.keys(blp.rows).sort() as IRowPattern[];
    rows.forEach((rowName) => {
      const rowInfo = blp.rows[rowName];
      const maxSizeLcg = blp.maxSizeLcg;

      if (!blp.pairedHas40) {
        // If paired has 40, don't draw.

        const x =
          missingImportantXcgs &&
          maxSizeLcg.size !== undefined &&
          maxSizeLcg.size >= 40
            ? (blp.pairedBay === ForeAftEnum.FWD
                ? maxSizeLcg.foreLcg
                : maxSizeLcg.aftLcg) || 0
            : maxSizeLcg.lcg || 0;
        const y = rowInfo.tcg;

        if (x !== undefined && y !== undefined) {
          const contSvg = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "use",
          );

          contSvg.setAttribute("href", `#dmbCn${maxSizeLcg.size}`);
          contSvg.setAttribute("x", `${xPos(x)}`);
          contSvg.setAttribute("y", `${zPos(y)}`);

          contSvg.setAttribute("data-p", `${blp.bayIsoNumber}-${rowName}`);
          svgGroup.appendChild(contSvg);

          if (symbolsOptions?.addRowLines) {
            const textColor: string | undefined =
              (rowName === "00"
                ? symbolsOptions?.fontColor
                : Number(rowName) % 2 === 0
                ? symbolsOptions?.portColor
                : symbolsOptions?.stbdColor) || symbolsOptions?.fontColor;

            const stckNumber = createSvgText({
              text: rowName,
              x: 0,
              y: 0,
              fontSize: 7,
              textColor,
            });

            stckNumber.setAttribute(
              "transform",
              `rotate(90, 0,0) translate(${zPos(y)}, ${-xPos(x)} )`,
            );
            svgGroup.appendChild(stckNumber);
          }
        }
      }
    });
  }

  function createSameBayAbBeSizesAndCgs(
    blpsAbove: IBaySizesAndCgs[],
    blpsBelow: IBaySizesAndCgs[],
  ) {
    const aboveBelowBlps: IBayAboveBelowLTcgs = {};

    blpsAbove.forEach((blpAb) => {
      const { portTcg, starboardTcg, lcg, foreLcg, aftLcg, maxSize } =
        getRelevantXcgs(blpAb, true);
      addToBay(blpAb, portTcg, starboardTcg, lcg, foreLcg, aftLcg, maxSize);
    });

    blpsBelow.forEach((blpBe) => {
      const { portTcg, starboardTcg, lcg, foreLcg, aftLcg, maxSize } =
        getRelevantXcgs(blpBe, false);
      addToBay(blpBe, portTcg, starboardTcg, lcg, foreLcg, aftLcg, maxSize);
    });

    supplyB20sLongPointsFor40s();

    return aboveBelowBlps;

    function addToBay(
      blp: IBaySizesAndCgs,
      portTcg: number,
      starboardTcg: number,
      lcg: number,
      foreLcg: number,
      aftLcg: number,
      maxSize: number,
    ) {
      let pairedInstance = aboveBelowBlps[blp.bayIsoNumber];

      if (pairedInstance === undefined) {
        aboveBelowBlps[blp.bayIsoNumber] = {
          aftLcg: aftLcg,
          foreLcg: foreLcg,
          lcg: lcg,
          portTcg: 0,
          starboardTcg: 0,
          pairedBay: blp.pairedBay,
          maxSize,
          b20:
            maxSize < 40
              ? {
                  fwdLPoint: foreLcg,
                  aftLPoint: aftLcg,
                }
              : undefined,
        };
        pairedInstance = aboveBelowBlps[blp.bayIsoNumber];
      }

      if (portTcg < pairedInstance.portTcg)
        pairedInstance.portTcg = roundDec(portTcg, 4);

      if (starboardTcg > pairedInstance.starboardTcg)
        pairedInstance.starboardTcg = roundDec(starboardTcg, 4);
    }

    function getRelevantXcgs(blp: IBaySizesAndCgs, isAbove: boolean) {
      const tiers = Object.keys(blp.tiers).sort(
        isAbove ? sortNumericAsc : sortNumericDesc,
      ) as IRowPattern[];

      const lcg = !missingImportantXcgs
        ? blp.maxSizeLcg.lcg || 0
        : (maxIsoBay + 1) * 0.5 * LCG_20_WITH_SEPARATION -
          SEPARATION_IN_BETWEEN -
          Number(blp.bayIsoNumber) * 0.5 * LCG_20_WITH_SEPARATION;

      const rows = (Object.keys(blp.rows) as IRowPattern[]).sort(sortRowsArray);
      const allTcgs = rows.map((row) => blp.rows[row].tcg || 0);
      const portTcg = allTcgs[0];
      const starboardTcg = allTcgs[allTcgs.length - 1];

      return {
        vcg: blp.tiers[tiers[0]]?.vcg,
        portTcg,
        starboardTcg,
        lcg: roundDec(lcg, 4),
        maxSize: blp.tiers[tiers[0]]?.maxSize,
        foreLcg: roundDec(blp.maxSizeLcg.foreLcg || 0, 4),
        aftLcg: roundDec(blp.maxSizeLcg.aftLcg || 0, 4),
      };
    }

    function supplyB20sLongPointsFor40s() {
      const bays = Object.keys(aboveBelowBlps).sort();
      bays.forEach((bay) => {
        const bp = aboveBelowBlps[bay];
        if (bp.b20 || bp.maxSize < 40 || bp.pairedBay === undefined) return;

        const midMaxSize = bp.maxSize * 0.5;
        const isPairedFwd = bp.pairedBay === ForeAftEnum.FWD;

        bp.b20 = {
          fwdLPoint: isPairedFwd
            ? bp.lcg
            : bp.lcg + feetToMillimeters(midMaxSize || 0),
          aftLPoint: isPairedFwd
            ? bp.lcg - feetToMillimeters(midMaxSize || 0)
            : bp.lcg,
        };
      });
    }
  }

  function generateShipContour(missingImportantXcgs: boolean): void {
    const depthInMilimeters = roundDec(TCG_IN_MMM * svgSizeRatio, 1);
    const pairedBlps = createSameBayAbBeSizesAndCgs(
      bayLevelPositionsAbove,
      bayLevelPositionsBelow,
    );

    const segmentsPort: [number, number][] = [];
    const segmentsStbd: [number, number][] = [];

    let prevPortTcg: number | undefined = undefined;
    let prevStarboardTcg: number | undefined = undefined;

    Object.keys(pairedBlps).forEach((isoBay) => {
      const paired = pairedBlps[isoBay];

      if (paired.portTcg === 0 && paired.starboardTcg === 0) return;

      const portBorder =
        roundDec(paired.portTcg - depthInMilimeters * 0.5, 2) - 2000;
      const stbdBorder =
        roundDec(paired.starboardTcg + depthInMilimeters * 0.5, 2) + 2000;

      // Add parts of line
      const fLcg = paired.foreLcg + (missingImportantXcgs ? 6000 : 0);
      const aLcg = paired.aftLcg;

      // Port side
      segmentsPort.push([xPos(fLcg, 1), zPos(portBorder, 1)]);
      segmentsPort.push([xPos(aLcg, 1), zPos(portBorder, 1)]);
      // Stbd side
      segmentsStbd.push([xPos(fLcg, 1), zPos(stbdBorder, 1)]);
      segmentsStbd.push([xPos(aLcg, 1), zPos(stbdBorder, 1)]);

      if ((prevPortTcg || 0) > portBorder) prevPortTcg = portBorder;
      if ((prevStarboardTcg || 0) < stbdBorder) prevStarboardTcg = stbdBorder;
    });

    // Finally, add stern
    segmentsPort.push([roundDec(addX * 0.25), zPos(prevPortTcg || 0, 1)]);
    segmentsStbd.push([roundDec(addX * 0.25), zPos(prevStarboardTcg || 0, 1)]);

    const bow = [
      [roundDec(segmentsPort[0][0]), roundDec(segmentsPort[0][1])],
      [xRange, roundDec(zRange * 0.5)],
      [roundDec(segmentsStbd[0][0]), roundDec(segmentsStbd[0][1])],
    ];

    const allSegments = [
      ...segmentsPort.reverse(),
      ...bow,
      ...segmentsStbd,
    ].map(([x, y]) => ({ x, y }));

    const uniqueSegments = makeHull(allSegments).map((point) => [
      point.x,
      point.y,
    ]);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const initialPoint = uniqueSegments.shift()!;

    const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
    path.setAttribute("stroke", symbolsOptions?.shipStrokeColor || "red");
    path.setAttribute(
      "stroke-width",
      String(symbolsOptions?.strokeWidth || 0.1),
    );
    path.setAttribute("fill-opacity", "0");
    path.setAttribute(
      "d",
      `M${initialPoint[0]},${initialPoint[1]} ${uniqueSegments
        .map((seg) => `L${seg[0]},${seg[1]}`)
        .join(" ")} z`,
    );
    svgGroup.appendChild(path);
  }

  function drawLids(lcgsBy20Bay: I20LcgsByBay) {
    const halfDepthInMilimeters = roundDec(TCG_IN_MMM * 0.5, 1); // Doesn't use svgSizeRatio because xPos is applied later
    const rowTcgsByBay: IBayRowsTcgs = {};
    bayLevelPositionsAbove.forEach(copyTcgOfRows);
    bayLevelPositionsBelow.forEach(copyTcgOfRows);

    lidData
      .filter(
        (lid) =>
          lcgsBy20Bay[lid.startIsoBay] !== undefined &&
          lcgsBy20Bay[lid.endIsoBay] !== undefined &&
          (rowTcgsByBay[lid.startIsoBay]?.[lid.portIsoRow] !== undefined ||
            rowTcgsByBay[lid.endIsoBay]?.[lid.portIsoRow] !== undefined) &&
          (rowTcgsByBay[lid.startIsoBay]?.[lid.starboardIsoRow] !== undefined ||
            rowTcgsByBay[lid.endIsoBay]?.[lid.starboardIsoRow] !== undefined),
      )
      .forEach((lid, idx) => {
        const foreLcg = lcgsBy20Bay[lid.startIsoBay].foreLcg;
        const aftLcg = lcgsBy20Bay[lid.endIsoBay].aftLcg;
        const portTcg =
          (rowTcgsByBay[lid.startIsoBay][lid.portIsoRow] ||
            rowTcgsByBay[lid.endIsoBay][lid.portIsoRow]) -
          halfDepthInMilimeters;
        const stbdTcg =
          (rowTcgsByBay[lid.startIsoBay][lid.starboardIsoRow] ||
            rowTcgsByBay[lid.endIsoBay][lid.starboardIsoRow]) +
          halfDepthInMilimeters;

        if (
          foreLcg !== undefined &&
          aftLcg !== undefined &&
          portTcg !== undefined &&
          stbdTcg !== undefined
        ) {
          const path = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "path",
          );
          path.setAttribute("stroke", symbolsOptions?.shipStrokeColor || "red");
          path.setAttribute(
            "stroke-width",
            String(symbolsOptions?.strokeWidth || 0.1),
          );
          path.setAttribute("fill", symbolsOptions?.lidFillColor || "black");
          path.setAttribute("fill-opacity", "0.4");
          path.setAttribute("data-idx", String(idx));
          path.setAttribute(
            "d",
            `M${xPos(aftLcg, 2)},${zPos(portTcg)}
              L${xPos(foreLcg, 2)},${zPos(portTcg)}
              L${xPos(foreLcg, 2)},${zPos(stbdTcg)}
              L${xPos(aftLcg, 2)},${zPos(stbdTcg)}
              z`,
          );

          const xText = xPos((aftLcg + foreLcg) * 0.5, 2),
            yText = zPos((portTcg + stbdTcg) * 0.5, 2);

          const title = createSvgText({
            text: lid.label,
            x: 0,
            y: symbolsOptions?.addRowLines ? 4 : 0,
            textColor: symbolsOptions?.lidTextColor || "red",
            fontSize: 8,
          });

          title.setAttribute(
            "transform",
            `rotate(90, 0,0) translate(${yText}, ${-xText} )`,
          );

          if (lid.overlapPort) {
            const overlapPort = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "line",
            );
            overlapPort.setAttribute(
              "stroke",
              symbolsOptions?.shipStrokeColor || "red",
            );
            overlapPort.setAttribute(
              "stroke-width",
              String((symbolsOptions?.strokeWidth || 0.1) * 4),
            );
            overlapPort.setAttribute("x1", String(xPos(aftLcg, 2)));
            overlapPort.setAttribute("y1", String(zPos(portTcg, 2)));
            overlapPort.setAttribute("x2", String(xPos(foreLcg, 2)));
            overlapPort.setAttribute("y2", String(zPos(portTcg, 2)));
            overlapPort.setAttribute("stroke-linejoin", "round");
            svgGroup.appendChild(overlapPort);
          }

          if (lid.overlapStarboard) {
            const overlapStbd = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "line",
            );
            overlapStbd.setAttribute(
              "stroke",
              symbolsOptions?.shipStrokeColor || "red",
            );
            overlapStbd.setAttribute(
              "stroke-width",
              String((symbolsOptions?.strokeWidth || 0.1) * 4),
            );
            overlapStbd.setAttribute("x1", String(xPos(aftLcg, 2)));
            overlapStbd.setAttribute("y1", String(zPos(stbdTcg, 2)));
            overlapStbd.setAttribute("x2", String(xPos(foreLcg, 2)));
            overlapStbd.setAttribute("y2", String(zPos(stbdTcg, 2)));
            svgGroup.appendChild(overlapStbd);
          }

          svgGroup.appendChild(path);
          svgGroup.appendChild(title);
        }
      });

    //  Helpers
    function copyTcgOfRows(blp: IBaySizesAndCgs) {
      const rows = blp.rows;
      const rowKeys = Object.keys(rows) as IRowPattern[];
      if (!rowTcgsByBay[blp.bayIsoNumber]) rowTcgsByBay[blp.bayIsoNumber] = {};
      rowKeys.forEach((rowKey) => {
        rowTcgsByBay[blp.bayIsoNumber][rowKey] = rows[rowKey].tcg || 0;
      });
    }
  }
}

interface IBayAboveBelowLTcgs {
  [name: string]: {
    portTcg: number;
    starboardTcg: number;
    foreLcg: number;
    aftLcg: number;
    lcg: number;
    maxSize: number;
    pairedBay?: ForeAftEnum;
    b20?: {
      fwdLPoint: number;
      aftLPoint: number;
    };
  };
}

interface IRowsTcgs {
  [row: IRowPattern]: number;
}

interface IBayRowsTcgs {
  [bay: IBayPattern]: IRowsTcgs;
}

export interface IGenerateDeckContainerSymbolsProps {
  fillColor?: string;
  strokeColor?: string;
  shipStrokeColor?: string;
  lidFillColor?: string;
  strokeWidth?: number;
  fontColor?: string;
  lidTextColor?: string;
  portColor?: string;
  stbdColor?: string;
  addRowLines?: boolean;
  warningColor?: string;
}

interface ICreateSimpleDeckViewProps {
  sizeSummary: ISizeSummary;
  lidData: ILidData[];
  lcgVcgTcgAndPairings: IGetBayLcgVcgAndPairingsResult;
  symbolsOptions?: IGenerateDeckContainerSymbolsProps;
  svgGroupId?: string;
  bayNumbersDoubleClickAction?: (ev: SVGElement) => void;
}
