import { ISlotData } from "open-vessel-definition";
import { RecursiveKeyOf } from "@baplie-viewer2/tedivo-pure-helpers";
import TContainerLengths from "../types/TContainerLengths";

const SIZES_COLORS: Record<TContainerLengths, [string, string]> = {
  20: ["#e06d38", "#fff"],
  24: ["#cb7e27", "#fff"],
  40: ["#53a34b", "#fff"],
  45: ["#32832f", "#fff"],
  48: ["#0a6413", "#fff"],
  53: ["#004600", "#fff"],
};

const CELL_ATTRIBUTES: ICellAttributes = {
  sizes: {
    20: {
      text: "20",
      bgColor: SIZES_COLORS[20][0],
      color: SIZES_COLORS[20][1],
      reducerAddKey: "ADD_SIZE_20",
      reducerRemoveKey: "REMOVE_SIZE_20",
      keyName: "sizes.20",
    },
    24: {
      text: "24",
      bgColor: SIZES_COLORS[24][0],
      color: SIZES_COLORS[24][1],
      reducerAddKey: "ADD_SIZE_24",
      reducerRemoveKey: "REMOVE_SIZE_24",
      keyName: "sizes.24",
    },
    40: {
      text: "40",
      bgColor: SIZES_COLORS[40][0],
      color: SIZES_COLORS[40][1],
      reducerAddKey: "ADD_SIZE_40",
      reducerRemoveKey: "REMOVE_SIZE_40",
      keyName: "sizes.40",
    },
    45: {
      text: "45",
      bgColor: SIZES_COLORS[45][0],
      color: SIZES_COLORS[45][1],
      reducerAddKey: "ADD_SIZE_45",
      reducerRemoveKey: "REMOVE_SIZE_45",
      keyName: "sizes.45",
    },
    48: {
      text: "48",
      bgColor: SIZES_COLORS[48][0],
      color: SIZES_COLORS[48][1],
      reducerAddKey: "ADD_SIZE_48",
      reducerRemoveKey: "REMOVE_SIZE_48",
      keyName: "sizes.48",
    },
    53: {
      text: "53",
      bgColor: SIZES_COLORS[53][0],
      color: SIZES_COLORS[53][1],
      reducerAddKey: "ADD_SIZE_53",
      reducerRemoveKey: "REMOVE_SIZE_53",
      keyName: "sizes.53",
    },
  },
  misc: {
    reefer: {
      text: "r",
      bgColor: "#00abe9",
      color: "#fff",
      reducerAddKey: "ADD_REEFER",
      reducerRemoveKey: "REMOVE_REEFER",
      keyName: "reefer",
    },
    coolStowProhibited: {
      text: "k",
      bgColor: "#5e338c",
      color: "#fff",
      reducerAddKey: "ADD_COOLSTOWPROHIBITED",
      reducerRemoveKey: "REMOVE_COOLSTOWPROHIBITED",
      keyName: "coolStowProhibited",
    },
    hazardousProhibited: {
      text: "h",
      bgColor: "#bb0d1e",
      color: "#fff",
      reducerAddKey: "ADD_HAZARDOUSPROHIBITED",
      reducerRemoveKey: "REMOVE_HAZARDOUSPROHIBITED",
      keyName: "hazardousProhibited",
    },
  },
  restricted: {
    restricted: {
      text: "-",
      bgColor: "#fff14b",
      color: "#000",
      reducerAddKey: "ADD_RESTRICTED",
      reducerRemoveKey: "REMOVE_RESTRICTED",
      keyName: "restricted",
    },
  },
  sizesWithCones: {
    20: {
      text: "20c",
      bgColor: SIZES_COLORS[20][0],
      color: SIZES_COLORS[20][1],
      reducerAddKey: "ADD_SIZE_20_CONEREQ",
      reducerRemoveKey: "ADD_SIZE_20",
      keyName: "sizes.20.cone",
    },
    24: {
      text: "24c",
      bgColor: SIZES_COLORS[24][0],
      color: SIZES_COLORS[24][1],
      reducerAddKey: "ADD_SIZE_24_CONEREQ",
      reducerRemoveKey: "ADD_SIZE_24",
      keyName: "sizes.24.cone",
    },
    40: {
      text: "40c",
      bgColor: SIZES_COLORS[40][0],
      color: SIZES_COLORS[40][1],
      reducerAddKey: "ADD_SIZE_40_CONEREQ",
      reducerRemoveKey: "ADD_SIZE_40",
      keyName: "sizes.40.cone",
    },
    45: {
      text: "45c",
      bgColor: SIZES_COLORS[45][0],
      color: SIZES_COLORS[45][1],
      reducerAddKey: "ADD_SIZE_45_CONEREQ",
      reducerRemoveKey: "ADD_SIZE_45",
      keyName: "sizes.45.cone",
    },
    48: {
      text: "48c",
      bgColor: SIZES_COLORS[48][0],
      color: SIZES_COLORS[48][1],
      reducerAddKey: "ADD_SIZE_48_CONEREQ",
      reducerRemoveKey: "ADD_SIZE_48",
      keyName: "sizes.48.cone",
    },
    53: {
      text: "53c",
      bgColor: SIZES_COLORS[53][0],
      color: SIZES_COLORS[53][1],
      reducerAddKey: "ADD_SIZE_53_CONEREQ",
      reducerRemoveKey: "ADD_SIZE_53",
      keyName: "sizes.53.cone",
    },
  },
};

export default CELL_ATTRIBUTES;

export interface ICellOptionsBase {
  color: string;
  bgColor: string;
  keyName: RecursiveKeyOf<ISlotData>;
  reducerAddKey: TReducerISlotDataActions;
  reducerRemoveKey: TReducerISlotDataActions;
}

type ICellOptions = ICellOptionsBase &
  (
    | {
        text: string;
        icon?: never;
      }
    | {
        text?: never;
        icon: string;
      }
  );

export const MISC_KEYS: TMiscKeys[] = [
  "reefer",
  "coolStowProhibited",
  "hazardousProhibited",
];

export type TMiscKeys = keyof Pick<
  ISlotData,
  "reefer" | "coolStowProhibited" | "hazardousProhibited"
>;

interface ICellAttributes {
  sizes: {
    [key in TContainerLengths]: ICellOptions;
  };
  sizesWithCones: {
    [key in TContainerLengths]: ICellOptions;
  };
  misc: {
    [name in TMiscKeys]: ICellOptions;
  };
  restricted: {
    restricted: ICellOptions;
  };
}

export type TCellAttributesAreasKeys =
  | "sizes"
  | "sizesWithCones"
  | "misc"
  | "restricted";

export type TReducerISlotDataActions =
  | "CLEAR"
  | "ADD_SIZE_20"
  | "ADD_SIZE_24"
  | "ADD_SIZE_40"
  | "ADD_SIZE_45"
  | "ADD_SIZE_48"
  | "ADD_SIZE_53"
  | "ADD_REEFER"
  | "ADD_RESTRICTED"
  | "ADD_COOLSTOWPROHIBITED"
  | "ADD_HAZARDOUSPROHIBITED"
  | "REMOVE_SIZE_20"
  | "REMOVE_SIZE_24"
  | "REMOVE_SIZE_40"
  | "REMOVE_SIZE_45"
  | "REMOVE_SIZE_48"
  | "REMOVE_SIZE_53"
  | "REMOVE_REEFER"
  | "REMOVE_RESTRICTED"
  | "REMOVE_COOLSTOWPROHIBITED"
  | "REMOVE_HAZARDOUSPROHIBITED"
  | "ADD_SIZE_20_CONEREQ"
  | "ADD_SIZE_24_CONEREQ"
  | "ADD_SIZE_40_CONEREQ"
  | "ADD_SIZE_45_CONEREQ"
  | "ADD_SIZE_48_CONEREQ"
  | "ADD_SIZE_53_CONEREQ";

export type TReducerIslotActionsWithoutClear = Exclude<
  TReducerISlotDataActions,
  "CLEAR"
>;
