import HttpClient, { IResponseModel } from "../HttpClient";

import { IDomainNotAllowed } from "@baplie-viewer2/tedivo-api-models";

const domainsBaseUrl = "domains";

const DomainsService = {
  getAll: async (): Promise<IResponseModel<IDomainNotAllowed[]>> => {
    const client = new HttpClient();
    return await client.request<IDomainNotAllowed[]>({
      method: "GET",
      url: domainsBaseUrl,
    });
  },

  get: async (id: string): Promise<IResponseModel<IDomainNotAllowed>> => {
    const client = new HttpClient();
    return await client.request<IDomainNotAllowed>({
      method: "GET",
      url: `${domainsBaseUrl}/${id}`,
    });
  },

  delete: async (id: string): Promise<IResponseModel<unknown>> => {
    const client = new HttpClient();
    return await client.request<unknown>({
      method: "DELETE",
      url: `${domainsBaseUrl}/${id}`,
      data: undefined,
    });
  },

  create: async (
    data: IDomainNotAllowed
  ): Promise<IResponseModel<ICreateOutput>> => {
    const client = new HttpClient();
    return await client.request<ICreateOutput>({
      method: "PUT",
      url: domainsBaseUrl,
      data,
    });
  },

  update: async (data: IDomainNotAllowed): Promise<IResponseModel<unknown>> => {
    const client = new HttpClient();
    return await client.request<unknown>({
      method: "PUT",
      url: domainsBaseUrl,
      data,
    });
  },
};

export default DomainsService;

interface ICreateOutput {
  domain: string;
}
