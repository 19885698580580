export enum LogEventTypesEnum {
  Created = "Created",
  Deleted = "Deleted",
  Modified = "Modified",
  StateChanged = "StateChanged",
  UpVoted = "UpVoted",
  RemovedVote = "RemovedVote",
  ClonedByThirdParty = "ClonedByThirdParty",
  ReplacedByVersion = "ReplacedByVersion",
  ReplacedByConsumer = "ReplacedByConsumer",
  IgnoreConsumerDiffers = "IgnoreConsumerDiffers",
  IgnoreSourcerDiffers = "IgnoreSourcerDiffers",
}
