import CELL_ATTRIBUTES, { MISC_KEYS, } from "./CellAttributesDefinition";
export function getSlotCapabilitiesLegendOptions(availableSizes, areas, featuresAllowed) {
    const allContainerLenghts = Object.keys(CELL_ATTRIBUTES.sizes).map(Number);
    const options = [];
    const areasFnsMap = {
        sizes: addSizesOptions,
        misc: addMiscOptions,
        restricted: addRestrictedOptions,
        sizesWithCones: addConesOptions,
    };
    function addSizesOptions() {
        allContainerLenghts
            .filter((size) => !availableSizes ||
            availableSizes.length === 0 ||
            availableSizes.indexOf(size) >= 0)
            .forEach((size) => {
            const attrs = CELL_ATTRIBUTES.sizes[size];
            options.push({
                id: attrs.reducerAddKey,
                color: attrs.color,
                bgColor: attrs.bgColor,
                labelKey: `slotCapabilities.size.${attrs.text || size}`,
                keyName: attrs.keyName,
            });
        });
    }
    function addMiscOptions() {
        MISC_KEYS.forEach((k) => {
            const addOption = (k === "coolStowProhibited" &&
                (featuresAllowed === null || featuresAllowed === void 0 ? void 0 : featuresAllowed.slotCoolStowProhibited)) ||
                (k === "hazardousProhibited" &&
                    (featuresAllowed === null || featuresAllowed === void 0 ? void 0 : featuresAllowed.slotHazardousProhibited)) ||
                k === "reefer";
            if (addOption)
                options.push({
                    id: CELL_ATTRIBUTES.misc[k].reducerAddKey,
                    color: CELL_ATTRIBUTES.misc[k].color,
                    bgColor: CELL_ATTRIBUTES.misc[k].bgColor,
                    labelKey: `slotCapabilities.misc.${CELL_ATTRIBUTES.misc[k].text || k}`,
                    keyName: CELL_ATTRIBUTES.misc[k].keyName,
                });
        });
    }
    function addRestrictedOptions() {
        options.push({
            id: CELL_ATTRIBUTES.restricted.restricted.reducerAddKey,
            color: CELL_ATTRIBUTES.restricted.restricted.color,
            bgColor: CELL_ATTRIBUTES.restricted.restricted.bgColor,
            labelKey: `slotCapabilities.restricted.${CELL_ATTRIBUTES.restricted.restricted.text}`,
            keyName: CELL_ATTRIBUTES.restricted.restricted.keyName,
        });
    }
    function addConesOptions() {
        allContainerLenghts
            .filter((size) => !availableSizes ||
            availableSizes.length === 0 ||
            availableSizes.indexOf(size) >= 0)
            .forEach((size) => {
            const attrs = CELL_ATTRIBUTES.sizesWithCones[size];
            options.push({
                id: attrs.reducerAddKey,
                color: attrs.color,
                bgColor: attrs.bgColor,
                labelKey: `slotCapabilities.size.${attrs.text || size}`,
                keyName: attrs.keyName,
            });
        });
    }
    areas.forEach((areaName) => areasFnsMap[areaName]());
    return options;
}
