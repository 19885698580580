import { __awaiter } from "tslib";
import HttpClient from "../HttpClient";
const notificationsBaseUrl = "notifications";
const NotificationsService = {
    getAll: () => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "GET",
            url: notificationsBaseUrl,
        });
    }),
    getAllCountOnly: () => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "GET",
            url: "notificationsCount",
        });
    }),
};
export default NotificationsService;
