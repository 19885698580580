import "./tvd-top-tools.component.scss";

import { ScrollModeEnum } from "../../../app/units/units-scroller-element";
import awsCognito from "../../../app/security/awsCognito";
import { getTranslation } from "../../../app/i18/i18tn";
import securityModule from "../../../app/security/SecurityModule";

export class OssTopToolsElement extends HTMLElement {
  public static observedAttributes = [];

  private wrapper: HTMLDivElement = document.createElement("div");

  constructor() {
    super();
    awsCognito.addEventListener(
      "authenticationChanged",
      this.showHideLoggedInOptions,
    );
  }

  connectedCallback() {
    const wrapper = document.createElement("div"),
      initialSlot = document.createElement("div"),
      accountExpired = document.createElement("sl-badge"),
      topTitle = document.createElement("span"),
      languageSelect = document.createElement("tvd-language-sel-element"),
      colorModeSelect = document.createElement("tvd-color-mode-element"),
      optionsCogBtn = document.createElement("tvd-user-options-component"),
      notificationsBtn = document.createElement("tvd-notifications-component");

    const unitsScroller = document.createElement("units-scroller-element");
    unitsScroller.align = "right";
    unitsScroller.doScroll = ScrollModeEnum.AUTO;

    wrapper.className = "oss-top-tools";
    this.wrapper = wrapper;

    initialSlot.className = "oss-top-message";

    initialSlot.appendChild(accountExpired);
    initialSlot.appendChild(topTitle);

    accountExpired.className = "account-expired";
    accountExpired.id = "oss-account-expired";
    accountExpired.variant = "danger";
    accountExpired.pulse = true;
    accountExpired.innerHTML = getTranslation(
      "general:organizations.planIsExpired",
    );

    topTitle.className = "top-title";
    topTitle.id = "oss-top-message";

    colorModeSelect.id = "color-mode-select";

    wrapper.appendChild(initialSlot);
    wrapper.appendChild(unitsScroller);
    wrapper.appendChild(optionsCogBtn);
    wrapper.appendChild(notificationsBtn);
    wrapper.appendChild(languageSelect);
    wrapper.appendChild(colorModeSelect);
    this.appendChild(wrapper);

    document.documentElement.addEventListener("globalUnitsChanged", () => {
      unitsScroller.update();
    });

    window.setTimeout(() => {
      if (securityModule.planIsExpired) accountExpired.dataset.expired = "true";
    }, 0);
  }

  showHideLoggedInOptions = () => {
    window.setTimeout(() => {
      // In the SCSS file we have a rule that hides the some elements when the user is not logged in.
      this.wrapper.dataset.loggedIn = securityModule.isLoggedIn
        ? "true"
        : "false";
    }, 0);
  };
}

customElements.define("tvd-top-tools-component", OssTopToolsElement);
