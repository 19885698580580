import { __awaiter } from "tslib";
import HttpClient from "../HttpClient";
const CommunitySearchService = {
    search: (q, fields) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        const fieldsQuery = (fields === null || fields === void 0 ? void 0 : fields.length) ? `&fields=${fields.join(",")}` : "";
        return yield client.request({
            method: "GET",
            url: `vessels/community-search?q=${encodeURI(q)}${fieldsQuery}`,
        });
    }),
};
export default CommunitySearchService;
