class TopMessageElement {
  public get element(): HTMLElement | null {
    return document.getElementById("oss-top-message");
  }

  public get planIsExpiredElement(): HTMLElement | null {
    return document.getElementById("oss-account-expired");
  }

  public set planIsExpired(value: boolean) {
    if (!this.planIsExpiredElement) return;
    this.planIsExpiredElement.dataset.expired = value ? "true" : "false";
  }
}

const topMessageElement = new TopMessageElement();
export default topMessageElement;
