/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  TReducerISlotDataActions,
  TReducerIslotActionsWithoutClear,
} from "@baplie-viewer2/tedivo-bay-grid-core";

import { ISlotData } from "open-vessel-definition";

export const SlotDataReducer: {
  [key in TReducerISlotDataActions]: (d: ISlotData) => ISlotData;
} = {
  CLEAR: (s: ISlotData): ISlotData => {
    return { sizes: {}, pos: s.pos };
  },

  ADD_SIZE_20: (s: ISlotData): ISlotData => ({
    ...s,
    restricted: undefined,
    sizes: { ...s.sizes, 20: 1 },
  }),
  ADD_SIZE_24: (s: ISlotData): ISlotData => ({
    ...s,
    restricted: undefined,
    sizes: { ...s.sizes, 24: 1 },
  }),
  ADD_SIZE_40: (s: ISlotData): ISlotData => ({
    ...s,
    restricted: undefined,
    sizes: { ...s.sizes, 40: 1 },
  }),
  ADD_SIZE_45: (s: ISlotData): ISlotData => ({
    ...s,
    restricted: undefined,
    sizes: { ...s.sizes, 45: 1 },
  }),
  ADD_SIZE_48: (s: ISlotData): ISlotData => ({
    ...s,
    restricted: undefined,
    sizes: { ...s.sizes, 48: 1 },
  }),
  ADD_SIZE_53: (s: ISlotData): ISlotData => ({
    ...s,
    restricted: undefined,
    sizes: { ...s.sizes, 53: 1 },
  }),

  ADD_SIZE_20_CONEREQ: (s: ISlotData): ISlotData => {
    if (!s.sizes?.[20]) return simpleClone(s);
    return {
      ...s,
      restricted: undefined,
      sizes: { ...s.sizes, 20: { cone: 1 } },
    };
  },
  ADD_SIZE_24_CONEREQ: (s: ISlotData): ISlotData => {
    if (!s.sizes?.[24]) return simpleClone(s);
    return {
      ...s,
      restricted: undefined,
      sizes: { ...s.sizes, 24: { cone: 1 } },
    };
  },
  ADD_SIZE_40_CONEREQ: (s: ISlotData): ISlotData => {
    if (!s.sizes?.[40]) return simpleClone(s);
    return {
      ...s,
      restricted: undefined,
      sizes: { ...s.sizes, 40: { cone: 1 } },
    };
  },
  ADD_SIZE_45_CONEREQ: (s: ISlotData): ISlotData => {
    if (!s.sizes?.[45]) return simpleClone(s);
    return {
      ...s,
      restricted: undefined,
      sizes: { ...s.sizes, 45: { cone: 1 } },
    };
  },
  ADD_SIZE_48_CONEREQ: (s: ISlotData): ISlotData => {
    if (!s.sizes?.[48]) return simpleClone(s);
    return {
      ...s,
      restricted: undefined,
      sizes: { ...s.sizes, 48: { cone: 1 } },
    };
  },
  ADD_SIZE_53_CONEREQ: (s: ISlotData): ISlotData => {
    if (!s.sizes?.[53]) return simpleClone(s);
    return {
      ...s,
      restricted: undefined,
      sizes: { ...s.sizes, 53: { cone: 1 } },
    };
  },

  ADD_REEFER: (s: ISlotData): ISlotData => ({
    ...s,
    restricted: undefined,
    sizes: { ...s.sizes },
    reefer: 1,
  }),
  ADD_RESTRICTED: (s: ISlotData): ISlotData => ({
    pos: s.pos,
    sizes: {},
    restricted: 1,
  }),
  ADD_COOLSTOWPROHIBITED: (s: ISlotData): ISlotData => ({
    ...s,
    sizes: { ...s.sizes },
    restricted: undefined,
    coolStowProhibited: 1,
  }),
  ADD_HAZARDOUSPROHIBITED: (s: ISlotData): ISlotData => ({
    ...s,
    sizes: { ...s.sizes },
    restricted: undefined,
    hazardousProhibited: true,
  }),

  REMOVE_SIZE_20: (s: ISlotData): ISlotData => {
    const { sizes, ...otherProps } = s;
    const { 20: unused, ...otherSizes } = sizes;
    return {
      ...otherProps,
      sizes: { ...otherSizes },
    };
  },
  REMOVE_SIZE_24: (s: ISlotData): ISlotData => {
    const { sizes, ...otherProps } = s;
    const { 24: unused, ...otherSizes } = sizes;
    return {
      ...otherProps,
      sizes: { ...otherSizes },
    };
  },
  REMOVE_SIZE_40: (s: ISlotData): ISlotData => {
    const { sizes, ...otherProps } = s;
    const { 40: unused, ...otherSizes } = sizes;
    return {
      ...otherProps,
      sizes: { ...otherSizes },
    };
  },
  REMOVE_SIZE_45: (s: ISlotData): ISlotData => {
    const { sizes, ...otherProps } = s;
    const { 45: unused, ...otherSizes } = sizes;
    return {
      ...otherProps,
      sizes: { ...otherSizes },
    };
  },
  REMOVE_SIZE_48: (s: ISlotData): ISlotData => {
    const { sizes, ...otherProps } = s;
    const { 48: unused, ...otherSizes } = sizes;
    return {
      ...otherProps,
      sizes: { ...otherSizes },
    };
  },
  REMOVE_SIZE_53: (s: ISlotData): ISlotData => {
    const { sizes, ...otherProps } = s;
    const { 53: unused, ...otherSizes } = sizes;
    return {
      ...otherProps,
      sizes: { ...otherSizes },
    };
  },

  REMOVE_REEFER: (s: ISlotData): ISlotData => {
    const { reefer, ...otherProps } = s;
    return {
      ...otherProps,
      sizes: { ...otherProps.sizes },
    };
  },
  REMOVE_RESTRICTED: (s: ISlotData): ISlotData => {
    const { restricted, pos, ...otherProps } = s;
    return {
      pos,
      sizes: {},
    };
  },
  REMOVE_COOLSTOWPROHIBITED: (s: ISlotData): ISlotData => {
    const { coolStowProhibited, ...otherProps } = s;
    return {
      ...otherProps,
      sizes: { ...otherProps.sizes },
    };
  },
  REMOVE_HAZARDOUSPROHIBITED: (s: ISlotData): ISlotData => {
    const { hazardousProhibited, ...otherProps } = s;
    return {
      ...otherProps,
      sizes: { ...otherProps.sizes },
    };
  },

  // SET_VOID_HULL: (s: ISlotData): ISlotData => {
  //   return { sizes: {}, pos: s.pos, restricted: VoidTypesEnum.HULL };
  // },
  // SET_VOID_BALLASTTANK: (s: ISlotData): ISlotData => {
  //   return { sizes: {}, pos: s.pos, restricted: VoidTypesEnum.BALLAST_TANK };
  // },
  // SET_VOID_DIESELTANK: (s: ISlotData): ISlotData => {
  //   return { sizes: {}, pos: s.pos, restricted: VoidTypesEnum.DIESEL_TANK };
  // },
  // SET_VOID_FUELTANK: (s: ISlotData): ISlotData => {
  //   return { sizes: {}, pos: s.pos, restricted: VoidTypesEnum.FUEL_TANK };
  // },
  // SET_VOID_FRESHWATERTANK: (s: ISlotData): ISlotData => {
  //   return {
  //     sizes: {},
  //     pos: s.pos,
  //     restricted: VoidTypesEnum.FRESH_WATER_TANK,
  //   };
  // },
  // SET_VOID_LUBEOILTANK: (s: ISlotData): ISlotData => {
  //   return { sizes: {}, pos: s.pos, restricted: VoidTypesEnum.LUBE_OIL_TANK };
  // },
  // SET_VOID_OTHER: (s: ISlotData): ISlotData => {
  //   return { sizes: {}, pos: s.pos, restricted: VoidTypesEnum.OTHER };
  // },
};

export const SlotReversedActions: {
  [key in TReducerIslotActionsWithoutClear]: TReducerISlotDataActions;
} = {
  ADD_SIZE_20: "REMOVE_SIZE_20",
  ADD_SIZE_24: "REMOVE_SIZE_24",
  ADD_SIZE_40: "REMOVE_SIZE_40",
  ADD_SIZE_45: "REMOVE_SIZE_45",
  ADD_SIZE_48: "REMOVE_SIZE_48",
  ADD_SIZE_53: "REMOVE_SIZE_53",
  ADD_REEFER: "REMOVE_REEFER",
  ADD_RESTRICTED: "REMOVE_RESTRICTED",
  ADD_COOLSTOWPROHIBITED: "REMOVE_COOLSTOWPROHIBITED",
  ADD_HAZARDOUSPROHIBITED: "REMOVE_HAZARDOUSPROHIBITED",
  REMOVE_SIZE_20: "ADD_SIZE_20",
  REMOVE_SIZE_24: "ADD_SIZE_24",
  REMOVE_SIZE_40: "ADD_SIZE_40",
  REMOVE_SIZE_45: "ADD_SIZE_45",
  REMOVE_SIZE_48: "ADD_SIZE_48",
  REMOVE_SIZE_53: "ADD_SIZE_53",
  REMOVE_REEFER: "ADD_REEFER",
  REMOVE_RESTRICTED: "ADD_RESTRICTED",
  REMOVE_COOLSTOWPROHIBITED: "ADD_COOLSTOWPROHIBITED",
  REMOVE_HAZARDOUSPROHIBITED: "ADD_HAZARDOUSPROHIBITED",
  ADD_SIZE_20_CONEREQ: "ADD_SIZE_20",
  ADD_SIZE_24_CONEREQ: "ADD_SIZE_24",
  ADD_SIZE_40_CONEREQ: "ADD_SIZE_40",
  ADD_SIZE_45_CONEREQ: "ADD_SIZE_45",
  ADD_SIZE_48_CONEREQ: "ADD_SIZE_48",
  ADD_SIZE_53_CONEREQ: "ADD_SIZE_53",
};

function simpleClone<T>(x: T): T {
  return JSON.parse(JSON.stringify(x)) as T;
}
