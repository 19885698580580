import CELL_ATTRIBUTES, { MISC_KEYS } from "./CellAttributesDefinition";
import { ISlotData, TContainerLengths } from "open-vessel-definition";

import { createSvgText } from "../helpers/createSvgText";
import { roundDec } from "@baplie-viewer2/tedivo-pure-helpers";

export function createSlotCapabilitiesSvg({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  slotWidth,
  slotHeight,
  fontSize,
  slotData,
  labelWidth = 12,
  labelHeight = 12,
  labelGap = 0,
  centered = false,
}: ICreateSlotCapabilitiesProps): SVGGElement {
  // Main group
  const svgGroup = document.createElementNS("http://www.w3.org/2000/svg", "g");
  const svgGCentered = centered
    ? document.createElementNS("http://www.w3.org/2000/svg", "g")
    : null;

  // Dimensions for labels
  //const maxLabelsV = Math.floor(slotHeight / labelHeight);
  const startY = 1;
  let x = labelGap,
    y = startY;

  // 1. acceptsContainers
  if (slotData.sizes) {
    (Object.keys(slotData.sizes) as unknown as TContainerLengths[])
      .filter((contLen) => typeof slotData.sizes[contLen])

      .forEach((contLen) => {
        const coneIsRequired =
          typeof slotData.sizes[contLen] === "object" &&
          (slotData.sizes[contLen] as { cone: 1 | 0 }).cone === 1;

        const sizeIsAllowed = slotData.sizes[contLen] === 1 || coneIsRequired;

        if (sizeIsAllowed) {
          const ca = CELL_ATTRIBUTES.sizes[contLen];
          addTextBox(
            svgGCentered || svgGroup,
            String(ca.text),
            x,
            y,
            ca.bgColor,
            ca.color,
            !coneIsRequired,
          );

          if (coneIsRequired) {
            const conePath = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "path",
            );
            const yCone = roundDec(
              y - labelHeight * (fontSize > 0 ? 0.05 : -0.45),
              2,
            );
            const xCone = roundDec(
              x + (fontSize > 0 ? 0 : labelWidth * -0.25),
              2,
            );

            conePath.setAttribute("fill", ca.color);
            conePath.setAttribute("stroke-width", "0");
            conePath.setAttribute(
              "d",
              `M${labelWidth * 0.8 + xCone},${yCone - 1} L${
                labelWidth * 0.6 + xCone
              },${labelHeight * 0.25 + yCone} L${labelWidth + xCone},${
                labelHeight * 0.25 + yCone
              } Z`,
            );
            (svgGCentered || svgGroup).appendChild(conePath);

            y += labelHeight + labelGap;
            if (y + labelHeight > slotHeight) {
              y = startY;
              x += labelWidth + labelGap;
            }
          }
        }
      });
  }

  // 2. Misc
  MISC_KEYS.filter((k) => !!slotData[k]).forEach((k) => {
    const ca = CELL_ATTRIBUTES.misc[k];
    addTextBox(
      svgGCentered || svgGroup,
      String(ca.text),
      x,
      y,
      ca.bgColor,
      ca.color,
    );
  });

  // 3. Restricted
  if (slotData.restricted) {
    const ca = CELL_ATTRIBUTES.restricted.restricted;
    addTextBox(
      svgGCentered || svgGroup,
      String(ca.text),
      x,
      y,
      ca.bgColor,
      ca.color,
    );
  }

  // IF CENTERED ***
  if (svgGCentered) {
    const gWidth = x + (y === startY ? startY : labelWidth);
    svgGCentered.setAttribute(
      "transform",
      `translate(${(slotWidth - gWidth) * 0.5}, 0)`,
    );
    svgGroup.appendChild(svgGCentered);
  }

  return svgGroup;

  function addTextBox(
    group: SVGGElement,
    text: string,
    xPos: number,
    yPos: number,
    bgColor: string,
    color: string,
    addUpCoordinates = true,
  ) {
    const rect = document.createElementNS("http://www.w3.org/2000/svg", "path");
    rect.setAttribute(
      "d",
      `M${x},${y} h${labelWidth} v${labelHeight} h-${labelWidth} z`,
    );
    rect.setAttribute("fill", bgColor);

    group.appendChild(rect);

    if (fontSize > 0) {
      const svgT = createSvgText({
        text,
        x: Math.round(xPos + labelWidth * 0.5),
        y: Math.ceil(yPos + labelHeight * 0.5) + 1,
        textColor: color,
        fontSize,
      });
      group.appendChild(svgT);
    }

    if (addUpCoordinates) {
      y += labelHeight + labelGap;
      if (y + labelHeight > slotHeight) {
        y = startY;
        x += labelWidth + labelGap;
      }
    }
  }
}

interface ICreateSlotCapabilitiesProps {
  slotWidth: number;
  slotHeight: number;
  fontSize: number;
  slotData: ISlotData;
  labelWidth?: number;
  labelHeight?: number;
  labelGap?: number;
  centered?: boolean;
}
