import { createSelectShoelace, } from "../select/select-shoelace";
/**
 * Creates an Edit button with multiple options and actions
 */
export function createMultiEditButton({ id, className, options, title, size = "small", showTitleAsTooltip = true, iconName = "pencil", variant, outlined, placement, disabled, }) {
    const editBtn = createSelectShoelace({
        id,
        caret: false,
        size: size || "small",
        pill: true,
        iconPrefix: iconName || "pencil",
        buttonText: showTitleAsTooltip ? "" : title || "",
        className,
        options,
        ommitCheckSign: true,
        variant,
        outlined,
        placement,
        disabled,
        onChange: (v) => {
            var _a;
            // Don't use strict equality because options return as strings and values might be numbers
            const action = (_a = options.find((o) => o.value == v)) === null || _a === void 0 ? void 0 : _a.action;
            if (action)
                action();
        },
    });
    if (title && showTitleAsTooltip) {
        const tooltip = document.createElement("sl-tooltip");
        tooltip.placement = "left";
        tooltip.content = title;
        tooltip.appendChild(editBtn);
        return tooltip;
    }
    else {
        return editBtn;
    }
}
