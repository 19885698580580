/* eslint-disable @typescript-eslint/no-non-null-assertion */

import {
  BayLevelEnum,
  ForeAftEnum,
  IBayLevelData,
  IBulkheadInfo,
  IMasterCGs,
  TCommonBayInfo,
  TContainerLengths,
  TRowInfoByLength,
} from "open-vessel-definition";
import {
  IBayPattern,
  IRowPattern,
  ISlotPattern,
  ITierPattern,
} from "../types/IPositionPatterns";
import {
  ITiersByRow,
  getRowsAndTiersFromSlotKeys,
} from "./getRowsAndTiersFromSlotKeys";
import {
  feetToMillimeters,
  pad2,
  pad3,
  roundDec,
} from "@baplie-viewer2/tedivo-pure-helpers";

import { IBayRowInfo } from "open-vessel-definition/build/src/models/v1/parts/IBayLevelData";
import { IIsoRowPattern } from "open-vessel-definition/build/src/models/base/types/IPositionPatterns";
import { findPaired40 } from "./findPaired40";
import { getSizesFromSlots } from "./getSizesFromSlots";

const EIGHTANDHALF_FEET_IN_MILLIMETERS = feetToMillimeters(8.5);

export function getBayLcgVcgTcgAndPairings(
  bls: IBayLevelData[],
  masterCGs: IMasterCGs,
): IGetBayLcgVcgAndPairingsResult {
  const lcgByBayAbove: IBaySizesAndCgs[] = [];
  const lcgByBayBelow: IBaySizesAndCgs[] = [];

  let minVcg = Infinity,
    maxVcg = -Infinity,
    minLcg = Infinity,
    maxLcg = -Infinity,
    minTcg = Infinity,
    maxTcg = -Infinity,
    maxIsoBay = -Infinity,
    countLcgs = 0,
    definedLcgs = 0,
    countTcgs = 0,
    definedTcgs = 0,
    countBottomBase = 0,
    definedBottomBase = 0,
    totalSlotsCount = 0;

  bls
    .slice()
    .sort(sortByBayAsc)
    .forEach((bl) => {
      const combinedTiersInfo: IBaySizesAndCgs = {
        bayIsoNumber: bl.isoBay,
        baylevel: bl.level,
        bayLabel: bl.label20 || bl.label40,
        pairedBay: bl.pairedBay,
        bulkhead: bl.bulkhead,
        tiers: {},
        rows: {},
        maxSizeLcg: {},
        sizes: [],
        missingImportantLcgs: false,
        missingImportantVcgs: false,
        missingImportantTcgs: false,
        countLcgs: 0,
        definedLcgs: 0,
        countTcgs: 0,
        definedTcgs: 0,
        countBottomBase: 0,
        definedBottomBase: 0,
      };

      const tiersBases: { [tier: ITierPattern]: number } = {};

      if (maxIsoBay < Number(bl.isoBay)) maxIsoBay = Number(bl.isoBay);

      const { infoByContLength, perSlotInfo, perRowInfo } = bl;
      const commonRowInfo = perRowInfo?.common;
      const perRowInfoEach = perRowInfo?.each;

      // Use perSlot to discover current lengths used
      combinedTiersInfo.sizes = getSizesFromSlots(perSlotInfo);
      // Expected LCGs count (one for each size)
      combinedTiersInfo.countLcgs = combinedTiersInfo.sizes.length;

      const slotsKeys = perSlotInfo
        ? (Object.keys(perSlotInfo) as ISlotPattern[]).filter(
            (slotKey) => !perSlotInfo?.[slotKey].restricted,
          )
        : undefined;

      if (slotsKeys) totalSlotsCount += slotsKeys.length;

      const { rows: rowsFromSlots, tiersByRow: tiersByRowFromSlots } =
        getRowsAndTiersFromSlotKeys(slotsKeys);

      // Initialize rows used in bay
      combinedTiersInfo.rows = rowsFromSlots.reduce((acc, row) => {
        acc[row] = { tcg: undefined };
        return acc;
      }, {} as { [s: IRowPattern]: { tcg?: number } });

      // Set LCGs using Bay's infoByContLength
      setLcgsFromInfoByContLength(
        bl.isoBay,
        infoByContLength,
        combinedTiersInfo,
      );

      // Set TCGs and BottomBases (former VCGs)
      if (perRowInfoEach !== undefined) {
        setTcgsAndBottomBases(
          bl,
          combinedTiersInfo,
          rowsFromSlots,
          tiersByRowFromSlots,
          perRowInfoEach,
          commonRowInfo,
          tiersBases,
        );
      } else {
        if (slotsKeys && slotsKeys.length > 0) {
          combinedTiersInfo.missingImportantTcgs = true;
          combinedTiersInfo.missingImportantVcgs = true;
        }
      }

      if (perSlotInfo !== undefined) {
        (slotsKeys || []).forEach((pos) => {
          const tier = pos.substring(2) as ITierPattern;
          const slotData = perSlotInfo[pos];

          if (slotData.sizes) {
            // A. Get maximum length from slots for each Tier
            const maxSize = Math.max(
              ...Object.keys(slotData.sizes).map((s) => Number(s)),
            ) as TContainerLengths;

            const lcgOfMaxSize = infoByContLength[maxSize]?.lcg;
            const tierInfo = combinedTiersInfo.tiers[tier];
            let updateDetails = false;

            // B. Add lcg of maxSize and vcg of the tier
            if (combinedTiersInfo.tiers[tier] === undefined) {
              combinedTiersInfo.tiers[tier] = {
                maxSize,
                vcg: tiersBases[tier],
                lcg: lcgOfMaxSize,
              };
              updateDetails = true;
            } else if (maxSize > combinedTiersInfo.tiers[tier].maxSize) {
              combinedTiersInfo.tiers[tier].maxSize = maxSize;
              combinedTiersInfo.tiers[tier].vcg = tiersBases[tier];
              combinedTiersInfo.tiers[tier].lcg = lcgOfMaxSize;
              updateDetails = true;
            }

            if (updateDetails) {
              if (tierInfo?.vcg !== undefined) {
                if (minVcg > tierInfo.vcg) minVcg = tierInfo.vcg;
                if (maxVcg < tierInfo.vcg) maxVcg = tierInfo.vcg;
              }

              combinedTiersInfo.missingImportantLcgs =
                combinedTiersInfo.missingImportantLcgs ||
                !updateMaxSizeDetailsOfBayAndLevel(
                  combinedTiersInfo.maxSizeLcg,
                  maxSize,
                  combinedTiersInfo.tiers[tier]?.lcg,
                );
            }
          }
        });
      }

      if (bl.level === BayLevelEnum.ABOVE) {
        lcgByBayAbove.push(combinedTiersInfo);
      } else if (bl.level === BayLevelEnum.BELOW) {
        lcgByBayBelow.push(combinedTiersInfo);
      }

      countLcgs += combinedTiersInfo.countLcgs;
      definedLcgs += combinedTiersInfo.definedLcgs;
      countTcgs += combinedTiersInfo.countTcgs;
      definedTcgs += combinedTiersInfo.definedTcgs;
      countBottomBase += combinedTiersInfo.countBottomBase;
      definedBottomBase += combinedTiersInfo.definedBottomBase;
    });

  findPaired40(lcgByBayAbove);
  findPaired40(lcgByBayBelow);

  const missingImportantLcgs = isMissingImportantXcgs(
    lcgByBayAbove,
    lcgByBayBelow,
    "missingImportantLcgs",
  );

  const missingImportantVcgs = isMissingImportantXcgs(
    lcgByBayAbove,
    lcgByBayBelow,
    "missingImportantVcgs",
  );

  const missingImportantTcgs = isMissingImportantXcgs(
    lcgByBayAbove,
    lcgByBayBelow,
    "missingImportantTcgs",
  );

  const isoBaysArray: IBayPattern[] = [];
  for (let i = 1; i <= maxIsoBay; i += 2) {
    isoBaysArray.push(pad3(i));
  }

  return {
    missingImportantLcgs,
    missingImportantVcgs,
    missingImportantTcgs,
    bayLevelPositionsAbove: lcgByBayAbove,
    bayLevelPositionsBelow: lcgByBayBelow,
    minLcg,
    maxLcg,
    minVcg,
    maxVcg,
    minTcg,
    maxTcg,
    maxIsoBay,
    isoBaysArray,
    totalSlotsCount,
    cgsStats: {
      countLcgs,
      definedLcgs,
      countTcgs,
      definedTcgs,
      countBottomBase,
      definedBottomBase,
    },
  };

  function setTcgsAndBottomBases(
    bl: IBayLevelData,
    combinedTiersInfo: IBaySizesAndCgs,
    rowsFromSlots: `${number}${number}`[],
    tiersByRowFromSlots: ITiersByRow,
    perRowInfoEach: {
      [key: IIsoRowPattern]: IBayRowInfo;
    },
    commonRowInfo: TCommonBayInfo | undefined,
    tiersBases: { [tier: ITierPattern]: number },
  ) {
    rowsFromSlots.forEach((row) => {
      const currentRowInfo = perRowInfoEach[row];
      combinedTiersInfo.countTcgs += 1;

      // 1. TCGs
      // TCG is the one defined in the bay or use master CGs
      const tcg =
        currentRowInfo?.tcg ??
        (bl.level === BayLevelEnum.ABOVE
          ? masterCGs.aboveTcgs[row]
          : masterCGs.belowTcgs[row]);

      if (tcg === undefined) {
        combinedTiersInfo.missingImportantTcgs = true;
      } else {
        combinedTiersInfo.definedTcgs += 1;
        combinedTiersInfo.rows[row] = { tcg };
        if (minTcg > tcg) minTcg = tcg;
        if (maxTcg < tcg) maxTcg = tcg;
      }

      // 2. Bottom Bases
      combinedTiersInfo.countBottomBase += 1;

      const bottomIsoTier = tiersByRowFromSlots[row]?.minTier
          ? pad2(tiersByRowFromSlots[row].minTier)
          : undefined,
        topIsoTier = tiersByRowFromSlots[row]?.maxTier
          ? pad2(tiersByRowFromSlots[row].maxTier)
          : undefined;

      // Bottom base is the one defined in the row, or in common, or in masterCGs
      const bottomBase =
        currentRowInfo?.bottomBase ??
        commonRowInfo?.bottomBase ??
        (bottomIsoTier !== undefined
          ? masterCGs.bottomBases[bottomIsoTier]
          : undefined);

      if (
        bottomBase === undefined ||
        bottomIsoTier === undefined ||
        topIsoTier === undefined
      ) {
        combinedTiersInfo.missingImportantVcgs = true;
      } else {
        combinedTiersInfo.definedBottomBase += 1;

        // 1. Find tiers
        const btmTierM = Number(bottomIsoTier),
          topTierN = Number(topIsoTier);
        // 2. Calculate VCGs
        for (let t = btmTierM; t <= topTierN; t += 2) {
          const isoTier = pad2(t);
          const tierVcg = roundDec(
            bottomBase +
              (t - btmTierM) * EIGHTANDHALF_FEET_IN_MILLIMETERS * 0.5,
            2,
          );

          tiersBases[isoTier] = tierVcg;

          if (minVcg > tierVcg) minVcg = tierVcg;
          if (maxVcg < tierVcg) maxVcg = tierVcg;
        }
      }
    });
  }

  function setLcgsFromInfoByContLength(
    bay: IBayPattern,
    infoByContLength: TRowInfoByLength | undefined,
    combinedTiersInfo: IBaySizesAndCgs,
  ) {
    if (infoByContLength === undefined) {
      return;
    }

    const cLengths = (
      Object.keys(infoByContLength).map(Number) as TContainerLengths[]
    ).filter((len) => combinedTiersInfo.sizes.indexOf(len) >= 0); // Only lengths used in the bay (from slots)

    // Defined LCGs count (one for each size)
    combinedTiersInfo.definedLcgs += cLengths
      .map((len) => infoByContLength[len]?.lcg)
      .filter((lcg) => lcg !== undefined).length;

    const maxSizeDefined = Math.max(...cLengths) as TContainerLengths;
    const lcgOfMaxSizeDefined = infoByContLength[maxSizeDefined]?.lcg;

    if (lcgOfMaxSizeDefined !== undefined) {
      if (minLcg > lcgOfMaxSizeDefined) minLcg = lcgOfMaxSizeDefined;
      if (maxLcg < lcgOfMaxSizeDefined) maxLcg = lcgOfMaxSizeDefined;

      combinedTiersInfo.missingImportantLcgs =
        combinedTiersInfo.missingImportantLcgs ||
        !updateMaxSizeDetailsOfBayAndLevel(
          combinedTiersInfo.maxSizeLcg,
          maxSizeDefined,
          lcgOfMaxSizeDefined,
        );
    }
  }
}

const updateMaxSizeDetailsOfBayAndLevel = (
  maxSizeLcg: IMaxSizeLcg,
  maxSize: TContainerLengths,
  lcg: number | undefined,
): boolean => {
  if (lcg === undefined) return false;

  maxSizeLcg.lcg = lcg;
  maxSizeLcg.aftLcg = getAftLcg(maxSize, lcg);
  maxSizeLcg.foreLcg = getForeLcg(maxSize, lcg);
  maxSizeLcg.size = maxSize;
  return true;
};

function isMissingImportantXcgs(
  lcgByBayAbove: IBaySizesAndCgs[],
  lcgByBayBelow: IBaySizesAndCgs[],
  attr:
    | "missingImportantLcgs"
    | "missingImportantVcgs"
    | "missingImportantTcgs",
) {
  return (
    lcgByBayAbove.reduce<boolean>((acc, y) => acc || y[attr], false) ||
    lcgByBayBelow.reduce<boolean>((acc, y) => acc || y[attr], false)
  );
}

const sortByBayAsc = (a: IBayLevelData, b: IBayLevelData): number =>
  Number(a.isoBay) - Number(b.isoBay);

const getForeLcg = (maxSize: number, lcg?: number): number | undefined =>
  lcg !== undefined
    ? roundDec(lcg + feetToMillimeters(maxSize * 0.5), 2)
    : undefined;

const getAftLcg = (maxSize: number, lcg?: number): number | undefined =>
  lcg !== undefined
    ? roundDec(lcg - feetToMillimeters(maxSize * 0.5), 2)
    : undefined;

// #region Interfaces
export interface IBaySizesAndCgs {
  bayIsoNumber: IBayPattern;
  baylevel: BayLevelEnum;
  bayLabel?: string;
  pairedBay?: ForeAftEnum;
  bulkhead?: IBulkheadInfo;
  tiers: {
    [tier: ITierPattern]: ILcgVcgByTier;
  };
  rows: {
    [row: IRowPattern]: ITcgByTier;
  };
  sizes: Array<TContainerLengths>;
  maxSizeLcg: IMaxSizeLcg;
  missingImportantLcgs: boolean;
  missingImportantTcgs: boolean;
  missingImportantVcgs: boolean;
  countLcgs: number;
  definedLcgs: number;
  countTcgs: number;
  definedTcgs: number;
  countBottomBase: number;
  definedBottomBase: number;
  pairedHas40?: boolean;
}

export interface IGetBayLcgVcgAndPairingsResult {
  missingImportantLcgs: boolean;
  missingImportantVcgs: boolean;
  missingImportantTcgs: boolean;
  bayLevelPositionsAbove: IBaySizesAndCgs[];
  bayLevelPositionsBelow: IBaySizesAndCgs[];
  minLcg: number;
  maxLcg: number;
  minVcg: number;
  maxVcg: number;
  minTcg: number;
  maxTcg: number;
  maxIsoBay: number;
  isoBaysArray: IBayPattern[];
  totalSlotsCount: number;
  cgsStats: ICGsStatistics;
}

interface ILcgVcgByTier {
  maxSize: TContainerLengths;
  lcg?: number;
  vcg?: number;
  pairedHas40?: boolean;
}

interface IMaxSizeLcg {
  size?: TContainerLengths;
  lcg?: number;
  foreLcg?: number;
  aftLcg?: number;
}

interface ITcgByTier {
  tcg?: number;
}

export interface ICGsStatistics {
  countLcgs: number;
  definedLcgs: number;
  countTcgs: number;
  definedTcgs: number;
  countBottomBase: number;
  definedBottomBase: number;
}

// #endregion Interfaces
