import { routeFns } from "../../../app/router/routes";
import { systemRights } from "@baplie-viewer2/tedivo-api-models";

export const TOGGLE_CHILDREN = "toggle-children" as const;

const MenuDefinition: IMenuItem[] = [
  {
    label: "menu:home",
    systemRights: [systemRights.NOT_LOGGED_IN],
    action: routeFns.login(),
    icon: "house",
  },
  {
    label: "menu:cloud",
    systemRights: [systemRights.ORG.ListOwnOrgFiles],
    action: routeFns.myCloud(),
    icon: "folder",
  },
  {
    label: "menu:community",
    systemRights: [systemRights.ORG.ListPublicFiles],
    action: routeFns.communityFiles(),
    iconSrc: "/assets/images/Icon_Library-Icon-color.svg",
    iconSrcDark: "/assets/images/Icon_Library-Icon-white.svg",
  },
  {
    label: "menu:files",
    action: TOGGLE_CHILDREN,
    icon: "file-earmark-plus",
    actionBase: "/" + routeFns.ovdNew().split("/")[1],
    isHiddenInReadOnly: true,
    menuItems: [
      {
        label: "menu:newOVD",
        action: routeFns.ovdNew(),
        icon: "file-plus",
        systemRights: [systemRights.USER.CreateFile],
      },
      {
        label: "menu:fileOpen",
        action: routeFns.ovdOpen(),
        icon: "file-code",
        systemRights: [systemRights.USER.CreateFile],
      },
      {
        label: "menu:fileImportStaf",
        action: routeFns.importStaf(),
        icon: "file-arrow-up",
        systemRights: [systemRights.USER.CreateFile],
      },
    ],
  },
  {
    label: "menu:organization",
    action: routeFns.myOrganization(),
    icon: "building-fill-gear",
    systemRights: [systemRights.USER.CreateUser],
  },
  {
    label: "general:domains.title",
    action: routeFns.adminDomains(),
    icon: "envelope-x",
    systemRights: [systemRights.ORG.AppAdminDomains],
  },
  {
    label: "menu:apiKeys",
    action: routeFns.apiKeys(),
    icon: "key",
    systemRights: [systemRights.USER.UserCanViewApiKeys],
  },
];

export type IMenuItem = {
  label: string;
  menuItems?: IMenuItem[];
  isHiddenInReadOnly?: boolean;
} & (
  | {
      action: typeof TOGGLE_CHILDREN;
      systemRights?: never;
      actionBase: string;
    }
  | {
      action: string;
      actionBase?: never;
      systemRights: string[];
    }
) &
  (
    | {
        icon: string;
        iconSrc?: never;
        iconSrcDark?: never;
      }
    | {
        icon?: never;
        iconSrc: string;
        iconSrcDark?: string;
      }
  );

export default MenuDefinition;
