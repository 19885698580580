/* eslint-disable @nx/enforce-module-boundaries */

import "./InteractiveGrid.scss";

import { Dragger, IDraggerDetails } from "./helpers/Dragger";
import {
  ICellName,
  ICellPositionsObject,
  ICreateGridFromConfigProps,
  IGridPartDimensions,
  ISlotCombinedPattern,
  calculateGridFromConfigDimensions,
  createGridFromConfig,
} from "@baplie-viewer2/tedivo-bay-grid-core";

import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";

export class InteractiveGrid<U> extends HTMLElement {
  width = 0;
  height = 0;
  /** Contains the coordinates of the cells */
  cellPositions: ICellPositionsObject = {};
  aboveDimensions: IGridPartDimensions | undefined;
  belowDimensions: IGridPartDimensions | undefined;
  // Main SVG holder
  svgNode: SVGSVGElement;
  // Options for the Grid. Contains widht and height of cells, ...
  gridOptions = {} as ICreateGridFromConfigProps<U>;
  dragger: Dragger;
  onSelection: undefined | ((keys: ICellName[]) => void);

  constructor() {
    super();

    this.svgNode = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "svg",
    );
    this.svgNode.setAttribute("xmlns", "http://www.w3.org/2000/svg");

    this.style.display = "inline-block";
    this.style.position = "relative";

    this.dragger = new Dragger(this, this._onDragClickSelected);
  }

  /**
   * Use this function to set the Grid options.
   * @param gridOptions
   * @returns void
   */
  initialize(gridOptions: ICreateGridFromConfigProps<U>) {
    this.gridOptions = gridOptions;

    const { width, height, cellPositions, aboveDimensions, belowDimensions } =
      calculateGridFromConfigDimensions(gridOptions);

    this.width = width;
    this.height = height;
    this.cellPositions = cellPositions;
    this.aboveDimensions = aboveDimensions;
    this.belowDimensions = belowDimensions;

    this.dragger.width = width;
    this.dragger.height = height;

    this.svgNode.setAttribute("width", `${width + 1}px`);
    this.svgNode.setAttribute("height", `${height + 1}px`);
    this.svgNode.setAttribute("viewBox", `0 0 ${width + 1} ${height + 1}`);
    this.svgNode.style.maxHeight = "65vh";
    this.svgNode.style.maxWidth = `calc(65vh * ${width / height})`;
    this.svgNode.style.overflow = "visible";

    const gridFromConfig = createGridFromConfig<U>({
      ...gridOptions,
      symbolPrefix: "editBay",
    });

    if (gridFromConfig === undefined) return;

    const { symbols, svgGroup } = gridFromConfig;

    if (symbols)
      Object.keys(symbols).forEach((n) => this.svgNode.appendChild(symbols[n]));

    this.svgNode.appendChild(svgGroup);
  }

  connectedCallback() {
    this.appendChild(this.svgNode);
  }

  disconnectedCallback() {
    this.removeChild(this.svgNode);
  }

  private _onDragClickSelected = (data: IDraggerDetails) => {
    if (this.onSelection === undefined) return;

    /** Controls for responsive SVG inside container */
    const responsiveRatio =
      this.svgNode.getBoundingClientRect().width / (this.width + 1);

    const containerWidth = this.gridOptions.containerWidth * responsiveRatio;
    const containerHeight = this.gridOptions.containerHeight * responsiveRatio;

    const cellPositions = this.cellPositions;
    const cells = Object.keys(cellPositions) as ICellName[];

    const cellsSelected = cells.filter((cell) => {
      const cellX = cellPositions[cell].x * responsiveRatio;
      const cellY = cellPositions[cell].y * responsiveRatio;
      return (
        data.startX <= cellX + containerWidth &&
        data.endX >= cellX &&
        data.startY <= cellY + containerHeight &&
        data.endY >= cellY
      );
    });

    this.onSelection(cellsSelected);
  };

  public updateCellData(
    cellsToDraw: Array<U>,
    hasCenterLineRow: 0 | 1,
    prohibitedCells: Array<ICellName> | undefined,
    enabledCellsMapper: ((dta: U) => ISlotCombinedPattern) | undefined,
    emphasizeCells: ICellName[] | undefined,
  ) {
    const enabledCells = enabledCellsMapper
      ? cellsToDraw.map(enabledCellsMapper)
      : this.gridOptions.enabledCells;

    const gridFromConfig = createGridFromConfig<U>({
      ...this.gridOptions,
      centerLineRow: hasCenterLineRow,
      enabledCells,
      prohibitedCells,
      cellsToDraw,
      symbolPrefix: "editBay",
      emphasizeCells,
    });

    if (gridFromConfig === undefined) return;

    const { cellPositions } = calculateGridFromConfigDimensions({
      ...this.gridOptions,
      centerLineRow: hasCenterLineRow,
    });
    this.cellPositions = cellPositions;

    removeChildren(this.svgNode);

    const { symbols, svgGroup } = gridFromConfig;

    if (symbols)
      Object.keys(symbols).forEach((n) => this.svgNode.appendChild(symbols[n]));

    this.svgNode.appendChild(svgGroup);
  }
}

customElements.define("tedivo-interactive-grid", InteractiveGrid);
