import { __awaiter } from "tslib";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";
import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import { cloneFileDialog } from "./cloneFileDialog";
import goSquared from "../../../app/tracking/goSquared";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import { setAppTitle } from "../../../app/app.element";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";
export class TVDViewTvlComponent extends HTMLElement {
    constructor() {
        super();
        this.titleNode = undefined;
        this.fileOrgId = undefined;
        this.fileId = undefined;
        this.fileName = undefined;
        this.setTitle = (title) => {
            if (!this.titleNode)
                return;
            this.titleNode.titleHtml = title;
        };
        this.cloneToMyAccount = (dta) => __awaiter(this, void 0, void 0, function* () {
            cloneFileDialog({
                onlyCommunity: true,
                actionLabel: "general:search.cloneItToMyAccount",
                goSquaredLabel: "TVL - Clone file",
                modal: this.cloneDialog,
                fileData: dta,
            });
            this.appendChild(this.cloneDialog);
        });
        this.btnVoteFileBtn = document.createElement("tvd-vote-tvl-component");
        this.cloneDialog = document.createElement("sl-dialog");
        const state = router.currentState;
        if (state === null || state === void 0 ? void 0 : state.vesselName)
            this.fileName = state.vesselName;
        this.i18nCR = new I18nComponentRegisterer(i18nReactive);
    }
    connectedCallback() {
        goSquared.trackPage("View TVD File");
        setAppTitle(getTranslation("general:viewTvl.title"));
        this.fileId = router.getRouteParams().id;
        this.fileOrgId = router.getRouteParams().orgId;
        if (!this.fileOrgId || !this.fileId) {
            router.navigate(routeFns.myCloud());
            return;
        }
        this.btnVoteFileBtn.fileOrgId = this.fileOrgId;
        this.btnVoteFileBtn.fileId = this.fileId;
        const divWithTitle = this.createNodes();
        const iframe = this.loadIframe(this.fileId);
        this.appendChild(divWithTitle);
        this.appendChild(iframe);
        const box = this.getBoundingClientRect();
        iframe.style.height = `calc(100vh - ${box.top + 100}px)`;
        const topEle = topMessageElement.element;
        if (topEle)
            topEle.innerHTML = getTranslation("general:tvl");
    }
    createNodes() {
        const divWithTitle = document.createElement("title-with-actions-component");
        divWithTitle.titleNodeName = "h1";
        divWithTitle.titleHtml = "general:viewTvl.title";
        this.titleNode = divWithTitle;
        const actionsNode = document.createElement("div");
        actionsNode.slot = "actions";
        actionsNode.className = "slot-actions";
        const btnClone = document.createElement("sl-button");
        const iconClone = document.createElement("sl-icon");
        iconClone.name = "cloud-download-fill";
        iconClone.slot = "prefix";
        btnClone.innerHTML = getTranslation("general:search.cloneItToMyAccount");
        btnClone.variant = "primary";
        btnClone.size = "small";
        btnClone.addEventListener("click", () => {
            this.cloneToMyAccount({
                name: this.fileName || "",
                fileId: this.fileId || "",
                organizationId: this.fileOrgId || "",
            });
        });
        btnClone.appendChild(iconClone);
        actionsNode.appendChild(btnClone);
        actionsNode.appendChild(this.btnVoteFileBtn);
        divWithTitle.appendChild(actionsNode);
        return divWithTitle;
    }
    loadIframe(cloudId) {
        let fullTitle = getTranslation("general:viewTvl.title");
        const iframe = document.createElement("iframe");
        iframe.className = "tvd-view-tvl-iframe";
        iframe.src = `${process.env.NX_TVL_URL}/redirect/${cloudId}`;
        iframe.addEventListener("load", () => {
            var _a;
            try {
                const title = (_a = iframe.contentWindow) === null || _a === void 0 ? void 0 : _a.document.body.innerHTML;
                if (title) {
                    fullTitle = `${getTranslation("general:viewTvl.title")}: ${title}`;
                    this.setTitle(fullTitle);
                    setAppTitle(fullTitle);
                }
            }
            catch (e) {
                console.warn("Error getting title from iframe", e);
            }
        });
        return iframe;
    }
}
TVDViewTvlComponent.observedAttributes = [];
customElements.define("tvl-view-component", TVDViewTvlComponent);
