import {
  ShoelaceVariant,
  createSelectShoelace,
} from "../select/select-shoelace";

/**
 * Creates an Edit button with multiple options and actions
 */
export function createMultiEditButton({
  id,
  className,
  options,
  title,
  size = "small",
  showTitleAsTooltip = true,
  iconName = "pencil",
  variant,
  outlined,
  placement,
  disabled,
}: ICreateMultiEditButtonProps) {
  const editBtn = createSelectShoelace({
    id,
    caret: false,
    size: size || "small",
    pill: true,
    iconPrefix: iconName || "pencil",
    buttonText: showTitleAsTooltip ? "" : title || "",
    className,
    options,
    ommitCheckSign: true,
    variant,
    outlined,
    placement,
    disabled,

    onChange: (v) => {
      // Don't use strict equality because options return as strings and values might be numbers
      const action = options.find((o) => o.value == v)?.action;
      if (action) action();
    },
  });

  if (title && showTitleAsTooltip) {
    const tooltip = document.createElement("sl-tooltip");
    tooltip.placement = "left";
    tooltip.content = title;

    tooltip.appendChild(editBtn);
    return tooltip;
  } else {
    return editBtn;
  }
}

interface ICreateMultiEditButtonProps {
  id: string;
  className?: string;
  title?: string;
  showTitleAsTooltip?: boolean;
  iconName?: string;
  /** Options props */
  options: IOptionsWithActions[];
  size?: "small" | "medium" | "large";
  variant?: ShoelaceVariant;
  outlined?: boolean;
  disabled?: boolean;
  placement?:
    | "top"
    | "top-start"
    | "top-end"
    | "bottom"
    | "bottom-start"
    | "bottom-end"
    | "right"
    | "right-start"
    | "right-end"
    | "left"
    | "left-start"
    | "left-end";
}

type IOptionsWithActions =
  | {
      divider?: never;
      name: string;
      value: string | number;
      action: () => void;
    }
  | {
      divider: boolean;
      name?: never;
      value?: never;
      action?: never;
    };
