import { __awaiter } from "tslib";
import "./notifications-element.scss";
import { filterNotificationsByType, } from "@baplie-viewer2/tedivo-api-models";
import { formatDateInCurrentTimezone, objectIsTruthy, } from "@baplie-viewer2/tedivo-pure-helpers";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";
import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import Services from "../../../app/services";
import goSquared from "../../../app/tracking/goSquared";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/app.element";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";
export class TVDNotificationsListComponent extends HTMLElement {
    constructor() {
        super();
        this.lastFilter = undefined;
        this.i18nCR = new I18nComponentRegisterer(i18nReactive);
        this.holder = document.createElement("div-spinner-element");
        this.titleWithActions = document.createElement("title-with-actions-component");
        this.titleWithActions.titleNodeName = "h1";
        this.dialogHistory = document.createElement("sl-dialog");
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            goSquared.trackPage("Notifications");
            goSquared.addEvent("Notifications - Show page");
            setAppTitle(getTranslation("general:notifications.title"));
            {
                this.dialogHistory.setAttribute("style", "--width: 80vw");
                this.dialogHistory.label = getTranslation("view:edit.fileHistory.title");
            }
            if (topMessageElement.element)
                topMessageElement.element.innerText =
                    securityModule.currentOrganizationName;
            const titleWithActions = this.titleWithActions;
            titleWithActions.titleHtml = "general:notifications.title";
            this.appendChild(titleWithActions);
            const holder = this.holder;
            holder.classList.add("hs-margin");
            this.appendChild(holder);
            holder.setLoading(true);
            yield this.displayNotifications();
            holder.setLoading(false);
        });
    }
    displayNotifications() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const holder = this.holder;
            const notificationsList = yield Services.notifications.getAll();
            removeChildren(holder);
            // No notifications text
            const noNotificationsText = document.createElement("div");
            holder.appendChild(noNotificationsText);
            noNotificationsText.style.display = "none";
            this.i18nCR.addConsumer(noNotificationsText, "general:notifications.noNotifications", "innerHTML");
            if (!((_a = notificationsList.data) === null || _a === void 0 ? void 0 : _a.length)) {
                noNotificationsText.style.display = "block";
                return;
            }
            const nodes = yield Promise.all([
                displayFilesSourceChanged(this.i18nCR, notificationsList.data),
                displayFilesConsumersChanged(this.i18nCR, notificationsList.data),
                displayFilesCloned(this.i18nCR, notificationsList.data),
                displayFilesVoted(this.i18nCR, notificationsList.data),
            ]);
            nodes.filter(objectIsTruthy).forEach((n) => holder.appendChild(n));
        });
    }
    disconnectedCallback() {
        this.i18nCR.disconnect();
    }
}
TVDNotificationsListComponent.observedAttributes = [];
customElements.define("tvd-notifications-list-component", TVDNotificationsListComponent);
function createSectionNodes(i18nCR, title) {
    const section = document.createElement("section");
    section.classList.add("notifications-section");
    const heading = document.createElement("h3");
    i18nCR.addConsumer(heading, title, "innerHTML");
    //section.appendChild(heading);
    const box = document.createElement("div");
    box.classList.add("oss-card");
    box.appendChild(heading);
    section.appendChild(box);
    return { section, box };
}
function displayFilesSourceChanged(i18nCR, notificationsList) {
    const filesChanged = notificationsList
        .filter((d) => d.type === "FileCloneDiffers")
        .map((d) => d.data);
    if (!filesChanged.length)
        return undefined;
    const { section, box } = createSectionNodes(i18nCR, "general:notifications.fileCloneDiffers.title");
    const ul = document.createElement("div");
    ul.className = "link-3-spans";
    box.appendChild(ul);
    filesChanged.sort(sortByDateFiles).forEach((f) => {
        const li = document.createElement("div");
        li.className = "notification-row";
        ul.appendChild(li);
        const linkOwn = document.createElement("link-element");
        linkOwn.url = routeFns.ovdEdit(f.fileId);
        linkOwn.text = f.name;
        const dateSpan = document.createElement("span");
        dateSpan.className = "date";
        const span1 = document.createElement("span");
        const span2 = document.createElement("span");
        span1.innerHTML = [f.imoCode || "?", f.callSign || "?"].join(" / ");
        dateSpan.innerHTML = formatDateInCurrentTimezone(f.lastModified);
        const link = document.createElement("link-element");
        link.url = routeFns.fileCompareTvlSource(f.fileId);
        link.className = "link";
        span2.appendChild(link);
        i18nCR.addConsumer(link, "general:notifications.fileCloneDiffers.action", "text");
        ul.appendChild(li);
        li.appendChild(dateSpan);
        li.appendChild(linkOwn);
        li.appendChild(span1);
        li.appendChild(span2);
    });
    return section;
}
function displayFilesConsumersChanged(i18nCR, notificationsList) {
    const filesCloned = notificationsList.filter((d) => filterNotificationsByType(d, ["FileConsumerDiffers"]));
    if (!filesCloned.length)
        return undefined;
    const { section, box } = createSectionNodes(i18nCR, "general:notifications.fileConsumerDiffers.title");
    const ul = document.createElement("div");
    ul.className = "link-3-spans";
    box.appendChild(ul);
    filesCloned.sort(sortByDateNotifs).forEach((f) => {
        const data = f.data;
        const li = document.createElement("div");
        li.className = "notification-row";
        ul.appendChild(li);
        const linkOwn = document.createElement("link-element");
        linkOwn.url = routeFns.ovdEdit(data.sourceFileId);
        linkOwn.text = data.shipName;
        const dateSpan = document.createElement("span");
        dateSpan.className = "date";
        const span1 = document.createElement("span");
        const span2 = document.createElement("span");
        span1.innerHTML = [data.imoCode || "?", data.callSign || "?"].join(" / ");
        dateSpan.innerHTML = formatDateInCurrentTimezone(f.date);
        const link = document.createElement("link-element");
        link.url = routeFns.fileCompareConsumer(f.data.sourceFileId, f.data.consumerOrgId, f.data.consumerFileId);
        link.className = "link";
        span2.appendChild(link);
        i18nCR.addConsumer(link, "general:notifications.fileCloneDiffers.action", "text");
        ul.appendChild(li);
        li.appendChild(dateSpan);
        li.appendChild(linkOwn);
        li.appendChild(span1);
        li.appendChild(span2);
    });
    return section;
}
function displayFilesCloned(i18nCR, notificationsList) {
    const filesCloned = notificationsList.filter((d) => filterNotificationsByType(d, ["FileCloned"]));
    if (!filesCloned.length)
        return undefined;
    const { section, box } = createSectionNodes(i18nCR, "general:notifications.filesCloned.title");
    const ul = document.createElement("div");
    ul.className = "link-3-spans";
    box.appendChild(ul);
    filesCloned.sort(sortByDateNotifs).forEach((f) => {
        const data = f.data;
        const li = document.createElement("div");
        li.className = "notification-row";
        const link = document.createElement("link-element");
        link.url = routeFns.ovdEdit(data.fileId);
        link.text = data.shipName;
        const dateSpan = document.createElement("span");
        dateSpan.className = "date";
        const span1 = document.createElement("span");
        const span2 = document.createElement("span");
        span1.innerHTML = [data.imoCode || "?", data.callSign || "?"].join(" / ");
        dateSpan.innerHTML = formatDateInCurrentTimezone(f.date);
        span2.innerHTML = `<em>${getTranslation("general:notifications.filesCloned.downloadedBy")}</em> ${data.orgName}`;
        ul.appendChild(li);
        li.appendChild(dateSpan);
        li.appendChild(link);
        li.appendChild(span1);
        li.appendChild(span2);
    });
    return section;
}
function displayFilesVoted(i18nCR, notificationsList) {
    const filesCloned = notificationsList.filter((d) => filterNotificationsByType(d, ["UpvotedFile", "RemovedVoteFile"]));
    if (!filesCloned.length)
        return undefined;
    const { section, box } = createSectionNodes(i18nCR, "general:notifications.filesVoted.title");
    const ul = document.createElement("div");
    ul.className = "link-3-spans";
    box.appendChild(ul);
    filesCloned.sort(sortByDateNotifs).forEach((f) => {
        const data = f.data;
        const li = document.createElement("div");
        li.className = "notification-row";
        const link = document.createElement("link-element");
        link.url = routeFns.ovdEdit(data.fileId);
        link.text = data.shipName;
        const dateSpan = document.createElement("span");
        dateSpan.className = "date";
        const span1 = document.createElement("span");
        const span2 = document.createElement("span");
        span1.innerHTML = [data.imoCode || "?", data.callSign || "?"].join(" / ");
        dateSpan.innerHTML = formatDateInCurrentTimezone(f.date);
        span2.innerHTML = `<em>${getTranslation(`general:notifications.filesVoted.${f.type === "UpvotedFile" ? "upVotedBy" : "downVotedBy"}`)}</em> ${data.orgName}`;
        ul.appendChild(li);
        li.appendChild(dateSpan);
        li.appendChild(link);
        li.appendChild(span1);
        li.appendChild(span2);
    });
    return section;
}
function sortByDateNotifs(a, b) {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
}
function sortByDateFiles(a, b) {
    return (new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime());
}
