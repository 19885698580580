import { systemRights } from "@baplie-viewer2/tedivo-api-models";

export const routeFns = {
  home: () => "/",
  login: (returnUrl?: string, setMfa?: boolean) =>
    `${returnUrl ? `/login?returnUrl=${returnUrl}` : `/login`}${
      setMfa ? `#setMfa` : ``
    }`,
  myProfile: (setMfa?: boolean) => `/my-profile${setMfa ? `#setMfa` : ``}`,
  adminDomains: () => `/domains`,
  adminOrganizations: () => `/admin/organizations`,
  adminOrganizationUsers: (id: string) => `/admin/organizations/${id}`,
  apiKeys: () => `/api-keys`,
  changePassword: () => `/change-password`,
  communityFiles: () => "/community-files",
  deleteMyAccount: () => `/delete-my-account`,
  deleteMyOrgAccount: () => `/delete-my-org-account`,
  editDetails: () => `/edit-details`,
  error: () => "/error",
  fileCompareTvlSource: (id: string) =>
    `${routeFns.myCloud()}/files-compare-source/${id}`,
  fileCompareOwnVersion: (id: string, version: string) =>
    `${routeFns.myCloud()}/files-compare-version/${id}/${version}`,
  fileCompareConsumer: (
    sourceFileId: string,
    consumerOrgId: string,
    consumerFileId: string,
  ) =>
    `${routeFns.myCloud()}/files-compare-consumer/${sourceFileId}/${consumerOrgId}/${consumerFileId}`,
  fileMap: (id: string) => `${routeFns.myCloud()}/file-map/${id}`,
  forgotPassword: () => `/forgot-password`,
  importStaf: () => "/ovd/import-staf",
  myCloud: () => "/my-cloud",
  myOrganization: () => `/my-organization`,
  newAccount: () => `/create-account`,
  notificationsList: () => `/notifications`,
  ovdEdit: (id: string) => `${routeFns.myCloud()}/edit/${id}`,
  ovdNew: () => "/ovd/new",
  ovdOpen: () => "/ovd/open",
  ovdViewOnly: (id: string) => `${routeFns.communityFiles()}/view/${id}`,
  ovdViewOnlyVersion: (id: string, version: string) =>
    `${routeFns.myCloud()}/view-version/${id}/${version}`,
  theirCloud: (orgId: string) => `/their-cloud/${orgId}`,
  verifyAddress: () => `/verify-address`,
  viewTvl: (orgId: string, fileId: string) =>
    `${routeFns.communityFiles()}/view-tvl/${orgId}/${fileId}`,
};

export const routes: {
  [name: string]: {
    securityRight: string;
    componentName: keyof HTMLElementTagNameMap;
    componentTitle?: string;
    hideMenu?: boolean;
  };
} = {
  [routeFns.home()]: {
    securityRight: systemRights.ANONYMOUS,
    componentName: "tvd-welcome-component",
  },
  [routeFns.error()]: {
    securityRight: systemRights.ANONYMOUS,
    componentName: "tvd-error-component",
  },
  [routeFns.login()]: {
    securityRight: systemRights.ANONYMOUS,
    componentName: "tvd-auth-login-component",
  },
  [routeFns.myProfile()]: {
    securityRight: systemRights.LOGGED_IN,
    componentName: "tvd-auth-profile-component",
  },
  [routeFns.newAccount()]: {
    securityRight: systemRights.ANONYMOUS,
    componentName: "tvd-auth-create-account-component",
  },
  [routeFns.verifyAddress()]: {
    securityRight: systemRights.ANONYMOUS,
    componentName: "tvd-auth-verify-address-component",
  },
  [routeFns.myCloud()]: {
    securityRight: systemRights.ORG.ListPublicFiles,
    componentName: "tvd-my-cloud-component",
    componentTitle: "menu:myCloud",
  },
  [routeFns.theirCloud(":orgId")]: {
    securityRight: systemRights.ORG.AppAdminListAllFiles,
    componentName: "tvd-their-cloud-component",
    componentTitle: "menu:myCloud",
  },
  [routeFns.ovdNew()]: {
    securityRight: systemRights.ORG.EditOwnOrgFiles,
    componentName: "tvd-new-json-component",
    componentTitle: "menu:newOVD",
  },
  [routeFns.ovdOpen()]: {
    securityRight: systemRights.ORG.EditOwnOrgFiles,
    componentName: "tvd-open-json-component",
    componentTitle: "menu:fileOpen",
  },
  [routeFns.fileMap(":id")]: {
    securityRight: systemRights.ORG.EditOwnOrgFiles,
    componentName: "tvd-filemap-component",
    componentTitle: "view:fileMap.pageTitle",
  },
  [routeFns.importStaf()]: {
    securityRight: systemRights.ORG.EditOwnOrgFiles,
    componentName: "tvd-import-staf-component",
    componentTitle: "menu:fileImportStaf",
  },
  [routeFns.ovdEdit(":id")]: {
    securityRight: systemRights.ORG.EditOwnOrgFiles,
    componentName: "tvd-view-json-component",
    componentTitle: "view:pageTitleEdit",
  },
  [routeFns.ovdViewOnly(":id")]: {
    securityRight: systemRights.ORG.EditOwnOrgFiles,
    componentName: "tvd-view-json-component",
    componentTitle: "view:pageTitleView",
  },
  [routeFns.ovdViewOnlyVersion(":id", ":version")]: {
    securityRight: systemRights.ORG.EditOwnOrgFiles,
    componentName: "tvd-view-json-component",
    componentTitle: "view:pageTitleViewVersion",
  },
  [routeFns.adminOrganizations()]: {
    securityRight: systemRights.ORG.AppAdminCrudOrganization,
    componentName: "tvd-admin-orgs-component",
    componentTitle: "general:organizations.organizations",
  },
  [routeFns.adminOrganizationUsers(":id")]: {
    securityRight: systemRights.ORG.AppAdminCrudOrganizationUser,
    componentName: "tvd-admin-orgs-users-component",
    componentTitle: "general:organizations.organizations",
  },
  [routeFns.adminDomains()]: {
    securityRight: systemRights.ORG.AppAdminCrudOrganization,
    componentName: "tvd-admin-domains-component",
    componentTitle: "general:domains.title",
  },
  [routeFns.myOrganization()]: {
    securityRight: systemRights.USER.CreateUser,
    componentName: "tvd-my-organization-component",
    componentTitle: "menu:organization",
  },
  [routeFns.deleteMyAccount()]: {
    securityRight: systemRights.USER.CanDeleteOwnUserAccount,
    componentName: "tvd-delete-account-component",
    componentTitle: "menu:organization",
  },
  [routeFns.deleteMyOrgAccount()]: {
    securityRight: systemRights.USER.CanDeleteOrgAccount,
    componentName: "tvd-delete-org-account-component",
    componentTitle: "menu:organization",
  },
  [routeFns.editDetails()]: {
    securityRight: systemRights.USER.UpdateMyOwnUser,
    componentName: "tvd-edit-details-component",
    componentTitle: "menu:organization",
  },
  [routeFns.changePassword()]: {
    securityRight: systemRights.USER.UpdateMyOwnUser,
    componentName: "tvd-change-password-component",
    componentTitle: "menu:organization",
  },
  [routeFns.forgotPassword()]: {
    securityRight: systemRights.ANONYMOUS,
    componentName: "tvd-forgot-password-component",
    componentTitle: "menu:organization",
  },
  [routeFns.apiKeys()]: {
    securityRight: systemRights.USER.UserCanViewApiKeys,
    componentName: "tvd-apikeys-component",
    componentTitle: "menu:apiKeys",
  },
  [routeFns.communityFiles()]: {
    securityRight: systemRights.ORG.ListPublicFiles,
    componentName: "tvd-community-component",
    componentTitle: "menu:community",
  },
  [routeFns.fileCompareTvlSource(":id")]: {
    securityRight: systemRights.ORG.EditOwnOrgFiles,
    componentName: "tvd-comparer-component",
    componentTitle: "menu:fileImportStaf",
  },
  [routeFns.fileCompareOwnVersion(":id", ":version")]: {
    securityRight: systemRights.ORG.EditOwnOrgFiles,
    componentName: "tvd-comparer-component",
    componentTitle: "menu:fileImportStaf",
  },
  [routeFns.fileCompareConsumer(":id", ":consumerOrgId", ":consumerFileId")]: {
    securityRight: systemRights.ORG.EditOwnOrgFiles,
    componentName: "tvd-comparer-component",
    componentTitle: "menu:fileImportStaf",
  },
  [routeFns.notificationsList()]: {
    securityRight: systemRights.USER.UserCanViewNotifications,
    componentName: "tvd-notifications-list-component",
    componentTitle: "menu:notifications",
  },
  [routeFns.viewTvl(":orgId", ":id")]: {
    securityRight: systemRights.ORG.ViewPublicFiles,
    componentName: "tvl-view-component",
  },
};

export const routesKeys = Object.keys(routes);
export default routes;
