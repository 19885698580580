import SlRadio from "@shoelace-style/shoelace/dist/components/radio/radio";
import SlRadioGroup from "@shoelace-style/shoelace/dist/components/radio-group/radio-group";
import { addStyles } from "../../helpers/addStyles";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";

export class TfRadioGroup extends HTMLElement {
  public static observedAttributes = ["class"];

  private internalRadioGroup: SlRadioGroup;
  private internalRadios: { [name: string]: SlRadio } = {};
  private itemsInfo: Array<IItem> = [];
  private attached = false;
  private internalValue: string | number | undefined = undefined;
  private internalLabel?: string = undefined;
  private internalHelpText?: string = undefined;
  private internalDisabled = false;
  private internalFieldset = false;
  private internalSingleColumn = false;

  public isNumericEnum = false;

  constructor() {
    super();
    this.internalRadioGroup = document.createElement("sl-radio-group");
    this.internalRadioGroup.className = "sl-radio-group";
    this.internalRadioGroup.setAttribute(
      "exportparts",
      "form-control form-control-label form-control-input help-text",
    );

    this.internalRadioGroup.addEventListener(
      "sl-change",
      this.onCheckboxClicked,
      false,
    );
  }

  get name() {
    return this.internalRadioGroup.id || "";
  }
  set name(n: string) {
    this.setAttribute("id", n);
    this.setAttribute("name", n);
    this.internalRadioGroup.id = n;
  }

  get label() {
    return this.internalLabel;
  }
  set label(v: string | undefined) {
    this.internalLabel = v;
    this.internalRadioGroup.label = `${this.internalLabel ?? ""} ${
      this.internalHelpText ? ` (${this.internalHelpText})` : ""
    }`;
  }

  get helpText() {
    return this.internalHelpText;
  }
  set helpText(v: string | undefined) {
    this.internalHelpText = v;
    this.internalRadioGroup.label = `${this.internalLabel ?? ""} ${
      this.internalHelpText ? ` (${this.internalHelpText})` : ""
    }`;
  }

  get singleColumn() {
    return this.internalSingleColumn;
  }
  set singleColumn(v: boolean) {
    this.internalSingleColumn = v;

    if (v && !this.internalRadioGroup.classList.contains("single-column"))
      this.internalRadioGroup.classList.add("single-column");
    else this.internalRadioGroup.classList.remove("single-column");
  }

  get tabIndex() {
    return this.internalRadioGroup.tabIndex;
  }

  set tabIndex(v: number) {
    this.internalRadioGroup.tabIndex = v;
  }

  get size() {
    return "medium";
  }
  set size(_s: "small" | "medium" | "large") {
    //Nothings
  }

  get fieldset() {
    return this.internalFieldset;
  }
  set fieldset(v: boolean) {
    if (v && !this.internalRadioGroup.classList.contains("with-fieldset"))
      this.internalRadioGroup.classList.add("with-fieldset");
    else this.internalRadioGroup.classList.remove("with-fieldset");

    this.internalFieldset = v;
  }

  get items() {
    return this.itemsInfo;
  }

  set items(v: IItem[]) {
    this.itemsInfo = v;
    if (!this.attached) return;

    removeChildren(this.internalRadioGroup);
    this.internalRadios = {};

    v.forEach((itemInfo) => {
      const radio = document.createElement("sl-radio");
      radio.innerHTML = itemInfo.label;
      radio.value = String(itemInfo.value);
      this.internalRadioGroup.appendChild(radio);
      this.internalRadios[String(itemInfo.value)] = radio;
    });

    if (this.internalValue !== undefined) {
      this.setOption(String(this.internalValue));
    }

    this.disabled = this.internalDisabled;
  }

  get value(): string | number {
    return this.isNumericEnum
      ? Number(this.internalValue)
      : String(this.internalValue);
  }
  set value(s: string | number) {
    this.internalValue = s;
    this.setOption(String(s));
  }

  get valueAsNumber() {
    return Number(this.internalValue);
  }
  set valueAsNumber(s: number) {
    this.internalValue = Number(s);
    this.setOption(String(s));
  }

  get disabled() {
    return this.internalDisabled;
  }
  set disabled(s: boolean) {
    this.internalDisabled = s;
    Object.keys(this.internalRadios).forEach((rad) => {
      this.internalRadios[rad].disabled = s;
    });
  }

  focus() {
    if (this.itemsInfo) {
      this.internalRadios[String(this.itemsInfo[0].value)].focus();
    }
  }

  connectedCallback() {
    this.attached = true;

    const shadow = this.attachShadow({ mode: "open" });
    shadow.appendChild(addStyles(styles));
    shadow.appendChild(this.internalRadioGroup);

    this.items = this.itemsInfo;
  }

  disconnectedCallback() {
    this.attached = false;
  }

  private setOption(value: string) {
    if (!this.attached) return;
    this.internalRadioGroup.value = value;
  }

  private onCheckboxClicked = (ev: Event) => {
    const target = ev.target as SlRadioGroup;
    this.internalValue = target.value;
    // this.dispatchEvent(new Event("sl-change"));
  };

  attributeChangedCallback(attrName: string, oldVal: string, newVal: string) {
    switch (attrName) {
      case "class":
        if (newVal.indexOf("has-error") >= 0) {
          if (!this.internalRadioGroup.classList.contains("has-error"))
            this.internalRadioGroup.classList.add("has-error");
        } else {
          this.internalRadioGroup.classList.remove("has-error");
        }
    }
  }
}

customElements.define("tf-radio-group", TfRadioGroup);

declare global {
  interface HTMLElementTagNameMap {
    "tf-radio-group": TfRadioGroup;
  }
}

const styles = `
 
  sl-radio { 
    display: inline-flex;
    margin-right: 2em;
  }

  .with-fieldset::part(form-control) {
    border: solid var(--sl-panel-border-width) var(--sl-panel-border-color);
    border-radius: var(--sl-border-radius-medium);
    padding: var(--sl-spacing-large);
    padding-top: var(--sl-spacing-x-small);
  }
  
  .with-fieldset::part(form-control-label) {
    font-family: var(--sl-input-font-family);
    font-size: var(--sl-input-font-size-medium);
    font-weight: var(--sl-input-font-weight);
    color: var(--sl-input-label-color);
    padding: 0;
    margin-bottom: var(--sl-spacing-small) !important;
  }

  .single-column::part(form-control-input) {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
`;

interface IItem {
  value: string | number;
  label: string;
}
