import {
  IBayLevelData,
  ILidData,
  IShipData,
  ISizeSummary,
  ISlotData,
} from "open-vessel-definition";
import {
  ICellName,
  INodesOptions,
  IReturnDrawFunction,
  calculateGridFromConfigDimensions,
  createSlotCapabilitiesSvg,
  getBaySlots,
} from "@baplie-viewer2/tedivo-bay-grid-core";
import {
  gridOptionsByCellDimension,
  gridOptionsByCellGap,
  slotOptionsByCellDimension,
} from "./consts";

import CellSpacingEnum from "../../../../app/enums/CellSpacingEnum";
import EditDrawer from "../../../common/EditDrawer";
import { IValidationResult } from "../../../../app/stores/validators/types";
import SizeSmallMidLargeEnum from "../../../../app/enums/SizeSmallMidLargeEnum";
import { TNumberPadded3 } from "@baplie-viewer2/tedivo-pure-helpers";
import { openBayEdit } from "./edits/openBayEdit";

export function createBayBoxes(
  bls: IBayLevelData[],
  sizeSummary: ISizeSummary,
  shipData: IShipData,
  lidData: Array<ILidData>,
  cellSize: SizeSmallMidLargeEnum,
  cellSeparation: CellSpacingEnum,
  editDrawer: EditDrawer,
  validationResult: IValidationResult[],
  readOnlyMode: boolean,
  canOpenDrawer: boolean,
): HTMLElement {
  const ssMaxRow = sizeSummary.maxRow;
  if (ssMaxRow === undefined || ssMaxRow === null) {
    return document.createElement("div");
  }

  const baysWrapper = document.createElement("div");
  baysWrapper.className = `bays-wrapper cSize-${SizeSmallMidLargeEnum[cellSize]} cSep-${CellSpacingEnum[cellSeparation]}`;

  const root = getComputedStyle(document.body);
  const nodesOptions: INodesOptions = {
    gridNumbersTextColor: root.getPropertyValue("--sl-color-neutral-500"),
    lineColor: root.getPropertyValue("--sl-color-neutral-300"),
    boxDisabledBackgroundColor: root.getPropertyValue("--sl-color-neutral-200"),
    boxDisabledBorderColor: root.getPropertyValue("--sl-color-neutral-200"),
    boxProhibitedBorderColor: root.getPropertyValue("--sl-color-neutral-300"),
    boxBackgroundColor: root.getPropertyValue("--sl-color-neutral-0"),
    boxBorderColor: root.getPropertyValue("--sl-color-neutral-300"),
    lidBackgroundColor: root.getPropertyValue("--sl-color-primary-100"),
    lidBorderColor: root.getPropertyValue("--sl-color-primary-400"),
    emphasizeLineColor: root.getPropertyValue("--sl-color-neutral-600"),
  };

  const { width, height } = calculateGridFromConfigDimensions<ISlotData>({
    ...sizeSummary,
    maxRow: ssMaxRow,
    ...gridOptionsByCellDimension[cellSize],
    cellSeparation: gridOptionsByCellGap[cellSeparation],
  });

  getUniqueBays(bls).forEach((isoBay) => {
    const bayBoxComp = document.createElement("bay-box-component");
    bayBoxComp.setAttribute("id", `bay-box-component-${isoBay}`);

    bayBoxComp
      .setSize(width, height)
      .setEditDrawer(editDrawer)
      .setData({
        sizeSummary,
        shipData,
        lidData,
        isoBay,
        width,
        height,
        ssMaxRow,
        nodesOptions,
        cellSize,
        cellSeparation,
        createFnSlotCell,
        openBayEdit,
        ...getBaySlots(isoBay, bls),
        validationResult,
        readOnlyMode,
        canOpenDrawer,
      });

    baysWrapper.appendChild(bayBoxComp);
  });

  function createFnSlotCell(cellSize: SizeSmallMidLargeEnum) {
    return function createSlotCell(slotData: ISlotData): IReturnDrawFunction {
      const svgG = createSlotCapabilitiesSvg({
        ...slotOptionsByCellDimension[cellSize],
        slotData,
        centered: true,
      });

      const p = slotData.pos;
      const pos = `${p.substring(0, 2)}|${p.substring(2)}` as ICellName;

      return {
        node: svgG,
        pos,
      };
    };
  }

  return baysWrapper;
}

function getUniqueBays(bls: IBayLevelData[]): TNumberPadded3[] {
  return bls
    .map((bl) => bl.isoBay)
    .filter((v, idx, arr) => arr.indexOf(v) === idx);
}
