import { __awaiter } from "tslib";
import HttpClient from "../HttpClient";
const apiKeysBaseUrl = "apiKeys";
const ApiKeysService = {
    getAll: () => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "GET",
            url: apiKeysBaseUrl,
        });
    }),
    get: (id) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "GET",
            url: `${apiKeysBaseUrl}/${id}`,
        });
    }),
    delete: (id) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "DELETE",
            url: `${apiKeysBaseUrl}/${id}`,
            data: undefined,
        });
    }),
    create: (data) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "PUT",
            url: apiKeysBaseUrl,
            data,
        });
    }),
    update: (data) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "PUT",
            url: apiKeysBaseUrl,
            data,
        });
    }),
};
export default ApiKeysService;
