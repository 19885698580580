/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  IFields,
  TedivoForm,
  translateTedivoForm,
} from "@baplie-viewer2/tedivo-form";

import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import SlButton from "@shoelace-style/shoelace/dist/components/button/button";
import topMessageElement from "../layout/top-tools/getTopMessageElement";
import { z } from "zod";

export type TCreateScreenInput<T extends Record<string, any>> = {
  fields: IFields<T>;
  onSubmitDataForm: (values: T) => void;
  formValidator: z.Schema<T>;
  i18nCR: I18nComponentRegisterer;
  h1Text: string;
  h1Node?: HTMLElement;
  titleText?: string;
  formId?: string;
  submitButton?: SlButton | HTMLButtonElement;
};

export function createScreen<T extends Record<string, any>>({
  fields,
  onSubmitDataForm,
  formValidator,
  i18nCR,
  h1Text,
  titleText = h1Text,
  h1Node,
  formId = "",
  submitButton,
}: TCreateScreenInput<T>): IFormAndNode<T> {
  const h1 = h1Node || document.createElement("h1");
  const wrapper = document.createElement("div");

  const box = document.createElement("div");
  box.className = "oss-card";

  if (submitButton === undefined) {
    submitButton = document.createElement("sl-button");
    submitButton.type = "submit";
    submitButton.variant = "primary";
  }

  const tedivoForm = new TedivoForm<T>({
    fields: fields,
    onSubmit: onSubmitDataForm,
    formValidator: formValidator,
    submitButton: submitButton,
    autoFocusOnFirstInput: true,
    formProps: { id: formId },
  });

  translateTedivoForm<T>({
    tedivoForm,
    i18nCR: i18nCR,
  });

  tedivoForm.form.appendChild(submitButton);
  box.appendChild(tedivoForm.form);

  wrapper.appendChild(h1);
  wrapper.appendChild(box);

  if (h1Text) i18nCR.addConsumer(h1, h1Text, "innerHTML");
  i18nCR.addConsumer(topMessageElement.element, titleText, "innerHTML");
  i18nCR.addConsumer(submitButton, "general:common.submit", "innerHTML");

  return {
    node: wrapper,
    form: tedivoForm,
    submitButton: submitButton as SlButton,
  };
}

export interface IFormAndNode<T extends Record<string, any>> {
  node: HTMLElement;
  form?: TedivoForm<T>;
  submitButton?: SlButton;
}
