import { __awaiter } from "tslib";
import { createScreen } from "../../helpers/createScreen";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";
import DivWithSpinner from "../../common/divWithSpinner/div-with-spinner-element";
import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import IntegratedDialogError from "../../common/IntegratedDialogError";
import Services from "../../../app/services";
import goSquared from "../../../app/tracking/goSquared";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/app.element";
import { z } from "zod";
export class TVDEditDetailsComponent extends HTMLElement {
    constructor() {
        super();
        this.showError = (e) => {
            this.dialogError.show(getTranslation(`errors:errorHasOcurred`), getTranslation(typeof e === "string" ? e : e.translationKey));
        };
        /** Replaces the UI */
        this.setCurrentFormAndNode = (fan) => {
            this.currentFormAndNode = fan;
            if (fan === null || fan === void 0 ? void 0 : fan.node) {
                this.divWithSpinner.setLoading(false);
                removeChildren(this.divWithSpinner);
                this.divWithSpinner.appendChild(fan.node);
            }
        };
        this.onSubmitEditDetailsForm = (values) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            goSquared.addEvent("Edit my details - Submit");
            this.divWithSpinner.setLoading(true);
            const data = {
                name: values.name,
                familyName: values.familyName,
                email: values.email || "",
            };
            const resp = yield Services.accounts.editMyDetails(data);
            if (!((_a = resp.data) === null || _a === void 0 ? void 0 : _a.id)) {
                this.showError({
                    errorCode: String(resp.statusCode),
                    message: resp.code || "errorModifyingUser",
                    translationKey: resp.message || "errors:errorModifyingUser",
                });
                this.divWithSpinner.setLoading(false);
                return false;
            }
            else {
                securityModule.updateUserDetails(values.name, values.familyName, values.email || "", values.allowMarketing || false);
                goSquared.identify({
                    email: values.email || "",
                    name: `${values.name || ""} ${values.familyName || ""}`,
                    organizationName: securityModule.currentOrganizationName || "",
                    marketingMailsConsent: values.allowMarketing || false,
                    organizationPlanId: (securityModule.currentOrganizationPlanId ||
                        "FREE"),
                    expirationDate: securityModule.planExpirationDate || "",
                });
                router.navigate(routeFns.login());
                return true;
            }
        });
        this.i18nCR = new I18nComponentRegisterer(i18nReactive);
        this.dialogError = new IntegratedDialogError(this);
        this.divWithSpinner = new DivWithSpinner();
        this.mainBox = document.createElement("div");
        this.mainBox.className = "oss-card";
        setAppTitle(getTranslation("auth:editDetails"));
    }
    connectedCallback() {
        return __awaiter(this, void 0, void 0, function* () {
            goSquared.trackPage("Edit my details");
            goSquared.addEvent("Edit my details - Show page");
            this.appendChild(this.divWithSpinner);
            this.divWithSpinner.setLoading(true);
            const myUserDetails = yield Services.accounts.getMyDetails();
            this.divWithSpinner.setLoading(false);
            if (!myUserDetails.data) {
                router.navigate(routeFns.login());
            }
            else {
                const { name, familyName, email, marketing } = myUserDetails.data || {};
                this.setCurrentFormAndNode(createOrgUserEdit(this.onSubmitEditDetailsForm, this.i18nCR, {
                    name: name || securityModule.name,
                    familyName: familyName || securityModule.familyName,
                    email,
                    allowMarketing: marketing || false,
                }, securityModule.orgAllowedDomains || []));
            }
        });
    }
    disconnectedCallback() {
        this.i18nCR.disconnect();
    }
}
TVDEditDetailsComponent.observedAttributes = [];
customElements.define("tvd-edit-details-component", TVDEditDetailsComponent);
function createOrgUserEdit(onSubmitDataForm, i18nCR, prevData, allowedDomains) {
    const formValidator = z
        .object({
        name: z.string().min(1),
        familyName: z.string().min(1),
        allowMarketing: z.boolean(),
        email: z
            .string()
            .email()
            .transform((v) => v === null || v === void 0 ? void 0 : v.toLowerCase()),
    })
        .refine((data) => {
        if (data.email) {
            const domain = (data.email.split("@")[1] || "").toLowerCase().trim();
            if (allowedDomains.indexOf(domain) < 0)
                return false;
            return true;
        }
        else
            return false;
    }, {
        path: ["email"],
    });
    const formFields = [
        [
            {
                name: "name",
                label: "general:common.name",
                type: "textBox",
                initialValue: (prevData === null || prevData === void 0 ? void 0 : prevData.name) || "",
            },
            {
                name: "familyName",
                label: "general:users.familyName",
                type: "textBox",
                initialValue: (prevData === null || prevData === void 0 ? void 0 : prevData.familyName) || "",
            },
        ],
        {
            name: "email",
            label: "general:common.email",
            type: "textBox",
            initialValue: (prevData === null || prevData === void 0 ? void 0 : prevData.email) || "",
            helpText: getTranslation("general:users.domainsHelpText", {
                domains: allowedDomains.join(", ") || "",
            }),
        },
        {
            name: "allowMarketing",
            label: "general:users.allowMarketing",
            type: "checkbox",
            initialValue: (prevData === null || prevData === void 0 ? void 0 : prevData.allowMarketing) || false,
        },
    ];
    return createScreen({
        fields: formFields,
        onSubmitDataForm,
        formValidator,
        i18nCR,
        h1Text: "auth:editDetails",
        titleText: "menu:organization",
    });
}
