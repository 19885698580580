import { ShoelaceVariant } from "@baplie-viewer2/tedivo-form";

export default function createNotification(
  message: string,
  variant: ShoelaceVariant = "primary",
  icon = "info-circle",
  duration = 3000,
) {
  const alert = Object.assign(document.createElement("sl-alert"), {
    variant,
    closable: true,
    duration: duration,
  });

  if (icon) {
    const iconEl = document.createElement("sl-icon");
    iconEl.name = icon;
    iconEl.slot = "icon";
    alert.appendChild(iconEl);
  }

  const sp = document.createElement("span");
  sp.innerHTML = message;

  alert.appendChild(sp);

  document.body.append(alert);

  return alert.toast();
}

export const createErrorNotification = (messge: string, duration = 5000) => {
  return createNotification(messge, "danger", "exclamation-octagon", duration);
};

export const createSuccessNotification = (messge: string, duration = 5000) => {
  return createNotification(messge, "success", "check2-circle", duration);
};

export const createWarningNotification = (messge: string, duration = 5000) => {
  return createNotification(
    messge,
    "warning",
    "exclamation-triangle",
    duration,
  );
};
