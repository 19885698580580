export const systemRights = {
  ANONYMOUS: "",
  NOT_LOGGED_IN: "NOT_LOGGED_IN",
  LOGGED_IN: "LOGGED_IN",
  USER: {
    CreateFile: "U0",
    ChangeFileStatusToDraft: "U1",
    ChangeFileStatusToOrganization: "U2",
    ChangeFileStatusToCommunity: "U3",
    BuyCredits: "U4",
    CreateUser: "U5",
    DeleteUser: "U6",
    UpdateUser: "U7",
    UpdateMyOwnUser: "U8",
    SetUserAsAdmin: "U9",
    SetUserAsSuperAdmin: "U10",
    CanDisableOthersMfa: "U11",
    ChangeOwnPassword: "U12",
    UserDownloadPublicFile: "U13",
    UserCanCreateApiKeys: "U14",
    UserCanViewApiKeys: "U15",
    CanChangeOrganizationName: "U16",
    CanDeleteOwnUserAccount: "U17",
    CanDeleteOrgAccount: "U18",
    CanRequestMergeOrganizations: "U19",
    CanAcceptMergeOrganizations: "U20",
    UserCanViewNotifications: "U21",
  },
  ORG: {
    ListPublicFiles: "O1",
    ListOtherOrgFiles: "O2",
    ListOwnOrgFiles: "O3",
    ViewPublicFiles: "O4",
    ViewOtherOrgFiles: "O5",
    ViewOwnOrgFiles: "O6",
    EditOwnOrgFiles: "O7",
    PublishCommunityFile: "O8",
    PublishOrganizationFile: "O9",
    DownloadOwnOrgFileAsStaf: "O10",
    DownloadOwnOrgFileAsOvd: "O11",
    CanDownloadPublicFile: "O12",
    UpVotePublicFile: "O13",
    AppAdminListAllFiles: "O14",
    AppAdminViewAnyFile: "O15",
    AppAdminCrudOrganization: "O16",
    AppAdminCrudOrganizationUser: "O17",
    AppAdminDomains: "O18",
    CanCreateMoreThanOneUser: "O19",
    IsTedivoAdmin: "O20",
    CanVotePublicFiles: "O21",
    CanSeePublicFileOrgName: "O22",
  },
};

export type TSecurityRights = typeof systemRights;
export type TKeySecurityRights = RecursiveKeyOf<TSecurityRights>;

export const ROLE_APP_ADMIN_RIGHTS = [
  systemRights.ORG.ListPublicFiles,
  systemRights.ORG.ListOtherOrgFiles,
  systemRights.ORG.ListOwnOrgFiles,
  systemRights.ORG.ViewPublicFiles,
  systemRights.ORG.ViewOtherOrgFiles,
  systemRights.ORG.ViewOwnOrgFiles,
  systemRights.ORG.EditOwnOrgFiles,
  systemRights.ORG.DownloadOwnOrgFileAsStaf,
  systemRights.ORG.DownloadOwnOrgFileAsOvd,
  systemRights.ORG.PublishOrganizationFile,
  systemRights.ORG.PublishCommunityFile,
  systemRights.ORG.UpVotePublicFile,
  systemRights.ORG.AppAdminListAllFiles,
  systemRights.ORG.AppAdminViewAnyFile,
  systemRights.ORG.AppAdminCrudOrganization,
  systemRights.ORG.AppAdminCrudOrganizationUser,
  systemRights.ORG.AppAdminDomains,
  systemRights.ORG.CanCreateMoreThanOneUser,
  systemRights.ORG.IsTedivoAdmin,
  systemRights.ORG.CanVotePublicFiles,
  systemRights.ORG.CanDownloadPublicFile,
  systemRights.ORG.CanSeePublicFileOrgName,
  systemRights.USER.CreateFile,
  systemRights.USER.ChangeFileStatusToDraft,
  systemRights.USER.ChangeFileStatusToOrganization,
  systemRights.USER.ChangeFileStatusToCommunity,
  systemRights.USER.BuyCredits,
  systemRights.USER.CreateUser,
  systemRights.USER.DeleteUser,
  systemRights.USER.UpdateUser,
  systemRights.USER.SetUserAsAdmin,
  systemRights.USER.SetUserAsSuperAdmin,
  systemRights.USER.ChangeOwnPassword,
  systemRights.USER.UserDownloadPublicFile,
  systemRights.USER.UpdateMyOwnUser,
  systemRights.USER.UserCanCreateApiKeys,
  systemRights.USER.UserCanViewApiKeys,
  systemRights.USER.CanChangeOrganizationName,
  systemRights.USER.CanRequestMergeOrganizations,
  systemRights.USER.CanAcceptMergeOrganizations,
  systemRights.USER.CanDisableOthersMfa,
  systemRights.USER.UserCanViewNotifications,
];

export const ROLE_ORG_PUBLIC_RIGHTS = [
  systemRights.ORG.ListPublicFiles,
  systemRights.ORG.ListOtherOrgFiles,
  systemRights.ORG.ViewPublicFiles,
];

export const ROLE_ORG_FREE_RIGHTS = [
  systemRights.ORG.ListPublicFiles,
  systemRights.ORG.ListOtherOrgFiles,
  systemRights.ORG.ListOwnOrgFiles,
  systemRights.ORG.ViewPublicFiles,
  systemRights.ORG.ViewOwnOrgFiles,
  systemRights.ORG.EditOwnOrgFiles,
  systemRights.ORG.DownloadOwnOrgFileAsStaf,
  systemRights.ORG.DownloadOwnOrgFileAsOvd,
  systemRights.ORG.CanDownloadPublicFile,
  systemRights.ORG.CanCreateMoreThanOneUser,
  systemRights.ORG.PublishCommunityFile,
];

export const ROLE_ORG_PAID1_RIGHTS = [
  systemRights.ORG.ListPublicFiles,
  systemRights.ORG.ListOtherOrgFiles,
  systemRights.ORG.ListOwnOrgFiles,
  systemRights.ORG.ViewPublicFiles,
  systemRights.ORG.ViewOwnOrgFiles,
  systemRights.ORG.EditOwnOrgFiles,
  systemRights.ORG.DownloadOwnOrgFileAsStaf,
  systemRights.ORG.DownloadOwnOrgFileAsOvd,
  systemRights.ORG.UpVotePublicFile,
  systemRights.ORG.PublishCommunityFile,
  systemRights.ORG.CanCreateMoreThanOneUser,
  systemRights.ORG.CanVotePublicFiles,
  systemRights.ORG.CanDownloadPublicFile,
  systemRights.ORG.CanSeePublicFileOrgName,
];

export const ROLE_ORG_PAID2_RIGHTS = [
  systemRights.ORG.ListPublicFiles,
  systemRights.ORG.ListOtherOrgFiles,
  systemRights.ORG.ListOwnOrgFiles,
  systemRights.ORG.ViewPublicFiles,
  systemRights.ORG.ViewOwnOrgFiles,
  systemRights.ORG.EditOwnOrgFiles,
  systemRights.ORG.DownloadOwnOrgFileAsStaf,
  systemRights.ORG.DownloadOwnOrgFileAsOvd,
  systemRights.ORG.UpVotePublicFile,
  systemRights.ORG.PublishOrganizationFile,
  systemRights.ORG.PublishCommunityFile,
  systemRights.ORG.CanCreateMoreThanOneUser,
  systemRights.ORG.CanVotePublicFiles,
  systemRights.ORG.CanDownloadPublicFile,
  systemRights.ORG.CanSeePublicFileOrgName,
];

export const ROLE_USER_SUPERADMIN = [
  systemRights.USER.CreateFile,
  systemRights.USER.ChangeFileStatusToDraft,
  systemRights.USER.ChangeFileStatusToOrganization,
  systemRights.USER.ChangeFileStatusToCommunity,
  systemRights.USER.BuyCredits,
  systemRights.USER.CreateUser,
  systemRights.USER.DeleteUser,
  systemRights.USER.UpdateUser,
  systemRights.USER.SetUserAsAdmin,
  systemRights.USER.SetUserAsSuperAdmin,
  systemRights.USER.ChangeOwnPassword,
  systemRights.USER.UserDownloadPublicFile,
  systemRights.USER.UpdateMyOwnUser,
  systemRights.USER.UserCanCreateApiKeys,
  systemRights.USER.UserCanViewApiKeys,
  systemRights.USER.CanChangeOrganizationName,
  systemRights.USER.CanDeleteOwnUserAccount,
  systemRights.USER.CanDeleteOrgAccount,
  systemRights.USER.CanRequestMergeOrganizations,
  systemRights.USER.CanAcceptMergeOrganizations,
  systemRights.USER.CanDisableOthersMfa,
  systemRights.USER.UserCanViewNotifications,
];

export const ROLE_USER_ADMIN = [
  systemRights.USER.CreateFile,
  systemRights.USER.ChangeFileStatusToDraft,
  systemRights.USER.ChangeFileStatusToOrganization,
  systemRights.USER.ChangeFileStatusToCommunity,
  systemRights.USER.BuyCredits,
  systemRights.USER.CreateUser,
  systemRights.USER.DeleteUser,
  systemRights.USER.UpdateUser,
  systemRights.USER.SetUserAsAdmin,
  systemRights.USER.ChangeOwnPassword,
  systemRights.USER.UserDownloadPublicFile,
  systemRights.USER.UpdateMyOwnUser,
  systemRights.USER.UserCanViewApiKeys,
  systemRights.USER.CanChangeOrganizationName,
  systemRights.USER.CanDeleteOwnUserAccount,
  systemRights.USER.UserCanViewNotifications,
];

export const ROLE_USER_BASIC = [
  systemRights.USER.CreateFile,
  systemRights.USER.ChangeFileStatusToDraft,
  systemRights.USER.ChangeFileStatusToOrganization,
  systemRights.USER.ChangeFileStatusToCommunity,
  systemRights.USER.UpdateUser,
  systemRights.USER.ChangeOwnPassword,
  systemRights.USER.UserDownloadPublicFile,
  systemRights.USER.UpdateMyOwnUser,
  systemRights.USER.CanDeleteOwnUserAccount,
  systemRights.USER.UserCanViewNotifications,
];

// From https://stackoverflow.com/questions/65332597/typescript-is-there-a-recursive-keyof
type RecursiveKeyOf<TObj> = {
  [TKey in keyof TObj & (string | number)]: RecursiveKeyOfHandleValue<
    TObj[TKey],
    `${TKey}`
  >;
}[keyof TObj & (string | number)];

type RecursiveKeyOfInner<TObj extends object> = {
  [TKey in keyof TObj & (string | number)]: RecursiveKeyOfHandleValue<
    TObj[TKey],
    `['${TKey}']` | `.${TKey}`
  >;
}[keyof TObj & (string | number)];

type RecursiveKeyOfHandleValue<
  TValue,
  Text extends string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
> = TValue extends any[]
  ? Text
  : TValue extends object
  ? Text | `${Text}${RecursiveKeyOfInner<TValue>}`
  : Text;
