import { IOrganizationsCognitoAttributeAugmented } from "@baplie-viewer2/tedivo-api-models";

export interface ICognitoEventClaimsBase {
  // Cognito claims
  sub: string;
  name: string;
  family_name: string;
  email: string;
  email_verified: "true" | "false";
  updated_at: string;
  "cognito:groups": string | string[];
  "cognito:username": string;
  "custom:marketing": "true" | "false";
  organizations: string;

  // Standard claims (Token)
  aud: string;
  auth_time: number;
  origin_jti: string;
  iss: string;
  event_id: string;
  token_use: "id" | "access";
  exp: number;
  iat: number;
  jti: string;
}

export interface ICognitoEventClaimsForFE {
  // Cognito claims
  sub: string;
  name: string;
  family_name: string;
  email: string;
  email_verified: boolean;
  updated_at: string;
  "cognito:groups": string[];
  "cognito:username": string;
  "custom:marketing": boolean;
  organizations: Array<IOrganizationsCognitoAttributeAugmented>;

  // Standard claims (Token)
  aud: string;
  auth_time: Date;
  origin_jti: string;
  iss: string;
  event_id: string;
  token_use: "id" | "access";
  exp: Date;
  iat: Date;
  jti: string;
}

export function parseCognitoClaimsForFe(
  inp: ICognitoEventClaimsBase,
): ICognitoEventClaimsForFE {
  const groups = inp["cognito:groups"]
    ? Array.isArray(inp["cognito:groups"])
      ? inp["cognito:groups"]
      : inp["cognito:groups"].split(",")
    : [];

  return {
    sub: inp.sub,
    name: inp.name,
    family_name: inp.family_name,
    email: inp.email,
    email_verified: inp.email_verified === "true",
    updated_at: inp.updated_at,
    "cognito:groups": groups,
    "cognito:username": inp["cognito:username"],
    "custom:marketing": inp["custom:marketing"] === "true",
    aud: inp.aud,
    auth_time: new Date(inp.auth_time * 1000),
    origin_jti: inp.origin_jti,
    iss: inp.iss,
    event_id: inp.event_id,
    token_use: inp.token_use,
    exp: new Date(inp.exp * 1000),
    iat: new Date(inp.iat * 1000),
    jti: inp.jti,
    organizations: JSON.parse(inp.organizations),
  };
}
