/* eslint-disable @typescript-eslint/no-explicit-any */
import { __awaiter } from "tslib";
import { AuthenticationDetails, CognitoUser, CognitoUserPool, } from "amazon-cognito-identity-js";
import { parseCognitoClaimsForFe, } from "./ICognitoEventClaims";
import { errorTracking } from "../tracking/errorTracking";
import { getPreferencesValue } from "@baplie-viewer2/tedivo-preferences";
import goSquared from "../tracking/goSquared";
/**
 * Class for AWSCognito API calls
 */
class TvdAwsCognito extends EventTarget {
    get isMfaEnabled() {
        return this._mfaEnabled;
    }
    get poolData() {
        return {
            UserPoolId: this.configUserPoolId,
            ClientId: this.configClientId,
        };
    }
    constructor() {
        super();
        this.currentCognitoSession = undefined;
        this.currentEmail = undefined;
        this.previousEmail = undefined;
        this._cognitoEventClaims = undefined;
        this._currentOrgSecurityData = undefined;
        this._userEditableDetails = {
            name: "",
            familyName: "",
            email: "",
        };
        this.jwtToken = undefined;
        this.accessTokenInternal = undefined;
        this.cognitoUserInstance = undefined;
        this.refreshToken = undefined;
        this.refreshTokenState = "idle";
        this.tempAcceptsMarketingMails = false;
        this.orgUsersBySub = undefined;
        this._mfaEnabled = undefined;
        this.setMfaFlagAsDisabled = () => {
            this._mfaEnabled = undefined;
        };
        this.configUserPoolId = process.env.NX_USER_POOL_ID || "";
        this.configClientId = process.env.NX_USER_APP_CLIENT_ID || "";
        // #endregion
        // private get accountExpirationDate(): Date | undefined {
        //   const expiresAt =
        //     this._cognitoEventClaims?.orgAdminOptions?.subscription?.expiresAt;
        //   if (typeof expiresAt === "string") return new Date(expiresAt as string);
        //   else if (expiresAt) return expiresAt;
        //   return undefined;
        // }
        this.changeCurrentOrganization = (orgId) => {
            var _a, _b;
            if (orgId === ((_a = this._currentOrgSecurityData) === null || _a === void 0 ? void 0 : _a.orgId))
                return false;
            const orgRel = (_b = this._cognitoEventClaims) === null || _b === void 0 ? void 0 : _b.organizations.find((o) => o.orgId === orgId && o.uEnabled === "1");
            if (orgRel) {
                this._currentOrgSecurityData = orgRel;
                window.setTimeout(() => {
                    this.dispatchEvent(new CustomEvent("userDataRetrieved", { detail: orgRel }));
                }, 0);
                return true;
            }
            else {
                console.error("Unable to set invalid organization", orgId);
                return false;
            }
        };
        this.changePassword = (username, oldPassword, newPassword) => {
            return new Promise((resolve, reject) => {
                const cognitoUser = this.createCognitoUserInstance(username);
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const callback = (err, _success) => {
                    if (err) {
                        reject(false);
                    }
                    else {
                        resolve(true);
                    }
                };
                cognitoUser.changePassword(oldPassword, newPassword, callback);
            });
        };
        this.associateMfaDevice = ({ associateSecretCode, onFailure, }) => {
            if (!this.cognitoUserInstance) {
                return false;
            }
            this.cognitoUserInstance.associateSoftwareToken({
                associateSecretCode,
                onFailure,
            });
            return true;
        };
        /**
         * This is called when the user has entered the MFA code for the first time and we need to verify it
         * @param code
         * @returns
         */
        this.verifyAndFinishMfaDeviceAssociation = (code) => {
            return new Promise((resolve) => {
                if (!this.cognitoUserInstance) {
                    return Promise.resolve(false);
                }
                this.cognitoUserInstance.verifySoftwareToken(code, getDeviceName(), {
                    onSuccess: (session) => __awaiter(this, void 0, void 0, function* () {
                        const res = session.Status === "SUCCESS";
                        if (res) {
                            yield this.enableMfa(true);
                            resolve(true);
                        }
                        else {
                            resolve(false);
                        }
                    }),
                    onFailure: (err) => {
                        resolve(false);
                        console.log(err);
                    },
                });
            });
        };
        this.enableMfa = (enable) => {
            return new Promise((resolve) => {
                var _a;
                (_a = this.cognitoUserInstance) === null || _a === void 0 ? void 0 : _a.setUserMfaPreference(null, { PreferredMfa: enable, Enabled: enable }, (err) => {
                    console.log({ err });
                    resolve(!err);
                });
            });
        };
        this.verifyTopTCode = (code, username) => {
            return new Promise((resolve) => {
                if (!this.cognitoUserInstance) {
                    return Promise.resolve(false);
                }
                const cognitoUser = this.createCognitoUserInstance(username);
                this.cognitoUserInstance.sendMFACode(code, this.createAuthCallbacks(cognitoUser, username, resolve), "SOFTWARE_TOKEN_MFA");
            });
        };
        this.createAuthCallbacks = (cognitoUser, username, resolve) => {
            return {
                onSuccess: (session) => {
                    cognitoUser.setSignInUserSession(session);
                    this.currentEmail = username;
                    this.currentCognitoSession = session;
                    this.previousEmail = username;
                    const accessToken = session.getAccessToken().getJwtToken();
                    const idToken = session.getIdToken().getJwtToken();
                    /* Use the idToken for Logins Map when Federating User Pools with identity pools or when passing through an Authorization Header to an API Gateway Authorizer */
                    this.jwtToken = idToken;
                    this.refreshToken = session.getRefreshToken();
                    this.accessTokenInternal = session.getAccessToken().getJwtToken();
                    if (this.processCognitoSessionClaimsForFE()) {
                        this.dispatchEvent(new CustomEvent("authenticationChanged", {
                            detail: { isLoggedIn: true },
                        }));
                        this.getMfaOptions();
                        resolve({ code: "ok", accessToken, idToken });
                    }
                    else {
                        this._mfaEnabled = undefined;
                        this.dispatchEvent(new CustomEvent("authenticationChanged", {
                            detail: { isLoggedIn: false },
                        }));
                        resolve({ code: "failure", error: "Failed to compose user data" });
                    }
                },
                onFailure: (err) => {
                    console.log("onFailure", err);
                    if (err && err.code === "UserLambdaValidationException") {
                        err.message = err.message.split("enabled=").pop().replace(/\.$/, "");
                    }
                    resolve({ code: "failure", error: err });
                },
                newPasswordRequired: (userAttrs, reqUserAttrs) => {
                    resolve({ code: "newPasswordRequired", userAttrs, reqUserAttrs });
                },
                customChallenge: (params) => {
                    resolve({ code: "customChallenge", params });
                },
                totpRequired: (challengeName, challengeParameters) => {
                    resolve({
                        code: "totpRequired",
                        challengeName,
                        challengeParameters,
                    });
                },
            };
        };
        const stage = process.env.NX_STAGE;
        if (stage === "dev" || stage === "alpha")
            window.awsCognito = this;
    }
    createCognitoUserInstance(username, force = false) {
        if (!this.cognitoUserInstance || force) {
            const userPool = new CognitoUserPool(this.poolData);
            const userData = { Username: username, Pool: userPool };
            this.cognitoUserInstance = new CognitoUser(userData);
        }
        return this.cognitoUserInstance;
    }
    get isLoggedIn() {
        return !!this.currentCognitoSession;
    }
    get userSub() {
        var _a;
        return (_a = this._cognitoEventClaims) === null || _a === void 0 ? void 0 : _a.sub;
    }
    get email() {
        return this.currentEmail;
    }
    get userMarketingMails() {
        var _a;
        return ((_a = this._cognitoEventClaims) === null || _a === void 0 ? void 0 : _a["custom:marketing"]) || false;
    }
    get userEditableDetails() {
        return Object.assign({}, this._userEditableDetails);
    }
    // #region Auth tokens info
    get sessionExpires() {
        var _a;
        const dtStr = (_a = this._cognitoEventClaims) === null || _a === void 0 ? void 0 : _a.exp;
        return dtStr ? new Date(dtStr) : null;
    }
    get idToken() {
        return this.jwtToken;
    }
    get accessToken() {
        return this.accessTokenInternal;
    }
    // #endregion
    // #region Current Organization data
    get currentOrganizationId() {
        var _a;
        return (_a = this._currentOrgSecurityData) === null || _a === void 0 ? void 0 : _a.orgId;
    }
    get currentOrgSecurityData() {
        return this._currentOrgSecurityData;
    }
    get orgAllowedDomains() {
        var _a;
        return (((_a = this._currentOrgSecurityData) === null || _a === void 0 ? void 0 : _a.domains) || "").split(",") || [];
    }
    get orgMfaForAllUsers() {
        var _a;
        return ((_a = this._currentOrgSecurityData) === null || _a === void 0 ? void 0 : _a.mfaAll) === "1" || false;
    }
    set orgMfaForAllUsers(v) {
        if (!this._currentOrgSecurityData)
            return;
        this._currentOrgSecurityData.mfaAll = v ? "1" : "0";
    }
    get isLoginInfoComplete() {
        return !!this._cognitoEventClaims;
    }
    get allOrganizationsInfo() {
        var _a;
        return ((_a = this._cognitoEventClaims) === null || _a === void 0 ? void 0 : _a.organizations) || [];
    }
    /**
     * This is only used the first time the browser loads, to see if there is an existing valid session
     */
    checkIfUserIsLoggedIn() {
        return new Promise((resolve, reject) => {
            const userPool = new CognitoUserPool(this.poolData);
            const userFromPool = userPool.getCurrentUser();
            if (!userFromPool) {
                this.dispatchEvent(new CustomEvent("authenticationChanged", {
                    detail: { isLoggedIn: false },
                }));
                return resolve(false);
            }
            try {
                const userData = { Username: userFromPool.username, Pool: userPool };
                const cognitoUser = new CognitoUser(userData);
                cognitoUser.getSession((_error, session) => {
                    if (session) {
                        // Resolve false if expired
                        if (!session.isValid()) {
                            this.clearSession();
                            resolve(false);
                            return;
                        }
                        this.currentCognitoSession = session;
                        this.cognitoUserInstance = cognitoUser;
                        this.jwtToken = session.getIdToken().getJwtToken();
                        this.accessTokenInternal = session.getAccessToken().getJwtToken();
                        this.refreshToken = session.getRefreshToken();
                        if (userFromPool.userDataKey) {
                            const dataString = localStorage.getItem(userFromPool.userDataKey);
                            if (dataString) {
                                this._cognitoEventClaims = JSON.parse(dataString);
                            }
                        }
                        this.getMfaOptions();
                        if (this.processCognitoSessionClaimsForFE()) {
                            this.dispatchEvent(new CustomEvent("authenticationChanged", {
                                detail: { isLoggedIn: true },
                            }));
                            resolve(true);
                        }
                        else {
                            this.dispatchEvent(new CustomEvent("authenticationChanged", {
                                detail: { isLoggedIn: false },
                            }));
                            resolve(false);
                        }
                    }
                    else {
                        this.currentCognitoSession = undefined;
                        this.dispatchEvent(new CustomEvent("authenticationChanged", {
                            detail: { isLoggedIn: false },
                        }));
                        resolve(false);
                    }
                });
            }
            catch (e) {
                this.dispatchEvent(new CustomEvent("authenticationChanged", {
                    detail: { isLoggedIn: false },
                }));
                reject(e);
            }
        });
    }
    /**
     * Authenticates a user in Cognito
     */
    authenticateUser(username, password) {
        return new Promise((resolve) => {
            const authenticationData = {
                Username: username,
                Password: password,
            };
            if (!authenticationData.Username || !authenticationData.Password) {
                resolve({ code: "loginMissingData" });
                return;
            }
            const authenticationDetails = new AuthenticationDetails(authenticationData);
            const cognitoUser = this.createCognitoUserInstance(username, this.previousEmail !== username);
            cognitoUser.authenticateUser(authenticationDetails, this.createAuthCallbacks(cognitoUser, username, resolve));
        });
    }
    setNewPassword(username, newPassword) {
        return new Promise((resolve, reject) => {
            if (!username) {
                reject({ code: "notLoggedIn" });
                return;
            }
            const cognitoUser = this.createCognitoUserInstance(username);
            cognitoUser.completeNewPasswordChallenge(newPassword, [], this.createAuthCallbacks(cognitoUser, username, resolve));
        });
    }
    updateUserDetails(name, familyName, email, marketingMails = undefined) {
        if (!this._cognitoEventClaims)
            return;
        this._userEditableDetails.name = name;
        this._userEditableDetails.familyName = familyName;
        if (email)
            this._userEditableDetails.email = email;
        if (marketingMails !== undefined)
            this._cognitoEventClaims["custom:marketing"] = marketingMails;
    }
    processCognitoSessionClaimsForFE() {
        var _a, _b;
        const idTokenAccessor = (_a = this.currentCognitoSession) === null || _a === void 0 ? void 0 : _a.getIdToken();
        const isValid = (_b = this.currentCognitoSession) === null || _b === void 0 ? void 0 : _b.isValid();
        const idTokenPayload = idTokenAccessor === null || idTokenAccessor === void 0 ? void 0 : idTokenAccessor.payload;
        if (!idTokenPayload || !isValid) {
            this._cognitoEventClaims = undefined;
            return false; // -> Fast Return. No valid session
        }
        const cognitoClaimsForFE = parseCognitoClaimsForFe(idTokenPayload);
        this._cognitoEventClaims = cognitoClaimsForFE;
        this.tempAcceptsMarketingMails = undefined;
        this.updateUserDetails(cognitoClaimsForFE.name, cognitoClaimsForFE.family_name, cognitoClaimsForFE.email, undefined);
        if (cognitoClaimsForFE.organizations.length === 1) {
            const defaultOrgId = cognitoClaimsForFE.organizations[0].orgId;
            this.changeCurrentOrganization(defaultOrgId);
        }
        else {
            const preferredOrgId = getPreferencesValue("preferredOrgId");
            if (preferredOrgId)
                this.changeCurrentOrganization(preferredOrgId);
        }
        goSquared.identify({
            email: cognitoClaimsForFE.email,
            name: `${cognitoClaimsForFE.name} ${cognitoClaimsForFE.family_name}`,
            marketingMailsConsent: this.tempAcceptsMarketingMails !== undefined
                ? this.tempAcceptsMarketingMails
                : cognitoClaimsForFE["custom:marketing"],
        });
        return true;
    }
    tryToRefreshToken() {
        return new Promise((resolve) => {
            if (!this.currentCognitoSession || !this.cognitoUserInstance) {
                this.refreshTokenState = "idle";
                resolve(false);
                return;
            }
            this.refreshTokenState = "refreshing";
            if (this.refreshToken !== undefined) {
                this.cognitoUserInstance.refreshSession(this.refreshToken, (err, session) => {
                    var _a;
                    console.log("refreshSession", err, session);
                    if (err) {
                        this.signOut();
                        resolve(false);
                    }
                    else {
                        this.currentCognitoSession = session;
                        this.jwtToken = session.getIdToken().getJwtToken();
                        this.refreshToken = session.getRefreshToken();
                        this.accessTokenInternal = session.getAccessToken().getJwtToken();
                        this.processCognitoSessionClaimsForFE();
                        console.log({
                            newsessionExpires: (_a = this.sessionExpires) === null || _a === void 0 ? void 0 : _a.toISOString(),
                        });
                        resolve(true);
                    }
                    this.refreshTokenState = "idle";
                });
            }
            else {
                resolve(false);
            }
        });
    }
    signOut() {
        return __awaiter(this, void 0, void 0, function* () {
            return new Promise((resolve) => {
                const sOutCb = () => {
                    this.clearSession();
                    resolve(true);
                };
                if (this.currentCognitoSession && this.cognitoUserInstance) {
                    this.cognitoUserInstance.globalSignOut({
                        onSuccess: () => {
                            sOutCb();
                        },
                        onFailure: (err) => {
                            errorTracking.leaveBreadcrumb("Cognito signOut error", err);
                            sOutCb();
                        },
                    });
                }
                else {
                    sOutCb();
                }
            });
        });
    }
    clearSession() {
        this.orgUsersBySub = undefined;
        this._cognitoEventClaims = undefined;
        this.currentCognitoSession = undefined;
        this.currentEmail = undefined;
        this.cognitoUserInstance = undefined;
        this._currentOrgSecurityData = undefined;
        errorTracking.leaveBreadcrumb("Cognito session cleared");
        goSquared.unIdentify();
        setTimeout(() => {
            this.dispatchEvent(new CustomEvent("authenticationChanged", {
                detail: { isLoggedIn: false },
            }));
            this.dispatchEvent(new CustomEvent("sessionTimedOut"));
        }, 250);
    }
    /** If the user is active, try to refresh the Tokens 20 minutes before the session ends */
    initiateRefreshToken() {
        var _a;
        const MIN_REFRESH_TIME = 20 * 60 * 1000; // 20 minutes before session expires, try to refresh the token if criteria is met
        if (!((_a = this.currentCognitoSession) === null || _a === void 0 ? void 0 : _a.isValid())) {
            this.signOut();
            return;
        }
        const sessionTimesOutInMilliseconds = this.sessionExpires.getTime() - Date.now();
        const remaining = sessionTimesOutInMilliseconds - MIN_REFRESH_TIME;
        if (remaining > 0 || this.refreshTokenState === "refreshing") {
            return;
        }
        if (sessionTimesOutInMilliseconds < 0) {
            this.signOut();
            return;
        }
        this.tryToRefreshToken();
    }
    /** Update the name (temporarily) to show the correct Organization Name */
    updateOrganizationName(organizationName) {
        if (!this.currentOrgSecurityData)
            return;
        this.currentOrgSecurityData.name = organizationName;
    }
    /** Ask Cognito back-end and register MFA internally */
    getMfaOptions() {
        if (!this.cognitoUserInstance) {
            return Promise.resolve(false);
        }
        this.cognitoUserInstance.getUserData((err, data) => {
            if (err || !data) {
                console.log(err);
                this.dispatchEvent(new CustomEvent("sessionTimedOut"));
                return;
            }
            this._mfaEnabled = data.PreferredMfaSetting;
        });
    }
}
/**
 * Singleton of TvdAwsCognito, an API to interact with Cognito Pool
 */
const awsCognito = new TvdAwsCognito();
export default awsCognito;
function getDeviceName() {
    return `TVD${process.env.NX_STAGE === "prod" ? "" : `-${process.env.NX_STAGE}`}`;
}
