import { EnumHelpers } from "@baplie-viewer2/tedivo-pure-helpers";
import { IFields } from "@baplie-viewer2/tedivo-form";
import { IStafImportFields } from "./import-staf.component";
import LenUnitsEnum from "../../../app/enums/LenUnitsEnum";
import VcgVerticalReferenceEnum from "../../../app/enums/VcgVerticalReferenceEnum";
import { getTranslation } from "../../../app/i18/i18tn";
import globalUnits from "../../../app/units/globalUnits";
import { z } from "zod";

export function createFormValidator({
  withLpp = false,
  withNameFields = false,
}: {
  withLpp?: boolean;
  withNameFields?: boolean;
} = {}): z.Schema<IStafImportFields> {
  let validator = z
    .object({
      fileInput: z
        .any()
        .refine((f) => f !== undefined, { message: "FileIsRequired" }),
      imoCode: z
        .string()
        .min(6)
        .max(9)
        .transform((v) => v?.toUpperCase())
        .refine(
          (imoCode) =>
            /^\d{6,8}$/.test(imoCode) || /^[A-Z]{1,2}\d{6,7}$/.test(imoCode),
        ),
      lineOperator: z
        .string()
        .min(withNameFields ? 1 : 0)
        .transform((v) => v?.toUpperCase()),
      shipClass: z
        .string()
        .min(withNameFields ? 1 : 0)
        .transform((v) => v?.toUpperCase()),
      shipName: z
        .string()
        .min(withNameFields ? 1 : 0)
        .transform((v) => v?.toUpperCase()),
      vcgRef: z.number().optional(),
      yearBuilt: z.number().optional(),
      callSign: z.string().optional(),
      aboveTier82is: z.number().min(68).optional(),
      removeCGs: z.boolean().optional(),
      lpp: z.number().optional(),
      loa: z.number().optional(),
    })
    .refine(
      (data) => {
        if (!withLpp || data.removeCGs) return true;

        if (!data.lpp) return false;

        if (data.lpp && data.loa) return data.lpp < data.loa;

        return true;
      },
      {
        path: ["lpp"],
      },
    );

  return validator;
}

export function createFormFields({
  imoCode,
  fileLabel = "general:openStaf.fileLabel",
  lppFromApi = 0,
  loaFromApi = 0,
  withLpp = false,
  withNameFields = undefined,
}: {
  imoCode: string;
  fileLabel?: string;
  lppFromApi?: number;
  loaFromApi?: number;
  withLpp?: boolean;
  withNameFields?: {
    lineOperator: string;
    shipClass: string;
    shipName: string;
  };
}): IFields<IStafImportFields> {
  const vcgRefOptions = EnumHelpers.getNamesAndValues(
    VcgVerticalReferenceEnum,
  ).map(({ value, name }) => ({
    value,
    label: getTranslation(`enums:VcgVerticalReferenceEnum.${name}`),
  }));

  // Common
  const fields: IFields<IStafImportFields> = [
    {
      name: "",
      type: "title",
      label: "view:importStaf.file",
      createSection: true,
    },
    {
      name: "fileInput",
      label: fileLabel,
      helpText: "general:openStaf.browseButtonText",
      type: "file",
      accept: ".txt",
    },
    {
      name: "imoCode",
      label: "view:imoCode",
      type: "textBox",
      autocapitalize: true,
      initialValue: imoCode || "",
      helpText: "view:imoCodeHelp",
    },
  ];

  if (withNameFields) {
    fields.push(
      {
        name: "",
        type: "title",
        label: "view:importStaf.shipName",
        createSection: true,
      },
      [
        {
          name: "shipName",
          label: "view:shipName",
          type: "textBox",
          autocapitalize: true,
          initialValue: withNameFields.shipName || "",
        },
        {
          name: "lineOperator",
          label: "view:lineOperator",
          type: "textBox",
          autocapitalize: true,
          initialValue: withNameFields.lineOperator || "",
        },
        {
          name: "shipClass",
          label: "view:shipClass",
          type: "textBox",
          autocapitalize: true,
          initialValue: withNameFields.shipClass || "",
        },
      ],
      [
        {
          name: "callSign",
          label: "view:callSign",
          type: "textBox",
          autocapitalize: true,
        },
        {
          name: "yearBuilt",
          label: "view:yearBuilt",
          type: "number",
        },
      ],
      {
        name: "",
        type: "title",
        label: "view:importStaf.references",
        createSection: true,
      },
      {
        label: "view:vcgReference",
        name: "vcgRef",
        type: "radioButtonList",
        fieldset: true,
        isNumericEnum: true,
        options: vcgRefOptions,
        initialValue: VcgVerticalReferenceEnum.VCG_45_8_6,
      },
      {
        label: "general:importStaf.remapAboveTiers",
        name: "aboveTier82is",
        type: "number",
        initialValue: 82,
      },
      {
        type: "checkbox",
        name: "removeCGs",
        label: "view:importStaf.removeCGs",
        initialValue: false,
      },
    );
  }

  if (!withLpp) return fields;

  fields.push(
    {
      name: "lpp",
      label: "view:lppHelpText",
      type: "numberWithUnits",
      converter: globalUnits.lengthUnits,
      initialValue: lppFromApi,
      helpText: getTranslation(
        `enums:LenUnitsEnum.${LenUnitsEnum[globalUnits.lengthUnits.units]}`,
      ),
    },
    {
      name: "loa",
      label: "view:loa",
      type: "numberWithUnits",
      converter: globalUnits.lengthUnits,
      initialValue: loaFromApi,
      helpText: getTranslation(
        `enums:LenUnitsEnum.${LenUnitsEnum[globalUnits.lengthUnits.units]}`,
      ),
    },
  );

  return fields;
}
