import { routeFns } from "../../../app/router/routes";

export enum ComparerTypesEnum {
  "TVL_SOURCE" = "TVL_SOURCE",
  "OWN_VERSION" = "OWN_VERSION",
  "TVL_CONSUMER" = "TVL_CONSUMER",
}

export function getComparerTypesFromRouteKey(
  currentRouteKey: string | undefined,
): ComparerTypesEnum | undefined {
  if (!currentRouteKey) return undefined;

  if (currentRouteKey === routeFns.fileCompareTvlSource(":id"))
    return ComparerTypesEnum.TVL_SOURCE;

  if (currentRouteKey === routeFns.fileCompareOwnVersion(":id", ":version"))
    return ComparerTypesEnum.OWN_VERSION;

  if (
    currentRouteKey ===
    routeFns.fileCompareConsumer(":id", ":consumerOrgId", ":consumerFileId")
  )
    return ComparerTypesEnum.TVL_CONSUMER;

  return undefined;
}
