/* eslint-disable @typescript-eslint/no-explicit-any */

import HttpClient, { IResponseModel } from "../../services/HttpClient";

import { ILogEvent } from "@baplie-viewer2/tedivo-api-models";
import securityModule from "../../security/SecurityModule";

const domainsBaseUrl = "logEvents";

class LogEventsBeaconServiceClass {
  private baseUrl: string | undefined;

  constructor() {
    this.baseUrl = process.env.NX_API_URL || "http://localhost:3000";

    //TODO: Pass the Authorization header to the beacon request
  }

  notify = (ev: ILogEvent): void => {
    const blob = new Blob([JSON.stringify(ev)], {
      "Content-Type": "application/json",
      Authorization: `Bearer ${securityModule.idToken}`,
    } as any);

    window.navigator.sendBeacon(`${this.baseUrl}/${domainsBaseUrl}`, blob);
  };

  notifyXhttp = async (ev: ILogEvent): Promise<IResponseModel<void>> => {
    const client = new HttpClient();
    return await client.request<void>({
      method: "POST",
      url: `${domainsBaseUrl}`,
      data: ev,
    });
  };
}

const LogEventsBeaconService = new LogEventsBeaconServiceClass();

export default LogEventsBeaconService;
