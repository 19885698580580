import "./my-cloud.component.scss";

import { IFileDisplay, createFilesList } from "./createFilesList";
import {
  getPreferencesValue,
  setPreferencesKeyAndValue,
} from "@baplie-viewer2/tedivo-preferences";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";

import DivWithSpinner from "../../common/divWithSpinner/div-with-spinner-element";
import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import ICustomError from "../../types/ICustomError";
import { IFile } from "@baplie-viewer2/tedivo-api-models";
import { ISortByField } from "@baplie-viewer2/tedivo-pure-helpers";
import { IViewHistoryState } from "../open-json/open-json.component";
import Services from "../../../app/services";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";
import globalStore from "../../../app/stores/globalStore";
import goSquared from "../../../app/tracking/goSquared";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import { setAppTitle } from "../../../app/app.element";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";

export class TVDTheirCloudComponent extends HTMLElement {
  public static observedAttributes = [];

  private dialogHistory: SlDialog;

  private i18nCR: I18nComponentRegisterer;
  private wrapper: DivWithSpinner;
  private mainBox: HTMLDivElement;
  private orgId: string | undefined = undefined;
  private sortOptions: ISortByField<IFileDisplay> = {
    name: "name",
    ascending: true,
  };

  constructor() {
    super();
    this.i18nCR = new I18nComponentRegisterer(i18nReactive);
    this.wrapper = document.createElement("div-spinner-element");
    this.mainBox = document.createElement("div");

    this.dialogHistory = document.createElement("sl-dialog");

    setAppTitle(getTranslation("general:organizations.organizations"));
  }

  async connectedCallback() {
    goSquared.trackPage("Tedivo Admin Pages - Their-Cloud");
    goSquared.addEvent("Tedivo Admin Their-Cloud - Show page");

    this.dialogHistory.setAttribute("style", "--width: 80vw");
    this.dialogHistory.label = getTranslation("view:edit.fileHistory.title");
    this.mainBox.className = "oss-card";

    const wrapper = this.wrapper,
      h1 = document.createElement("h1");

    const mainBox = this.mainBox;

    wrapper.appendChild(h1);
    wrapper.appendChild(mainBox);

    this.appendChild(wrapper);

    const state = router.currentState as ITheirCloundState;

    // Translations
    const i18nCR = this.i18nCR;

    i18nCR.addConsumer(
      topMessageElement.element,
      "general:appName",
      "innerHTML",
    );
    i18nCR.addConsumer(h1, "general:cloud.myCloud", "innerHTML", {
      name: state.organizationName,
    });

    this.orgId = router.getRouteParams().orgId;

    const sortOptionsSavedStr = getPreferencesValue("myCloud-filesSort") as
      | string
      | undefined;

    if (sortOptionsSavedStr) {
      this.sortOptions = JSON.parse(sortOptionsSavedStr);
    }

    this.displayFiles(this.orgId);
  }

  async displayFiles(organizationId: string | undefined) {
    if (!organizationId) {
      router.navigate(routeFns.myCloud());
      return;
    }

    globalStore.touchLastUserInteraction();

    const wrapper = this.wrapper;
    const mainBox = this.mainBox;

    removeChildren(mainBox);

    wrapper.setLoading(true);
    const data = await Services.files.getAllAdmin(organizationId);

    if (data.statusCode === 200)
      mainBox.appendChild(
        createFilesList({
          data: (data.data as IFileDisplay[]) || [],
          fileTags: undefined,
          onDelete: undefined,
          onOpen: this.openFile,
          onSort: this.saveSortKey,
          onTagsUpdated: undefined,
          onTagSelected: undefined,
          onFilter: undefined,
          onClone: undefined,
          onColumnSelectorChange: undefined,
          onError: this.onError,
          onRefreshList: undefined,
          sortBy: this.sortOptions,
          actionsDropdown: undefined,
          dialogHistory: this.dialogHistory,
          dialogTagFile: undefined,
          dialogFileNotes: undefined,
          isReadOnly: true,
          organizationUsersBySub: undefined,
        }),
      );

    wrapper.setLoading(false);
  }

  private openFile = async (id: string) => {
    const state = router.currentState as ITheirCloundState;

    const viewState: IViewHistoryState = {
      source: "cloudOvdJson",
      cloudId: id,
      organizationId: this.orgId,
      organizationName: state.organizationName,
      impersonate: true,
    };

    goSquared.addEvent("Tedivo Admin Their-Cloud - View file");
    router.navigate(routeFns.ovdViewOnly(id), viewState);
  };

  private saveSortKey = (sortFields: ISortByField<IFileDisplay>[]) => {
    this.sortOptions = sortFields[0];
    setPreferencesKeyAndValue("myCloud-filesSort", sortFields[0]);
  };

  private onError = (e: ICustomError) => {
    console.error(e);

    const errorEvent =
      typeof e === "string"
        ? new CustomEvent<ICustomError>("customError", {
            detail: {
              errorCode: e,
              message: "errors:errorHasOcurred",
              translationKey: getTranslation(e),
            },
          })
        : new CustomEvent<ICustomError>("customError", {
            detail: {
              errorCode: e.errorCode,
              message: "errors:errorHasOcurred",
              translationKey: getTranslation(
                e.translationKey || e.message || "errors:errorHasOcurred",
              ),
            },
          });

    document.body.dispatchEvent(
      new CustomEvent<ICustomError>("customError", errorEvent),
    );
  };
}

customElements.define("tvd-their-cloud-component", TVDTheirCloudComponent);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-their-cloud-component": TVDTheirCloudComponent;
  }
}

interface ITheirCloundState {
  organizationName: string;
}
