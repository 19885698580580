export const isTruthy = <T>(item: T | undefined): item is T => {
  return item !== undefined && item !== null;
};

export const objectIsTruthy = <T>(item: T | undefined): item is T => {
  const toi = typeof item;
  if (toi !== "object" && toi !== "undefined") {
    throw new Error(`item ${item} is not an object`);
  }
  return item !== undefined && item !== null;
};
