import {
  IFeaturesAllowed,
  IShipData,
  PositionFormatEnum,
} from "open-vessel-definition";
import i18n, { getTranslation } from "../../../../app/i18/i18tn";

import { IMapConfigArray } from "../../../common/dictionaryDisplay/helpers/mapDataToDictionary";
import { getPositionFormatEnumTranslationKey } from "@baplie-viewer2/tedivo-pure-helpers";

type TFeaturesKeys = keyof IFeaturesAllowed;

const ViewShipDataConfig: IMapConfigArray<IShipData> = [
  {
    label: "view:shipName",
    key: "shipName",
    type: "string",
    undefinedIsDash: true,
  },
  {
    label: "view:imoCode",
    key: "imoCode",
    type: "string",
    undefinedIsDash: true,
  },
  {
    label: "view:callSign",
    key: "callSign",
    type: "string",
    undefinedIsDash: true,
  },
  {
    label: "view:lineOperator",
    key: "lineOperator",
    type: "string",
    undefinedIsDash: true,
  },
  {
    label: "view:shipClass",
    key: "shipClass",
    type: "string",
    undefinedIsDash: true,
  },
  {
    label: "view:yearBuilt",
    key: "yearBuilt",
    type: "string",
    undefinedIsDash: true,
  },
  {
    label: "view:positionFormat",
    key: "positionFormat",
    type: "string",
    undefinedIsDash: true,
    format: (v) =>
      i18n.t(getPositionFormatEnumTranslationKey(v as PositionFormatEnum)),
  },
  {
    label: "view:availableSizes",
    key: "containersLengths",
    type: "string",
    undefinedIsDash: true,
    format: (v) => (v as unknown as Array<string>).join(", "),
  },
  {
    label: "view:featuresAllowed",
    key: "featuresAllowed",
    type: "string",
    undefinedIsDash: false,
    format: (v) => {
      if (!v) return getTranslation("general:common.none");

      const result: string[] = [];
      const ob = v as unknown as Record<TFeaturesKeys, boolean>;
      if (ob.slotHazardousProhibited)
        result.push(getTranslation("general:slotCapabilities.misc.h"));

      if (ob.slotCoolStowProhibited)
        result.push(getTranslation("general:slotCapabilities.misc.k"));

      if (ob.slotConeRequired)
        result.push(getTranslation("general:slotCapabilities.conesPerSize"));

      if (result.length) {
        return result.join(", ");
      } else {
        return getTranslation("general:common.none");
      }
    },
  },
  // {
  //   label: "view:shipNameAkaStr",
  //   key: "shipNameAkas",
  //   type: "string",
  //   undefinedIsDash: true,
  //   format: (v) => (v as unknown as Array<string>).join(", ") || "-",
  // },
];

export default ViewShipDataConfig;
