import { BayLevelEnum, ForeAftEnum, } from "open-vessel-definition";
import { createGridFromConfig, createTiersFromConfig, } from "@baplie-viewer2/tedivo-bay-grid-core";
import { gridOptionsByCellDimension, gridOptionsByCellGap } from "./consts";
import CellSpacingEnum from "../../../../app/enums/CellSpacingEnum";
import SizeSmallMidLargeEnum from "../../../../app/enums/SizeSmallMidLargeEnum";
import { createMultiEditButton } from "@baplie-viewer2/tedivo-form";
import { createObserverWithCallback } from "../../../helpers/createObserverWithCallback";
import { getTranslation } from "../../../../app/i18/i18tn";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
const EXTRA_SPACE_TOP = 60;
class BayBoxComponent extends HTMLElement {
    constructor() {
        super();
        this.data = {};
        this.createGridOfBay = (isoBay, ssMaxRow, bayAbove, bayBelow, enabledCells, prohibitedRow00, slotsData, lidDataOfBay, width, height, box, cellSize, cellSeparation) => {
            return () => {
                console.time(`createGridFromConfig ${isoBay}`);
                const { svgGroup, symbols } = createGridFromConfig(Object.assign(Object.assign(Object.assign(Object.assign({}, this.data.sizeSummary), { maxRow: ssMaxRow, centerLineRow: (bayAbove === null || bayAbove === void 0 ? void 0 : bayAbove.centerLineRow) || (bayBelow === null || bayBelow === void 0 ? void 0 : bayBelow.centerLineRow) ? 1 : 0, nodesOptions: this.data.nodesOptions }), gridOptionsByCellDimension[cellSize]), { cellSeparation: gridOptionsByCellGap[cellSeparation], enabledCells, prohibitedCells: prohibitedRow00, cellsDrawFunction: this.data.createFnSlotCell(cellSize), cellsToDraw: slotsData, lidData: lidDataOfBay }));
                const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
                svg.setAttribute("xmlns", "http://www.w3.org/2000/svg");
                svg.setAttribute("width", `${width}px`);
                svg.setAttribute("height", `${height}px`);
                svg.setAttribute("viewBox", `0 0 ${width} ${height}`);
                svg.setAttribute("part", "svg-bay");
                svg.setAttribute("role", "figure");
                if (symbols)
                    Object.keys(symbols).forEach((n) => svg.appendChild(symbols[n]));
                svg.appendChild(svgGroup);
                console.timeEnd(`createGridFromConfig ${isoBay}`);
                box.appendChild(svg);
            };
        };
        this.bayBox = document.createElement("div");
        this.bayBox.setAttribute("part", "box");
    }
    setSize(width, height) {
        const bayBox = this.bayBox;
        bayBox.style.width = `${width}px`;
        bayBox.style.height = `${height + EXTRA_SPACE_TOP}px`;
        return this;
    }
    setData(data) {
        this.data = data;
        this.setAttribute("data-bay", data.isoBay);
        return this;
    }
    setEditDrawer(editDrawer) {
        this.editDrawer = editDrawer;
        return this;
    }
    draw(root) {
        if (!this.data.isoBay || !root) {
            if (!this.data.isoBay)
                console.warn("No data provided");
            if (!root)
                console.warn("Root is null");
            return;
        }
        const { sizeSummary, shipData, lidData, bays, isoBay, bayAbove, bayBelow, enabledCells, slotsDataAbove, slotsDataBelow, slotsData, width, height, ssMaxRow, nodesOptions, cellSize, cellSeparation, validationResult, createFnSlotCell, openBayEdit, } = this.data;
        const bayBox = this.bayBox;
        const top = document.createElement("div");
        top.setAttribute("part", "top");
        const h3 = document.createElement("h3");
        h3.setAttribute("part", "title");
        h3.innerHTML = bays.map((b, idx) => (idx === 0 ? b : `(${b})`)).join(" ");
        top.appendChild(h3);
        const editBtn = createMultiEditButton({
            id: `editBay-${isoBay}`,
            className: "bay-edit-btn",
            disabled: !this.data.canOpenDrawer,
            options: [
                {
                    value: BayLevelEnum.ABOVE,
                    name: getTranslation(`enums:BayLevelEnum.${BayLevelEnum[BayLevelEnum.ABOVE]}`),
                    action: () => {
                        var _a;
                        const gridSize = {
                            centerLineRow: (_a = bayAbove === null || bayAbove === void 0 ? void 0 : bayAbove.centerLineRow) !== null && _a !== void 0 ? _a : sizeSummary.centerLineRow,
                            isoBays: sizeSummary.isoBays,
                            maxAboveTier: sizeSummary.maxAboveTier,
                            minAboveTier: sizeSummary.minAboveTier,
                            maxRow: ssMaxRow,
                        };
                        openBayEdit({
                            isoBay,
                            level: BayLevelEnum.ABOVE,
                            availableLengths: shipData.containersLengths,
                            bayData: bayAbove,
                            sizeSummary,
                            shipData,
                            editDrawer: this.editDrawer,
                            readOnlyMode: this.data.readOnlyMode,
                            gridData: Object.assign(Object.assign(Object.assign(Object.assign({ maxRow: ssMaxRow }, gridSize), { nodesOptions }), gridOptionsByCellDimension[SizeSmallMidLargeEnum.MIDDLE]), { cellSeparation: gridOptionsByCellGap[CellSpacingEnum.SPACED], enabledCells, cellsDrawFunction: createFnSlotCell(SizeSmallMidLargeEnum.MIDDLE), cellsToDraw: slotsDataAbove }),
                        });
                    },
                },
                {
                    value: BayLevelEnum.BELOW,
                    name: getTranslation(`enums:BayLevelEnum.${BayLevelEnum[BayLevelEnum.BELOW]}`),
                    action: () => {
                        var _a;
                        const gridSize = {
                            centerLineRow: (_a = bayBelow === null || bayBelow === void 0 ? void 0 : bayBelow.centerLineRow) !== null && _a !== void 0 ? _a : sizeSummary.centerLineRow,
                            isoBays: sizeSummary.isoBays,
                            maxBelowTier: sizeSummary.maxBelowTier,
                            minBelowTier: sizeSummary.minBelowTier,
                            maxRow: ssMaxRow,
                        };
                        openBayEdit({
                            isoBay,
                            level: BayLevelEnum.BELOW,
                            availableLengths: shipData.containersLengths,
                            bayData: bayBelow,
                            sizeSummary,
                            shipData,
                            editDrawer: this.editDrawer,
                            readOnlyMode: this.data.readOnlyMode,
                            gridData: Object.assign(Object.assign(Object.assign(Object.assign({ maxRow: ssMaxRow }, gridSize), { nodesOptions }), gridOptionsByCellDimension[SizeSmallMidLargeEnum.MIDDLE]), { cellSeparation: gridOptionsByCellGap[CellSpacingEnum.SPACED], enabledCells, cellsDrawFunction: createFnSlotCell(SizeSmallMidLargeEnum.MIDDLE), cellsToDraw: slotsDataBelow }),
                        });
                    },
                },
            ],
        });
        editBtn.setAttribute("part", "edit-btn");
        top.appendChild(editBtn);
        bayBox.appendChild(top);
        bayBox.appendChild(getDetailPills(bayAbove, bayBelow, validationResult));
        /** In case we need to show as disabled the 00 row column for some bays */
        const prohibitedRow00 = sizeSummary.centerLineRow
            ? get00RowCells(sizeSummary, !(bayAbove === undefined || (bayAbove === null || bayAbove === void 0 ? void 0 : bayAbove.centerLineRow)), !(bayBelow === undefined || (bayBelow === null || bayBelow === void 0 ? void 0 : bayBelow.centerLineRow)))
            : [];
        const lidDataOfBay = lidData.filter((ld) => ld.startIsoBay === isoBay || ld.endIsoBay === isoBay);
        const ioObserver = createObserverWithCallback(this.createGridOfBay(isoBay, ssMaxRow, bayAbove, bayBelow, enabledCells, prohibitedRow00, slotsData, lidDataOfBay, width, height, bayBox, cellSize, cellSeparation), { delay: 1 });
        ioObserver.observe(bayBox);
        root.appendChild(bayBox);
    }
    updateData(newData) {
        this.data = Object.assign(Object.assign({}, this.data), newData);
        if (this.shadowRoot) {
            removeChildren(this.bayBox);
            this.draw(this.shadowRoot);
        }
        return this;
    }
    connectedCallback() {
        const shadowRoot = this.attachShadow({ mode: "open" });
        this.draw(shadowRoot);
    }
}
export default BayBoxComponent;
customElements.define("bay-box-component", BayBoxComponent);
const foreAftTranslations = {
    [ForeAftEnum.AFT]: getTranslation(`enums:ForeAftEnum.AFT`),
    [ForeAftEnum.FWD]: getTranslation(`enums:ForeAftEnum.FWD`),
    [0]: getTranslation(`general:common.unknown`),
};
const arrowDirection = {
    [ForeAftEnum.AFT]: "→",
    [ForeAftEnum.FWD]: "←",
    [0]: "?",
};
const yesNoTranslations = (v) => getTranslation(v ? "general:common.yes" : "general:common.no");
const yesNoSymbols = (v) => (v ? "✓" : "&times;");
function getDetailPills(blA, blB, validationResult) {
    const holder = document.createElement("div");
    holder.setAttribute("part", "details-holder");
    if (blA === undefined && blB === undefined) {
        return holder;
    }
    const allBaysWith40sArePairedResult = validationResult.find((v) => v.name === "allBaysWith40sArePaired");
    drawSingleDetail(blA, blB, {
        key: "pairedBay",
        labelKey: "view:details.paired",
        iconName: "link",
        textStrFn: (a) => foreAftTranslations[(a === null || a === void 0 ? void 0 : a.pairedBay) || 0],
        textIconFn: (a) => arrowDirection[(a === null || a === void 0 ? void 0 : a.pairedBay) || 0],
        warningFn: (a, b) => {
            if (allBaysWith40sArePairedResult) {
                return allBaysWith40sArePairedResult.invalidResults.some((r) => (r.bay === (a === null || a === void 0 ? void 0 : a.isoBay) && a.level === r.level) ||
                    (r.bay === (b === null || b === void 0 ? void 0 : b.isoBay) && b.level === r.level));
            }
            return false;
        },
    });
    drawSingleDetail(blA, blB, {
        key: "reeferPlugs",
        labelKey: "view:details.reeferPlugs",
        iconName: "plug",
        undefinedIsZero: true,
        textStrFn: (a) => foreAftTranslations[(a === null || a === void 0 ? void 0 : a.reeferPlugs) || 0],
        textIconFn: (a) => arrowDirection[(a === null || a === void 0 ? void 0 : a.reeferPlugs) || 0],
    });
    drawSingleDetail(blA, blB, {
        key: "doors",
        labelKey: "view:details.doors",
        iconName: "door-closed",
        undefinedIsZero: true,
        textStrFn: (a) => foreAftTranslations[(a === null || a === void 0 ? void 0 : a.doors) || 0],
        textIconFn: (a) => arrowDirection[(a === null || a === void 0 ? void 0 : a.doors) || 0],
    });
    drawSingleDetail(blA, blB, {
        key: "telescoping",
        labelKey: "view:details.telescoping",
        iconName: "arrow-left-right",
        ommitBothTrue: true,
        undefinedIsZero: true,
        textStrFn: (a) => yesNoTranslations(!!(a === null || a === void 0 ? void 0 : a.telescoping)),
        textIconFn: (a) => yesNoSymbols(!!(a === null || a === void 0 ? void 0 : a.telescoping)),
    });
    return holder;
    function drawSingleDetail(blA, blB, { iconName, labelKey, key, ommitBothTrue, undefinedIsZero = false, textStrFn, textIconFn, warningFn, }) {
        if (blA === undefined && blB === undefined)
            return;
        const label = getTranslation(labelKey);
        const valA = !undefinedIsZero ? blA === null || blA === void 0 ? void 0 : blA[key] : (blA === null || blA === void 0 ? void 0 : blA[key]) || 0;
        const valB = !undefinedIsZero ? blB === null || blB === void 0 ? void 0 : blB[key] : (blB === null || blB === void 0 ? void 0 : blB[key]) || 0;
        if (valA && valB && valA === valB) {
            const commonBl = blA !== null && blA !== void 0 ? blA : blB;
            const title = `${label}: ${textStrFn(commonBl)}`;
            const inner = `${textIconFn(commonBl)}`;
            const badge = document.createElement("sl-badge");
            const icon = document.createElement("sl-icon");
            icon.setAttribute("name", iconName);
            icon.className = "icon-big";
            badge.title = title;
            badge.appendChild(icon);
            if (!ommitBothTrue)
                badge.appendChild(document.createTextNode(inner));
            badge.variant = "neutral";
            badge.pill = true;
            holder.appendChild(badge);
            if (warningFn && warningFn(blA, blB)) {
                badge.variant = "warning";
            }
            else {
                badge.variant = "neutral";
            }
        }
        else if (valA !== valB) {
            const title = [
                blA
                    ? `${label} ${getTranslation(`enums:BayLevelEnum.ABOVE`)}: ${textStrFn(blA)}`
                    : undefined,
                blB
                    ? `${label} ${getTranslation(`enums:BayLevelEnum.BELOW`)}: ${textStrFn(blB)}`
                    : undefined,
            ]
                .filter(Boolean)
                .join("\n");
            const inner = [
                blA ? `<sup>${textIconFn(blA)}</sup>` : undefined,
                blB ? `<sub>${textIconFn(blB)}</sub>` : undefined,
            ]
                .filter(Boolean)
                .join("/");
            const badge = document.createElement("sl-badge");
            const icon = document.createElement("sl-icon");
            icon.setAttribute("name", iconName);
            icon.className = "icon-big";
            const innerSpan = document.createElement("span");
            innerSpan.innerHTML = inner;
            badge.title = title;
            badge.appendChild(icon);
            badge.appendChild(innerSpan);
            if (warningFn && warningFn(blA, blB)) {
                badge.variant = "warning";
            }
            else {
                badge.variant = "neutral";
            }
            badge.pill = true;
            holder.appendChild(badge);
        }
    }
}
function get00RowCells(sizeSummary, aboveShouldHide00 = false, belowShouldHide00 = false) {
    const tiers = [
        ...(aboveShouldHide00
            ? createTiersFromConfig(sizeSummary.minAboveTier, sizeSummary.maxAboveTier)
            : []),
        ...(belowShouldHide00
            ? createTiersFromConfig(sizeSummary.minBelowTier, sizeSummary.maxBelowTier)
            : []),
    ];
    return tiers.map((tier) => `00|${tier}`);
}
