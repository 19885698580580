export default function readOVDJsonFile(f) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.onload = (ev) => {
            try {
                const target = ev.target;
                const json = JSON.parse(target.result);
                if (isOvdSchema(json)) {
                    resolve(json);
                }
                else {
                    reject("errors:notValidOvdFile");
                }
            }
            catch (err) {
                reject(err);
            }
        };
        reader.onerror = (ev) => {
            var _a;
            reject((_a = ev.target) === null || _a === void 0 ? void 0 : _a.error);
        };
        reader.readAsText(f, "utf-8");
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isOvdSchema(json) {
    if (json.schema) {
        return (json.schema === "OpenVesselDefinition" &&
            json.version &&
            json.sizeSummary &&
            json.shipData);
    }
    else if (json.$schema) {
        return ((json.$schema ===
            "https://github.com/tedivo/OpenVesselDefinition/blob/master/schema.json" ||
            json.$schema ===
                "https://raw.githubusercontent.com/tedivo/OpenVesselDefinition/master/schema.json") &&
            (json.$schemaId === "IOpenVesselDefinitionV1" ||
                json.$schemaId === "OpenVesselDefinition") &&
            json.version &&
            json.sizeSummary &&
            json.shipData);
    }
    return false;
}
