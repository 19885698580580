/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { BayLevelEnum, } from "open-vessel-definition";
import { getRowsAndTiersFromSlotKeys, } from "./getRowsAndTiersFromSlotKeys";
import { feetToMillimeters, pad2, pad3, roundDec, } from "@baplie-viewer2/tedivo-pure-helpers";
import { findPaired40 } from "./findPaired40";
import { getSizesFromSlots } from "./getSizesFromSlots";
const EIGHTANDHALF_FEET_IN_MILLIMETERS = feetToMillimeters(8.5);
export function getBayLcgVcgTcgAndPairings(bls, masterCGs) {
    const lcgByBayAbove = [];
    const lcgByBayBelow = [];
    let minVcg = Infinity, maxVcg = -Infinity, minLcg = Infinity, maxLcg = -Infinity, minTcg = Infinity, maxTcg = -Infinity, maxIsoBay = -Infinity, countLcgs = 0, definedLcgs = 0, countTcgs = 0, definedTcgs = 0, countBottomBase = 0, definedBottomBase = 0, totalSlotsCount = 0;
    bls
        .slice()
        .sort(sortByBayAsc)
        .forEach((bl) => {
        const combinedTiersInfo = {
            bayIsoNumber: bl.isoBay,
            baylevel: bl.level,
            bayLabel: bl.label20 || bl.label40,
            pairedBay: bl.pairedBay,
            bulkhead: bl.bulkhead,
            tiers: {},
            rows: {},
            maxSizeLcg: {},
            sizes: [],
            missingImportantLcgs: false,
            missingImportantVcgs: false,
            missingImportantTcgs: false,
            countLcgs: 0,
            definedLcgs: 0,
            countTcgs: 0,
            definedTcgs: 0,
            countBottomBase: 0,
            definedBottomBase: 0,
        };
        const tiersBases = {};
        if (maxIsoBay < Number(bl.isoBay))
            maxIsoBay = Number(bl.isoBay);
        const { infoByContLength, perSlotInfo, perRowInfo } = bl;
        const commonRowInfo = perRowInfo === null || perRowInfo === void 0 ? void 0 : perRowInfo.common;
        const perRowInfoEach = perRowInfo === null || perRowInfo === void 0 ? void 0 : perRowInfo.each;
        // Use perSlot to discover current lengths used
        combinedTiersInfo.sizes = getSizesFromSlots(perSlotInfo);
        // Expected LCGs count (one for each size)
        combinedTiersInfo.countLcgs = combinedTiersInfo.sizes.length;
        const slotsKeys = perSlotInfo
            ? Object.keys(perSlotInfo).filter((slotKey) => !(perSlotInfo === null || perSlotInfo === void 0 ? void 0 : perSlotInfo[slotKey].restricted))
            : undefined;
        if (slotsKeys)
            totalSlotsCount += slotsKeys.length;
        const { rows: rowsFromSlots, tiersByRow: tiersByRowFromSlots } = getRowsAndTiersFromSlotKeys(slotsKeys);
        // Initialize rows used in bay
        combinedTiersInfo.rows = rowsFromSlots.reduce((acc, row) => {
            acc[row] = { tcg: undefined };
            return acc;
        }, {});
        // Set LCGs using Bay's infoByContLength
        setLcgsFromInfoByContLength(bl.isoBay, infoByContLength, combinedTiersInfo);
        // Set TCGs and BottomBases (former VCGs)
        if (perRowInfoEach !== undefined) {
            setTcgsAndBottomBases(bl, combinedTiersInfo, rowsFromSlots, tiersByRowFromSlots, perRowInfoEach, commonRowInfo, tiersBases);
        }
        else {
            if (slotsKeys && slotsKeys.length > 0) {
                combinedTiersInfo.missingImportantTcgs = true;
                combinedTiersInfo.missingImportantVcgs = true;
            }
        }
        if (perSlotInfo !== undefined) {
            (slotsKeys || []).forEach((pos) => {
                var _a, _b;
                const tier = pos.substring(2);
                const slotData = perSlotInfo[pos];
                if (slotData.sizes) {
                    // A. Get maximum length from slots for each Tier
                    const maxSize = Math.max(...Object.keys(slotData.sizes).map((s) => Number(s)));
                    const lcgOfMaxSize = (_a = infoByContLength[maxSize]) === null || _a === void 0 ? void 0 : _a.lcg;
                    const tierInfo = combinedTiersInfo.tiers[tier];
                    let updateDetails = false;
                    // B. Add lcg of maxSize and vcg of the tier
                    if (combinedTiersInfo.tiers[tier] === undefined) {
                        combinedTiersInfo.tiers[tier] = {
                            maxSize,
                            vcg: tiersBases[tier],
                            lcg: lcgOfMaxSize,
                        };
                        updateDetails = true;
                    }
                    else if (maxSize > combinedTiersInfo.tiers[tier].maxSize) {
                        combinedTiersInfo.tiers[tier].maxSize = maxSize;
                        combinedTiersInfo.tiers[tier].vcg = tiersBases[tier];
                        combinedTiersInfo.tiers[tier].lcg = lcgOfMaxSize;
                        updateDetails = true;
                    }
                    if (updateDetails) {
                        if ((tierInfo === null || tierInfo === void 0 ? void 0 : tierInfo.vcg) !== undefined) {
                            if (minVcg > tierInfo.vcg)
                                minVcg = tierInfo.vcg;
                            if (maxVcg < tierInfo.vcg)
                                maxVcg = tierInfo.vcg;
                        }
                        combinedTiersInfo.missingImportantLcgs =
                            combinedTiersInfo.missingImportantLcgs ||
                                !updateMaxSizeDetailsOfBayAndLevel(combinedTiersInfo.maxSizeLcg, maxSize, (_b = combinedTiersInfo.tiers[tier]) === null || _b === void 0 ? void 0 : _b.lcg);
                    }
                }
            });
        }
        if (bl.level === BayLevelEnum.ABOVE) {
            lcgByBayAbove.push(combinedTiersInfo);
        }
        else if (bl.level === BayLevelEnum.BELOW) {
            lcgByBayBelow.push(combinedTiersInfo);
        }
        countLcgs += combinedTiersInfo.countLcgs;
        definedLcgs += combinedTiersInfo.definedLcgs;
        countTcgs += combinedTiersInfo.countTcgs;
        definedTcgs += combinedTiersInfo.definedTcgs;
        countBottomBase += combinedTiersInfo.countBottomBase;
        definedBottomBase += combinedTiersInfo.definedBottomBase;
    });
    findPaired40(lcgByBayAbove);
    findPaired40(lcgByBayBelow);
    const missingImportantLcgs = isMissingImportantXcgs(lcgByBayAbove, lcgByBayBelow, "missingImportantLcgs");
    const missingImportantVcgs = isMissingImportantXcgs(lcgByBayAbove, lcgByBayBelow, "missingImportantVcgs");
    const missingImportantTcgs = isMissingImportantXcgs(lcgByBayAbove, lcgByBayBelow, "missingImportantTcgs");
    const isoBaysArray = [];
    for (let i = 1; i <= maxIsoBay; i += 2) {
        isoBaysArray.push(pad3(i));
    }
    return {
        missingImportantLcgs,
        missingImportantVcgs,
        missingImportantTcgs,
        bayLevelPositionsAbove: lcgByBayAbove,
        bayLevelPositionsBelow: lcgByBayBelow,
        minLcg,
        maxLcg,
        minVcg,
        maxVcg,
        minTcg,
        maxTcg,
        maxIsoBay,
        isoBaysArray,
        totalSlotsCount,
        cgsStats: {
            countLcgs,
            definedLcgs,
            countTcgs,
            definedTcgs,
            countBottomBase,
            definedBottomBase,
        },
    };
    function setTcgsAndBottomBases(bl, combinedTiersInfo, rowsFromSlots, tiersByRowFromSlots, perRowInfoEach, commonRowInfo, tiersBases) {
        rowsFromSlots.forEach((row) => {
            var _a, _b, _c, _d, _e;
            const currentRowInfo = perRowInfoEach[row];
            combinedTiersInfo.countTcgs += 1;
            // 1. TCGs
            // TCG is the one defined in the bay or use master CGs
            const tcg = (_a = currentRowInfo === null || currentRowInfo === void 0 ? void 0 : currentRowInfo.tcg) !== null && _a !== void 0 ? _a : (bl.level === BayLevelEnum.ABOVE
                ? masterCGs.aboveTcgs[row]
                : masterCGs.belowTcgs[row]);
            if (tcg === undefined) {
                combinedTiersInfo.missingImportantTcgs = true;
            }
            else {
                combinedTiersInfo.definedTcgs += 1;
                combinedTiersInfo.rows[row] = { tcg };
                if (minTcg > tcg)
                    minTcg = tcg;
                if (maxTcg < tcg)
                    maxTcg = tcg;
            }
            // 2. Bottom Bases
            combinedTiersInfo.countBottomBase += 1;
            const bottomIsoTier = ((_b = tiersByRowFromSlots[row]) === null || _b === void 0 ? void 0 : _b.minTier)
                ? pad2(tiersByRowFromSlots[row].minTier)
                : undefined, topIsoTier = ((_c = tiersByRowFromSlots[row]) === null || _c === void 0 ? void 0 : _c.maxTier)
                ? pad2(tiersByRowFromSlots[row].maxTier)
                : undefined;
            // Bottom base is the one defined in the row, or in common, or in masterCGs
            const bottomBase = (_e = (_d = currentRowInfo === null || currentRowInfo === void 0 ? void 0 : currentRowInfo.bottomBase) !== null && _d !== void 0 ? _d : commonRowInfo === null || commonRowInfo === void 0 ? void 0 : commonRowInfo.bottomBase) !== null && _e !== void 0 ? _e : (bottomIsoTier !== undefined
                ? masterCGs.bottomBases[bottomIsoTier]
                : undefined);
            if (bottomBase === undefined ||
                bottomIsoTier === undefined ||
                topIsoTier === undefined) {
                combinedTiersInfo.missingImportantVcgs = true;
            }
            else {
                combinedTiersInfo.definedBottomBase += 1;
                // 1. Find tiers
                const btmTierM = Number(bottomIsoTier), topTierN = Number(topIsoTier);
                // 2. Calculate VCGs
                for (let t = btmTierM; t <= topTierN; t += 2) {
                    const isoTier = pad2(t);
                    const tierVcg = roundDec(bottomBase +
                        (t - btmTierM) * EIGHTANDHALF_FEET_IN_MILLIMETERS * 0.5, 2);
                    tiersBases[isoTier] = tierVcg;
                    if (minVcg > tierVcg)
                        minVcg = tierVcg;
                    if (maxVcg < tierVcg)
                        maxVcg = tierVcg;
                }
            }
        });
    }
    function setLcgsFromInfoByContLength(bay, infoByContLength, combinedTiersInfo) {
        var _a;
        if (infoByContLength === undefined) {
            return;
        }
        const cLengths = Object.keys(infoByContLength).map(Number).filter((len) => combinedTiersInfo.sizes.indexOf(len) >= 0); // Only lengths used in the bay (from slots)
        // Defined LCGs count (one for each size)
        combinedTiersInfo.definedLcgs += cLengths
            .map((len) => { var _a; return (_a = infoByContLength[len]) === null || _a === void 0 ? void 0 : _a.lcg; })
            .filter((lcg) => lcg !== undefined).length;
        const maxSizeDefined = Math.max(...cLengths);
        const lcgOfMaxSizeDefined = (_a = infoByContLength[maxSizeDefined]) === null || _a === void 0 ? void 0 : _a.lcg;
        if (lcgOfMaxSizeDefined !== undefined) {
            if (minLcg > lcgOfMaxSizeDefined)
                minLcg = lcgOfMaxSizeDefined;
            if (maxLcg < lcgOfMaxSizeDefined)
                maxLcg = lcgOfMaxSizeDefined;
            combinedTiersInfo.missingImportantLcgs =
                combinedTiersInfo.missingImportantLcgs ||
                    !updateMaxSizeDetailsOfBayAndLevel(combinedTiersInfo.maxSizeLcg, maxSizeDefined, lcgOfMaxSizeDefined);
        }
    }
}
const updateMaxSizeDetailsOfBayAndLevel = (maxSizeLcg, maxSize, lcg) => {
    if (lcg === undefined)
        return false;
    maxSizeLcg.lcg = lcg;
    maxSizeLcg.aftLcg = getAftLcg(maxSize, lcg);
    maxSizeLcg.foreLcg = getForeLcg(maxSize, lcg);
    maxSizeLcg.size = maxSize;
    return true;
};
function isMissingImportantXcgs(lcgByBayAbove, lcgByBayBelow, attr) {
    return (lcgByBayAbove.reduce((acc, y) => acc || y[attr], false) ||
        lcgByBayBelow.reduce((acc, y) => acc || y[attr], false));
}
const sortByBayAsc = (a, b) => Number(a.isoBay) - Number(b.isoBay);
const getForeLcg = (maxSize, lcg) => lcg !== undefined
    ? roundDec(lcg + feetToMillimeters(maxSize * 0.5), 2)
    : undefined;
const getAftLcg = (maxSize, lcg) => lcg !== undefined
    ? roundDec(lcg - feetToMillimeters(maxSize * 0.5), 2)
    : undefined;
// #endregion Interfaces
