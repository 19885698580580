export function safeDateString(inp?: Date | string): string {
  return inp
    ? typeof inp === "string"
      ? inp
      : inp.toISOString()
    : new Date().toISOString();
}

export function safeDateType(inp: Date | string | undefined): Date;
export function safeDateType(
  inp: Date | string | undefined,
  useUndefined: true,
): Date | undefined;

export function safeDateType(
  inp: Date | string | undefined,
  useUndefined = false,
): Date | undefined {
  if (inp === undefined && useUndefined) return undefined;

  return inp ? (typeof inp === "string" ? new Date(inp) : inp) : new Date();
}
