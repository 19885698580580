import { ForeAftEnum, ISizeSummary } from "open-vessel-definition";
import {
  IBaySizesAndCgs,
  IGetBayLcgVcgAndPairingsResult,
} from "../../helpers/getBayLcgVcgTcgAndPairings";
import {
  IGenerateSideContainerSymbolsProps,
  generateSideContainerSymbols,
} from "./generateSideContainerSymbols";
import {
  LCG_20_WITH_SEPARATION,
  LCG_SEPARATION,
  SEPARATION_IN_BETWEEN,
  generateCoordFunctions,
} from "../helpers/generateCoordFunctions";
import {
  feetToMillimeters,
  roundDec,
  sortNumericAsc,
  sortNumericDesc,
} from "@baplie-viewer2/tedivo-pure-helpers";
import generate20Lcgs, { I20LcgsByBay } from "../helpers/generate20Lcgs";

import { IRowPattern } from "../../types/IPositionPatterns";
import { createSvgPath } from "../helpers/createSvgPath";
import { setTopBayNumbers } from "../helpers/setTopBayNumbers";

export const SVG_WIDTH = 1500;
export const DISTANCE_TO_CONTAINER_FOR_SHIP_LINE = 2000;

export function createSimpleSideView({
  sizeSummary,
  lcgVcgTcgAndPairings,
  symbolsOptions,
  bayNumbersDoubleClickAction,
}: ICreateSimpleSideViewProps): {
  sideViewSvg: SVGElement;
  missingImportantXcgs: boolean;
  xRange: number;
  yRange: number;
} {
  const {
    bayLevelPositionsAbove,
    bayLevelPositionsBelow,
    missingImportantVcgs,
    missingImportantLcgs,
    isoBaysArray,
    maxIsoBay,
    minLcg,
    maxLcg,
    minVcg,
    maxVcg,
    minTcg,
    maxTcg,
    totalSlotsCount,
  } = lcgVcgTcgAndPairings;

  const missingImportantXcgs = missingImportantVcgs || missingImportantLcgs;

  // 1.1 LCGs of 20
  const lcgsBy20Bay: I20LcgsByBay = generate20Lcgs(
    sizeSummary,
    bayLevelPositionsAbove,
    bayLevelPositionsBelow,
    missingImportantXcgs,
  );

  // 1. Add missing LCGs and VCGs if needed, return x,y functions
  const { xRange, yRange, xPos, yPos, addX, svgSizeRatio } =
    generateCoordFunctions({
      missingImportantXcgs,
      maxIsoBay,
      sizeSummary,
      bayLevelPositionsAbove,
      bayLevelPositionsBelow,
      maxLcg,
      minLcg,
      maxVcg,
      minVcg,
      maxTcg,
      minTcg,
      lcgNormalization: SVG_WIDTH,
      lcgsBy20Bay,
    });

  const pairedBlps = createSameBayAbBeSizesAndCgs(
    bayLevelPositionsAbove,
    bayLevelPositionsBelow,
  );

  // 2. SVG Node
  const svgNode = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svgNode.setAttribute("width", String(xRange));
  svgNode.setAttribute("height", String(yRange));
  svgNode.setAttribute("viewBox", `0 0 ${xRange} ${yRange}`);
  svgNode.setAttribute("data-scale", String(roundDec(svgSizeRatio, 5)));

  // 3. Add symbols
  const symbols = generateSideContainerSymbols(
    symbolsOptions || {},
    svgSizeRatio,
  );

  const symbolsNames = Object.keys(symbols);
  symbolsNames.forEach((key) => {
    svgNode.appendChild(symbols[key]);
  });

  // 4. Top bay numbers
  const drawTopBayNumbers = (blps: IBaySizesAndCgs[]) =>
    setTopBayNumbers({
      blps,
      lcgsBy20Bay,
      svgNode,
      xPos,
      fontColor: symbolsOptions && symbolsOptions.fontColor,
      onDoubleClick: bayNumbersDoubleClickAction,
    });

  if (bayLevelPositionsAbove.length) drawTopBayNumbers(bayLevelPositionsAbove);
  else drawTopBayNumbers(bayLevelPositionsBelow);

  if (totalSlotsCount > 0) {
    // 5. Ship line
    generateShipLine(missingImportantXcgs);

    // 6. Draw containers
    bayLevelPositionsAbove.filter(Boolean).forEach(drawContainers);
    bayLevelPositionsBelow.filter(Boolean).forEach(drawContainers);

    // 7. Draw bulkheads
    drawBulkheads(missingImportantXcgs, xRange);

    //_helperDraw();
  }

  return {
    sideViewSvg: svgNode,
    missingImportantXcgs,
    xRange,
    yRange,
  };

  function drawContainers(blp: IBaySizesAndCgs): void {
    const tiers = Object.keys(blp.tiers).sort(sortNumericAsc) as IRowPattern[];

    tiers.forEach((tierName) => {
      const tierInfo = blp.tiers[tierName];

      if (
        !(tierInfo.maxSize < 40 && tierInfo.pairedHas40) &&
        tierInfo.lcg !== undefined &&
        tierInfo.vcg !== undefined
      ) {
        // If paired has 40, don't draw.
        const contSvg = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "use",
        );
        contSvg.setAttribute(
          "href",
          `#smbCn${tierInfo.maxSize}-${blp.pairedBay || 0}`,
        );
        contSvg.setAttribute("x", `${xPos(tierInfo.lcg)}`);
        contSvg.setAttribute("y", `${yPos(tierInfo.vcg)}`);
        contSvg.setAttribute("data-p", `${blp.bayIsoNumber}-${tierName}`);

        const contTitle = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "title",
        );
        contTitle.innerHTML = `${blp.bayIsoNumber}-${tierName}`;
        contSvg.appendChild(contTitle);

        svgNode.appendChild(contSvg);
      }
    });
  }

  function createSameBayAbBeSizesAndCgs(
    blpsAbove: IBaySizesAndCgs[],
    blpsBelow: IBaySizesAndCgs[],
  ): IBayAboveBelowLVcgs {
    const aboveBelowBlps: IBayAboveBelowLVcgs = {};

    blpsAbove.forEach((blpAb) => {
      const { vcg, lcg, foreLcg, aftLcg, maxSize } = getNearestVcgFromTiers({
        blp: blpAb,
        isAbove: true,
      });
      addToBay(blpAb, vcg, lcg, foreLcg, aftLcg, maxSize, "nearestAboveVcg");
    });

    blpsBelow.forEach((blpBe) => {
      const { vcg, lcg, foreLcg, aftLcg, maxSize } = getNearestVcgFromTiers({
        blp: blpBe,
        isAbove: false,
      });
      addToBay(blpBe, vcg, lcg, foreLcg, aftLcg, maxSize, "nearestBelowVcg");
    });

    fillEmptyBaySlotsLcgs();
    supplyB20sLongPointsFor40s();

    return aboveBelowBlps;

    function getNearestVcgFromTiers({
      blp,
      isAbove,
    }: {
      blp: IBaySizesAndCgs;
      isAbove: boolean;
    }) {
      const tiers = Object.keys(blp.tiers).sort(
        isAbove ? sortNumericAsc : sortNumericDesc,
      ) as IRowPattern[];

      return {
        vcg: blp.tiers[tiers[0]]?.vcg,
        lcg: roundDec(blp.maxSizeLcg.lcg || 0, 4),
        maxSize: blp.tiers[tiers[0]]?.maxSize,
        foreLcg: roundDec(blp.maxSizeLcg.foreLcg || 0, 4),
        aftLcg: roundDec(blp.maxSizeLcg.aftLcg || 0, 4),
      };
    }

    function addToBay(
      blp: IBaySizesAndCgs,
      vcg: number | undefined,
      lcg: number,
      foreLcg: number,
      aftLcg: number,
      maxSize: number,
      propVcg: keyof Pick<
        IBayAboveBelowLVcgs,
        "nearestAboveVcg" | "nearestBelowVcg"
      >,
    ) {
      let pairedInstance = aboveBelowBlps[blp.bayIsoNumber];

      if (pairedInstance === undefined) {
        aboveBelowBlps[blp.bayIsoNumber] = {
          lcg,
          aftLcg: aftLcg,
          foreLcg: foreLcg,
          pairedBay: blp.pairedBay,
          maxSize,
          b20:
            maxSize < 40
              ? {
                  fwdLPoint: foreLcg,
                  aftLPoint: aftLcg,
                }
              : undefined,
        };
        pairedInstance = aboveBelowBlps[blp.bayIsoNumber];
      }

      if (pairedInstance.pairedBay === undefined && blp.pairedBay !== undefined)
        pairedInstance.pairedBay = blp.pairedBay;

      pairedInstance[propVcg] = vcg;

      if (pairedInstance.nearestAboveVcg !== undefined) {
        pairedInstance.midVcg = roundDec(
          pairedInstance.nearestAboveVcg - DISTANCE_TO_CONTAINER_FOR_SHIP_LINE,
        );
      }
    }

    function supplyB20sLongPointsFor40s() {
      const bays = Object.keys(aboveBelowBlps).sort();
      bays.forEach((bay) => {
        const bp = aboveBelowBlps[bay];
        if (bp.b20 || bp.maxSize < 40 || bp.pairedBay === undefined) return;

        const midMaxSize = bp.maxSize * 0.5;
        const isPairedFwd = bp.pairedBay === ForeAftEnum.FWD;
        const bpLcg = bp.lcg;

        bp.b20 = {
          fwdLPoint: isPairedFwd
            ? bpLcg
            : bpLcg +
              feetToMillimeters(!Number.isNaN(midMaxSize) ? midMaxSize : 0),
          aftLPoint: isPairedFwd
            ? bpLcg -
              feetToMillimeters(!Number.isNaN(midMaxSize) ? midMaxSize : 0)
            : bpLcg,
        };
      });
    }

    function fillEmptyBaySlotsLcgs() {
      const pairedKeys = Object.keys(aboveBelowBlps).sort(sortNumericAsc);

      for (let i = 0; i < pairedKeys.length; i += 1) {
        const bay = pairedKeys[i];
        const paired = aboveBelowBlps[bay];

        if (paired.pairedBay !== undefined) {
          const pairedBlp =
            aboveBelowBlps[
              paired.pairedBay === ForeAftEnum.FWD
                ? pairedKeys[i - 1]
                : pairedKeys[i + 1]
            ];

          if (!paired.lcg && pairedBlp.lcg !== undefined) {
            paired.lcg =
              pairedBlp.lcg +
              (paired.pairedBay === ForeAftEnum.FWD ? -1 : 1) *
                feetToMillimeters(20);
          }

          if (!paired.midVcg && pairedBlp.midVcg !== undefined) {
            paired.midVcg = pairedBlp.midVcg;
          }
        }
      }
    }
  }

  function generateShipLine(missingImportantXcgs: boolean): void {
    let lastXy: { x: number; y: number; move?: boolean } | undefined =
      undefined;

    let lastWasWrong = false;

    const generalMidVcg = calculateGeneralMidVcg(missingImportantXcgs);

    const segmentsXy: { x: number; y: number; move?: boolean }[] = [
      { x: roundDec(addX * 0.5), y: yRange - 1 }, // bottom-left
      { x: roundDec(xRange - addX * 0.5), y: yRange - 1 }, // bottom-right
    ];

    isoBaysArray.forEach((isoBay) => {
      const paired = pairedBlps[isoBay];
      const midVcg = generalMidVcg ?? paired.midVcg;

      if (midVcg === undefined) {
        lastWasWrong = true;
        return;
      }

      // Add parts of line

      if (segmentsXy.length === 2) {
        // top-right (nose of the ship)
        segmentsXy.push({
          x: xRange,
          y: yPos(midVcg + DISTANCE_TO_CONTAINER_FOR_SHIP_LINE, 1),
        });
        segmentsXy.push({
          x: roundDec(xRange - addX * 0.5),
          y: yPos(midVcg, 1),
        });
      }

      if (
        paired.b20 &&
        !Number.isNaN(paired.b20.fwdLPoint) &&
        !Number.isNaN(paired.b20.aftLPoint)
      ) {
        // We have correct info about LCGs of 20s
        lastXy = { x: xPos(paired.b20.fwdLPoint, 1), y: yPos(midVcg, 1) };
        if (lastWasWrong) lastXy.move = true;
        segmentsXy.push(lastXy);

        lastXy = { x: xPos(paired.b20.aftLPoint, 1), y: yPos(midVcg, 1) };
        segmentsXy.push(lastXy);
        lastWasWrong = false;
      } else {
        lastWasWrong = true;
      }
    });

    // Finally, add stern
    if (lastXy !== undefined) {
      segmentsXy.push({
        x: roundDec(addX * 0.6),
        y: (lastXy as { y: number }).y,
        move: lastWasWrong,
      });
      segmentsXy.push({
        x: roundDec(addX * 0.25),
        y: (lastXy as { y: number }).y,
      });
    }
    segmentsXy.push({ x: roundDec(addX * 0.3, 2), y: roundDec(yRange * 0.7) });
    segmentsXy.push({ x: roundDec(addX * 0.4), y: roundDec(yRange * 0.7) });

    // Prepare SVG paths
    const svgOk = createSvgPath({
      strokeColor: symbolsOptions?.shipStrokeColor,
      strokeWidth: symbolsOptions?.strokeWidth,
      fillOpactiy: 0,
    });
    svgOk.dataset["edx"] = "shipLine";

    const svgWrong = createSvgPath({
      strokeColor: symbolsOptions?.warningColor || "red",
      strokeWidth: symbolsOptions?.strokeWidth,
      fillOpactiy: 0,
    });
    svgWrong.dataset["edx"] = "shipLineErrors";

    const pathArr: string[] = [];
    const pathArrWrong: string[] = [];

    let prevXyOk: { x: number; y: number; move?: boolean } | undefined;
    segmentsXy.forEach((xy, i) => {
      pathArr.push(`${i === 0 || xy.move ? "M" : "L"}${xy.x},${xy.y}`);

      if (xy.move && prevXyOk) {
        pathArrWrong.push("M" + prevXyOk.x + "," + prevXyOk.y);
        pathArrWrong.push("L" + xy.x + "," + xy.y);
      }

      prevXyOk = xy;
    });

    const pathStr = `${pathArr.join(" ")} L${segmentsXy[0].x},${
      segmentsXy[0].y
    }`;

    if (pathStr.indexOf("Infinity") < 0) {
      svgOk.setAttribute("d", pathStr);
      svgWrong.setAttribute("d", pathArrWrong.join(" "));
    }

    svgNode.appendChild(svgOk);
    if (pathArrWrong.length) svgNode.appendChild(svgWrong);
  }

  function drawBulkheads(missingImportantXcgs: boolean, xRange: number): void {
    const generalMidVcg = calculateGeneralMidVcg(missingImportantXcgs);

    bayLevelPositionsBelow
      .filter(
        (m) => m.tiers && m.bulkhead && (m.bulkhead.fore || m.bulkhead.aft),
      )
      .forEach((maxByTier) => {
        const paired = pairedBlps[maxByTier.bayIsoNumber];
        const tiers = Object.keys(maxByTier.tiers) as IRowPattern[];

        let minBayVcg = Infinity,
          maxBayVcg = -Infinity;

        tiers.forEach((tier) => {
          const vcg = maxByTier.tiers[tier].vcg;
          if (vcg !== undefined && minBayVcg > vcg) minBayVcg = vcg;
          if (vcg !== undefined && maxBayVcg < vcg) maxBayVcg = vcg;
        });

        maxBayVcg = generalMidVcg || paired.midVcg || 0;

        if (maxByTier.bulkhead) {
          const hasFore = maxByTier.bulkhead.fore;
          const hasAft = maxByTier.bulkhead.aft;

          const maxLcg =
            Math.ceil(maxIsoBay * 0.5) * LCG_20_WITH_SEPARATION -
            SEPARATION_IN_BETWEEN;

          const baseLcg =
            maxLcg -
            Number(maxByTier.bayIsoNumber) * 0.5 * LCG_20_WITH_SEPARATION;

          if (hasFore) {
            const foreLcg =
              (!missingImportantXcgs
                ? maxByTier.bulkhead.foreLcg
                : undefined) || baseLcg + LCG_SEPARATION;

            addBulkSvgNode(foreLcg, minBayVcg, maxBayVcg);
          }
          if (hasAft) {
            const aftLcg =
              (!missingImportantXcgs ? maxByTier.bulkhead.aftLcg : undefined) ||
              baseLcg - LCG_SEPARATION;

            addBulkSvgNode(aftLcg, minBayVcg, maxBayVcg);
          }
        }
      });

    function addBulkSvgNode(lcg?: number, minV?: number, maxV?: number) {
      const BULK_WIDTH = 2;
      if (!lcg || !minV || !maxV) return;

      const x = xPos(lcg);
      if (Math.abs(x / xRange) > 1.5) return;

      const path = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "path",
      );

      path.setAttribute("data-type", "bulkhead");
      path.setAttribute("stroke", symbolsOptions?.shipStrokeColor || "red");
      path.setAttribute("fill", symbolsOptions?.shipStrokeColor || "red");
      path.setAttribute(
        "stroke-width",
        String(symbolsOptions?.strokeWidth || 0.1),
      );
      path.setAttribute("fill-opacity", "1");
      path.setAttribute(
        "d",
        `M${xPos(lcg + BULK_WIDTH)},${yPos(maxV)} h${BULK_WIDTH} V${
          yRange - 1
        } h-${BULK_WIDTH} z`,
      );
      svgNode.appendChild(path);
    }
  }

  function calculateGeneralMidVcg(
    missingImportantXcgs: boolean,
  ): number | undefined {
    if (!missingImportantXcgs) return undefined;

    let maxBelowVcg = -Infinity,
      minAboveVcg = Infinity;

    Object.keys(pairedBlps).forEach((bay) => {
      const p = pairedBlps[bay];
      if (p.nearestBelowVcg !== undefined && p.nearestBelowVcg > maxBelowVcg)
        maxBelowVcg = p.nearestBelowVcg;
      if (p.nearestAboveVcg !== undefined && p.nearestAboveVcg < minAboveVcg)
        minAboveVcg = p.nearestAboveVcg;
    });

    return (maxBelowVcg + minAboveVcg) * 0.5;
  }

  // function _helperDraw() {
  //   const path = document.createElementNS("http://www.w3.org/2000/svg", "path");
  //   path.setAttribute("stroke", "red");
  //   path.setAttribute("stroke-width", String(symbolsOptions?.strokeWidth || 1));
  //   path.setAttribute("fill-opacity", "0");
  //   path.setAttribute("d", `M${xPos(1955)},0 L${xPos(1955)},${yRange}`);
  //   svgNode.appendChild(path);
  // }
}

export interface ICreateSimpleSideViewProps {
  sizeSummary: ISizeSummary;
  lcgVcgTcgAndPairings: IGetBayLcgVcgAndPairingsResult;
  symbolsOptions?: IGenerateSideContainerSymbolsProps;
  bayNumbersDoubleClickAction?: (ev: SVGElement) => void;
}

export interface IBayAboveBelowLVcgs {
  [name: string]: {
    nearestAboveVcg?: number;
    nearestBelowVcg?: number;
    midVcg?: number;
    foreLcg: number;
    aftLcg: number;
    lcg: number;
    maxSize: number;
    pairedBay?: ForeAftEnum;
    b20?: {
      fwdLPoint: number;
      aftLPoint: number;
    };
  };
}
