import { IFormAndNode, createScreen } from "../../helpers/createScreen";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";

import DivWithSpinner from "../../common/divWithSpinner/div-with-spinner-element";
import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import ICustomError from "../../types/ICustomError";
import { IFields } from "@baplie-viewer2/tedivo-form";
import IntegratedDialogError from "../../common/IntegratedDialogError";
import Services from "../../../app/services";
import goSquared from "../../../app/tracking/goSquared";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/app.element";
import { z } from "zod";

export class TVDForgotPasswordComponent extends HTMLElement {
  public static observedAttributes = [];

  private i18nCR: I18nComponentRegisterer;
  private dialogError: IntegratedDialogError;
  private divWithSpinner: DivWithSpinner;
  private currentFormAndNode?: IFormAndNode<IAuthForgotPassword>;

  constructor() {
    super();
    this.i18nCR = new I18nComponentRegisterer(i18nReactive);
    this.dialogError = new IntegratedDialogError(this);
    this.divWithSpinner = new DivWithSpinner();

    setAppTitle(getTranslation("auth:forgotPassword"));
  }

  connectedCallback() {
    if (securityModule.isLoggedIn) {
      router.navigate(routeFns.login());
      return;
    }

    goSquared.trackPage("Forgot password");
    goSquared.addEvent("Forgot password - Show page");

    this.appendChild(this.divWithSpinner);

    this.setCurrentFormAndNode(
      createForgotPasswordScreen(this.onSubmitForgotPasswordForm, this.i18nCR),
    );
  }

  disconnectedCallback() {
    this.i18nCR.disconnect();
  }

  showError = (e: ICustomError) => {
    this.dialogError.show(
      getTranslation(`errors:errorHasOcurred`),
      getTranslation(typeof e === "string" ? e : e.translationKey),
    );
  };

  /** Replaces the UI */
  private setCurrentFormAndNode = (fan: typeof this.currentFormAndNode) => {
    this.currentFormAndNode = fan;

    if (fan?.node) {
      this.divWithSpinner.setLoading(false);
      removeChildren(this.divWithSpinner);
      this.divWithSpinner.appendChild(fan.node);
    }
  };

  private onSubmitForgotPasswordForm = async (values: IAuthForgotPassword) => {
    goSquared.addEvent("Forgot password - Submit");

    this.divWithSpinner.setLoading(true);
    const resp = await Services.accounts.forgotMyPassword(values.email);
    this.divWithSpinner.setLoading(false);

    if (!resp.data?.id) {
      this.showError({
        errorCode: String(resp.statusCode),
        message: resp.code || "errorModifyingUser",
        translationKey: resp.message || "errors:errorModifyingUser",
      });
      return false;
    } else {
      router.navigate(routeFns.login());
      return true;
    }
  };
}

customElements.define(
  "tvd-forgot-password-component",
  TVDForgotPasswordComponent,
);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-forgot-password-component": TVDForgotPasswordComponent;
  }
}

function createForgotPasswordScreen(
  onSubmitDataForm: (values: IAuthForgotPassword) => void,
  i18nCR: I18nComponentRegisterer,
): IFormAndNode<IAuthForgotPassword> | undefined {
  const AuthForgotPasswordFormValidator = z.object({
    email: z
      .string()
      .email()
      .transform((v) => v?.toLowerCase()),
  });

  const fields: IFields<IAuthForgotPassword> = [
    {
      name: "id",
      type: "title",
      label: "auth:forgotPasswordInstructions",
      tagName: "p",
    },
    {
      type: "textBox",
      name: "email",
      label: "general:common.email",
    },
  ];

  return createScreen<IAuthForgotPassword>({
    fields,
    onSubmitDataForm,
    formValidator: AuthForgotPasswordFormValidator,
    i18nCR,
    h1Text: "auth:forgotPassword",
    titleText: "auth:logIn",
  });
}

interface IAuthForgotPassword {
  id?: string;
  email: string;
}
