import HttpClient, { IResponseModel } from "./HttpClient";

import { routeFns } from "../router/routes";
import router from "../router";
import securityModule from "../security/SecurityModule";

export default class NetworkService {
  static async getMethod<TOutput>(url: string): Promise<IResponseModel<TOutput>> {
    // 0. Check if should be logged in
    const { idToken, sessionIsExpired } = securityModule;
    if (idToken && sessionIsExpired) {
      securityModule.signOut().then(() => {
        router.navigate(routeFns.login(), undefined, true);
      });
    }

    // 1. Make the request
    const client = new HttpClient();
    return await client.request<TOutput>({
      method: "GET",
      url,
    });
  }
}

