/* eslint-disable @typescript-eslint/no-explicit-any */

import HttpClient, {
  IResponseModel,
  IResponseModelWithPagination,
  TDataWithPagination,
} from "../../services/HttpClient";

import { ILogEvent } from "@baplie-viewer2/tedivo-api-models";

const domainsBaseUrl = "logEvents";

class LogEventsBeaconServiceClass {
  notifyXhttp = async (ev: ILogEvent): Promise<IResponseModel<void>> => {
    const client = new HttpClient();
    return await client.request<void>({
      method: "POST",
      url: `${domainsBaseUrl}`,
      data: ev,
    });
  };

  getFileLogEvents = async (
    orgId: string,
    fileId: string,
    lastEvaluatedKey?: string,
  ): Promise<IResponseModelWithPagination<ILogEvent[]>> => {
    const client = new HttpClient();
    const url = [
      `/fileEvents/${orgId}/${fileId}`,
      lastEvaluatedKey
        ? `?lastEvaluatedKey=${encodeURIComponent(
            lastEvaluatedKey.replace(/#/g, "|"),
          )}`
        : "",
    ].join("");

    return await client.request<TDataWithPagination<ILogEvent[]>>({
      method: "GET",
      url,
    });
  };
}

const LogEventsBeaconService = new LogEventsBeaconServiceClass();

export default LogEventsBeaconService;
