import { __awaiter } from "tslib";
import HttpClient from "../HttpClient";
import NetworkService from "../NetworkService";
const FilesService = {
    getAll: () => __awaiter(void 0, void 0, void 0, function* () {
        return NetworkService.getMethod("files");
    }),
    get: (id, noData) => __awaiter(void 0, void 0, void 0, function* () {
        return NetworkService.getMethod(`files/${id}${noData ? "?noData=true" : ""}`);
    }),
    isCommunityFile: (orgId, id) => __awaiter(void 0, void 0, void 0, function* () {
        return NetworkService.getMethod(`files/isCommunity/${orgId}/${id}`);
    }),
    getAllAdmin: (orgId) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "GET",
            url: `files/org/${orgId}`,
        });
    }),
    getAdmin: (id, orgId) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "GET",
            url: `files/${id}/${orgId}`,
        });
    }),
    delete: (id) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "DELETE",
            url: `files/${id}`,
            data: undefined,
        });
    }),
    create: (data) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "PUT",
            url: "files",
            data,
        });
    }),
    update: (data) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "PUT",
            url: "files",
            data,
        });
    }),
    changeFileState: (fileId, newState, comments) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "PUT",
            url: "files/fileState",
            data: {
                fileId,
                newState,
                comments,
            },
        });
    }),
    clone: (fileId, organizationId, onlyCommunity, newFileName) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        const nName = newFileName ? `?name=${encodeURI(newFileName)}` : "";
        return yield client.request({
            method: "PUT",
            url: `files/clone/${fileId}/${organizationId}${nName}`,
        });
    }),
    ignoreCloneDiffers: (fileId) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "PUT",
            url: `files/ignoreCloneDiffers/${fileId}`,
        });
    }),
    ignoreConsumerDiffers: (details) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "PUT",
            url: `files/ignoreConsumerDiffers`,
            data: Object.assign({}, details),
        });
    }),
    compareSourceTvl: (fileId) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "POST",
            url: `files/compareSource/${fileId}`,
        });
    }),
    compareOwnVersion: (fileId, version) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "POST",
            url: `files/compareVersion/${fileId}?v=${version}`,
        });
    }),
    compareConsumerTvl: (details) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "POST",
            url: `files/compareConsumer`,
            data: Object.assign({}, details),
        });
    }),
    updateWithSource: (fileId) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "POST",
            url: `files/updateWithSource/${fileId}`,
        });
    }),
    updateWithVersion: (fileId, version) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "POST",
            url: `files/updateWithVersion/${fileId}?v=${version}`,
        });
    }),
    updateWithConsumer: (details) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "POST",
            url: `files/updateWithConsumer`,
            data: Object.assign({}, details),
        });
    }),
    search: (term) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "GET",
            url: `files/search?q=${term}`,
        });
    }),
    getTvlVotes: (orgId, fileId) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "GET",
            url: `fileVotes/${orgId}/${fileId}`,
        });
    }),
    toggleOwnTvlVote: (fileOrgId, fileId) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "PUT",
            url: `fileVotes`,
            data: {
                fileOrgId,
                fileId,
            },
        });
    }),
    getFileVersions: (orgId, fileId) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "GET",
            url: `files/versions/${orgId}/${fileId}`,
        });
    }),
    getFileVersion: (orgId, fileId, date) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "POST",
            url: `files/versions`,
            data: {
                fileId,
                organizationId: orgId,
                date: date,
            },
        });
    }),
    getFileConsumers: (orgId, fileId) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "GET",
            url: `files/consumers/${orgId}/${fileId}`,
        });
    }),
    getFileNotes: (orgId, fileId, lastEvaluatedKey) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        const url = [
            `/fileNotes/${orgId}/${fileId}`,
            lastEvaluatedKey
                ? `?lastEvaluatedKey=${encodeURIComponent(lastEvaluatedKey.replace(/#/g, "|"))}`
                : "",
        ].join("");
        return yield client.request({
            method: "GET",
            url,
        });
    }),
    addFileNote: (fileId, note) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "PUT",
            url: `/fileNotes`,
            data: {
                fileId,
                note,
            },
        });
    }),
    deleteFileNote: (fileId, date) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "DELETE",
            url: `/fileNotes/delete/${fileId}/${date}`,
        });
    }),
};
export default FilesService;
