import LenUnitsEnum from "../enums/LenUnitsEnum";
import MassUnitsEnum from "../enums/MassUnitsEnum";
import { PortStarboardEnum } from "open-vessel-definition";
import UoLcgReferenceEnum from "../enums/UoLcgReferenceEnum";
import VcgVerticalReferenceEnum from "../enums/VcgVerticalReferenceEnum";
import { getPreferencesValue } from "@baplie-viewer2/tedivo-preferences";
import ovdJsonStore from "./OVDJsonStore";

class GlobalStore {
  private lastUserInteractionDate: Date | null = null;

  units: IGlobalUnits = {
    length: LenUnitsEnum.METERS,
    mass: MassUnitsEnum.TONNES,
    lcgRef: UoLcgReferenceEnum.AFT_PERPENDICULAR_DFWD,
    tcgDir: PortStarboardEnum.PORT,
    vcgRef: VcgVerticalReferenceEnum.BOTTOM_BASE,
  };

  get resolvedColorMode() {
    const savedColorMode = getPreferencesValue("color-mode");
    const darkMode =
      savedColorMode === "dark" ||
      ((!savedColorMode || savedColorMode === "auto") &&
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches);

    return darkMode ? "dark" : "light";
  }

  screenColorMode: "light" | "dark" =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light";

  formattedJson = false;

  get lastUserInteraction(): Date | null {
    return this.lastUserInteractionDate;
  }

  constructor() {
    const lengthUnitsSaved = getPreferencesValue("units-length"),
      massUnitsSaved = getPreferencesValue("units-mass"),
      lcgRefSaved = getPreferencesValue("units-lcgRef"),
      tcgDirSaved = getPreferencesValue("units-tcgRef"),
      vcgRefSaved = getPreferencesValue("units-vcgRef"),
      saveFormattedJson = getPreferencesValue("json-formatted");

    if (lengthUnitsSaved) this.units.length = Number(lengthUnitsSaved);
    if (massUnitsSaved) this.units.mass = Number(massUnitsSaved);
    if (lcgRefSaved) this.units.lcgRef = Number(lcgRefSaved);
    if (tcgDirSaved) this.units.tcgDir = Number(tcgDirSaved);
    if (vcgRefSaved) this.units.vcgRef = Number(vcgRefSaved);

    if (saveFormattedJson)
      this.formattedJson =
        saveFormattedJson === "true" || saveFormattedJson === true;

    ovdJsonStore.addEventListener("jsonUpdated", () => {
      this.touchLastUserInteraction();
    });
  }

  touchLastUserInteraction() {
    this.lastUserInteractionDate = new Date();
  }
}

const globalStore = new GlobalStore();
export default globalStore;

interface IGlobalUnits {
  length: LenUnitsEnum;
  mass: MassUnitsEnum;
  lcgRef: UoLcgReferenceEnum;
  tcgDir: PortStarboardEnum;
  vcgRef: VcgVerticalReferenceEnum;
}
