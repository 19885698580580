export function openOvdInTvl(id: string | undefined, tvlDomain: string) {
  if (!id) return;

  const fileId = id.indexOf(":") > -1 ? id.split(":")[1] : id;

  if (!fileId) return;

  const url = `${tvlDomain}/redirect/${fileId}`;

  window.open(url, "_blank");
}
