import {
  IFields,
  TedivoForm,
  translateTedivoForm,
} from "@baplie-viewer2/tedivo-form";
import {
  LogEventEntitiesEnum,
  LogEventTypesEnum,
} from "@baplie-viewer2/tedivo-api-models";

import BeaconServices from "../../../../../../../app/beaconServices";
import { IOpenVesselDefinitionV1 } from "open-vessel-definition";
import { getTranslation } from "../../../../../../../app/i18/i18tn";
import goSquared from "../../../../../../../app/tracking/goSquared";
import ovdJsonStore from "../../../../../../..//app/stores/OVDJsonStore";
import { removeCGsfromJson } from "../../../../serv/removeCGsfromJson";
import securityModule from "../../../../../../../app/security/SecurityModule";
import { z } from "zod";

export function createRemoveCGsDialog(
  json: IOpenVesselDefinitionV1 | undefined,
): IEditReturn {
  if (!json) {
    return { node: document.createElement("div"), submitFunction: () => false };
  }

  goSquared.addEvent("Edit-OVD - Remove CGs");

  const holder = document.createElement("div");
  const tedivoForm = createRemoveCGsDialogForm();
  holder.appendChild(tedivoForm.form);

  return {
    node: holder,
    submitFunction: submitPassedToEditDrawer,
  };

  function submitPassedToEditDrawer(): boolean {
    if (!json || !ovdJsonStore.tvdId) return false;

    const validate = tedivoForm.doSubmitForm();
    if (!validate.success) return false;

    const confirmation = tedivoForm.getValues().confirmation;

    if (!confirmation) return false;

    const newJson = removeCGsfromJson(json);
    ovdJsonStore.replaceJson(newJson);

    BeaconServices.logEvents.notifyXhttp({
      eventEntity: LogEventEntitiesEnum.File,
      eventType: LogEventTypesEnum.Modified,
      subEvent: "BayCGs",
      itemId: ovdJsonStore.tvdId.fileId,
      ...securityModule.getBeaconMetadata(),
    });

    goSquared.addEvent("Edit-OVD - Remove CGs - Save");

    return true;
  }
}

function createRemoveCGsDialogForm(): TedivoForm<IRemoveCGsForm> {
  const fields: IFields<IRemoveCGsForm> = [
    [
      {
        name: "confirmation",
        type: "checkbox",
        label: "view:importStaf.confirmRemoveCGs",
        initialValue: false,
        padStart: true,
      },
    ],
  ];

  const validator: z.Schema<IRemoveCGsForm> = z.object({
    confirmation: z.boolean().refine((v) => !!v),
  });

  const tedivoForm = new TedivoForm<IRemoveCGsForm>({
    fields,
    onSubmit: () => null,
    formValidator: validator,
    submitButton: document.createElement("button"),
  });

  translateTedivoForm<IRemoveCGsForm>({ tedivoForm, getTranslation });

  return tedivoForm;
}

interface IEditReturn {
  node: HTMLElement;
  submitFunction: () => boolean;
}

interface IRemoveCGsForm extends Record<string, unknown> {
  confirmation: boolean;
}
