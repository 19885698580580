/* eslint-disable @typescript-eslint/no-explicit-any */

import { getTranslation, i18nReactive } from "../../app/i18/i18tn";

import DivWithSpinner from "./divWithSpinner/div-with-spinner-element";
import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import ICustomError from "../types/ICustomError";
import IntegratedDialogError from "./IntegratedDialogError";
import SlButton from "@shoelace-style/shoelace/dist/components/button/button";
import { TedivoForm } from "@baplie-viewer2/tedivo-form";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";

export abstract class ComponentWithFormAndNode<
  T extends Record<string, any>,
> extends HTMLElement {
  public currentFormAndNode?: IFormAndNode<T> | IFormAndNode<IEmpty>;
  public divWithSpinner: DivWithSpinner;
  public dialogError: IntegratedDialogError;
  public i18nCR: I18nComponentRegisterer;

  private onDissmissDialog: (() => void) | undefined = undefined;

  constructor() {
    super();

    this.dialogError = new IntegratedDialogError(this);
    this.i18nCR = new I18nComponentRegisterer(i18nReactive);
    this.divWithSpinner = document.createElement("div-spinner-element");

    this.dialogError.onDismiss = () => {
      if (this.onDissmissDialog) this.onDissmissDialog();
    };
  }

  /** Replaces the UI */
  public setCurrentFormAndNode(fan: typeof this.currentFormAndNode) {
    this.currentFormAndNode = fan;

    if (fan?.node) {
      this.divWithSpinner.setLoading(false);
      removeChildren(this.divWithSpinner);
      this.divWithSpinner.appendChild(fan.node);
    }
  }

  public showDialog = (
    e: ICustomError,
    title = `errors:errorHasOcurred`,
    onDissmissDialog?: () => void,
  ) => {
    if (onDissmissDialog) this.onDissmissDialog = onDissmissDialog;

    this.dialogError.show(
      getTranslation(title),
      getTranslation(typeof e === "string" ? e : e.translationKey),
    );

    console.error(e);
  };
}

export interface IFormAndNode<T extends Record<string, any>> {
  node: HTMLElement;
  form?: TedivoForm<T>;
  submitButton?: SlButton;
}

interface IEmpty {
  id?: string;
}
