import { stylesFromStringToTag } from "@baplie-viewer2/tedivo-dom-helpers";
export class CardElement extends HTMLElement {
    get actions() {
        return this.intActions;
    }
    set actions(v) {
        if (v === null)
            return;
        this.intActions = v;
        const actionsDiv = document.createElement("div");
        actionsDiv.appendChild(v);
        actionsDiv.slot = "actions";
        this.titlePart.appendChild(actionsDiv);
    }
    constructor() {
        super();
        this.titleHtml = "";
        this.name = "";
        this.intActions = null;
        this.titlePart = document.createElement("title-with-actions-component");
    }
    connectedCallback() {
        const shadowRoot = this.attachShadow({ mode: "open" });
        const holder = document.createElement("div");
        holder.className = "card-element-holder";
        const titlePart = this.titlePart;
        titlePart.titleHtml = this.titleHtml;
        titlePart.titleNodeName = "h2";
        this.titlePart = titlePart;
        const slot = document.createElement("slot");
        holder.appendChild(titlePart);
        holder.appendChild(slot);
        shadowRoot.appendChild(stylesFromStringToTag(styles));
        shadowRoot.appendChild(holder);
    }
}
CardElement.observedAttributes = [];
customElements.define("card-element", CardElement);
const styles = `
  .card-element-holder {
    
    background-color: var(--sl-color-neutral-0);
    border-radius: var(--sl-input-border-radius-medium);
    border: solid var(--sl-panel-border-width) var(--sl-panel-border-color);
    padding: var(--sl-spacing-large);
    margin-bottom: var(--sl-spacing-medium);
  }

  title-with-actions-component {
    display: flex;
  }
`;
