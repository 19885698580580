import { __awaiter } from "tslib";
import HttpClient from "../HttpClient";
const MarineTrafficService = {
    searchVesselByImoCode: (imoCode, forceApi = false) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        const url = [
            `vesselInfo/byImo/${encodeURI(imoCode)}`,
            forceApi ? "forceApi=true" : "",
        ]
            .filter(Boolean)
            .join("?");
        return yield client.request({
            method: "GET",
            url,
        });
    }),
    searchVesselByImoCodeWithParticulars: (imoCode) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "GET",
            url: `vesselInfo/byImoWithParticulars/${encodeURI(imoCode)}`,
        });
    }),
};
export default MarineTrafficService;
