import {
  BayLevelEnum,
  ForeAftEnum,
  IBayLevelData,
  IBaySlotData,
  IFeaturesAllowed,
  ISizeSummary,
  ISlotData,
  TContainerLengths,
  ValuesSourceEnum,
} from "open-vessel-definition";
import {
  EditToolsComponent,
  ICustomEventEmphasizeCheckbox,
} from "./edit-tools.component";
import {
  IBayPattern,
  ICellName,
  ICreateGridFromConfigProps,
  getSizesFromSlotDataArray,
} from "@baplie-viewer2/tedivo-bay-grid-core";
import {
  IFields,
  TedivoForm,
  translateTedivoForm,
} from "@baplie-viewer2/tedivo-form";
import {
  IJoinedRowTierPattern,
  TYesNo,
} from "open-vessel-definition/build/src/models/base/types/IPositionPatterns";
import {
  getPreferencesValue,
  setPreferencesKeyAndValue,
} from "@baplie-viewer2/tedivo-preferences";
import {
  modifyCellsWithActions,
  slotDataIsNotEmpty,
  slotIdJoinedToMixed,
} from "./serv/modifyCellsWithActions";
import { pad2, pad3 } from "@baplie-viewer2/tedivo-pure-helpers";

import EditDrawer from "../../common/EditDrawer";
import EditToolsEnum from "./types/EditToolsEnum";
import { IEditBayFields } from "./parts/edits/openBayEdit";
import { IOptionChanged } from "../../../../typings";
import { ISlotSizeOptions } from "open-vessel-definition/build/src/models/v1/parts/ISlotData";
import { IToolsState } from "./types/IToolsState";
import { InteractiveGrid } from "@baplie-viewer2/tedivo-bay-grid-interactive";
import SlDialog from "@shoelace-style/shoelace/dist/components/dialog/dialog";
import TYesNoEnum from "../../../app/enums/TYesNoEnum";
import { getTranslation } from "../../../app/i18/i18tn";
import ovdJsonStore from "../../../app/stores/OVDJsonStore";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { z } from "zod";

const BAY_HELPERS_PREF_ID = "bayHelpers-copyBayData";

export class BayEditComponent extends HTMLElement {
  data: IBayEditComponentData = {} as IBayEditComponentData;

  public onSlotsChanged?: (data: ISlotData[]) => void;

  private toolsState: IToolsState = {
    activeTool: EditToolsEnum.ADD_MANY,
    tools: {
      [EditToolsEnum.ADD_MANY]: [],
      [EditToolsEnum.ADD_REMOVE_SINGLE]: [],
      [EditToolsEnum.CLEAR]: [],
      [EditToolsEnum.ADD_REMOVE_RESTRICTED]: [],
      [EditToolsEnum.ADD_REMOVE_SPECIAL]: [],
    },
  };

  private internalProhibitedCells?: Array<ICellName>;
  private internalCenterLineRow: TYesNoEnum = 0;
  private internalPairedBay: ForeAftEnum | undefined;
  private internalCellsData: Array<ISlotData> = [];
  public cellsDataIsDirty = false;

  //private cellsData?: Array<ISlotData>;
  private emphasizeCells?: ICellName[];
  private mirrorSlotsDefinition: boolean;

  private interactiveGrid: InteractiveGrid<ISlotData>;
  private interactiveTools: EditToolsComponent;
  private editDrawer: EditDrawer | undefined = undefined;

  private helpersCommonDialog: SlDialog;

  public postSaveActions: Array<IPostSaveAction> = [];

  public updateDetailsForm: (data: Partial<IEditBayFields>) => void = () =>
    undefined;

  get cellsData(): Array<ISlotData> {
    return this.internalCellsData;
  }
  set cellsData(cd: Array<ISlotData> | undefined) {
    this.internalCellsData = cd || [];
    this.cellsDataIsDirty = true;
  }

  constructor() {
    super();

    this.interactiveGrid = new InteractiveGrid<ISlotData>();
    this.interactiveGrid.className = "svg-bay-edit";
    this.interactiveGrid.onSelection = this.onUserDiagramSelected;

    this.interactiveTools = new EditToolsComponent();
    this.mirrorSlotsDefinition = this.interactiveTools.mirrorSlotsDefinition;

    this.helpersCommonDialog = document.createElement("sl-dialog");
    this.helpersCommonDialog.addEventListener(
      "sl-after-hide",
      (ev) => {
        if (ev.target === this.helpersCommonDialog) {
          this.keepDrawerOpened(false);
          removeChildren(this.helpersCommonDialog);
        }
      },
      false,
    );
    this.helpersCommonDialog.addEventListener(
      "sl-after-show",
      (ev) => {
        if (ev.target === this.helpersCommonDialog) this.keepDrawerOpened(true);
      },
      false,
    );
  }

  get prohibitedCells() {
    return this.internalProhibitedCells || [];
  }
  set prohibitedCells(pc: Array<ICellName>) {
    this.internalProhibitedCells = pc;
    this.updateGrid();
  }

  get centerLineRowValue() {
    return this.internalCenterLineRow;
  }
  set centerLineRowValue(n: TYesNoEnum) {
    this.internalCenterLineRow = n;
    this.updateGrid();
  }

  get pairedBayValue() {
    return this.internalPairedBay;
  }
  set pairedBayValue(v: ForeAftEnum | undefined) {
    this.internalPairedBay = v;
    this.emphasizeSlots(this.interactiveTools.emphasizedValue);
    this.interactiveTools.emphasizeCheckboxDisabled = !v;
  }

  connectedCallback() {
    const div = document.createElement("div");
    div.className = "bec";

    this.interactiveTools.containersLengths = this.data.availableLengths;
    this.interactiveTools.featuresAllowed = this.data.featuresAllowed;

    this.interactiveTools.addEventListener(
      "emphasizePairedSlotsChanged",
      ((ev: CustomEvent<ICustomEventEmphasizeCheckbox>) => {
        this.emphasizeSlots(ev.detail.isChecked);
      }) as EventListenerOrEventListenerObject,
      false,
    );

    this.interactiveTools.addEventListener(
      "mirrorSlotsDefinitionChanged",
      ((ev: CustomEvent<ICustomEventEmphasizeCheckbox>) => {
        this.mirrorSlotsDefinition = ev.detail.isChecked;
      }) as EventListenerOrEventListenerObject,
      false,
    );

    this.interactiveTools.addEventListener(
      "copyFromBay",
      this.copyFromBay as EventListener,
      { capture: false },
    );

    this.interactiveTools.addEventListener(
      "remapTiers",
      this.remapTiers as EventListener,
      { capture: false },
    );

    this.interactiveTools.addEventListener(
      "move40Definitions",
      this.move40Definitions as EventListener,
      { capture: false },
    );

    this.interactiveTools.addEventListener(
      "optionChanged",
      this.onToolsChanged as EventListener,
      { capture: false },
    );

    this.toolsState = this.interactiveTools.state;

    div.appendChild(this.interactiveTools);
    div.appendChild(this.interactiveGrid);

    this.appendChild(div);
    this.appendChild(this.helpersCommonDialog);

    this.mirrorSlotsDefinition = this.interactiveTools.mirrorSlotsDefinition;
  }

  initialize(
    isoBay: IBayPattern,
    level: BayLevelEnum,
    gridData: ICreateGridFromConfigProps<ISlotData>,
    availableLengths: Array<TContainerLengths>,
    maxIsoBay: number,
    featuresAllowed: IFeaturesAllowed | undefined,
    bayData: IBayLevelData | undefined,
    editDrawer: EditDrawer,
  ) {
    this.data.isoBay = isoBay;
    this.data.level = level;
    this.data.gridData = gridData;
    this.data.availableLengths = availableLengths;
    this.data.maxIsoBay = maxIsoBay;
    this.data.featuresAllowed = featuresAllowed;
    this.data.bayData = bayData;

    this.internalCellsData = gridData.cellsToDraw?.map(cloneSlotData) || [];

    this.interactiveGrid.initialize(gridData);
    this.internalCenterLineRow = gridData.centerLineRow;

    this.editDrawer = editDrawer;

    return this;
  }

  private onToolsChanged = (ev: CustomEvent<IOptionChanged>) => {
    this.toolsState = ev.detail.state;
  };

  private onUserDiagramSelected = (positionsSelected: ICellName[]) => {
    if (!positionsSelected || !positionsSelected.length) return;

    const positionsSelectedToUse = this.mirrorSlotsDefinition
      ? mirrorSlots(positionsSelected)
      : positionsSelected;

    this.cellsData = modifyCellsWithActions(
      this.toolsState,
      positionsSelectedToUse,
      this.cellsData || [],
    )
      .filter(slotDataIsNotEmpty)
      .filter(
        (slotData) =>
          !this.internalProhibitedCells ||
          this.internalProhibitedCells.indexOf(
            slotIdJoinedToMixed(slotData.pos),
          ) < 0,
      );

    this.updateGrid();

    if (this.onSlotsChanged) {
      this.onSlotsChanged(this.cellsData);
    }
  };

  private updateGrid = () => {
    this.interactiveGrid.updateCellData(
      this.cellsData || [],
      this.internalCenterLineRow,
      this.prohibitedCells,
      getSlotPos,
      this.emphasizeCells,
    );
  };

  private emphasizeSlots = (doEmphasize: boolean) => {
    // 1. Get Bay data
    const bayData = this.data.bayData;
    const pairedBayAdd = this.internalPairedBay;

    if (!doEmphasize || !bayData || !pairedBayAdd) {
      this.emphasizeCells = [];
      this.updateGrid();
      return;
    }

    const bay =
      Number(this.data.isoBay) + (pairedBayAdd === ForeAftEnum.AFT ? 2 : -2);

    if (bay >= 1 && bay <= this.data.maxIsoBay) {
      const bayInfo = ovdJsonStore.findBayInfo(pad3(bay), this.data.level);

      if (bayInfo?.perSlotInfo !== undefined) {
        this.emphasizeCells = (
          Object.keys(bayInfo.perSlotInfo) as IJoinedRowTierPattern[]
        ).map(slotIdJoinedToMixed);
      }
    } else {
      this.emphasizeCells = [];
    }

    // 2. Update
    this.updateGrid();
  };

  private copyFromBay = () => {
    if (ovdJsonStore.currentJson === undefined) return;
    const json = ovdJsonStore.currentJson;

    const helpersCommonDialog = this.helpersCommonDialog;
    const isoBay = this.data.isoBay;
    const level = this.data.level;
    const updateDetailsForm = this.updateDetailsForm;
    const updateCellsData = (cellsData: ISlotData[]) => {
      this.cellsData = cellsData;
      this.updateGrid();
      if (this.onSlotsChanged) {
        this.onSlotsChanged(this.cellsData);
      }
    };

    helpersCommonDialog.label = getTranslation(
      "view:edit.helpers.copyFromBay.title",
    );
    helpersCommonDialog.setAttribute("style", "--width: 900px");

    const btn = document.createElement("sl-button");
    btn.innerHTML = getTranslation("general:common.ok");
    btn.slot = "footer";
    btn.variant = "primary";
    btn.type = "submit";
    btn.addEventListener("click", execute, false);

    const { fields, validator } = createCopyDataFields(
      isoBay,
      level,
      json.sizeSummary,
      json.shipData.containersLengths,
      json.shipData.featuresAllowed,
    );

    const tedivoForm = new TedivoForm<ICopyBayData>({
      fields,
      onSubmit: () => null,
      formValidator: validator,
      submitButton: btn,
    });

    translateTedivoForm<ICopyBayData>({ tedivoForm, getTranslation });

    helpersCommonDialog.appendChild(tedivoForm.form);
    helpersCommonDialog.appendChild(btn);

    helpersCommonDialog.show();

    function execute() {
      executeCopyData(
        isoBay,
        tedivoForm.getValues(),
        updateDetailsForm,
        updateCellsData,
      );
      helpersCommonDialog.hide();
    }
  };

  private remapTiers = () => {
    if (ovdJsonStore.currentJson === undefined) return;

    const helpersCommonDialog = this.helpersCommonDialog;
    const cellsData = this.cellsData || [];
    const bayTiers = cellsData.map((s) => Number(s.pos.substring(2)));
    const minTier = Math.min(...bayTiers);
    const maxTier = Math.max(...bayTiers);
    const tierDiff = maxTier - minTier;
    const sizeSummary = ovdJsonStore.currentJson.sizeSummary;

    helpersCommonDialog.label = getTranslation(
      "view:edit.helpers.remapTiers.title",
    );
    helpersCommonDialog.setAttribute("style", "--width: 600px");

    const btn = document.createElement("sl-button");
    btn.innerHTML = getTranslation("general:common.ok");
    btn.slot = "footer";
    btn.variant = "primary";
    btn.type = "submit";
    btn.addEventListener(
      "click",
      () => {
        const newCellsData = doTheTranslation();
        if (newCellsData) {
          this.cellsData = newCellsData;

          if (this.onSlotsChanged) {
            this.onSlotsChanged(this.cellsData);
          }

          this.updateGrid();
        }
      },
      false,
    );

    const options: { value: string; label: string }[] = [];
    const tierFrom = Number(
      this.data.level === BayLevelEnum.ABOVE
        ? sizeSummary.minAboveTier
        : sizeSummary.minBelowTier,
    );
    const tierTo =
      Number(
        this.data.level === BayLevelEnum.ABOVE
          ? sizeSummary.maxAboveTier
          : sizeSummary.maxBelowTier,
      ) - tierDiff;

    for (let i = tierTo; i >= tierFrom; i -= 2) {
      options.push({ value: pad2(i), label: pad2(i) });
    }

    const fields: IFields<IRemapTiersFields> = [
      {
        name: "newTier",
        type: "select",
        label: "view:tier",
        options,
        initialValue: pad2(minTier),
        hoist: true,
      },
    ];

    const validator: z.Schema<IRemapTiersFields> = z.object({
      newTier: z.number(),
    });

    const tedivoForm = new TedivoForm<IRemapTiersFields>({
      fields,
      onSubmit: () => null,
      formValidator: validator,
      submitButton: btn,
    });

    translateTedivoForm<IRemapTiersFields>({ tedivoForm, getTranslation });

    removeChildren(helpersCommonDialog);

    helpersCommonDialog.innerHTML = getTranslation(
      "view:edit.helpers.remapTiers.intro",
      { minTier },
    );

    tedivoForm.form.classList.add("tvd-form-margin-top");

    helpersCommonDialog.appendChild(tedivoForm.form);
    helpersCommonDialog.appendChild(btn);

    helpersCommonDialog.show();

    function doTheTranslation(): ISlotData[] | null {
      helpersCommonDialog.hide();
      const newDiff = Number(tedivoForm.getValues().newTier) - minTier;
      if (newDiff === 0) return null;

      const newCellsData: ISlotData[] = JSON.parse(JSON.stringify(cellsData));

      newCellsData.forEach((slotData) => {
        const row = Number(slotData.pos.substring(0, 2));
        const tier = Number(slotData.pos.substring(2));
        slotData.pos = `${pad2(row)}${pad2(tier + newDiff)}`;
      });

      return newCellsData;
    }
  };

  private move40Definitions = () => {
    if (ovdJsonStore.currentJson === undefined) return;

    const helpersCommonDialog = this.helpersCommonDialog;
    const cellsData = this.cellsData || [];
    const sizeSummary = ovdJsonStore.currentJson.sizeSummary;
    const cellsDataHas40s = getSizesFromSlotDataArray(cellsData).some(
      (size) => size >= 40,
    );

    const shipData = ovdJsonStore.currentJson.shipData;
    const shipHasCGs =
      shipData.lcgOptions?.values === ValuesSourceEnum.KNOWN ||
      shipData.vcgOptions?.values === ValuesSourceEnum.KNOWN ||
      shipData.tcgOptions?.values === ValuesSourceEnum.KNOWN;

    helpersCommonDialog.label = getTranslation(
      "view:edit.helpers.move40Definitions.title",
    );
    helpersCommonDialog.setAttribute("style", "--width: 600px");

    const btn = document.createElement("sl-button");
    btn.innerHTML = getTranslation("general:common.ok");
    btn.slot = "footer";
    btn.variant = "primary";
    btn.type = "submit";
    btn.addEventListener(
      "click",
      () => {
        const movedCells = doTheMovement(
          this.data.isoBay,
          this.data.level,
          this.pairedBayValue,
          cellsDataHas40s,
        );
        if (movedCells) {
          this.cellsData = movedCells.newCellsData;

          if (movedCells.postAction)
            this.postSaveActions.push(movedCells.postAction);

          this.updateGrid();

          if (this.onSlotsChanged) {
            this.onSlotsChanged(this.cellsData);
          }
        }
      },
      false,
    );

    removeChildren(helpersCommonDialog);

    if (cellsDataHas40s) {
      if (this.pairedBayValue) {
        const pairedBay =
          Number(this.data.isoBay) +
          (this.pairedBayValue === ForeAftEnum.FWD ? -2 : 2);

        if (pairedBay < 1 || pairedBay > sizeSummary.isoBays) {
          helpersCommonDialog.innerHTML = getTranslation(
            "view:edit.helpers.move40Definitions.incorrect",
          );
        } else {
          helpersCommonDialog.innerHTML = `${getTranslation(
            "view:edit.helpers.move40Definitions.intro",
            { pairedBay: pad3(pairedBay) },
          )}${
            shipHasCGs
              ? "<br /><br /><strong>" +
                getTranslation(
                  "view:edit.helpers.move40Definitions.reEnterCGs",
                ) +
                "</strong>"
              : ""
          }`;
        }
      } else {
        helpersCommonDialog.innerHTML = getTranslation(
          "view:edit.helpers.move40Definitions.notPaired",
        );
      }
    } else {
      helpersCommonDialog.innerHTML = getTranslation(
        "view:edit.helpers.move40Definitions.no40s",
      );
    }

    helpersCommonDialog.appendChild(btn);
    helpersCommonDialog.show();

    /** This function moves out the 40s and creates a postAction to add them to the pairedBay */
    function doTheMovement(
      isoBay: IBayPattern,
      level: BayLevelEnum,
      currentIsPaired: ForeAftEnum | undefined,
      cellsDataHas40s: boolean,
    ): { newCellsData: ISlotData[]; postAction?: IPostSaveAction } | null {
      helpersCommonDialog.hide();
      if (!currentIsPaired || !cellsDataHas40s) return null;

      const pairedBay =
        Number(isoBay) + (currentIsPaired === ForeAftEnum.FWD ? -2 : 2);

      if (pairedBay < 1 || pairedBay > sizeSummary.isoBays) return null;

      const newCellsData: ISlotData[] = JSON.parse(JSON.stringify(cellsData));
      /** Contains the 40s data for the paired bay */
      const infoOf40s: { [pos: IJoinedRowTierPattern]: TContainerLengths[] } =
        {};

      for (let i = 0; i < newCellsData.length; i++) {
        const slotData = newCellsData[i];
        const sizesInSlot = Object.keys(slotData.sizes).map(
          Number,
        ) as TContainerLengths[];

        sizesInSlot.forEach((size) => {
          if (size >= 40) {
            // Delete from current slot
            delete slotData.sizes[size];
            // Save the data to move it later
            if (!infoOf40s[slotData.pos]) infoOf40s[slotData.pos] = [];
            infoOf40s[slotData.pos].push(size);
          }
        });

        const remainingSizes = Object.keys(slotData.sizes).length;
        if (!remainingSizes) slotData.restricted = 1;
      }

      const positionsToComplete = Object.keys(
        infoOf40s,
      ) as IJoinedRowTierPattern[];

      if (!positionsToComplete.length) return { newCellsData };

      const postAction: IPostSaveAction = {
        isoBay: pad3(pairedBay),
        level,
        action: (bl: IBayLevelData) => {
          const newPerSlotInfo: IBaySlotData = JSON.parse(
            JSON.stringify(bl.perSlotInfo || {}),
          );

          positionsToComplete.forEach((pos) => {
            if (!newPerSlotInfo[pos]) newPerSlotInfo[pos] = { pos, sizes: {} };
            if (!newPerSlotInfo[pos].sizes) newPerSlotInfo[pos].sizes = {};

            if (infoOf40s[pos]) {
              infoOf40s[pos].forEach((size) => {
                newPerSlotInfo[pos].sizes[size] = 1;
              });
              delete newPerSlotInfo[pos].restricted;
            }
          });

          return {
            ...bl,
            perSlotInfo: newPerSlotInfo,
          };
        },
      };

      return { newCellsData, postAction };
    }
  };

  private keepDrawerOpened = (isOpened: boolean) => {
    if (this.editDrawer) this.editDrawer.shouldNotClose = isOpened;
  };
}

customElements.define("tvd-bay-edit-component", BayEditComponent);

function mirrorSlots(positionsSelected: ICellName[]) {
  const positionsSelectedToUse: ICellName[] = [];
  positionsSelected.forEach((pos) => {
    const [row, tier] = pos.split("|").map(Number);
    if (row > 0) {
      // 1 Self
      positionsSelectedToUse.push(`${pad2(row)}|${pad2(tier)}`);
      // 2 Mirror
      positionsSelectedToUse.push(
        `${pad2(row + (row % 2 === 0 ? -1 : 1))}|${pad2(tier)}`,
      );
    } else {
      positionsSelectedToUse.push(pos);
    }
    return positionsSelectedToUse;
  });

  return positionsSelectedToUse.filter((v, idx, arr) => arr.indexOf(v) === idx);
}

function cloneSlotData(slot: ISlotData): ISlotData {
  const newData = { ...slot, sizes: { ...slot.sizes } } as ISlotData;
  return newData;
}

function getSlotPos(slotData: ISlotData) {
  return slotIdJoinedToMixed(slotData.pos);
}

function createCopyDataFields(
  isoBay: IBayPattern,
  level: BayLevelEnum,
  sizeSummary: ISizeSummary,
  availableLengths: TContainerLengths[],
  featuresAllowed: IFeaturesAllowed = {} as IFeaturesAllowed,
): {
  fields: IFields<ICopyBayData>;
  validator: z.ZodType<ICopyBayData, z.ZodTypeDef, ICopyBayData>;
} {
  const options: { value: string; label: string }[] = [];
  const bayLabel = getTranslation("general:grid.bay");

  for (let b = 1; b <= sizeSummary.isoBays; b += 2) {
    if (
      level === BayLevelEnum.ABOVE &&
      sizeSummary.minAboveTier !== undefined &&
      sizeSummary.maxAboveTier !== undefined &&
      pad3(b) !== isoBay
    )
      options.push({
        value: `${b}-${BayLevelEnum.ABOVE}`,
        label: `${bayLabel} ${pad3(b)}, ${getTranslation(
          "enums:BayLevelEnum.ABOVE",
        )}`,
      });

    if (
      level === BayLevelEnum.BELOW &&
      sizeSummary.minBelowTier !== undefined &&
      sizeSummary.maxBelowTier !== undefined &&
      pad3(b) !== isoBay
    )
      options.push({
        value: `${b}-${BayLevelEnum.BELOW}`,
        label: `${bayLabel} ${pad3(b)}, ${getTranslation(
          "enums:BayLevelEnum.BELOW",
        )}`,
      });
  }

  const currentBay = Number(isoBay);
  const defaultOption = `${currentBay === 1 ? 3 : currentBay - 2}-${level}`;

  const sizes = availableLengths.map((size) => ({
    value: `size-${size}`,
    label: getTranslation(`general:slotCapabilities.size.${size}`),
  }));

  const sizesWithCones = featuresAllowed.slotConeRequired
    ? availableLengths.map((size) => ({
        value: `sizeC-${size}`,
        label: getTranslation(`general:slotCapabilities.size.${size}c`),
      }))
    : [];

  const slotOptions = [
    ...sizes,
    ...sizesWithCones,
    {
      value: "restricted",
      label: getTranslation("general:slotCapabilities.restricted.-"),
    },
    {
      value: "reefer",
      label: getTranslation("general:slotCapabilities.misc.r"),
    },
    featuresAllowed?.slotHazardousProhibited
      ? {
          value: "hazardousProhibited",
          label: getTranslation("general:slotCapabilities.misc.h"),
        }
      : undefined,
    featuresAllowed?.slotCoolStowProhibited
      ? {
          value: "coolStowProhibited",
          label: getTranslation("general:slotCapabilities.misc.k"),
        }
      : undefined,
  ].filter(Boolean) as { value: string | number; label: string }[];

  const bayOptions = [
    {
      value: "pairedBay",
      label: getTranslation("view:details.paired"),
    },
    {
      value: "reeferPlugs",
      label: getTranslation("view:details.reeferPlugs"),
    },
    {
      value: "doors",
      label: getTranslation("view:details.doors"),
    },
    {
      value: "telescoping",
      label: getTranslation("view:details.telescoping"),
    },
  ];

  const initalValues = JSON.parse(
    (getPreferencesValue(BAY_HELPERS_PREF_ID) as string) || "{}",
  ) as ICopyBayData;

  const fields: IFields<ICopyBayData> = [
    {
      name: "fromBay",
      type: "select",
      label: "view:edit.helpers.fromBay",
      options,
      initialValue: defaultOption,
      hoist: true,
    },
    {
      name: "bayOptions",
      label: "view:edit.helpers.copyFromBay.bayOptions",
      type: "checkboxesList",
      fieldset: true,
      options: bayOptions,
      initialValue: initalValues.bayOptions || [],
    },
    {
      name: "slotOptions",
      type: "checkboxesList",
      label: "view:edit.helpers.copyFromBay.slotOptions",
      fieldset: true,
      options: slotOptions,
      initialValue: initalValues.slotOptions || slotOptions.map((v) => v.value),
    },
  ];

  const validator: z.Schema<ICopyBayData> = z.object({
    fromBay: z.string(),
    bayOptions: z.array(z.any()),
    slotOptions: z.array(z.string()),
  });
  return { fields, validator };
}

function executeCopyData(
  targetIsoBay: IBayPattern,
  { fromBay: src, bayOptions, slotOptions }: ICopyBayData,
  updateDetailsForm: (data: IEditBayFields) => void,
  updateCellsData: (cellData: ISlotData[]) => void,
): void {
  const [fromIsoBay, level] = src.split("-").map(Number) as [
    number,
    BayLevelEnum,
  ];

  const fromBayData = ovdJsonStore.findBayInfo(pad3(fromIsoBay), level);

  if (!fromBayData) return;

  // 1. Update Bay Details
  const bayDataToChange: IEditBayFields = {};
  bayOptions.forEach((option) => {
    bayDataToChange[option as unknown as keyof IEditBayFields] =
      fromBayData[option];
  });

  if (Object.keys(bayDataToChange).length) updateDetailsForm(bayDataToChange);

  // 2. Update Slots
  const srcPerSlotInfo = fromBayData.perSlotInfo;
  if (srcPerSlotInfo) {
    const slotNames = Object.keys(srcPerSlotInfo) as IJoinedRowTierPattern[];
    const cellData = slotNames.map((slotName) => srcPerSlotInfo[slotName]);
    const slotOptionsAttrs = convertSlotOptionsToAttrs(slotOptions);

    if (cellData.length) {
      const copiedCellData: ISlotData[] = [];

      for (let i = 0; i < cellData.length; i += 1) {
        const newSlot = copySlotSelectedAttributes(
          cellData[i],
          slotOptionsAttrs,
        );

        if (newSlot) copiedCellData.push(newSlot);
      }

      updateCellsData(copiedCellData);
    }
  }

  // 3. Save preferences
  setPreferencesKeyAndValue(BAY_HELPERS_PREF_ID, {
    bayOptions,
    slotOptions,
  });

  function convertSlotOptionsToAttrs(slotOptions: string[]) {
    const attrs: ISlotOptionsAttrs = { misc: [], sizes: [], cones: [] };

    slotOptions.forEach((option) => {
      const [type, value] = option.split("-") as [string, TContainerLengths];

      if (type === "size") attrs.sizes.push(value);
      else if (type === "sizeC") attrs.cones.push(value);
      else attrs.misc.push(type as TAllowedKeys);
    });

    return attrs;
  }

  function copySlotSelectedAttributes(
    src: ISlotData,
    slotOptionsAttrs: ISlotOptionsAttrs,
  ): ISlotData {
    const cell: ISlotData = { pos: src.pos, sizes: {} };

    slotOptionsAttrs.sizes.forEach((size) => {
      if (src.sizes[size]) cell.sizes[size] = 1;
    });

    slotOptionsAttrs.cones.forEach((size) => {
      if (
        src.sizes[size] &&
        typeof src.sizes[size] === "object" &&
        (src.sizes[size] as ISlotSizeOptions).cone
      )
        cell.sizes[size] = { cone: 1 };
    });

    slotOptionsAttrs.misc.forEach((key) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (key && src[key]) (cell[key] as any) = src[key] as TYesNo;
    });

    return cell;
  }
}

interface IBayEditComponentData {
  isoBay: IBayPattern;
  level: BayLevelEnum;
  gridData: ICreateGridFromConfigProps<ISlotData>;
  availableLengths: Array<TContainerLengths>;
  featuresAllowed?: IFeaturesAllowed;
  maxIsoBay: number;
  bayData?: IBayLevelData;
}

interface IRemapTiersFields extends Record<string, unknown> {
  newTier: number;
}

interface IPostSaveAction {
  isoBay: IBayPattern;
  level: BayLevelEnum;
  action: (bl: IBayLevelData) => IBayLevelData;
}

interface ICopyBayData extends Record<string, unknown> {
  fromBay: string;
  bayOptions: Array<keyof IBayLevelData>;
  slotOptions: string[];
}

interface ISlotOptionsAttrs {
  misc: TAllowedKeys[];
  sizes: TContainerLengths[];
  cones: TContainerLengths[];
}

type TAllowedKeys =
  | "restricted"
  | "reefer"
  | "hazardousProhibited"
  | "coolStowProhibited";
