import { __awaiter } from "tslib";
import HttpClient from "../HttpClient";
const AccountsService = {
    createNewAccount: (data) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "POST",
            url: "accounts/create",
            data,
        });
    }),
    resendVerificationEmail: (hash, email) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "POST",
            url: "accounts/resendVerificationEmail",
            data: { hash, email },
        });
    }),
    verifyEmail: (data) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "POST",
            url: "accounts/verify",
            data,
        });
    }),
    notifyNewPassword: () => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "POST",
            url: "accounts/notifyNewPassword",
        });
    }),
    editMyDetails: (data) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "PUT",
            url: "accounts/editMyDetails",
            data,
        });
    }),
    getMyDetails: () => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "GET",
            url: "accounts/getMyDetails",
        });
    }),
    getUserDetailsByAdmin: (orgId, id) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "GET",
            url: `/accounts/admin/getDetails/${orgId}/${id}`,
        });
    }),
    getMyOrganizationUsers: (orgId) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "GET",
            url: `accounts/usersNamesByIdOfOrganization/${orgId || ""}`,
        });
    }),
    changeMyPassword: (password) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "PUT",
            url: "accounts/changeMyPassword",
            data: { password },
        });
    }),
    forgotMyPassword: (email) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "POST",
            url: "accounts/forgotMyPassword",
            data: { email },
        });
    }),
    changeOrganizationSelfDetails: (name, mfaForAllUsers) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "PUT",
            url: "accounts/organizationSelf",
            data: { name, mfaForAllUsers },
        });
    }),
    deleteMyUserAccount: (mfaCode, accessToken) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "DELETE",
            url: "accounts/deleteMyAccount",
            data: { mfaCode, accessToken },
        });
    }),
    deleteMyOrgAccount: (mfaCode, accessToken) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "DELETE",
            url: "accounts/deleteMyOrgAccount",
            data: { mfaCode, accessToken },
        });
    }),
    updateUserMarketingPreferences: (acceptsMarketing) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "PUT",
            url: `accounts/marketingPreferences`,
            data: {
                acceptsMarketing,
            },
        });
    }),
};
export default AccountsService;
