import {
  IDomainNotAllowed,
  systemRights,
} from "@baplie-viewer2/tedivo-api-models";
import {
  IFields,
  IFormReturn,
  TedivoForm,
  translateTedivoForm,
} from "@baplie-viewer2/tedivo-form";

import { ITable } from "../../common/smartTable/smart-table.element";
import { ListComponentWithActions } from "../../common/ListComponentWithActions";
import Services from "../../../app/services";
import SlButton from "@shoelace-style/shoelace/dist/components/button/button";
import { getTranslation } from "../../../app/i18/i18tn";
import goSquared from "../../../app/tracking/goSquared";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/app.element";
import { z } from "zod";

export class TVDAdminDomainsComponent extends ListComponentWithActions<IDomainNotAllowed> {
  public static observedAttributes = [];

  constructor() {
    super();

    this.h1Label = "general:domains.title";
    this.goSquaredLabel = "Admin/Domains";
    this.topLabel = "general:adminArea";
    this.createLabel = "general:domains.adminCreate";
    this.deleteLabel = "general:domains.delete";

    this.serviceListCall = Services.domains.getAll;
    this.serviceDeleteCall = Services.domains.delete;
    this.serviceEditCall = Services.domains.update;

    this.systemRightToEdit = systemRights.ORG.AppAdminDomains;

    this.tableConfig = (data: IDomainNotAllowed[]) =>
      createDomainsTableConfig(
        data,
        this.systemRightToEdit,
        this.openEditModal,
        this.openDeleteModal,
      );

    this.createEditForm = createDomainEdit;

    setAppTitle(getTranslation("general:domains.title"));
    goSquared.trackPage("TEDIVO Admin Pages - Domains");
  }
}

customElements.define("tvd-admin-domains-component", TVDAdminDomainsComponent);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-admin-domains-component": TVDAdminDomainsComponent;
  }
}

function createDomainsTableConfig(
  domains: IDomainNotAllowed[],
  systemRightToEdit: string | undefined,
  onEditSaveFn: (pk: string, data: IDomainNotAllowed) => void,
  onDeleteFn: (
    pk: string,
    data: IDomainNotAllowed,
    name: keyof IDomainNotAllowed,
  ) => void,
): ITable<IDomainNotAllowed> {
  function yesNoMapper(v: number): string {
    return getTranslation(`general:common.${v == 1 ? "yes" : "no"}`);
  }

  const tableConfig: ITable<IDomainNotAllowed> = {
    className: "tvd-table tvd-table-smart-table",
    data: domains,
    defaultSort: "domain",
    fields: [
      {
        name: "domain",
        label: getTranslation("general:domains.domain"),
      },
      {
        name: "allowed",
        label: getTranslation("general:domains.attrAllowed"),
        mapper: (v) => yesNoMapper(v.allowed),
        className: "centered",
      },
      {
        name: "abused",
        label: getTranslation("general:domains.attrAbused"),
        mapper: (v) => yesNoMapper(v.abused),
        className: "centered",
      },
      {
        name: "modifiedAt",
        label: getTranslation("general:common.modifiedAt"),
        valueFunction: (dta) => {
          if (dta.modifiedAt) {
            const d = new Date(dta.modifiedAt);
            return `${d.toLocaleDateString()}, ${d.toLocaleTimeString()}`;
          } else {
            return "";
          }
        },
        className: "centered",
      },
    ],
    settings: {
      sortEnabled: true,
      sortVisible: true,
      filterEnabled: true,
      labelSortBy: getTranslation("general:common.sortBy"),
      labelSearch: getTranslation("general:common.filterBy"),
      filterFields: ["domain"],
    },
    pkFunction: (dta) => dta.domain,
  };

  if (
    systemRightToEdit &&
    securityModule.userHasPermission(systemRightToEdit)
  ) {
    tableConfig.fields.push({
      name: "domain",
      label: getTranslation("general:common.edit"),
      type: "icon",
      icon: "pencil",
      notSortable: true,
      onClick: (dta) => {
        onEditSaveFn(dta.domain, dta);
      },
    });

    tableConfig.fields.push({
      name: "domain",
      label: getTranslation("general:common.delete"),
      type: "icon",
      icon: "trash",
      iconVariant: "danger",
      notSortable: true,
      onClick: (dta) => {
        onDeleteFn?.(dta.domain, dta, "domain");
      },
    });
  }

  return tableConfig;
}

function createDomainEdit(
  submitButton: SlButton,
  prevData?: IDomainNotAllowed,
): IFormReturn<IDomainNotAllowed> {
  const holder = document.createElement("div");

  const formFields: IFields<IDomainNotAllowed> = [
    {
      name: "domain",
      label: "general:domains.domain",
      type: "textBox",
      initialValue: prevData?.domain || "",
    },
    {
      name: "allowed",
      label: "general:domains.attrAllowed",
      type: "checkbox",
      initialValue: !!prevData?.allowed,
    },
    {
      name: "abused",
      label: "general:domains.attrAbused",
      type: "checkbox",
      initialValue: !!prevData?.abused,
    },
  ];

  const formValidator = z.object({
    domain: z.string().min(1),
  }) as unknown as z.Schema<IDomainNotAllowed>;

  const tedivoForm = new TedivoForm<IDomainNotAllowed>({
    fields: formFields,
    onSubmit: () => undefined,
    formValidator: formValidator,
    submitButton: submitButton,
    autoFocusOnFirstInput: true,
    hiddenData: prevData,
  });

  translateTedivoForm<IDomainNotAllowed>({
    tedivoForm,
    getTranslation: getTranslation,
  });

  holder.appendChild(tedivoForm.form);

  return {
    node: holder,
    tedivoForm,
    submitFunction: submitPassedToEditDrawer,
  };

  async function submitPassedToEditDrawer() {
    const validResult = tedivoForm.doSubmitForm();
    const values = tedivoForm.getValues();

    if (validResult.success) {
      await Services.domains.update({
        domain: values.domain,
        allowed: values.allowed ? 1 : 0,
        abused: values.abused ? 1 : 0,
        modifiedAt: new Date(),
      });
      return true;
    } else {
      return false;
    }
  }
}
