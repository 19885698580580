import "@shoelace-style/shoelace/dist/components/avatar/avatar";
import "@shoelace-style/shoelace/dist/components/button/button";
import "@shoelace-style/shoelace/dist/components/icon/icon";
import "@shoelace-style/shoelace/dist/components/menu-item/menu-item";
import "@shoelace-style/shoelace/dist/components/menu/menu";
import "./tvd-menu.element.scss";

import MenuDefinition, { IMenuItem, TOGGLE_CHILDREN } from "./MenuDefinition";
// import goSquared from "../../../app/tracking/goSquared";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";

import SlAvatar from "@shoelace-style/shoelace/dist/components/avatar/avatar";
import SlButton from "@shoelace-style/shoelace/dist/components/button/button";
import awsCognito from "../../../app/security/awsCognito";
import { createMenuList } from "@baplie-viewer2/tedivo-form";
import globalStore from "../../../app/stores/globalStore";
import { isLocationRouteActive } from "@baplie-viewer2/tedivo-pure-helpers";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";

export class TVDMenuElement extends HTMLElement {
  public static observedAttributes = [];

  private ulMenu: HTMLElement;
  private ulOpenedSubMenu: HTMLElement | undefined;
  private divClickOutside: HTMLElement;
  private avatar: SlAvatar | undefined = undefined;
  private currentColorMode: "light" | "dark" =
    globalStore.resolvedColorMode || "light";

  onOptionSelected: ((action: string) => void) | undefined;

  constructor() {
    super();
    this.ulMenu = document.createElement("ul");
    this.ulMenu.className = "ul-main-menu";
    this.ulMenu.setAttribute("role", "menu");

    this.divClickOutside = document.createElement("div");
    this.divClickOutside.className = "click-outside";
  }

  connectedCallback() {
    const clickOutside = this.divClickOutside;

    this.drawMenuItems();

    const loginLink = document.createElement("sl-button");
    loginLink.className = "menu-avatar";
    loginLink.circle = true;
    loginLink.title = getTranslation("auth:openMyProfile");
    loginLink.addEventListener("click", (ev: Event) => {
      router.navigate(
        securityModule.isLoggedIn ? routeFns.myProfile() : routeFns.login(),
      );
    });

    const avatar = document.createElement("sl-avatar");
    avatar.initials = securityModule.initials;
    this.avatar = avatar;

    loginLink.appendChild(avatar);

    this.appendChild(clickOutside);
    this.appendChild(this.ulMenu);
    this.appendChild(loginLink);
    // this.addChatButton();

    this.divClickOutside = clickOutside;

    this.attachEvents();
  }

  private drawMenuItems = () => {
    const menu = this.ulMenu;
    removeChildren(menu);

    MenuDefinition.filter(
      (menuItem) =>
        (hasSystemRight(menuItem) && !menuItem.isHiddenInReadOnly) ||
        (menuItem.isHiddenInReadOnly &&
          !securityModule.planIsReadOnly &&
          securityModule.isLoggedIn),
    ).forEach(
      ({
        action,
        label,
        icon,
        iconSrc,
        iconSrcDark,
        menuItems,
        actionBase,
      }) => {
        const menuItem = document.createElement("li");

        menuItem.role = "menuitem";
        menuItem.setAttribute("data-action", actionBase || action || "");

        const menuButton = document.createElement("sl-button");
        menuButton.value = action;
        menuButton.className = "main-option";
        i18nReactive.addConsumer(menuButton, label, "title");

        const iconEl = document.createElement("sl-icon");
        iconEl.slot = "prefix";
        i18nReactive.addConsumer(iconEl, label, "label");
        menuButton.appendChild(iconEl);

        if (iconSrc) {
          iconEl.setAttribute(
            "src",
            this.currentColorMode === "light"
              ? iconSrc
              : iconSrcDark || iconSrc,
          );
          iconEl.style.fontSize = "1.25rem";
          iconEl.classList.add("custom-icon");
          menuButton.classList.add("custom-icon-button");
        } else {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          iconEl.setAttribute("name", icon!);
        }

        menuItem.appendChild(menuButton);

        const hasChildren = !!menuItems && menuItems.length > 0;

        if (!hasChildren) {
          menu.appendChild(menuItem);
          return; // --> FAST RETURN - NO CHILDREN
        }

        const effectiveMenuItems = menuItems.filter(({ systemRights }) =>
          systemRights?.reduce((acc, sr) => {
            return acc && securityModule.userHasPermission(sr);
          }, true),
        );

        if (effectiveMenuItems.length === 0) return; // --> FAST RETURN - NO CHILDREN - NO SYSTEM RIGHTS

        const { menu: subMenu } = createMenuList({
          options: menuItems.map((mi) => ({
            name: getTranslation(mi.label),
            value: mi.action,
            icon: mi.icon,
          })),
          ommitCheckSign: true,
          currentValue: undefined,
        });

        menuItem.appendChild(subMenu);
        menu.appendChild(menuItem);
      },
    );

    this.setActiveRoute();
  };

  private attachEvents = () => {
    this.ulMenu.addEventListener("click", (ev: MouseEvent) => {
      const target = ev.target as HTMLButtonElement;
      const divClickOutside = this.divClickOutside;

      if (
        !target ||
        !(
          target.nodeName === "SL-BUTTON" ||
          target.nodeName === "BUTTON" ||
          target.nodeName === "SL-MENU-ITEM"
        )
      )
        return;

      if (target.value === TOGGLE_CHILDREN) {
        if (target.nextSibling) {
          const ulSubMenu = target.nextSibling as HTMLUListElement;
          const isOpened = divClickOutside.dataset.opened === "1";
          if (isOpened) {
            // opened now, then close
            divClickOutside.dataset.opened = "0";
            ulSubMenu.dataset.opened = "0";
            this.ulOpenedSubMenu = undefined;
          } else {
            // do open
            divClickOutside.dataset.opened = "1";
            ulSubMenu.dataset.opened = "1";
            this.ulOpenedSubMenu = ulSubMenu;
          }
        }
      } else {
        console.log("Selected", target.value);
        if (this.onOptionSelected) this.onOptionSelected(target.value);
        this.toggleOff();
      }
    });

    this.divClickOutside.addEventListener("click", this.toggleOff, false);

    securityModule.addEventListener("userDataRetrieved", this.onUpdateSources);
    awsCognito.addEventListener("sessionTimedOut", this.onUpdateSources);
    router.addEventListener("navigationComponentChanged", this.setActiveRoute);

    document.documentElement.addEventListener(
      "colorModeChanged",
      this.onColorModeChanged,
    );

    document.documentElement.addEventListener("keydown", this.onEscape);
  };

  private onColorModeChanged = ((
    ev: CustomEvent<{ detail: { mode: "light" | "dark" } }>,
  ): void => {
    this.currentColorMode = (ev as any).detail.mode;
    this.drawMenuItems();
  }) as EventListener;

  private onUpdateSources = ((ev: CustomEvent<{}>): void => {
    this.updateAvatar();
    this.drawMenuItems();
  }) as EventListener;

  private onEscape = (ev: KeyboardEvent) => {
    if (ev.key === "Escape") {
      this.toggleOff();
    }
  };

  disconnectedCallback() {
    securityModule.removeEventListener(
      "userDataRetrieved",
      this.onUpdateSources,
    );

    awsCognito.removeEventListener("sessionTimedOut", this.onUpdateSources);

    document.documentElement.removeEventListener(
      "colorModeChanged",
      this.onColorModeChanged,
    );

    document.documentElement.removeEventListener("keydown", this.onEscape);

    router.removeEventListener(
      "navigationComponentChanged",
      this.setActiveRoute,
    );
  }

  private updateAvatar = () => {
    if (this.avatar) {
      this.avatar.setAttribute("initials", securityModule.initials);
    }
  };

  private toggleOff = () => {
    this.divClickOutside.dataset.opened = "0";

    if (this.ulOpenedSubMenu) {
      this.ulOpenedSubMenu.dataset.opened = "0";
      this.ulOpenedSubMenu = undefined;
    }
  };

  private setActiveRoute = () => {
    if (!this.ulMenu.children) return;

    const menuChildren = Array.from(this.ulMenu.children);

    menuChildren.forEach((c) => {
      const routeTo = c.getAttribute("data-action");
      const btn = c.getElementsByTagName("sl-button")[0] as unknown as
        | SlButton
        | undefined;

      if (
        routeTo &&
        isLocationRouteActive(
          window.location.pathname,
          router.basePath,
          routeTo,
        )
      ) {
        if (btn) {
          btn.variant = "primary";
          btn.outline = true;
        }
      } else {
        if (btn) {
          btn.variant = "default";
          btn.outline = false;
        }
      }
    });
  };
}

customElements.define("tvd-menu-component", TVDMenuElement);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-menu-component": TVDMenuElement;
  }
}

function hasSystemRight(menuItem: IMenuItem): boolean {
  if (menuItem.action === TOGGLE_CHILDREN) {
    const isAllowed = menuItem.menuItems?.some((ch) => {
      return ch.systemRights?.reduce((acc, sr) => {
        return acc && securityModule.userHasPermission(sr);
      }, true);
    });
    return !!isAllowed;
  } else {
    return !!menuItem.systemRights?.reduce((acc, sr) => {
      return acc && securityModule.userHasPermission(sr);
    }, true);
  }
}
