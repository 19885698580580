import { __awaiter } from "tslib";
import HttpClient from "../HttpClient";
const urlOrganizations = "organizations";
const OrganizationsService = {
    adminGetAllOrganizations: () => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "GET",
            url: urlOrganizations,
        });
    }),
    getSingleOrganizationAndUsers: (id) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "GET",
            url: `${urlOrganizations}/${id}/users`,
        });
    }),
    adminCreateOrganization: (data) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "PUT",
            url: urlOrganizations,
            data,
        });
    }),
    adminUpdateOrganization: (data) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "PUT",
            url: urlOrganizations,
            data,
        });
    }),
    adminDeleteOrganization: (id) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "DELETE",
            url: `${urlOrganizations}/${id}`,
            data: undefined,
        });
    }),
    adminCreateOrganizationUser: (orgId, data) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "PUT",
            url: `${urlOrganizations}/${orgId}/users`,
            data,
        });
    }),
    adminUpdateOrganizationUser: (orgId, data) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "PUT",
            url: `${urlOrganizations}/${orgId}/users`,
            data,
        });
    }),
    adminDeleteOrganizationUser: (orgId, userId) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "DELETE",
            url: `${urlOrganizations}/${orgId}/users/${userId}`,
            data: undefined,
        });
    }),
    getOrganizationName: (orgId) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "GET",
            url: `organizationName/${orgId}`,
        });
    }),
    updateFileTags: (data) => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "PUT",
            url: "organizations/updateTags",
            data,
        });
    }),
    getFileTags: () => __awaiter(void 0, void 0, void 0, function* () {
        const client = new HttpClient();
        return yield client.request({
            method: "GET",
            url: "organizations/getTags",
        });
    }),
};
export default OrganizationsService;
