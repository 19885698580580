/**
 * For User View Options only
 */
enum UoLcgReferenceEnum {
  "AFT_PERPENDICULAR_DFWD" = 1,
  "FWD_PERPENDICULAR_DAFT" = 2,
  "MIDSHIPS_DFWD" = 3,
  "MIDSHIPS_DAFT" = 4,
}

export default UoLcgReferenceEnum;
