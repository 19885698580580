import { History, Location, createBrowserHistory } from "history";

import IRouteInternal from "./types/IRouteInternal";
import computeScore from "./helpers/computeScore";
import matchRoutes from "./helpers/matchRoutes";

export class Router extends EventTarget {
  private middleware: Array<(url: string) => string> = [];

  public routes: Array<IRouteInternal> = [];
  public currentRouteKey: string | undefined = undefined;
  public basePath = "/";
  public forceRenderOfPaths: Array<string> = [];

  public onChange:
    | ((
        route: string,
        baseRoute: string,
        elFn: () => HTMLElement,
        hideMenu: boolean,
      ) => void)
    | undefined = undefined;

  public onError: (() => void) | undefined = undefined;

  public onRouteNotFound: (() => void) | undefined = undefined;

  get currentPathname(): string {
    return this.history.location.pathname;
  }

  private currentHref: string | undefined = undefined;

  private history: History;

  constructor(history?: History) {
    super();
    this.history = history || createBrowserHistory();
  }

  get currentState() {
    return this.history.location.state;
  }

  start(omitInitialCall = false) {
    const historyChanged = ({ location }: { location: Location }) => {
      const initialPath =
        sanitizePath(location.pathname) + (location.hash || "");
      let path = initialPath;

      if (this.middleware.length > 0) {
        this.middleware.forEach((fn) => {
          path = sanitizePath(fn(path));
        });

        if (path !== initialPath && path !== location.pathname) {
          this.history.replace(path);
        }
      }

      if (
        this.currentHref === path &&
        this.forceRenderOfPaths.indexOf(path) < 0
      )
        return;

      // FIND THE BEST MATCH
      const routeInternal = matchRoutes(this.routes, path, this.basePath);

      this.currentHref = path;

      if (routeInternal === null) {
        if (this.onRouteNotFound) this.onRouteNotFound();
        return;
      }

      if (routeInternal !== null && this.onChange) {
        this.currentRouteKey = routeInternal.path;
        this.onChange(
          path,
          routeInternal.path,
          routeInternal.componentFn,
          !!routeInternal.hideMenu,
        );
      }
    };

    this.history.listen(historyChanged);

    // Initial call
    if (!omitInitialCall) {
      historyChanged({ location: this.history.location });
    }
  }

  addRoute(name: string, componentFn: () => HTMLElement) {
    const path = sanitizePath(name);
    const pathExistsPos = this.routes.findIndex((a) => a.path === path);

    if (pathExistsPos >= 0) {
      this.routes[pathExistsPos] = {
        path: path,
        score: computeScore(path, undefined),
        componentFn,
      };
    } else {
      this.routes.push({
        path: path,
        score: computeScore(path, undefined),
        componentFn,
      });
    }

    return this;
  }

  removeRoute(name: string) {
    const path = sanitizePath(name);

    const pathExistsPos = this.routes.findIndex((a) => a.path === path);

    if (pathExistsPos >= 0) {
      this.routes = this.routes.filter((_, idx) => idx !== pathExistsPos);
    }

    return this;
  }

  navigate = (
    path: string,
    state?: unknown,
    replaceCurrentHref = false,
  ): Promise<void> => {
    return new Promise((resolve) => {
      const newUrl =
        (this.basePath + "/" + path).split("/").filter(Boolean).join("/") ||
        "/";

      if (!replaceCurrentHref) {
        this.history.push(newUrl, state);
      } else {
        this.currentHref = newUrl;
        this.history.replace(newUrl, state);
      }

      resolve();
    });
  };

  getRouteParams(): Record<string, string | undefined> {
    const routeKey = this.currentRouteKey;
    if (routeKey === undefined) return {};

    const pathnameParts = window.location.pathname.split("/").filter(Boolean);
    const routeKeyParts = (this.basePath + "/" + routeKey)
      .split("/")
      .filter(Boolean);

    if (pathnameParts.length !== routeKeyParts.length) return {};

    const params: Record<string, string> = {};
    routeKeyParts.forEach((p, idx) => {
      if (p.indexOf(":") === 0) {
        params[p.substring(1)] = pathnameParts[idx];
      }
    });

    return params;
  }

  getRouteQuerystring(): Record<string, string | undefined> {
    const params: Record<string, string> = {};

    const search = window.location.search;
    if (!search) return params;

    search
      .substring(1)
      .split("&")
      .forEach((pairValue) => {
        const [vName, vValue] = pairValue.split("=");
        params[vName] = vValue;
      });

    return params;
  }

  getRouteHash(): Record<string, string> {
    const params: Record<string, string> = {};

    const hashString = window.location.hash;
    if (!hashString) return params;

    hashString
      .substring(1)
      .split("&")
      .forEach((pairValue) => {
        const [vName, vValue] = pairValue.split("=");
        params[vName] = vValue || "1";
      });

    return params;
  }

  addMiddleware(fn: (url: string) => string) {
    this.middleware.push(fn);
  }
}

function sanitizePath(name: string): string {
  return "/" + name.split("/").filter(Boolean).join("/");
}
