import { IMapConfigArray } from "../../../common/dictionaryDisplay/helpers/mapDataToDictionary";
import { ValuesSourceEnum } from "open-vessel-definition";
import { getValuesSourceEnumTranslationKey } from "@baplie-viewer2/tedivo-pure-helpers";
import globalUnits from "../../../../app/units/globalUnits";
import i18n from "../../../../app/i18/i18tn";

export interface ICgsPartData {
  lcgValues: ValuesSourceEnum;
  tcgValues: ValuesSourceEnum;
  vcgValues: ValuesSourceEnum;
  lcgDataFilled: number;
  tcgDataFilled: number;
  vcgDataFilled: number;
  lpp: number;
  loa: number;
  sternToAftPp: number;
}

const ViewCgsConfig: IMapConfigArray<ICgsPartData> = [
  {
    label: "view:lcgValues",
    key: "lcgValues",
    type: "string",
    undefinedIsDash: true,
    format: (v) =>
      i18n.t(getValuesSourceEnumTranslationKey(v as ValuesSourceEnum)),
  },
  {
    label: "view:lcgDataFilled",
    key: "lcgDataFilled",
    type: "string",
    undefinedIsDash: true,
    format: (v) => `${v}%`,
    className: (dta: ICgsPartData) =>
      dta.lcgValues === ValuesSourceEnum.KNOWN && dta.lcgDataFilled < 100
        ? "warning"
        : "",
  },
  {
    label: "view:vcgValues",
    key: "vcgValues",
    type: "string",
    undefinedIsDash: true,
    format: (v) =>
      i18n.t(getValuesSourceEnumTranslationKey(v as ValuesSourceEnum)),
  },
  {
    label: "view:vcgDataFilled",
    key: "vcgDataFilled",
    type: "string",
    undefinedIsDash: true,
    format: (v) => `${v}%`,
    className: (dta: ICgsPartData) =>
      dta.vcgValues === ValuesSourceEnum.KNOWN && dta.vcgDataFilled < 100
        ? "warning"
        : "",
  },
  {
    label: "view:tcgValues",
    key: "tcgValues",
    type: "string",
    undefinedIsDash: true,
    format: (v) =>
      i18n.t(getValuesSourceEnumTranslationKey(v as ValuesSourceEnum)),
  },
  {
    label: "view:tcgDataFilled",
    key: "tcgDataFilled",
    type: "string",
    undefinedIsDash: true,
    format: (v) => `${v}%`,
    className: (dta: ICgsPartData) =>
      dta.tcgValues === ValuesSourceEnum.KNOWN && dta.tcgDataFilled < 100
        ? "warning"
        : "",
  },
  {
    label: "view:lpp",
    key: "lpp",
    type: "number",
    undefinedIsDash: true,
    format: (v) =>
      globalUnits.lengthUnits.fromValueToFormattedDisplay(Number(v)),
    className: (dta: ICgsPartData) =>
      dta.lpp === undefined || dta.lpp === 0 ? "warning warning-strong" : "",
  },
  {
    label: "view:loa",
    key: "loa",
    type: "number",
    undefinedIsDash: true,
    format: (v) =>
      globalUnits.lengthUnits.fromValueToFormattedDisplay(Number(v)),
  },
  {
    label: "view:sternToAftPp",
    key: "sternToAftPp",
    type: "number",
    undefinedIsDash: true,
    format: (v) =>
      globalUnits.lengthUnits.fromValueToFormattedDisplay(Number(v)),
  },
];

export default ViewCgsConfig;
