/* eslint-disable @typescript-eslint/no-unused-vars */
import { __rest } from "tslib";
export const SlotDataReducer = {
    CLEAR: (s) => {
        return { sizes: {}, pos: s.pos };
    },
    ADD_SIZE_20: (s) => (Object.assign(Object.assign({}, s), { restricted: undefined, sizes: Object.assign(Object.assign({}, s.sizes), { 20: 1 }) })),
    ADD_SIZE_24: (s) => (Object.assign(Object.assign({}, s), { restricted: undefined, sizes: Object.assign(Object.assign({}, s.sizes), { 24: 1 }) })),
    ADD_SIZE_40: (s) => (Object.assign(Object.assign({}, s), { restricted: undefined, sizes: Object.assign(Object.assign({}, s.sizes), { 40: 1 }) })),
    ADD_SIZE_45: (s) => (Object.assign(Object.assign({}, s), { restricted: undefined, sizes: Object.assign(Object.assign({}, s.sizes), { 45: 1 }) })),
    ADD_SIZE_48: (s) => (Object.assign(Object.assign({}, s), { restricted: undefined, sizes: Object.assign(Object.assign({}, s.sizes), { 48: 1 }) })),
    ADD_SIZE_53: (s) => (Object.assign(Object.assign({}, s), { restricted: undefined, sizes: Object.assign(Object.assign({}, s.sizes), { 53: 1 }) })),
    ADD_SIZE_20_CONEREQ: (s) => {
        var _a;
        if (!((_a = s.sizes) === null || _a === void 0 ? void 0 : _a[20]))
            return simpleClone(s);
        return Object.assign(Object.assign({}, s), { restricted: undefined, sizes: Object.assign(Object.assign({}, s.sizes), { 20: { cone: 1 } }) });
    },
    ADD_SIZE_24_CONEREQ: (s) => {
        var _a;
        if (!((_a = s.sizes) === null || _a === void 0 ? void 0 : _a[24]))
            return simpleClone(s);
        return Object.assign(Object.assign({}, s), { restricted: undefined, sizes: Object.assign(Object.assign({}, s.sizes), { 24: { cone: 1 } }) });
    },
    ADD_SIZE_40_CONEREQ: (s) => {
        var _a;
        if (!((_a = s.sizes) === null || _a === void 0 ? void 0 : _a[40]))
            return simpleClone(s);
        return Object.assign(Object.assign({}, s), { restricted: undefined, sizes: Object.assign(Object.assign({}, s.sizes), { 40: { cone: 1 } }) });
    },
    ADD_SIZE_45_CONEREQ: (s) => {
        var _a;
        if (!((_a = s.sizes) === null || _a === void 0 ? void 0 : _a[45]))
            return simpleClone(s);
        return Object.assign(Object.assign({}, s), { restricted: undefined, sizes: Object.assign(Object.assign({}, s.sizes), { 45: { cone: 1 } }) });
    },
    ADD_SIZE_48_CONEREQ: (s) => {
        var _a;
        if (!((_a = s.sizes) === null || _a === void 0 ? void 0 : _a[48]))
            return simpleClone(s);
        return Object.assign(Object.assign({}, s), { restricted: undefined, sizes: Object.assign(Object.assign({}, s.sizes), { 48: { cone: 1 } }) });
    },
    ADD_SIZE_53_CONEREQ: (s) => {
        var _a;
        if (!((_a = s.sizes) === null || _a === void 0 ? void 0 : _a[53]))
            return simpleClone(s);
        return Object.assign(Object.assign({}, s), { restricted: undefined, sizes: Object.assign(Object.assign({}, s.sizes), { 53: { cone: 1 } }) });
    },
    ADD_REEFER: (s) => (Object.assign(Object.assign({}, s), { restricted: undefined, sizes: Object.assign({}, s.sizes), reefer: 1 })),
    ADD_RESTRICTED: (s) => ({
        pos: s.pos,
        sizes: {},
        restricted: 1,
    }),
    ADD_COOLSTOWPROHIBITED: (s) => (Object.assign(Object.assign({}, s), { sizes: Object.assign({}, s.sizes), restricted: undefined, coolStowProhibited: 1 })),
    ADD_HAZARDOUSPROHIBITED: (s) => (Object.assign(Object.assign({}, s), { sizes: Object.assign({}, s.sizes), restricted: undefined, hazardousProhibited: true })),
    REMOVE_SIZE_20: (s) => {
        const { sizes } = s, otherProps = __rest(s, ["sizes"]);
        const { 20: unused } = sizes, otherSizes = __rest(sizes, ["20"]);
        return Object.assign(Object.assign({}, otherProps), { sizes: Object.assign({}, otherSizes) });
    },
    REMOVE_SIZE_24: (s) => {
        const { sizes } = s, otherProps = __rest(s, ["sizes"]);
        const { 24: unused } = sizes, otherSizes = __rest(sizes, ["24"]);
        return Object.assign(Object.assign({}, otherProps), { sizes: Object.assign({}, otherSizes) });
    },
    REMOVE_SIZE_40: (s) => {
        const { sizes } = s, otherProps = __rest(s, ["sizes"]);
        const { 40: unused } = sizes, otherSizes = __rest(sizes, ["40"]);
        return Object.assign(Object.assign({}, otherProps), { sizes: Object.assign({}, otherSizes) });
    },
    REMOVE_SIZE_45: (s) => {
        const { sizes } = s, otherProps = __rest(s, ["sizes"]);
        const { 45: unused } = sizes, otherSizes = __rest(sizes, ["45"]);
        return Object.assign(Object.assign({}, otherProps), { sizes: Object.assign({}, otherSizes) });
    },
    REMOVE_SIZE_48: (s) => {
        const { sizes } = s, otherProps = __rest(s, ["sizes"]);
        const { 48: unused } = sizes, otherSizes = __rest(sizes, ["48"]);
        return Object.assign(Object.assign({}, otherProps), { sizes: Object.assign({}, otherSizes) });
    },
    REMOVE_SIZE_53: (s) => {
        const { sizes } = s, otherProps = __rest(s, ["sizes"]);
        const { 53: unused } = sizes, otherSizes = __rest(sizes, ["53"]);
        return Object.assign(Object.assign({}, otherProps), { sizes: Object.assign({}, otherSizes) });
    },
    REMOVE_REEFER: (s) => {
        const { reefer } = s, otherProps = __rest(s, ["reefer"]);
        return Object.assign(Object.assign({}, otherProps), { sizes: Object.assign({}, otherProps.sizes) });
    },
    REMOVE_RESTRICTED: (s) => {
        const { restricted, pos } = s, otherProps = __rest(s, ["restricted", "pos"]);
        return {
            pos,
            sizes: {},
        };
    },
    REMOVE_COOLSTOWPROHIBITED: (s) => {
        const { coolStowProhibited } = s, otherProps = __rest(s, ["coolStowProhibited"]);
        return Object.assign(Object.assign({}, otherProps), { sizes: Object.assign({}, otherProps.sizes) });
    },
    REMOVE_HAZARDOUSPROHIBITED: (s) => {
        const { hazardousProhibited } = s, otherProps = __rest(s, ["hazardousProhibited"]);
        return Object.assign(Object.assign({}, otherProps), { sizes: Object.assign({}, otherProps.sizes) });
    },
    // SET_VOID_HULL: (s: ISlotData): ISlotData => {
    //   return { sizes: {}, pos: s.pos, restricted: VoidTypesEnum.HULL };
    // },
    // SET_VOID_BALLASTTANK: (s: ISlotData): ISlotData => {
    //   return { sizes: {}, pos: s.pos, restricted: VoidTypesEnum.BALLAST_TANK };
    // },
    // SET_VOID_DIESELTANK: (s: ISlotData): ISlotData => {
    //   return { sizes: {}, pos: s.pos, restricted: VoidTypesEnum.DIESEL_TANK };
    // },
    // SET_VOID_FUELTANK: (s: ISlotData): ISlotData => {
    //   return { sizes: {}, pos: s.pos, restricted: VoidTypesEnum.FUEL_TANK };
    // },
    // SET_VOID_FRESHWATERTANK: (s: ISlotData): ISlotData => {
    //   return {
    //     sizes: {},
    //     pos: s.pos,
    //     restricted: VoidTypesEnum.FRESH_WATER_TANK,
    //   };
    // },
    // SET_VOID_LUBEOILTANK: (s: ISlotData): ISlotData => {
    //   return { sizes: {}, pos: s.pos, restricted: VoidTypesEnum.LUBE_OIL_TANK };
    // },
    // SET_VOID_OTHER: (s: ISlotData): ISlotData => {
    //   return { sizes: {}, pos: s.pos, restricted: VoidTypesEnum.OTHER };
    // },
};
export const SlotReversedActions = {
    ADD_SIZE_20: "REMOVE_SIZE_20",
    ADD_SIZE_24: "REMOVE_SIZE_24",
    ADD_SIZE_40: "REMOVE_SIZE_40",
    ADD_SIZE_45: "REMOVE_SIZE_45",
    ADD_SIZE_48: "REMOVE_SIZE_48",
    ADD_SIZE_53: "REMOVE_SIZE_53",
    ADD_REEFER: "REMOVE_REEFER",
    ADD_RESTRICTED: "REMOVE_RESTRICTED",
    ADD_COOLSTOWPROHIBITED: "REMOVE_COOLSTOWPROHIBITED",
    ADD_HAZARDOUSPROHIBITED: "REMOVE_HAZARDOUSPROHIBITED",
    REMOVE_SIZE_20: "ADD_SIZE_20",
    REMOVE_SIZE_24: "ADD_SIZE_24",
    REMOVE_SIZE_40: "ADD_SIZE_40",
    REMOVE_SIZE_45: "ADD_SIZE_45",
    REMOVE_SIZE_48: "ADD_SIZE_48",
    REMOVE_SIZE_53: "ADD_SIZE_53",
    REMOVE_REEFER: "ADD_REEFER",
    REMOVE_RESTRICTED: "ADD_RESTRICTED",
    REMOVE_COOLSTOWPROHIBITED: "ADD_COOLSTOWPROHIBITED",
    REMOVE_HAZARDOUSPROHIBITED: "ADD_HAZARDOUSPROHIBITED",
    ADD_SIZE_20_CONEREQ: "ADD_SIZE_20",
    ADD_SIZE_24_CONEREQ: "ADD_SIZE_24",
    ADD_SIZE_40_CONEREQ: "ADD_SIZE_40",
    ADD_SIZE_45_CONEREQ: "ADD_SIZE_45",
    ADD_SIZE_48_CONEREQ: "ADD_SIZE_48",
    ADD_SIZE_53_CONEREQ: "ADD_SIZE_53",
};
function simpleClone(x) {
    return JSON.parse(JSON.stringify(x));
}
