import { addStyles } from "../../helpers/addStyles";
export class FileElement extends HTMLElement {
    get value() {
        return this.files ? this.files[0] : undefined;
    }
    set value(s) {
        console.warn("File cannot be setted");
    }
    get accept() {
        return this.acceptTypesString;
    }
    set accept(s) {
        this.acceptTypesString = s;
        this.fileInputNode.accept = s;
    }
    get required() {
        return this.isRequired;
    }
    set required(s) {
        this.isRequired = s;
        if (s) {
            this.setAttribute("required", "");
            this.fileInputNode.setAttribute("required", "");
        }
        else {
            this.removeAttribute("required");
            this.fileInputNode.removeAttribute("required");
        }
    }
    get size() {
        return "medium";
    }
    set size(v) {
        this.internalSize = v;
    }
    get id() {
        return this.getAttribute("name") || "";
    }
    set id(n) {
        this.setAttribute("name", n);
    }
    get name() {
        return this.getAttribute("name") || "";
    }
    set name(n) {
        this.setAttribute("name", n);
    }
    get label() {
        return this.internalLabel;
    }
    set label(v) {
        this.internalLabel = v;
        this.spanNode.innerHTML = v;
    }
    get helpText() {
        return this.internalHelpText;
    }
    set helpText(v) {
        this.internalHelpText = v;
        this.spanNode.dataset.buttonText = v;
    }
    get type() {
        return this.localName;
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    focus() { }
    constructor() {
        super();
        this.acceptTypesString = "*.*";
        this.isRequired = false;
        this.files = null;
        this.internalSize = "medium";
        this.internalLabel = "";
        this.internalHelpText = "";
        this.reset = () => {
            this.fileInputNode.value = "";
            this.spanNode.innerHTML = this.internalLabel || "";
            this.files = null;
            this.dispatchEvent(new Event("sl-change"));
        };
        /**
         * Checks if there are files. Sets the label and makes validation
         */
        this.checkFile = () => {
            var _a;
            const curFiles = this.fileInputNode.files;
            if (this.matches(":disabled"))
                return;
            if (curFiles) {
                this.files = curFiles;
                this.spanNode.innerHTML =
                    curFiles.length === 0
                        ? this.getAttribute("data-label") || ""
                        : ((_a = curFiles.item(0)) === null || _a === void 0 ? void 0 : _a.name) || "";
            }
            this.dispatchEvent(new Event("sl-change"));
        };
        const shadow = this.attachShadow({ mode: "open" });
        const labelNode = document.createElement("label");
        const spanNode = document.createElement("span");
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.name = this.id;
        labelNode.className = "file-input";
        fileInput.tabIndex = -1;
        this.spanNode = spanNode;
        this.fileInputNode = fileInput;
        labelNode.appendChild(fileInput);
        labelNode.appendChild(spanNode);
        shadow.appendChild(addStyles(styles));
        shadow.appendChild(labelNode);
        this.attachEvents();
    }
    connectedCallback() {
        this.spanNode.innerHTML = this.label;
        this.spanNode.dataset.buttonText = this.internalHelpText || "Browse";
        if (!this.hasAttribute("tabindex")) {
            this.setAttribute("tabindex", "0");
        }
    }
    attributeChangedCallback(attrName, oldVal, newVal) {
        switch (attrName) {
            case "label":
                this.spanNode.innerHTML = newVal;
                break;
            case "helpText":
                this.spanNode.dataset.buttonText = newVal;
                break;
            case "class":
                if (newVal.indexOf("has-error") >= 0) {
                    if (!this.spanNode.classList.contains("has-error"))
                        this.spanNode.classList.add("has-error");
                }
                else {
                    this.spanNode.classList.remove("has-error");
                }
        }
    }
    attachEvents() {
        this.fileInputNode.addEventListener("change", this.checkFile, false);
        this.addEventListener("keyup", (ev) => {
            if (ev.code === "Space" || ev.code === "Enter")
                this.fileInputNode.click();
        }, false);
        this.fileInputNode.dispatchEvent(new Event("sl-change"));
    }
}
FileElement.formAssociated = true;
FileElement.observedAttributes = ["label", "class", "helpText"];
customElements.define("tf-file", FileElement);
const styles = `
  label.file-input input {
    opacity: 0;
    position: absolute;
  }

  label.file-input span {
    margin-right: 1em;
  }

  .has-error {
    color: var(--sl-color-danger-700);
  }
  
  label.file-input span:before {
    content: attr(data-button-text);
    display: inline-flex;
    margin-right: 1em;
    align-items: stretch;
    justify-content: center;
    border-radius: var(--sl-input-border-radius-medium);
    border-style: solid;
    border-width: var(--sl-input-border-width);
    background-color: var(--sl-color-neutral-0);
    border-color: var(--sl-color-neutral-300);
    color: var(--sl-color-neutral-700);
        padding: 0 1.25em;
    font-size: var(--sl-button-font-size-medium);
    height: var(--sl-input-height-medium);
    line-height: calc(
      var(--sl-input-height-medium) - var(--sl-input-border-width) * 2
    );
    font-family: var(--sl-input-font-family);
    font-weight: var(--sl-font-weight-semibold);
    white-space: nowrap;
    vertical-align: middle;
  }`;
