import { __awaiter } from "tslib";
import { ComponentWithFormAndNode, } from "../../common/ComponentWithFormAndNode";
import Services from "../../../app/services";
import awsCognito from "../../../app/security/awsCognito";
import { createScreen } from "../../helpers/createScreen";
import { getTranslation } from "../../../app/i18/i18tn";
import goSquared from "../../../app/tracking/goSquared";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/app.element";
import { z } from "zod";
const labelId = "auth:deleteAccount.deleteMyAccount";
export class TVDDeleteAccountComponent extends ComponentWithFormAndNode {
    constructor() {
        super();
        this.onSubmitDataForm = (values) => __awaiter(this, void 0, void 0, function* () {
            this.divWithSpinner.setLoading(true);
            if (securityModule.isMfaEnabled) {
                if (!values.mfaCode) {
                    this.showDialog("auth:deleteAccount.mfaCodeRequired");
                    this.divWithSpinner.setLoading(false);
                    return false;
                }
            }
            const resp = yield Services.accounts.deleteMyUserAccount(values.mfaCode || "", awsCognito.accessToken || "");
            this.divWithSpinner.setLoading(false);
            if (resp.statusCode !== 204) {
                this.showDialog(resp.message || "auth:deleteAccount.deleteAccountFailed");
                return false;
            }
            else {
                securityModule.signOut().then(() => {
                    router.navigate(routeFns.login(), undefined, true);
                });
            }
        });
        this.mainBox = document.createElement("div");
        this.mainBox.className = "oss-card";
        setAppTitle(getTranslation(labelId));
        goSquared.trackPage("Delete User Account");
    }
    connectedCallback() {
        this.appendChild(this.divWithSpinner);
        this.divWithSpinner.appendChild(this.mainBox);
        this.setCurrentFormAndNode(createDeleteAccountScreen(this.onSubmitDataForm, this.i18nCR));
    }
}
TVDDeleteAccountComponent.observedAttributes = [];
customElements.define("tvd-delete-account-component", TVDDeleteAccountComponent);
function createDeleteAccountScreen(onSubmitDataForm, i18nCR) {
    const DeleteAccountFormValidator = z.object({
        reType: z.string().refine((inp) => getTranslation(labelId) === inp),
        mfaCode: z
            .string()
            .optional()
            .refine((cc) => cc || !securityModule.isMfaEnabled),
    });
    const fields = [
        {
            type: "title",
            label: getTranslation("auth:deleteAccount.text"),
            createSection: false,
            tagName: "h3",
            name: "reType",
        },
        {
            type: "textBox",
            name: "reType",
            label: getTranslation("general:common.reTypeToConfirm", {
                word: getTranslation(labelId),
            }),
            placeholder: getTranslation(labelId),
            autoCompleteAttribute: "off",
        },
    ];
    if (securityModule.isMfaEnabled) {
        fields.push({
            type: "textBox",
            name: "mfaCode",
            label: "auth:deleteAccount.mfa",
            helpText: "auth:deleteAccount.mfaCodeRequired",
            autoCompleteAttribute: "one-time-code",
        });
    }
    const screen = createScreen({
        fields,
        onSubmitDataForm,
        formValidator: DeleteAccountFormValidator,
        i18nCR,
        h1Text: labelId,
        titleText: "menu:organization",
    });
    return screen;
}
