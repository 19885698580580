import {
  IOpenVesselDefinitionV1,
  TContainerLengths,
  ValuesSourceEnum,
} from "open-vessel-definition";

import { IIsoRowPattern } from "open-vessel-definition/build/src/models/base/types/IPositionPatterns";

export function removeCGsfromJson(
  json: IOpenVesselDefinitionV1,
): IOpenVesselDefinitionV1 {
  const clone = JSON.parse(JSON.stringify(json)) as IOpenVesselDefinitionV1;

  // 1. Remove Master CGs
  clone.shipData.masterCGs = {
    aboveTcgs: {},
    belowTcgs: {},
    bottomBases: {},
  };

  // 2. Remove Bay CGs
  for (const bayData of clone.baysData) {
    const perRowInfo = bayData.perRowInfo;
    const infoByContLength = bayData.infoByContLength;

    if (perRowInfo) {
      if (perRowInfo.each) {
        const rows = Object.keys(perRowInfo.each) as IIsoRowPattern[];

        for (const perRowInfoKey of rows) {
          const perRowInfoItem = perRowInfo.each[perRowInfoKey];
          perRowInfoItem.tcg = undefined;
          perRowInfoItem.bottomBase = undefined;

          const rowInfoByLength = perRowInfoItem.rowInfoByLength;

          if (rowInfoByLength) {
            const sizes = Object.keys(rowInfoByLength).map(
              Number,
            ) as TContainerLengths[];

            sizes.forEach((size) => {
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              const rowInfoByLengthItem = rowInfoByLength[size]!;
              rowInfoByLengthItem.lcg = undefined;
            });
          }
        }
      }

      if (perRowInfo.common) {
        perRowInfo.common.bottomBase = undefined;
      }
    }

    if (infoByContLength) {
      const sizes = Object.keys(infoByContLength).map(
        Number,
      ) as TContainerLengths[];

      sizes.forEach((size) => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const infoByContLengthItem = infoByContLength[size]!;
        infoByContLengthItem.lcg = undefined;
      });
    }
  }

  if (clone.shipData.lcgOptions)
    clone.shipData.lcgOptions.values = ValuesSourceEnum.ESTIMATED;

  if (clone.shipData.tcgOptions)
    clone.shipData.tcgOptions.values = ValuesSourceEnum.ESTIMATED;

  if (clone.shipData.vcgOptions)
    clone.shipData.vcgOptions.values = ValuesSourceEnum.ESTIMATED;

  return clone;
}
