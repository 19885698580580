import { IDetailsDownloads } from "./logEvents/logEvents";
import { IFile } from "./files";
import { IFileConsumer } from "./fileConsumers";

export type INotification =
  | INotificationFileCloneDiffers
  | INotificationVoteDownloadFile
  | INotificationConsumerDiffers;

type INotificationBase = { date: Date };

export type INotificationFileCloneDiffers = INotificationBase & {
  type: "FileCloneDiffers";
  data: IFile;
};

export type INotificationVoteDownloadFile = INotificationBase & {
  type: "UpvotedFile" | "RemovedVoteFile" | "FileCloned";
  data: IDetailsDownloads & {
    shipName: string;
    imoCode: string;
    callSign: string;
    fileId: string;
  };
};

export type INotificationConsumerDiffers = INotificationBase & {
  type: "FileConsumerDiffers";
  data: {
    sourceFileId: string;
    consumerOrgId: string;
    consumerOrgName: string;
    consumerFileId: string;
    shipName: string;
    imoCode: string;
    callSign: string;
  };
};

export function filterNotificationsByType(
  notification: INotification,
  types: Array<INotification["type"]>,
): INotification | undefined {
  return types.indexOf(notification.type) > -1 ? notification : undefined;
}
