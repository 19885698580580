/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { __awaiter } from "tslib";
import SmartTable from "../common/smartTable/smart-table.element";
import { getTranslation, i18nReactive } from "../../app/i18/i18tn";
import EditDrawer from "./EditDrawer";
import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import IntegratedDialogError from "../common/IntegratedDialogError";
import globalStore from "../../app/stores/globalStore";
import goSquared from "../../app/tracking/goSquared";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import securityModule from "../../app/security/SecurityModule";
import topMessageElement from "../layout/top-tools/getTopMessageElement";
export class ListComponentWithActions extends HTMLElement {
    constructor() {
        super();
        this.goSquaredLabel = "";
        this.h1Label = "";
        this.topLabel = "";
        this.createLabel = "";
        this.deleteLabel = "";
        this.systemRightToEdit = "";
        this.tableConfig = undefined;
        this.serviceListCall = undefined;
        this.serviceDeleteCall = undefined;
        this.serviceEditCall = undefined;
        this.createEditForm = undefined;
        this.displayList = () => {
            if (!this.serviceListCall) {
                throw new Error("serviceCall is undefined");
            }
            if (this.tableConfig === undefined) {
                throw new Error("tableConfig is undefined");
            }
            globalStore.touchLastUserInteraction();
            const wrapper = this.wrapper;
            const box = this.mainBox;
            removeChildren(box);
            let actionAddNewButton = undefined;
            if (this.createLabel &&
                securityModule.userHasPermission(this.systemRightToEdit)) {
                actionAddNewButton = document.createElement("sl-button");
                actionAddNewButton.innerHTML = getTranslation(this.createLabel);
                actionAddNewButton.variant = "primary";
                actionAddNewButton.addEventListener("click", () => {
                    this.openEditModal();
                }, false);
            }
            wrapper.setLoading(true);
            this.serviceListCall()
                .then((resp) => {
                if (resp.data) {
                    const tableConfig = this.tableConfig(resp.data);
                    const tableUsers = new SmartTable().initialize(Object.assign(Object.assign({}, tableConfig), { settings: Object.assign(Object.assign({}, tableConfig.settings), { createSortIconFn: createSortIcon, actionBox: actionAddNewButton }) }));
                    box.appendChild(tableUsers);
                }
            })
                .catch((e) => {
                this.showError(e);
            })
                .finally(() => {
                wrapper.setLoading(false);
            });
        };
        this.showError = (e) => {
            this.dialogError.show(getTranslation(`errors:errorHasOcurred`), getTranslation(typeof e === "string" ? e : e.translationKey));
            console.error(e);
        };
        this.openEditModal = (pk, prevData) => {
            if (!this.serviceEditCall) {
                throw new Error("serviceEditCall is undefined");
            }
            if (!this.createEditForm) {
                throw new Error("createEditForm is undefined");
            }
            const editForm = this.createEditForm(this.editDrawer.submitButton, prevData);
            if (editForm) {
                const drawer = this.editDrawer.getEditDrawer({
                    title: getTranslation(this.createLabel),
                    showUnits: false,
                    readOnlyMode: false,
                    onSave: () => __awaiter(this, void 0, void 0, function* () {
                        this.editDrawer.disabled = true;
                        const valid = yield editForm.submitFunction();
                        this.editDrawer.disabled = false;
                        if (valid) {
                            this.displayList();
                        }
                        return valid;
                    }),
                });
                drawer.appendChild(editForm.node);
                drawer.show();
            }
        };
        this.openDeleteModal = (pk, dta, name) => {
            const dialog = this.deleteDialog;
            removeChildren(dialog);
            if (!this.serviceDeleteCall) {
                throw new Error("serviceDeleteCall is undefined");
            }
            dialog.label = getTranslation(this.deleteLabel);
            const htmlContent = document.createElement("div");
            htmlContent.innerHTML = `<strong>${dta[name]}</strong>`;
            const closeBtn = document.createElement("sl-button");
            closeBtn.slot = "footer";
            closeBtn.variant = "danger";
            closeBtn.autofocus = true;
            closeBtn.tabIndex = 0;
            closeBtn.innerHTML = getTranslation("general:common.delete");
            closeBtn.addEventListener("click", () => __awaiter(this, void 0, void 0, function* () {
                closeBtn.disabled = true;
                yield this.serviceDeleteCall(pk);
                dialog.hide();
                this.displayList();
            }), false);
            dialog.appendChild(htmlContent);
            dialog.appendChild(closeBtn);
            this.appendChild(dialog);
            dialog.show();
        };
        this.i18nCR = new I18nComponentRegisterer(i18nReactive);
        this.dialogError = new IntegratedDialogError(this);
        this.wrapper = document.createElement("div-spinner-element");
        this.mainBox = document.createElement("div");
        this.mainBox.className = "oss-card";
        this.deleteDialog = document.createElement("sl-dialog");
        this.editDrawer = new EditDrawer();
    }
    connectedCallback() {
        goSquared.addEvent(`${this.goSquaredLabel} - Show page`);
        const wrapper = this.wrapper, h1 = document.createElement("h1");
        wrapper.appendChild(h1);
        wrapper.appendChild(this.mainBox);
        this.appendChild(this.editDrawer.drawer);
        this.appendChild(wrapper);
        this.displayList();
        this.i18nCR.addConsumer(topMessageElement.element, this.topLabel, "innerHTML");
        this.i18nCR.addConsumer(h1, this.h1Label, "innerHTML");
    }
    disconnectedCallback() {
        this.i18nCR.disconnect();
    }
}
ListComponentWithActions.observedAttributes = [];
function createSortIcon(asc) {
    const icon = document.createElement("sl-icon");
    icon.name = asc ? "sort-down-alt" : "sort-up-alt";
    return icon;
}
