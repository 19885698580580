import IRouteInternal from "../types/IRouteInternal";
import { matchPath } from "./matchPath";
import { parsePath } from "history";

/**
 * Matches the given routes to a location and returns the match data.
 *
 * @see https://reactrouter.com/docs/en/v6/api#matchroutes
 */
export default function matchRoutes(
  routes: IRouteInternal[],
  locationArg: Partial<Location> | string,
  basename = "/",
): IRouteInternal | null {
  const location =
    typeof locationArg === "string" ? parsePath(locationArg) : locationArg;

  const pathname = stripBasename(location.pathname || "/", basename);

  if (pathname == null) {
    return null;
  }

  const rankedRoutes = routes
    .slice()
    .sort((a: IRouteInternal, b: IRouteInternal) => b.score - a.score);

  for (let i = 0; i < rankedRoutes.length; ++i) {
    if (matchPath(rankedRoutes[i].path, pathname)) {
      return rankedRoutes[i];
    }
  }

  return null;
}

function stripBasename(pathname: string, basename: string): string | null {
  if (basename === "/") return pathname;

  if (!pathname.toLowerCase().startsWith(basename.toLowerCase())) {
    return null;
  }

  const nextChar = pathname.charAt(basename.length);
  if (nextChar && nextChar !== "/") {
    // pathname does not start with basename/
    return null;
  }

  return pathname.slice(basename.length) || "/";
}
