import SlSwitch from "@shoelace-style/shoelace/dist/components/switch/switch";
import { addStyles } from "../../helpers/addStyles";

const styles = (padStart: boolean) => `

  .checkbox {
    ${
      padStart
        ? "padding-top: calc(var(--sl-input-label-font-size-medium) * 2);"
        : ""
    }
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .checkbox__help-text {
    font-size: var(--sl-input-help-text-font-size-medium);
    color: var(--sl-input-help-text-color);
    margin-top: 0.5em;
  }

  .checkbox:not(.checkbox--has-help-text) .checkbox__help-text {
    display:none;
  }

  .has-error::part(label),
  :host(.has-error)::part(label) {
    color: var(--sl-color-danger-700) !important;
  }

  .has-error::part(thumb),
  .has-error::part(control),
  :host(.has-error)::part(thumb),
  :host(.has-error)::part(control) {
    border-color: var(--sl-color-danger-700) !important;
  }

  a {
    color: var(--sl-color-primary-600);
  }
  
  a:visited {
    color: var(--sl-color-primary-500);
  }
`;

export class TfCheckbox extends HTMLElement {
  public static observedAttributes = ["class"];

  private holder: HTMLDivElement;
  private slCheckbox: SlSwitch;
  private internalHelpDiv: HTMLDivElement;

  private internalLabel?: string = undefined;
  private internalHelpText?: string = undefined;
  private internalId = "";
  private internalPadStart = false;
  private internalIsNumericEnum = false;

  public get required() {
    return this.slCheckbox.required;
  }
  public set required(v: boolean) {
    this.slCheckbox.required = v;
  }

  constructor() {
    super();

    this.holder = document.createElement("div");
    this.holder.className = "checkbox";

    this.slCheckbox = document.createElement("sl-switch");
    this.slCheckbox.tabIndex = 0;
    this.slCheckbox.setAttribute("exportparts", "label base control");

    this.internalHelpDiv = document.createElement("div");
    this.internalHelpDiv.className = "checkbox__help-text";

    const onKeyPressed = (ev: KeyboardEvent) => {
      const t = ev.target as SlSwitch;
      if (t.disabled) return;
      if (ev.key === " ") {
        ev.preventDefault();
        this.slCheckbox.checked = !this.slCheckbox.checked;
        t.dispatchEvent(new Event("sl-change"));
      }
    };

    this.slCheckbox.addEventListener("keydown", onKeyPressed, false);
  }

  get name() {
    return this.internalId;
  }
  set name(n: string) {
    this.setAttribute("name", n);
    this.slCheckbox.id = n;
    this.internalId = n;
  }

  get label() {
    return this.internalLabel;
  }
  set label(v: string | undefined) {
    this.internalLabel = v;
    this.slCheckbox.innerHTML = this.internalLabel ?? "";
  }

  get helpText() {
    return this.internalHelpText;
  }
  set helpText(v: string | undefined) {
    this.internalHelpText = v;
    this.internalHelpDiv.innerHTML = this.internalHelpText ?? "";

    v
      ? this.holder.classList.add("checkbox--has-help-text")
      : this.holder.classList.remove("checkbox--has-help-text");
  }

  get size() {
    return "medium";
  }
  set size(_s: "small" | "medium" | "large") {
    //Nothings
  }

  get disabled() {
    return this.slCheckbox.disabled;
  }
  set disabled(v: boolean) {
    this.slCheckbox.disabled = v;
  }

  get value(): boolean | (1 | 0) {
    return this.isNumericEnum
      ? this.slCheckbox.checked
        ? 1
        : 0
      : this.slCheckbox.checked;
  }

  set value(s: boolean | (1 | 0)) {
    if (typeof s === "boolean") {
      this.slCheckbox.checked = s;
    } else {
      this.slCheckbox.checked = s === 1;
    }
  }

  get valueAsNumber(): 1 | 0 {
    return this.value ? 1 : 0;
  }
  set valueAsNumber(s: 1 | 0) {
    this.slCheckbox.checked = s === 1;
  }

  get padStart(): boolean {
    return !this.internalPadStart;
  }
  set padStart(s: boolean) {
    this.internalPadStart = s;
  }

  get isNumericEnum() {
    return this.internalIsNumericEnum;
  }
  set isNumericEnum(v: boolean) {
    this.internalIsNumericEnum = v;
  }

  focus = () => {
    this.slCheckbox.focus();
  };

  select = () => {
    this.focus();
  };

  connectedCallback() {
    const shadow = this.attachShadow({ mode: "open" });

    this.holder.appendChild(this.slCheckbox);
    this.holder.appendChild(this.internalHelpDiv);

    shadow.appendChild(addStyles(styles(this.internalPadStart)));
    shadow.appendChild(this.holder);
  }

  attributeChangedCallback(name: string, oldValue: string, newValue: string) {
    if (name === "class") {
      if (newValue.indexOf("has-error") >= 0) {
        if (!this.slCheckbox.classList.contains("has-error"))
          this.slCheckbox.classList.add("has-error");
      } else {
        this.slCheckbox.classList.remove("has-error");
      }
    }
  }
}

customElements.define("tf-checkbox", TfCheckbox);

declare global {
  interface HTMLElementTagNameMap {
    "tf-checkbox": TfCheckbox;
  }
}
