import { addStyles } from "../../helpers/addStyles";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
export class CheckboxGroup extends HTMLElement {
    constructor() {
        super();
        this.internalCheckboxes = {};
        this.itemsInfo = [];
        this.attached = false;
        this.internalValue = [];
        this.internalLabel = undefined;
        this.internalHelpText = undefined;
        this.internalId = "";
        this.showAsFieldset = false;
        this.disabledItems = [];
        this.isNumericEnum = false;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.onCheckboxClicked = (_ev) => {
            this.internalValue = this.itemsInfo
                .filter(({ value }) => this.internalCheckboxes[String(value)].checked)
                .map(({ value }) => value);
            this.dispatchEvent(new Event("sl-change"));
        };
        this.internalRootFieldset = document.createElement("fieldset");
        this.internalRootFieldset.className = "checkbox-group";
        this.internalLegend = document.createElement("legend");
        this.internalLegend.className = "checkbox-group__label";
        this.internalHelpDiv = document.createElement("div");
        this.internalHelpDiv.className = "checkbox-group__help-text";
        this.internalRootFieldset.appendChild(this.internalLegend);
    }
    get name() {
        return this.internalId;
    }
    set name(n) {
        this.setAttribute("id", n);
        this.setAttribute("name", n);
        this.internalRootFieldset.id = n;
        this.internalId = n;
    }
    get label() {
        return this.internalLabel;
    }
    set label(v) {
        var _a;
        this.internalLabel = v;
        this.internalLegend.innerHTML = (_a = this.internalLabel) !== null && _a !== void 0 ? _a : "";
    }
    get helpText() {
        return this.internalHelpText;
    }
    set helpText(v) {
        var _a;
        this.internalHelpText = v;
        this.internalHelpDiv.innerHTML = (_a = this.internalHelpText) !== null && _a !== void 0 ? _a : "";
        v
            ? this.internalRootFieldset.classList.add("checkbox-group--has-help-text")
            : this.internalRootFieldset.classList.remove("checkbox-group--has-help-text");
    }
    get fieldset() {
        return this.showAsFieldset;
    }
    set fieldset(v) {
        this.showAsFieldset = v;
        v
            ? this.internalRootFieldset.classList.add("checkbox-group--has-fieldset")
            : this.internalRootFieldset.classList.remove("checkbox-group--has-fieldset");
    }
    get size() {
        return "medium";
    }
    set size(_s) {
        //Nothings
    }
    get items() {
        return this.itemsInfo;
    }
    set items(v) {
        this.itemsInfo = v;
        if (!this.attached)
            return;
        removeChildren(this.internalRootFieldset);
        this.internalCheckboxes = {};
        this.internalRootFieldset.appendChild(this.internalLegend);
        v.forEach((itemInfo) => {
            const chkbx = document.createElement("sl-checkbox");
            chkbx.innerHTML = itemInfo.label;
            chkbx.value = `${this.internalId}-${itemInfo.value}`;
            chkbx.tabIndex = 0;
            this.internalRootFieldset.appendChild(chkbx);
            this.internalCheckboxes[String(itemInfo.value)] = chkbx;
            chkbx.addEventListener("keydown", onKeyPressed, false);
            chkbx.addEventListener("sl-change", this.onCheckboxClicked, false);
        });
        if (this.internalValue !== undefined) {
            this.setOptions(this.internalValue);
        }
        this.internalRootFieldset.appendChild(this.internalHelpDiv);
        function onKeyPressed(ev) {
            const t = ev.target;
            if (t.disabled)
                return;
            if (ev.key === " ") {
                t.checked = !t.checked;
                t.dispatchEvent(new Event("sl-change"));
            }
        }
    }
    get disabled() {
        return this.disabledItems;
    }
    set disabled(v) {
        this.disabledItems = v;
        this.setOptions(this.internalValue);
    }
    get value() {
        return this.isNumericEnum
            ? this.internalValue.map(Number)
            : this.internalValue.map(String);
    }
    set value(s) {
        this.internalValue = s;
        this.setOptions(s);
    }
    focus() {
        if (this.itemsInfo) {
            this.internalCheckboxes[String(this.itemsInfo[0].value)].focus();
        }
    }
    connectedCallback() {
        this.attached = true;
        const shadow = this.attachShadow({ mode: "open" });
        shadow.appendChild(addStyles(styles));
        shadow.appendChild(this.internalRootFieldset);
        this.items = this.itemsInfo;
    }
    disconnectedCallback() {
        this.attached = false;
    }
    setOptions(values) {
        if (!this.attached)
            return;
        this.itemsInfo.forEach(({ value }) => {
            this.internalCheckboxes[String(value)].checked =
                values.indexOf(value) >= 0;
            this.internalCheckboxes[String(value)].disabled =
                this.disabledItems.indexOf(value) >= 0;
        });
    }
    attributeChangedCallback(attrName, oldVal, newVal) {
        switch (attrName) {
            case "class":
                if (newVal.indexOf("has-error") >= 0) {
                    if (!this.internalRootFieldset.classList.contains("has-error"))
                        this.internalRootFieldset.classList.add("has-error");
                }
                else {
                    this.internalRootFieldset.classList.remove("has-error");
                }
        }
    }
}
CheckboxGroup.observedAttributes = ["class"];
customElements.define("tf-checkbox-group", CheckboxGroup);
const styles = `

  sl-checkbox { 
    display: inline-flex;
    margin-right: 2em;
  }

  .checkbox-group {
    border: solid var(--sl-panel-border-width) var(--sl-panel-border-color);
    border-radius: var(--sl-border-radius-medium);
    padding: var(--sl-spacing-large);
    padding-top: var(--sl-spacing-x-small);
  }
  .checkbox-group .checkbox-group__label {
    font-family: var(--sl-input-font-family);
    font-size: var(--sl-input-font-size-medium);
    font-weight: var(--sl-input-font-weight);
    color: var(--sl-input-color);
    padding: 0 var(--sl-spacing-2x-small);
  }

  .checkbox-group:not(.checkbox-group--has-fieldset) {
    border: none;
    padding: 0;
    margin: 0;
    min-width: 0;
  }
  .checkbox-group:not(.checkbox-group--has-fieldset) .checkbox-group__label {
    position: absolute;
    width: 0;
    height: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    white-space: nowrap;
  }

  .checkbox-group__help-text {
    font-size: var(--sl-input-help-text-font-size-medium);
    color: var(--sl-input-help-text-color);
    margin-top: 0.5em;
  }

  .checkbox-group:not(.checkbox-group--has-help-text) .checkbox-group__help-text {
    display:none;
  }

  
`;
