import { IOpenVesselDefinitionV1, IShipData } from "open-vessel-definition";
import ViewCgsConfig, { ICgsPartData } from "./ViewCgsConfig";

import { ICGsStatistics } from "@baplie-viewer2/tedivo-bay-grid-core";
import ISizeSummary from "open-vessel-definition/build/src/models/base/ISizeSummary";
import ViewShipDataConfig from "./ViewShipDataConfig";
import ViewSizeSummaryConfig from "./ViewSizeSummaryConfig";
import getTranslatedDictionary from "../../../common/dictionaryDisplay/helpers/getTranslatedDictionary";
import mapDataToDictionary from "../../../common/dictionaryDisplay/helpers/mapDataToDictionary";

export const buildConfigObjects = (
  data: IOpenVesselDefinitionV1,
  generatedData: IExtraDataForConfigObjects,
) => {
  const shipPartDataElements = getTranslatedDictionary(
    mapDataToDictionary<IShipData>(data.shipData, ViewShipDataConfig),
  );

  const sizeSummaryDataElements = getTranslatedDictionary(
    mapDataToDictionary<ISizeSummary & { totalTEUs: number }>(
      { ...data.sizeSummary, totalTEUs: generatedData.totalTEUs },
      ViewSizeSummaryConfig,
    ),
  );

  const { cgsStats } = generatedData;
  const cgsPartData: ICgsPartData = {
    lcgValues: data.shipData.lcgOptions.values,
    vcgValues: data.shipData.vcgOptions.values,
    tcgValues: data.shipData.tcgOptions.values,
    lcgDataFilled: Math.floor(
      (cgsStats.definedLcgs / (cgsStats.countLcgs || 1)) * 100,
    ),
    vcgDataFilled: Math.floor(
      (cgsStats.definedBottomBase / (cgsStats.countBottomBase || 1)) * 100,
    ),
    tcgDataFilled: Math.floor(
      (cgsStats.definedTcgs / (cgsStats.countTcgs || 1)) * 100,
    ),
    lpp: data.shipData.lcgOptions.lpp,
    loa: data.shipData.loa || 0,
    sternToAftPp: data.shipData.sternToAftPp || 0,
  };

  const cgsPartDataElements = getTranslatedDictionary(
    mapDataToDictionary<ICgsPartData>(cgsPartData, ViewCgsConfig),
  );

  return {
    shipPartDataElements,
    cgsPartDataElements,
    sizeSummaryDataElements,
  };
};

export interface IExtraDataForConfigObjects {
  cgsStats: ICGsStatistics;
  totalTEUs: number;
}
