import { IFields } from "@baplie-viewer2/tedivo-form";
import MassUnitsEnum from "../../../../../app/enums/MassUnitsEnum";
import globalUnits from "../../../../../app/units/globalUnits";
import { numberIsOdd } from "@baplie-viewer2/tedivo-pure-helpers";
import { z } from "zod";

const createFormFields = (
  label: string,
  startIsoBay: number,
  endIsoBay: number,
  portIsoRow: number,
  starboardIsoRow: number,
  weight: number,
  overlapPort: number,
  overlapStarboard: number
): IFields<ILidDataStore> => [
  {
    name: "startIsoBay",
    type: "number",
    label: "view:lids.startIsoBay",
    initialValue: startIsoBay,
    holderClassName: "field50centered",
  },
  [
    {
      name: "portIsoRow",
      type: "number",
      initialValue: portIsoRow,
      label: "view:lids.portIsoRow",
    },
    {
      name: "starboardIsoRow",
      type: "number",
      initialValue: starboardIsoRow,
      label: "view:lids.starboardIsoRow",
    },
  ],
  {
    name: "endIsoBay",
    type: "number",
    initialValue: endIsoBay,
    label: "view:lids.endIsoBay",
    holderClassName: "field50centered",
  },
  [
    {
      name: "label",
      type: "textBox",
      label: "general:common.label",
      initialValue: label,
    },
    {
      name: "weight",
      type: "numberWithUnits",
      label: "view:weight",
      initialValue: weight,
      converter: globalUnits.massUnits,
      helpText: `enums:MassUnitsEnum.${
        MassUnitsEnum[globalUnits.massUnits.units]
      }`,
    },
  ],
  [
    {
      name: "overlapPort",
      type: "checkbox",
      label: "view:lids.overlapPort",
      initialValue: overlapPort,
    },
    {
      name: "overlapStarboard",
      type: "checkbox",
      label: "view:lids.overlapStarboard",
      initialValue: overlapStarboard,
    },
  ],
];

export default createFormFields;

export const CreateLidFormFieldsValidator: z.Schema<ILidDataStore> = z
  .object({
    label: z.string().optional(),
    startIsoBay: z.number().min(1).refine(numberIsOdd),
    endIsoBay: z.number().min(1).refine(numberIsOdd),
    portIsoRow: z.number(),
    starboardIsoRow: z.number(),
    overlapPort: z.preprocess<z.ZodNumber>(
      (v) => (v ? 1 : 0),
      z.number()
    ) as z.ZodEffects<z.ZodNumber>,
    overlapStarboard: z.preprocess<z.ZodNumber>(
      (v) => (v ? 1 : 0),
      z.number()
    ) as z.ZodEffects<z.ZodNumber>,
    weight: z.number().optional(),
  })
  .refine((values) => values.endIsoBay >= values.startIsoBay, {
    path: ["startIsoBay", "endIsoBay"],
  })
  .refine(
    (values) => {
      const portIsEven = values.portIsoRow % 2 === 0,
        stbdIsEven = values.starboardIsoRow % 2 === 0;

      if (portIsEven && stbdIsEven)
        return values.portIsoRow >= values.starboardIsoRow;

      if (portIsEven && !stbdIsEven) return true;

      if (!portIsEven && stbdIsEven) return false;

      if (!portIsEven && !stbdIsEven)
        return values.portIsoRow <= values.starboardIsoRow;

      return false;
    },
    {
      path: ["portIsoRow", "starboardIsoRow"],
    }
  );

export interface ILidDataStore extends Record<string, unknown> {
  label?: string;
  portIsoRow: number;
  starboardIsoRow: number;
  startIsoBay: number;
  endIsoBay: number;
  overlapPort: number;
  overlapStarboard: number;
  weight?: number;
}
