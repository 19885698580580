import { IFormAndNode, createScreen } from "../../helpers/createScreen";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";

import DivWithSpinner from "../../common/divWithSpinner/div-with-spinner-element";
import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import ICustomError from "../../types/ICustomError";
import { IFields } from "@baplie-viewer2/tedivo-form";
import IntegratedDialogError from "../../common/IntegratedDialogError";
import Services from "../../../app/services";
import { TPlan } from "@baplie-viewer2/tedivo-api-models";
import goSquared from "../../../app/tracking/goSquared";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/app.element";
import { z } from "zod";

export class TVDEditDetailsComponent extends HTMLElement {
  public static observedAttributes = [];

  private i18nCR: I18nComponentRegisterer;
  private dialogError: IntegratedDialogError;
  private divWithSpinner: DivWithSpinner;
  private mainBox: HTMLDivElement;
  private currentFormAndNode?: IFormAndNode<IAuthEditDetails>;

  constructor() {
    super();
    this.i18nCR = new I18nComponentRegisterer(i18nReactive);
    this.dialogError = new IntegratedDialogError(this);
    this.divWithSpinner = new DivWithSpinner();
    this.mainBox = document.createElement("div");
    this.mainBox.className = "oss-card";

    setAppTitle(getTranslation("auth:editDetails"));
  }

  async connectedCallback() {
    goSquared.trackPage("Edit my details");
    goSquared.addEvent("Edit my details - Show page");

    this.appendChild(this.divWithSpinner);

    this.divWithSpinner.setLoading(true);
    const myUserDetails = await Services.accounts.getMyDetails();
    this.divWithSpinner.setLoading(false);

    if (!myUserDetails.data) {
      router.navigate(routeFns.login());
    } else {
      const { name, familyName, email, marketing } = myUserDetails.data || {};

      this.setCurrentFormAndNode(
        createOrgUserEdit(
          this.onSubmitEditDetailsForm,
          this.i18nCR,
          {
            name: name || securityModule.name,
            familyName: familyName || securityModule.familyName,
            email,
            allowMarketing: marketing || false,
          },
          securityModule.orgAllowedDomains || [],
        ),
      );
    }
  }

  disconnectedCallback() {
    this.i18nCR.disconnect();
  }

  showError = (e: ICustomError) => {
    this.dialogError.show(
      getTranslation(`errors:errorHasOcurred`),
      getTranslation(typeof e === "string" ? e : e.translationKey),
    );
  };

  /** Replaces the UI */
  private setCurrentFormAndNode = (fan: typeof this.currentFormAndNode) => {
    this.currentFormAndNode = fan;

    if (fan?.node) {
      this.divWithSpinner.setLoading(false);
      removeChildren(this.divWithSpinner);
      this.divWithSpinner.appendChild(fan.node);
    }
  };

  private onSubmitEditDetailsForm = async (values: IAuthEditDetails) => {
    goSquared.addEvent("Edit my details - Submit");
    this.divWithSpinner.setLoading(true);

    const data = {
      name: values.name,
      familyName: values.familyName,
      email: values.email || "",
    };

    const resp = await Services.accounts.editMyDetails(data);

    if (!resp.data?.id) {
      this.showError({
        errorCode: String(resp.statusCode),
        message: resp.code || "errorModifyingUser",
        translationKey: resp.message || "errors:errorModifyingUser",
      });
      this.divWithSpinner.setLoading(false);
      return false;
    } else {
      securityModule.updateUserDetails(
        values.name,
        values.familyName,
        values.email || "",
        values.allowMarketing || false,
      );

      goSquared.identify({
        email: values.email || "",
        name: `${values.name || ""} ${values.familyName || ""}`,
        organizationName: securityModule.currentOrganizationName || "",
        marketingMailsConsent: values.allowMarketing || false,
        organizationPlanId: (securityModule.currentOrganizationPlanId ||
          "FREE") as TPlan,
        expirationDate: securityModule.planExpirationDate || "",
      });

      router.navigate(routeFns.login());
      return true;
    }
  };
}

customElements.define("tvd-edit-details-component", TVDEditDetailsComponent);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-edit-details-component": TVDEditDetailsComponent;
  }
}

function createOrgUserEdit(
  onSubmitDataForm: (values: IAuthEditDetails) => void,
  i18nCR: I18nComponentRegisterer,
  prevData: IAuthEditDetails,
  allowedDomains: string[],
): IFormAndNode<IAuthEditDetails> | undefined {
  const formValidator = z
    .object({
      name: z.string().min(1),
      familyName: z.string().min(1),
      allowMarketing: z.boolean(),
      email: z
        .string()
        .email()
        .transform((v) => v?.toLowerCase()),
    })
    .refine(
      (data) => {
        if (data.email) {
          const domain = (data.email.split("@")[1] || "").toLowerCase().trim();
          if (allowedDomains.indexOf(domain) < 0) return false;
          return true;
        } else return false;
      },
      {
        path: ["email"],
      },
    );

  const formFields: IFields<IAuthEditDetails> = [
    [
      {
        name: "name",
        label: "general:common.name",
        type: "textBox",
        initialValue: prevData?.name || "",
      },
      {
        name: "familyName",
        label: "general:users.familyName",
        type: "textBox",
        initialValue: prevData?.familyName || "",
      },
    ],
    {
      name: "email",
      label: "general:common.email",
      type: "textBox",
      initialValue: prevData?.email || "",
      helpText: getTranslation("general:users.domainsHelpText", {
        domains: allowedDomains.join(", ") || "",
      }),
    },
    {
      name: "allowMarketing",
      label: "general:users.allowMarketing",
      type: "checkbox",
      initialValue: prevData?.allowMarketing || false,
    },
  ];

  return createScreen<IAuthEditDetails>({
    fields: formFields,
    onSubmitDataForm,
    formValidator,
    i18nCR,
    h1Text: "auth:editDetails",
    titleText: "menu:organization",
  });
}

interface IAuthEditDetails {
  name: string;
  familyName: string;
  email?: string;
  allowMarketing: boolean;
}
