/* eslint-disable @typescript-eslint/no-explicit-any */

import { IFieldsTypes, TedivoForm } from "./TedivoForm";

import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";

export function translateTedivoForm<T extends Record<string, unknown>>({
  tedivoForm,
  i18nCR,
  getTranslation,
}: ITedivoFormTranslatorProps<T>): void {
  const allFormNodes = tedivoForm.getAllFormNodes();

  allFormNodes.forEach((fieldStateNode) => {
    const fieldProps = fieldStateNode.props;
    const formField = fieldStateNode.field;

    switch (fieldProps.type) {
      case "node":
        break;

      case "title":
        if (i18nCR) {
          if (fieldProps.label)
            i18nCR.addConsumer(formField, fieldProps.label, "innerHTML");
        } else if (getTranslation !== undefined) {
          if (fieldProps.label)
            formField.innerHTML = getTranslation(fieldProps.label);
        }

        break;

      default:
        if (i18nCR) {
          if (fieldProps.label)
            i18nCR.addConsumer(formField, fieldProps.label, "label");
          if (fieldProps.helpText)
            i18nCR.addConsumer(formField, fieldProps.helpText, "helpText");
          if ((fieldProps as any).placeholder)
            i18nCR.addConsumer(
              formField,
              (fieldProps as any).placeholder,
              "placeholder",
            );
        } else if (getTranslation !== undefined) {
          if (fieldProps.label)
            (formField as IFieldsTypes<any>).label = getTranslation(
              fieldProps.label,
            );
          if (fieldProps.helpText)
            (formField as IFieldsTypes<any>).helpText = getTranslation(
              fieldProps.helpText,
            );
          if ((fieldProps as any).placeholder)
            (formField as any).placeholder = getTranslation(
              (fieldProps as any).placeholder,
            );
        }
    }
  });
}

type ITedivoFormTranslatorProps<T extends Record<string, unknown>> = {
  tedivoForm: TedivoForm<T>;
} & (
  | {
      i18nCR: I18nComponentRegisterer;
      getTranslation?: never;
    }
  | {
      i18nCR?: never;
      getTranslation: (s: string) => string;
    }
);
