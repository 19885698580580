import "@shoelace-style/shoelace/dist/components/badge/badge";

import {
  IGetBayLcgVcgAndPairingsResult,
  createSimpleDeckView,
} from "@baplie-viewer2/tedivo-bay-grid-core";
import { ILidData, ISizeSummary } from "open-vessel-definition";

import { getTranslation } from "../../../../app/i18/i18tn";

interface ICreateDeckViewProps {
  sizeSummary: ISizeSummary;
  lidData: ILidData[];
  lcgVcgTcgAndPairings: IGetBayLcgVcgAndPairingsResult;
  editActionNode: HTMLElement;
  bayNumbersDoubleClickAction?: (ev: SVGElement) => void;
}

export function createDeckView({
  sizeSummary,
  lidData,
  lcgVcgTcgAndPairings,
  editActionNode,
  bayNumbersDoubleClickAction,
}: ICreateDeckViewProps): HTMLElement {
  const root = getComputedStyle(document.body);

  const card = document.createElement("card-element");
  card.titleHtml = getTranslation("view:deckView");
  card.name = "deckView";

  if (editActionNode) card.actions = editActionNode;

  try {
    if (lcgVcgTcgAndPairings.totalSlotsCount === 0) {
      const empty = document.createElement("div");
      empty.className = "no-slots-defined";
      empty.innerHTML = getTranslation(
        "view:graphicWillDisplaySlotsWhenDefined",
      );
      card.appendChild(empty);
    }

    const { deckViewSvg, missingImportantXcgs } = createSimpleDeckView({
      sizeSummary,
      lidData,
      lcgVcgTcgAndPairings,
      symbolsOptions: {
        strokeWidth: 1,
        strokeColor: root.getPropertyValue("--sl-color-neutral-200"),
        fontColor: root.getPropertyValue("--sl-color-neutral-700"),
        fillColor: root.getPropertyValue("--sl-color-neutral-50"),
        shipStrokeColor: root.getPropertyValue("--sl-color-primary-300"),
        lidFillColor: root.getPropertyValue("--sl-color-primary-200"),
        lidTextColor: root.getPropertyValue("--sl-color-primary-400"),
        warningColor: root.getPropertyValue("--sl-color-warning-700"),
      },
      bayNumbersDoubleClickAction,
    });

    card.appendChild(deckViewSvg);

    if (
      missingImportantXcgs &&
      (lcgVcgTcgAndPairings.cgsStats.definedBottomBase > 0 ||
        lcgVcgTcgAndPairings.cgsStats.definedLcgs > 0 ||
        lcgVcgTcgAndPairings.cgsStats.definedTcgs > 0)
    ) {
      const note = document.createElement("sl-badge");
      note.variant = "warning";
      note.pill = true;
      note.innerHTML = getTranslation("view:errors.missingXcgs");
      card.appendChild(note);
    }
  } catch (e) {
    console.error(e);
  }

  return card;
}
