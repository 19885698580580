export function sanitizeString(str: string | undefined): string {
  if (!str) return "";
  return str
    .toLowerCase()
    .replace(/[^a-z0-9áéíóúñü.,_-\s]/gim, "")
    .replace(/\s/gm, "-")
    .replace(/\./gm, "-")
    .replace(/--/gm, "-")
    .replace(/--/gm, "-")
    .trim();
}

export function getFilenameParts(str: string) {
  const pathParts = str.split("/");
  const filename = pathParts.pop();
  const path = pathParts.join("/");
  const nameParts = filename?.split(".");
  let extension: string | undefined = undefined;
  let name = "";

  if (nameParts && nameParts.length) {
    if (nameParts.length > 1) {
      extension = nameParts.pop();
      name = nameParts.join(".");
    } else {
      name = filename ?? "";
    }
  }

  return {
    full: str,
    path,
    filename,
    name,
    extension,
  };
}

export type IGetFileName = ReturnType<typeof getFilenameParts>;

/**
 * Returns a formatted date in the current timezone
 * @param d
 * @returns string YYYY-MM-DD HH:MM:SS
 */
export function formatDateInCurrentTimezone(
  d: Date | undefined | null | string,
  omitTime = false,
) {
  if (!d) return "";

  const dd = typeof d === "string" ? new Date(d) : d;
  const offset = dd.getTimezoneOffset();
  const localDate = new Date(dd.getTime() - offset * 60 * 1000);
  const parts = localDate.toISOString().split("T");

  if (omitTime) return parts[0];
  else return parts[0] + " " + parts[1].split(".")[0];
}
