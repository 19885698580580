import { ISlotData, TContainerLengths } from "open-vessel-definition";

import { ISlotPattern } from "../types/IPositionPatterns";

export function getSizesFromSlots(
  perSlotInfo:
    | {
        [key: ISlotPattern]: ISlotData;
      }
    | undefined,
): TContainerLengths[] {
  if (!perSlotInfo) return [];

  const slotKeys = Object.keys(perSlotInfo) as ISlotPattern[];
  const allSizes = new Set<TContainerLengths>();

  slotKeys.forEach((key) => {
    const perSlotSizes = perSlotInfo[key].sizes;
    if (perSlotSizes) {
      (Object.keys(perSlotSizes) as unknown as TContainerLengths[]).forEach(
        (s) => allSizes.add(Number(s) as TContainerLengths),
      );
    }
  });

  return Array.from(allSizes) as TContainerLengths[];
}

export function getSizesFromSlotDataArray(
  slotsData: ISlotData[],
): TContainerLengths[] {
  if (!slotsData || slotsData.length === 0) return [];

  const allSizes = new Set<TContainerLengths>();

  slotsData.forEach((slotData) => {
    const perSlotSizes = slotData.sizes;
    if (perSlotSizes) {
      (Object.keys(perSlotSizes) as unknown as TContainerLengths[]).forEach(
        (s) => allSizes.add(Number(s) as TContainerLengths),
      );
    }
  });

  return Array.from(allSizes) as TContainerLengths[];
}
