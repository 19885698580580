import {
  ITedivoShoelaceSelectOptions,
  SelectShoelace,
  createSelectShoelace,
} from "@baplie-viewer2/tedivo-form";
import {
  createDictionary,
  formatDateInCurrentTimezone,
  safeDateString,
} from "@baplie-viewer2/tedivo-pure-helpers";

import { IFileVersion } from "@baplie-viewer2/tedivo-api-models";
import { IResponseModel } from "../../app/services/HttpClient";
import { getTranslation } from "../../app/i18/i18tn";

export function createVersionsDropdown({
  selectedVersion,
  addCurrentVersion,
  versionsResponse,
  onChange,
}: {
  selectedVersion: string;
  addCurrentVersion: boolean;
  versionsResponse: IResponseModel<IFileVersion[]> | undefined;
  onChange: (
    value: string,
    oldValue: string | undefined,
    select: SelectShoelace<string>,
  ) => void;
}) {
  if (!versionsResponse || versionsResponse.statusCode !== 200)
    return undefined;

  const versionsByV = createDictionary(versionsResponse.data || [], (v) =>
    safeDateString(v.date),
  );

  const options = [
    addCurrentVersion
      ? { name: getTranslation("view:comparer.currentVersion"), value: "" }
      : undefined,
    ...(versionsResponse.data || []).map((v) => ({
      name: labelOfVersion(v.date, v.vNumber),
      value: safeDateString(v.date),
    })),
  ].filter(Boolean) as ITedivoShoelaceSelectOptions[];

  const select = createSelectShoelace({
    id: "versions",
    caret: true,
    ommitCheckSign: false,
    variant: "primary",
    size: "small",
    outlined: true,
    buttonText: labelOfVersion(
      selectedVersion,
      versionsByV[selectedVersion]?.vNumber || 0,
    ),
    iconPrefix: "calendar3-week",
    options,
    onChange,
  });

  if (selectedVersion) select.value = safeDateString(selectedVersion);

  return select;
}

function labelOfVersion(dt: string | Date, vNumber: number) {
  return `v${vNumber} - ${formatDateInCurrentTimezone(dt)}`;
}
