import { AttributeValue } from "@aws-sdk/client-dynamodb/dist-types/models";
import { safeDateType } from "../helpers/safeDates";

export enum FileNoteType {
  MANUAL = 0,
  PUBLISHED = 1,
}

export interface IFileNote {
  organizationId: string;
  fileId: string;
  userSub: string;
  email: string;
  date: Date;
  note: string;
  type: FileNoteType;
}

export interface IFileNoteDBItem
  extends Partial<Record<keyof Omit<IFileNote, "fileId">, AttributeValue>> {
  organizationId: { S: string };
  sk: { S: string }; // fileId#date#type
  userSub: { S: string };
  email: { S: string };
  note: { S: string };
}

export function convertIFileNoteDBItemToIFileNote(
  inp: IFileNoteDBItem,
): IFileNote {
  const [fileId, date, typeStr] = inp.sk.S.split("#");

  const note: IFileNote = {
    organizationId: inp.organizationId.S,
    fileId,
    date: safeDateType(date),
    userSub: inp.userSub.S,
    email: inp.email.S,
    note: inp.note.S,
    type: parseInt(typeStr, 10),
  };
  return note;
}

export function convertIFileNoteToIFileNoteDBItem(
  inp: IFileNote,
): IFileNoteDBItem {
  const noteDb: IFileNoteDBItem = {
    organizationId: { S: inp.organizationId },
    sk: { S: `${inp.fileId}#${inp.date.toISOString()}#${inp.type}` },
    userSub: { S: inp.userSub },
    email: { S: inp.email },
    note: { S: inp.note },
  };
  return noteDb;
}
