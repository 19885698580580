import HttpClient, { IResponseModel } from "../HttpClient";

import { INotification } from "@baplie-viewer2/tedivo-api-models";

const notificationsBaseUrl = "notifications";

const NotificationsService = {
  getAll: async (): Promise<IResponseModel<INotification[]>> => {
    const client = new HttpClient();
    return await client.request<INotification[]>({
      method: "GET",
      url: notificationsBaseUrl,
    });
  },

  getAllCountOnly: async (): Promise<IResponseModel<number>> => {
    const client = new HttpClient();
    return await client.request<number>({
      method: "GET",
      url: "notificationsCount",
    });
  },
};

export default NotificationsService;
