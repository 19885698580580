import {
  BayLevelEnum,
  ForeAftEnum,
  IBayLevelData,
} from "open-vessel-definition";

import { IBayPattern } from "@baplie-viewer2/tedivo-bay-grid-core";
import { IJoinedRowTierPattern } from "open-vessel-definition/build/src/models/base/types/IPositionPatterns";
import { sortByMultipleFields } from "@baplie-viewer2/tedivo-pure-helpers";

export function getAllThePairedBays(
  bls: IBayLevelData[],
  checkOnly40s: boolean,
): IGetPairedBaysOutput {
  const levels = [BayLevelEnum.ABOVE, BayLevelEnum.BELOW];
  const pairedBays: {
    base: IBayPattern;
    paired: IBayPattern;
    level: BayLevelEnum;
  }[] = [];

  const unpairedBays: {
    base: IBayPattern;
    level: BayLevelEnum;
  }[] = [];

  levels.forEach((level) => {
    const bays = bls
      .filter((a) => a.level === level)
      .sort(sortByMultipleFields([{ name: "isoBay", ascending: true }]));

    for (let i = 0; i < bays.length; i++) {
      const bay = bays[i];

      let has40 = true;

      if (checkOnly40s) {
        has40 = (
          Object.keys(bay.perSlotInfo || {}) as IJoinedRowTierPattern[]
        ).some((slot) =>
          Object.keys(bay.perSlotInfo?.[slot]?.sizes || {})
            .map(Number)
            .some((size) => size >= 40),
        );
      }

      if (bay.pairedBay === ForeAftEnum.AFT) {
        const nextBay = bays[i + 1];
        if (nextBay && nextBay.pairedBay === ForeAftEnum.FWD) {
          pairedBays.push({ base: bay.isoBay, paired: nextBay.isoBay, level });
          i++;
        } else {
          if (has40 || !checkOnly40s)
            unpairedBays.push({ base: bay.isoBay, level });
        }
      } else {
        if (has40 || !checkOnly40s)
          unpairedBays.push({ base: bay.isoBay, level });
      }
    }
  });

  return { pairedBays, unpairedBays };
}

export interface IGetPairedBaysOutput {
  pairedBays: {
    base: IBayPattern;
    paired: IBayPattern;
    level: BayLevelEnum;
  }[];
  unpairedBays: IUnpairedBays[];
}

type IUnpairedBays = {
  base: IBayPattern;
  level: BayLevelEnum;
};
