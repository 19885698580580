import {
  FileElement,
  TedivoForm,
  translateTedivoForm,
} from "@baplie-viewer2/tedivo-form";
import {
  IFile,
  IMarineTrafficSearchByImoData,
} from "@baplie-viewer2/tedivo-api-models";
import {
  LcgReferenceEnum,
  ValuesSourceRowTierEnum,
} from "open-vessel-definition";
import { createFormFields, createFormValidator } from "./importStafFields";
import {
  getFilenameParts,
  roundDec,
  sanitizeString,
} from "@baplie-viewer2/tedivo-pure-helpers";
import { getTranslation, i18nReactive } from "../../../app/i18/i18tn";

import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import ICustomError from "../../types/ICustomError";
import { IViewHistoryState } from "../open-json/open-json.component";
import { ImoFoundResultEnum } from "./ImoFoundResultEnum";
import IntegratedDialogError from "../../common/IntegratedDialogError";
import LenUnitsEnum from "../../../app/enums/LenUnitsEnum";
import Services from "../../../app/services";
import SlButton from "@shoelace-style/shoelace/dist/components/button/button.component";
import SlInput from "@shoelace-style/shoelace/dist/components/input/input";
import VcgVerticalReferenceEnum from "../../../app/enums/VcgVerticalReferenceEnum";
import { createFoundImoDialog } from "./createFoundImoDialog";
import { createNotFoundImoDialog } from "./createNotFoundImoDialog";
import { focusAndSelect } from "./focusAndSelect";
import { getBayLcgVcgTcgAndPairings } from "@baplie-viewer2/tedivo-bay-grid-core";
import globalStore from "../../../app/stores/globalStore";
import globalUnits from "../../../app/units/globalUnits";
import goSquared from "../../../app/tracking/goSquared";
import { routeFns } from "../../../app/router/routes";
import router from "../../../app/router";
import securityModule from "../../../app/security/SecurityModule";
import { setAppTitle } from "../../../app/app.element";
import { splitNameForMainNames } from "../../../app/services/import-staf/stafFileConvertToOVD";
import topMessageElement from "../../layout/top-tools/getTopMessageElement";

export class TVDImportStafComponent extends HTMLElement {
  public static observedAttributes = [];

  private i18nCR: I18nComponentRegisterer;
  private dialogError: IntegratedDialogError = new IntegratedDialogError(this);
  private submitButton: SlButton | null = null;

  private ownFiles: { lastUpdated: number; list: IFile[] } = {
    lastUpdated: 0,
    list: [],
  };

  private lppFieldsState: TLppFieldsState = {
    formWithLpp: false,
    numberOfDefinedCgs: 0,
    numberOfCgsNeeded: 0,
    lppFromApi: 0,
    name: "",
    shipName: "",
    shipClass: "",
  };

  private readyToSubmit = false;

  private internalMatch: IMarineTrafficSearchByImoData | undefined = undefined;

  private imoApiState: ImoFoundResultEnum = ImoFoundResultEnum.INITIALIZED;

  constructor() {
    super();
    this.i18nCR = new I18nComponentRegisterer(i18nReactive);

    setAppTitle(getTranslation("menu:fileImportStaf"));
  }

  connectedCallback() {
    if (securityModule.planIsReadOnly) {
      router.navigate(routeFns.myCloud());
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const me = this;
    goSquared.trackPage("Import-STAF");
    goSquared.addEvent("Import-STAF - Show page");

    const wrapper = document.createElement("div"),
      h1 = document.createElement("h1");

    const box = document.createElement("div");
    box.className = "sections-oss-card";

    const submitButton = document.createElement("sl-button");
    submitButton.type = "submit";
    submitButton.innerHTML = getTranslation("general:common.submit");
    submitButton.variant = "primary";
    submitButton.disabled = true;
    this.submitButton = submitButton;

    const nextButton = document.createElement("sl-button");
    nextButton.type = "button";
    nextButton.innerHTML = getTranslation("general:common.next");
    nextButton.outline = true;
    nextButton.variant = "primary";

    const tedivoForm = new TedivoForm<IStafImportFields>({
      fields: createFormFields({ imoCode: "" }),
      onSubmit: this.onSubmitDataForm,
      formValidator: createFormValidator(),
      submitButton: submitButton,
    });

    const loadingBlocker = document.createElement("loading-blocker-element");
    this.appendChild(loadingBlocker);

    translateTedivoForm<IStafImportFields>({
      tedivoForm,
      i18nCR: this.i18nCR,
    });

    tedivoForm.onDataChange = async (
      values: IStafImportFields,
      name?: keyof IStafImportFields,
    ) => {
      globalStore.touchLastUserInteraction();
      const fileInput = values.fileInput;
      let fnameParts: ReturnType<typeof getFilenameParts> | undefined;

      // This part is used to augment fields (LPP) when needed (LGCRef !== AftPerp)
      switch (name) {
        case "fileInput":
          if (fileInput === undefined) return;

          fnameParts = getFilenameParts((values.fileInput as File).name);

          this.lppFieldsState.name = fnameParts.filename;
          this.lppFieldsState.formWithLpp = false;
          this.lppFieldsState.numberOfDefinedCgs = 0;
          this.lppFieldsState.numberOfCgsNeeded = 0;

          if (fnameParts.name === "") {
            this.showError(
              undefined,
              "general:common.attention",
              "errors:fileNameInvalid",
            );
            (
              tedivoForm.getFormControlsByName().fileInput.field as FileElement
            ).reset();
            return;
          }

          Services.importStaf
            .stafFileReadShipData(fileInput)
            .then(async (shipData) => {
              const formWithLpp: boolean =
                shipData.lcgOptions.reference !==
                LcgReferenceEnum.AFT_PERPENDICULAR;

              /** This is only to calculate existing CGs */
              const jsonTemporary =
                await Services.importStaf.stafFileConvertToOVD(
                  fileInput,
                  0,
                  1,
                  82,
                );

              const { cgsStats } = getBayLcgVcgTcgAndPairings(
                jsonTemporary.baysData,
                jsonTemporary.shipData.masterCGs,
              );

              const numberOfCgsNeeded =
                cgsStats.countLcgs +
                cgsStats.countBottomBase +
                cgsStats.countTcgs;

              const numberOfDefinedCgs =
                cgsStats.definedLcgs +
                cgsStats.definedBottomBase +
                cgsStats.definedTcgs;

              this.lppFieldsState.formWithLpp = formWithLpp;
              this.lppFieldsState.numberOfCgsNeeded = numberOfCgsNeeded;
              this.lppFieldsState.numberOfDefinedCgs = numberOfDefinedCgs;

              console.info(
                `"${this.lppFieldsState.name}" STAF file summary:\nLGC Ref: ${
                  LcgReferenceEnum[shipData.lcgOptions.reference]
                } / VCG Ref: ${
                  ValuesSourceRowTierEnum[shipData.vcgOptions.values]
                } / CGs: ${numberOfDefinedCgs}/${numberOfCgsNeeded}: ${
                  (100 * numberOfDefinedCgs) / roundDec(numberOfCgsNeeded, 2)
                }%`,
              );

              showOtherFields(
                this.lppFieldsState,
                this.i18nCR,
                this.imoApiState,
              );

              if (this.internalMatch) {
                const match = this.internalMatch;
                tedivoForm
                  .setValue("shipName", match.SHIPNAME)
                  .setValue("callSign", match.CALLSIGN || "N/A")
                  .setValue("yearBuilt", Number(match.YEAR_BUILT ?? 0));
              }
            })
            .catch((e) => {
              this.showError(e);
              console.error(e);
              (
                tedivoForm.getFormControlsByName().fileInput
                  .field as FileElement
              ).reset();
            });
          break;
        case "imoCode":
          if (values.imoCode) {
            loadingBlocker.show();

            const imoService = this.lppFieldsState.formWithLpp
              ? Services.marineTrafficApi.searchVesselByImoCodeWithParticulars
              : Services.marineTrafficApi.searchVesselByImoCode;

            const [nameInfo, searchResults] = await Promise.all([
              imoService(values.imoCode),
              Services.vesselSearch.search(`'${values.imoCode}'`, ["imoCode"]),
            ]);

            const tvlResults =
              searchResults?.data?.filter(
                (r) =>
                  r.item.organizationId !==
                  securityModule.currentOrganizationId,
              ) || [];

            loadingBlocker.hide();

            if (nameInfo?.data?.source === "error") {
              if (tedivoForm.execValidation(["imoCode", "fileInput"])) {
                this.imoApiState = ImoFoundResultEnum.NOT_FOUND_CONTINUE;
                showOtherFields(
                  this.lppFieldsState,
                  this.i18nCR,
                  this.imoApiState,
                );
              }
            }

            const match = nameInfo?.data?.match;
            this.internalMatch = match;

            if (!match) {
              createNotFoundImoDialog({
                parentNode: this,
                imoCode: values.imoCode || "",
                onButtonClicked: (btnSelected): void => {
                  switch (btnSelected) {
                    case "continue":
                      this.imoApiState = ImoFoundResultEnum.NOT_FOUND_CONTINUE;

                      break;
                    case "fix":
                      this.imoApiState = ImoFoundResultEnum.NOT_FOUND_FIX;
                      setTimeout(() => {
                        focusAndSelect(
                          tedivoForm.getFormControlsByName().imoCode
                            .field as SlInput,
                        );
                      }, 150);
                      break;
                  }

                  if (tedivoForm.execValidation(["imoCode", "fileInput"])) {
                    showOtherFields(
                      this.lppFieldsState,
                      this.i18nCR,
                      this.imoApiState,
                    );
                  }
                },
              });
            } else {
              createFoundImoDialog({
                parentNode: this,
                imoCode: values.imoCode || "",
                btnSeeTvlSearchResults: tvlResults.length > 0,
                lastFetched: String(match.modifiedAt),
                shipName: match.SHIPNAME,
                onButtonClicked: (btnSelected): void => {
                  switch (btnSelected) {
                    case "continue":
                      this.imoApiState = ImoFoundResultEnum.FOUND_CONTINUE;
                      this.lppFieldsState.shipName = match.SHIPNAME;
                      this.lppFieldsState.lppFromApi =
                        Number(
                          match.particularsJson?.LENGTH_B_W_PERPENDICULARS || 0,
                        ) * 1000;
                      this.lppFieldsState.loaFromApi =
                        Number(match.particularsJson?.LENGTH_OVERALL || 0) *
                        1000;

                      // Show
                      if (tedivoForm.execValidation(["imoCode", "fileInput"])) {
                        showOtherFields(
                          this.lppFieldsState,
                          this.i18nCR,
                          this.imoApiState,
                        );
                      }

                      // Set fields
                      tedivoForm
                        .setValue("shipName", match.SHIPNAME)
                        .setValue("callSign", match.CALLSIGN || "N/A")
                        .setValue("yearBuilt", Number(match.YEAR_BUILT ?? 0))
                        .setValue("lpp", this.lppFieldsState.lppFromApi)
                        .setValue("loa", this.lppFieldsState.loaFromApi);

                      break;
                    case "fix":
                      this.imoApiState = ImoFoundResultEnum.FOUND_FIX_IMO;
                      if (tedivoForm.execValidation(["imoCode", "fileInput"])) {
                        showOtherFields(
                          this.lppFieldsState,
                          this.i18nCR,
                          this.imoApiState,
                        );
                      }

                      focusAndSelect(
                        tedivoForm.getFormControlsByName().imoCode
                          .field as SlInput,
                      );

                      break;
                    case "doubleCheck":
                      this.imoApiState = ImoFoundResultEnum.FOUND_MANUAL_SET;

                      Services.marineTrafficApi
                        .searchVesselByImoCode(values.imoCode || "", true)
                        .then((doucleCheckNameInfo) => {
                          const newMatch = doucleCheckNameInfo?.data?.match;
                          if (newMatch) {
                            this.lppFieldsState.shipName = newMatch.SHIPNAME;
                            tedivoForm.setValue("shipName", newMatch.SHIPNAME);
                          }
                        });
                      break;
                    case "seeSearchResults":
                      router.navigate(routeFns.communityFiles(), {
                        searchText: values.imoCode,
                        fields: ["imoCode"],
                        data: searchResults.data,
                      });
                      break;
                  }
                },
              });
            }
          }

          break;
        case "removeCGs":
          const fields = tedivoForm.getFormControlsByName();
          const disabledValue = values.removeCGs ? "true" : "false";

          fields.lpp?.field.setAttribute("disabled", disabledValue);
          fields.loa?.field.setAttribute("disabled", disabledValue);
      }

      function showOtherFields(
        lppFieldsState: TLppFieldsState,
        i18nCR: I18nComponentRegisterer,
        imoApiState: ImoFoundResultEnum,
      ) {
        const { formWithLpp, numberOfDefinedCgs, name, shipClass, shipName } =
          lppFieldsState;

        const doContinue =
          imoApiState === ImoFoundResultEnum.FOUND_CONTINUE ||
          imoApiState === ImoFoundResultEnum.FOUND_MANUAL_SET ||
          imoApiState === ImoFoundResultEnum.NOT_FOUND_CONTINUE;

        if (!doContinue) return;

        me.readyToSubmit = true;

        const nameFields = splitNameForMainNames(name || "", shipClass || "");
        if (shipName) nameFields.shipName = shipName;

        const newFields = createFormFields({
          imoCode: values.imoCode || "",
          fileLabel: name,
          withLpp: formWithLpp && numberOfDefinedCgs > 0,
          withNameFields: nameFields,
        });

        tedivoForm.setFields(newFields).setFormValidator(
          createFormValidator({
            withLpp: formWithLpp && numberOfDefinedCgs > 0,
            withNameFields: true,
          }),
        );

        const divButtons = document.createElement("div");
        divButtons.className = "form-buttons";
        divButtons.appendChild(submitButton);
        tedivoForm.form.appendChild(divButtons);

        translateTedivoForm<IStafImportFields>({
          tedivoForm,
          i18nCR,
        });

        submitButton.disabled = false;
      }
    };

    box.appendChild(tedivoForm.form);

    const divButtons = document.createElement("div");
    divButtons.className = "form-buttons";
    divButtons.appendChild(nextButton);
    tedivoForm.form.appendChild(divButtons);

    wrapper.appendChild(h1);
    wrapper.appendChild(box);
    this.appendChild(wrapper);

    // // Translations
    const i18nCR = this.i18nCR;

    i18nCR.addConsumer(h1, "menu:fileImportStaf", "innerHTML");
    i18nCR.addConsumer(
      topMessageElement.element,
      "menu:fileImportStaf",
      "innerHTML",
    );

    nextButton.addEventListener("click", () => {
      tedivoForm.onDataChange?.(tedivoForm.getValues(), "imoCode");
    });

    document.documentElement.addEventListener("globalUnitsChanged", () => {
      const lppField = tedivoForm.getFormControlsByName().lpp;
      if (lppField)
        i18nCR.addConsumer(
          lppField.field,
          `enums:LenUnitsEnum.${LenUnitsEnum[globalUnits.lengthUnits.units]}`,
          "helpText",
        );
    });
  }

  disconnectedCallback() {
    this.i18nCR.disconnect();
  }

  onSubmitDataForm = async (values: IStafImportFields) => {
    globalStore.touchLastUserInteraction();

    if (!this.readyToSubmit) return;

    if (this.submitButton) {
      this.submitButton.loading = true;
      this.submitButton.disabled = true;
    }

    const file = values.fileInput as File;
    const filenameParts = getFilenameParts(file.name);
    const name = sanitizeString(filenameParts.name);

    const viewState: IViewHistoryState = {
      file,
      filenameParts,
      source: "stafText",
      formFields: {
        lpp: Number((values.lpp || this.lppFieldsState.lppFromApi) ?? 0),
        loa: Number((values.loa || this.lppFieldsState.loaFromApi) ?? 0),
        shipClass: values.shipClass || "",
        shipName: values.shipName || "",
        lineOperator: values.lineOperator || "",
        imoCode: values.imoCode || "",
        callSign: values.callSign,
        yearBuilt: values.yearBuilt,
        vcgRef: values.vcgRef || VcgVerticalReferenceEnum.VCG_45_8_6,
        aboveTier82is: values.aboveTier82is || 82,
        removeCGs: values.removeCGs || false,
      },
    };

    await this.getOwnFiles();

    if (this.ownFiles.list.some(({ name }) => name === values.shipName)) {
      this.showError(
        undefined,
        "general:common.attention",
        "errors:fileWithSameNameExists",
      );
    } else {
      goSquared.addEvent("Import-STAF - Exec");
      router.navigate(routeFns.ovdEdit(name), viewState);
    }

    if (this.submitButton) {
      this.submitButton.loading = false;
      this.submitButton.disabled = false;
    }
  };

  showError = (
    e: ICustomError | undefined,
    errorTlt = "errors:errorHasOcurred",
    errorStr = "errors:notValidStafFile",
  ) => {
    this.dialogError.show(getTranslation(errorTlt), getTranslation(errorStr));
  };

  private async getOwnFiles() {
    const lastUpdated = this.ownFiles.lastUpdated;

    //Fetch if first-time or cache is > 5s
    if (lastUpdated === 0 || Date.now() - lastUpdated > 5000) {
      const data = await Services.files.getAll();
      if (data.statusCode === 200 && data.data !== undefined) {
        this.ownFiles.list = data.data;
        this.ownFiles.lastUpdated = Date.now();
      }
    }
  }
}

customElements.define("tvd-import-staf-component", TVDImportStafComponent);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-import-staf-component": TVDImportStafComponent;
  }
}

export interface IStafImportFields extends Record<string, unknown> {
  fileInput?: File;
  lpp?: number;
  loa?: number;
  lineOperator?: string;
  shipClass?: string;
  shipName?: string;
  callSign?: string;
  yearBuilt?: number;
  vcgRef?: number;
  aboveTier82is?: number;
  imoCode?: string;
  removeCGs?: boolean;
}

type TLppFieldsState = {
  formWithLpp: boolean;
  numberOfDefinedCgs: number;
  numberOfCgsNeeded: number;
  name?: string;
  shipName?: string;
  shipClass?: string;
  lppFromApi?: number;
  loaFromApi?: number;
  yearBuilt?: number;
  callSign?: string;
};
