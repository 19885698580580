export class TfHidden extends HTMLElement {
    constructor() {
        super();
        this.internalValue = undefined;
        this.internalLabel = "";
        this.internalHelpText = undefined;
        this.internalId = "";
        this.isNumeric = false;
        this.elInput = document.createElement("input");
        this.elInput.type = "hidden";
    }
    get value() {
        return this.isNumeric
            ? Number(this.internalValue)
            : String(this.internalValue);
    }
    set value(s) {
        this.internalValue = s;
    }
    get label() {
        return this.internalLabel || "";
    }
    set label(v) {
        this.internalLabel = v;
    }
    get helpText() {
        return this.internalHelpText || "";
    }
    set helpText(v) {
        this.internalHelpText = v;
    }
    get name() {
        return this.internalId;
    }
    set name(s) {
        this.internalId = s;
    }
    get size() {
        return "medium";
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    set size(v) { }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    focus() { }
    connectedCallback() {
        const shadow = this.attachShadow({ mode: "open" });
        shadow.appendChild(this.elInput);
    }
}
TfHidden.observedAttributes = ["class"];
customElements.define("tf-hidden", TfHidden);
