import {
  IAuthVerifyEmail,
  IAuthVerifyEmailOutput,
} from "../../../../app/services/accounts/accounts";
import { getTranslation, i18nReactive } from "../../../../app/i18/i18tn";

import DivWithSpinner from "../../../common/divWithSpinner/div-with-spinner-element";
import { I18nComponentRegisterer } from "@baplie-viewer2/tedivo-i18";
import { IFormAndNode } from "../../../helpers/createScreen";
import Services from "../../../../app/services";
import goSquared from "../../../../app/tracking/goSquared";
import { removeChildren } from "@baplie-viewer2/tedivo-dom-helpers";
import { routeFns } from "../../../../app/router/routes";
import router from "../../../../app/router";
import securityModule from "../../../../app/security/SecurityModule";
import { setAppTitle } from "../../../../app/app.element";

export class TVDAuthVerifyAddressComponent extends HTMLElement {
  public static observedAttributes = [];

  private i18nCR: I18nComponentRegisterer;
  private divWithSpinner: DivWithSpinner;
  private currentFormAndNode?: IFormAndNode<IEmpty>;

  constructor() {
    super();
    this.i18nCR = new I18nComponentRegisterer(i18nReactive);
    this.divWithSpinner = document.createElement("div-spinner-element");

    setAppTitle(getTranslation("general:organizations.createNewAccount"));
  }

  connectedCallback() {
    if (securityModule.isLoggedIn) {
      const div = document.createElement("div");
      div.innerHTML = getTranslation("auth:logoutFirst", {
        name: securityModule.name,
      });
      this.appendChild(div);
      return;
    }

    goSquared.trackPage("Verify Address");

    this.appendChild(this.divWithSpinner);
    this.divWithSpinner.setLoading(true);
    const queryString = router.getRouteQuerystring();

    if (queryString["hash"] && queryString["email"]) {
      this.processVerification({
        hash: queryString["hash"],
        email: queryString["email"],
      })
        .then((resp) => {
          this.setCurrentFormAndNode(
            showResponse(resp.isVerified, resp.password, queryString["email"]),
          );
          this.divWithSpinner.setLoading(false);
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      this.setCurrentFormAndNode(showResponse(false, undefined, undefined));
      this.divWithSpinner.setLoading(false);
    }
  }

  /** Replaces the UI */
  private setCurrentFormAndNode(fan: typeof this.currentFormAndNode) {
    this.currentFormAndNode = fan;

    if (fan?.node) {
      this.divWithSpinner.setLoading(false);
      removeChildren(this.divWithSpinner);
      this.divWithSpinner.appendChild(fan.node);
    }
  }

  private processVerification = async (
    values: IAuthVerifyEmail,
  ): Promise<IAuthVerifyEmailOutput> => {
    const resp = await Services.accounts.verifyEmail(values);
    return {
      isVerified: !!resp.data?.isVerified,
      password: resp.data?.password,
    };
  };
}

customElements.define(
  "tvd-auth-verify-address-component",
  TVDAuthVerifyAddressComponent,
);

declare global {
  interface HTMLElementTagNameMap {
    "tvd-auth-verify-address-component": TVDAuthVerifyAddressComponent;
  }
}

interface IEmpty {
  id?: string;
}

function showResponse(
  isOk: boolean,
  password: string | undefined,
  email: string | undefined,
) {
  if (isOk) {
    const viewState = { email, password, fromVerification: true };
    router.navigate(routeFns.login(), viewState, true);
    return;
  }

  const h1 = document.createElement("h1");
  const wrapper = document.createElement("div");

  const box = document.createElement("div");
  box.className = "oss-card";

  box.innerHTML = getTranslation("general:organizations.newAccountFailed");
  h1.innerHTML = getTranslation("general:organizations.verifyEmail");

  wrapper.appendChild(h1);
  wrapper.appendChild(box);

  return {
    node: wrapper,
    form: undefined,
  };
}
