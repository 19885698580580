import { IFile, systemRights } from "@baplie-viewer2/tedivo-api-models";

import { IViewHistoryState } from "../../components/features/open-json/open-json.component";
import goSquared from "../tracking/goSquared";
import { openOvdInTvl } from "./openOvdInTvl";
import { routeFns } from "../router/routes";
import router from "../router";
import securityModule from "../security/SecurityModule";

export function openOvdInTvd(
  dta: Pick<IFile, "organizationId" | "organizationName" | "fileId" | "name">,
  tvlDomain: string | undefined,
) {
  if (tvlDomain) {
    openOvdInTvl(dta.fileId, tvlDomain);
  }

  if (!securityModule.userHasPermission(systemRights.ORG.CanVotePublicFiles)) {
    // Limited view (inside an iframe)
    router.navigate(routeFns.viewTvl(dta.organizationId, dta.fileId), {
      vesselName: dta.name,
    });
  } else {
    // Full view Read-only
    const viewState: IViewHistoryState = {
      source: "cloudOvdJson",
      cloudId: dta.fileId,
      organizationId: dta.organizationId,
      organizationName: dta.organizationName,
      impersonate: true,
    };

    goSquared.addEvent("Community - View public file");
    router.navigate(routeFns.ovdViewOnly(dta.fileId), viewState);
  }
}
