import { TPasswordCompliesWithCriteria } from "./TPasswordCompliesWithCriteria";

/**
 * Returns an array of errors. If the array is empty, the password complies with the criteria.
 * @param password
 * @returns Array<TPasswordCompliesWithCriteria> "minLength" | "minNumber" | "minSpecialChar" | "minUpperCase" | "minLowerCase"
 */

export function passwordCompliesWithCriteria(
  password: string,
): Array<TPasswordCompliesWithCriteria> {
  const result: Array<TPasswordCompliesWithCriteria> = [];
  // MinimumLength: 8
  if (password.length < 8 || password.length > 99) result.push("minLength");
  // RequireLowercase: true
  if (password.match(/[a-z]/) === null) result.push("minLowerCase");
  // RequireNumbers: true
  if (password.match(/[0-9]/) === null) result.push("minNumber");
  // RequireSymbols: true
  if (password.match(/[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+-]/) === null)
    result.push("minSpecialChar");
  // RequireUppercase: true
  if (password.match(/[A-Z]/) === null) result.push("minUpperCase");

  return result;
}
